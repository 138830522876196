import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Bars } from "react-loader-spinner";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { getAnalyticsEnabled } from "../../helpers/backend_helper";

import {
  clearPackageDetails,
  getPackageDetails,
} from "../../store/details/actions";
import "./Details.scss";
import ImageGallery from "./ImageGallery";
import ImageGalleryPopup from "./ImageGalleryPopup";
import RightContainer from "./RightContainer";
import LeftContainer from "./LeftContainer";
import { formatMoney } from "../../helpers/utils";
import { Helmet } from "react-helmet";
import { createCustomerProfile } from "../../store/customer/actions";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../helpers/firebase";
import ItineraryStays from "../customItinerary/ItineraryStays";
import itineraryPlanStore from "../../store/itineraryPlanStore";
import ValidationComponent from "../validationComponent/ValidationComponent";
import { toJS } from "mobx";

interface CustomizedState {
  startDate: string;
  endDate: string;
  noOfRooms: number;
  noOfAdults: number;
  noOfChilds: number;
  couponCode: string;
}

function Details() {
  const screenWidth = window.innerWidth;
  const prevScrollY = React.useRef(0);
  const THRESHOLD = 0;
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  let params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const state = location.state as CustomizedState;

  const [selectedRoomType, setSelectedRoomType] = useState<any>("");
  const [showImageGalleryPopup, setShowImageGalleryPopup] =
    useState<any>(false);
  const [urlCouponCode, setUrlCouponCode] = useState<any>("");
  const [navData, setNavData] = useState<any>({ title: "", link: "" });
  const [showError, setShowError] = useState<any>(false);
  const [showLoader, setShowLoader] = useState<any>(true);
  const [loaderForPropertyChange, setLoaderForPropertyChange] =
    useState<any>(false);

  const [showBookNow, setShowBookNow] = useState(true);
  const guestDetailsRef = useRef<HTMLDivElement>(null);
  const [scrollDirection, setScrollDirection] = React.useState("");
  const { roomDetails } = useSelector((state: any) => state.RoomDetailReducer);

  const [metaTags, setMetaTags] = useState<any[]>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);

  const [packageIdentifier, setPackageIdentifier] = useState("");

  const [openValidationComponent, setOpenValidationComponent] = useState(false);

  const isAnalyticsEnabled = getAnalyticsEnabled();

  const { customerProfileResponse } = useSelector(
    (state: any) => state.CustomerProfileReducer
  );

  useEffect(() => {
    prevScrollY.current = window.pageYOffset;
    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      if (Math.abs(scrollY - prevScrollY.current) >= THRESHOLD) {
        const newScrollDirection =
          scrollY > prevScrollY.current ? "down" : "up";
        if (
          scrollY < 3600 &&
          showBookNow === false &&
          scrollDirection === "up"
        ) {
          setShowBookNow(true);
        }
        setScrollDirection(newScrollDirection);
        prevScrollY.current = scrollY > 0 ? scrollY : 0;
      }
    };
    const onScroll = () => {
      window.requestAnimationFrame(updateScrollDirection);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDirection]);

  useEffect(() => {
    if (isAnalyticsEnabled) {
      ReactGA.event({
        action: `${window.location.pathname} Detail Page`,
        category: `details_page`,
      });
    }

    window.scrollTo(0, 0);
    document.title = "Details | Voyaah";
    ReactPixel.fbq("init", process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq("track", "PageView");
    const navigationData = localStorage.getItem("listNav");
    if (navigationData) {
      setNavData(JSON.parse(navigationData));
    }

    return () => {
      dispatch(clearPackageDetails());
    };
  }, []);

  useEffect(() => {
    if (customerProfileResponse != undefined) {
      const customerTracker = localStorage.getItem("voyaahCustomerTracker");
      const customerConsentStore = localStorage.getItem(
        "voyaahCustomerConsent"
      );

      if (!customerTracker) {
        const customerProfile = {
          id: customerProfileResponse,
        };

        localStorage.setItem(
          "voyaahCustomerTracker",
          JSON.stringify(customerProfile)
        );
      }

      if (!customerConsentStore) {
        const customerConsent = {
          essentialCookieEnabled: true,
          analyticsCookieEnabled: true,
          personalizationCookieEnabled: true,
        };

        localStorage.setItem(
          "voyaahCustomerConsent",
          JSON.stringify(customerConsent)
        );
      }
    }
  }, [customerProfileResponse]);

  function formatMetatagsForPackage(metaTags: any) {
    const metaTagArray: any[] = [];
    metaTags.forEach((metaTag: { tag: any; value: any }) => {
      const metaTagObject = { key: metaTag.tag, value: metaTag.value };
      metaTagArray.push(metaTagObject);
    });

    setMetaTags(metaTagArray);
  }

  function formatBreadcrumbsForPackage(breadcrumbs: any) {
    const breadcrumbsArray: any[] = [];
    breadcrumbs.forEach((breadcrumb: { name: any; url: any }) => {
      const breadcrumbObject = { name: breadcrumb.name, url: breadcrumb.url };
      breadcrumbsArray.push(breadcrumbObject);
    });

    setBreadcrumbs(breadcrumbsArray);
  }

  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);
    const trackingId = searchParams.get("tracking_id");
    if (trackingId && isAnalyticsEnabled) {
      ReactGA.event({
        action: "tracking_" + trackingId,
        category: "details_page",
        label: params.id,
      });
    }
  }, [location]);

  useEffect(() => {
    let customerTracker = localStorage.getItem("voyaahCustomerTracker");
    if (customerTracker == null && !loading) {
      const consentTracker = {
        customerProfileId: null,
        accountId: user?.uid ?? null,
        essentialConsent: true,
        analyticsConsent: true,
        personalizationConsent: true,
      };

      dispatch(createCustomerProfile(consentTracker));
    }
  }, [dispatch, loading]);

  useEffect(() => {
    const localStorageData = localStorage.getItem("voyaahCustomerTracker");
    let customerProfileId = "";
    if (localStorageData) {
      customerProfileId = JSON.parse(localStorageData!).id;
    }

    if (!loading) {
      dispatch(
        getPackageDetails(params.id, customerProfileId, user?.uid ?? "")
      );
    }
  }, [dispatch, params.id, loading]);

  useEffect(() => {
    const urlCouponCode = searchParams.get("couponCode");
    if (urlCouponCode) {
      const data = {
        packageId: params.id,
        couponCode: urlCouponCode,
      };
      setUrlCouponCode(urlCouponCode);
      localStorage.setItem("packageCouponCode", JSON.stringify(data));
    } else {
      setUrlCouponCode("");
    }
  }, [searchParams, params.id]);

  const handleBookNow = () => {
    guestDetailsRef.current?.scrollIntoView({ behavior: "smooth" });
    setShowBookNow(false);
  };

  const { packages, loader, detailsError } = useSelector(
    (state: any) => state.PackageDetailsReducer
  );

  // const { packageAvailability, packageAvailabilityLoader } = useSelector(
  //   (state: any) => state.PackageAvailabilityReducer
  // );
  const [opportunityStays, setOpportunityStays] = useState<any[]>([]);

  useEffect(() => {
    if (packages.metaTags) {
      formatMetatagsForPackage(packages.metaTags);
    }

    if (packages.id) {
      setPackageIdentifier(packages.id);
    }

    if (packages.breadcrumbs) {
      formatBreadcrumbsForPackage(packages.breadcrumbs);
    }

    if (packages.itineraryPlans) {
      setOpportunityStays(packages.itineraryPlans[0].opportunitystays);
      itineraryPlanStore.setDetailPageOpportunityStays(
        packages.itineraryPlans[0].opportunitystays
      );
    }
  }, [packages]);

  useEffect(() => {
    if (packages.itineraryPlans) {
      setOpportunityStays(itineraryPlanStore.detailPageOpportunityStays);
      setLoaderForPropertyChange(false);
    }
  }, [itineraryPlanStore.detailPageOpportunityStays]);

  useEffect(() => {
    setShowLoader(false);
    if (packages && packages.id) {
      setShowError(false);
    } else if (
      (packages && packages?.message) ||
      (detailsError && detailsError?.message)
    ) {
      setShowError(true);
    }
  }, [dispatch, packages, detailsError]);

  const updateSelectedRoomType = (data: any) => {
    setSelectedRoomType(data?.roomType?.name);
  };

  const updateShowImageGalleryPopup = (data: any) => {
    setShowImageGalleryPopup(data);
  };

  const navLinkHandle = () => {
    if (isAnalyticsEnabled) {
      ReactGA.event({
        action: `${navData.link} breadcrumb nav`,
        category: `details_page`,
      });
    }
  };

  return (
    <>
      {!showLoader && !showError && Object.keys(packages).length > 7 && (
        <>
          <div className="inner-page details-page">
            {packages && (
              <Helmet>
                {packages.keywords !== "" && (
                  <meta name="keywords" content={packages.keywords} />
                )}
                {metaTags.length > 0 &&
                  metaTags.map((metaTag, index) => {
                    return (
                      <meta property={metaTag.key} content={metaTag.value} />
                    );
                  })}
                {breadcrumbs.length > 0 && (
                  <script type="application/ld+json">
                    {`{
                      "@context": "https://schema.org",
                      "@type": "BreadcrumbList",
                      "itemListElement": ${JSON.stringify(
                        breadcrumbs.map((item, index) => ({
                          "@type": "ListItem",
                          position: index + 1,
                          name: item.name,
                          item: item.url,
                        }))
                      )}
                    }`}
                  </script>
                )}
              </Helmet>
            )}
            <div className="section">
              <div className="breadcrum">
                <ul>
                  <li>
                    <Link onClick={() => navLinkHandle()} to="/">
                      Home
                    </Link>
                  </li>
                  {navData && navData.title !== "" && (
                    <>
                      <li>›</li>
                      <li>
                        <Link
                          onClick={() => {
                            navLinkHandle();
                          }}
                          to={navData.link}
                        >
                          {navData.title}
                        </Link>
                      </li>
                    </>
                  )}
                  <li>›</li>
                  <li>{packages?.name}</li>
                </ul>
              </div>

              <div className="inner-heading holiday-buttton">
                <h1 className="head">{packages?.name}</h1>
                {navData &&
                  navData.title &&
                  navData.title.includes("Holiday") && (
                    <button
                      className="customize-btn"
                      onClick={() => {
                        if (!user) {
                          setOpenValidationComponent(true);
                        } else {
                          const propertyArr: any = [];
                          opportunityStays.forEach((stay) => {
                            if (stay?.property) {
                              propertyArr.push({ id: stay.property?.id });
                            }
                          });
                          navigate(`/custom-itinerary`, {
                            state: {
                              packageIdentifier: packageIdentifier,
                              startDate:
                                itineraryPlanStore.rightContainerData.fromDate,
                              oppStays: propertyArr,
                            },
                          });
                        }
                      }}
                    >
                      Customize this Itinerary
                    </button>
                  )}
              </div>

              <h2 className="inner-sub-head">{packages?.destination}</h2>

              {/* {screenWidth < 767 && (
                <div className="inner-sub-head lato-font">{`₹ ${formatMoney(
                  packages?.packageValues?.fare?.totalFare
                )} Onwards`}</div>
              )} */}

              <ImageGallery
                mediaCollectionData={packages.mediaCollections}
                showImageGalleryPopup={showImageGalleryPopup}
                updateShowImageGalleryPopup={updateShowImageGalleryPopup}
              />
            </div>
            {screenWidth > 767 && <div className="bottom-inner-image"></div>}
          </div>

          <div className="white-bg">
            <div className="section">
              <div className="details-content-blk">
                <LeftContainer
                  packageData={packages}
                  pdfData={packages}
                  updateSelectedRoomType={updateSelectedRoomType}
                />
                <div ref={guestDetailsRef}>
                  {/* {!packageAvailabilityLoader && ( */}
                  <RightContainer
                    packageData={packages}
                    selectedRoomType={selectedRoomType}
                    stateData={state}
                    urlCouponCode={urlCouponCode}
                    availabilityData={[]}
                  />
                  {/* )} */}
                </div>
              </div>
            </div>
          </div>
          <div
            className="blue-bg"
            style={{
              backgroundColor: "#d2f6ff",
              padding: "70px 175px 50px 175px",
              display: "flex",
              flexDirection: "column",
              gap: "35px",
            }}
          >
            <div className="pdp-stays">
              {packages.type === "packageType.Holiday" && (
                <div
                  style={{
                    fontWeight: "600px",
                    color: "#000000",
                    width: "1140px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  <h1 style={{ color: "#000", marginBottom: "10px" }}>
                    Choose your stays
                  </h1>
                  <p
                    style={{
                      color: "#000",
                      fontSize: "13px",
                      lineHeight: "5px",
                    }}
                  >
                    Design your ultimate getaway, select the hotels that
                  </p>
                  <p
                    style={{
                      color: "#000",
                      fontSize: "13px",
                      lineHeight: "5px",
                    }}
                  >
                    match your unique style and preferences
                  </p>
                </div>
              )}
              {packages.type === "packageType.Staycation" && (
                <div
                  style={{
                    fontWeight: "600px",
                    color: "#000000",
                    width: "1140px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  <h1 style={{ color: "#000", marginBottom: "10px" }}>
                    Your stay
                  </h1>
                  <p
                    style={{
                      color: "#000",
                      fontSize: "13px",
                      lineHeight: "5px",
                    }}
                  >
                    Your ultimate getaway will be at the hotel shown below
                  </p>
                </div>
              )}
              {opportunityStays && (
                <ItineraryStays
                  // itineraryPlansData={packages.itineraryPlans[0].opportunitystays}
                  // itineraryPlansData={itineraryPlanStore.detailPageOpportunityStays}
                  itineraryPlansData={opportunityStays}
                  // cityName={""}
                  // existingProperty={prop.property}
                  // roomTypes={prop.roomTypes}
                  packageId={packages.id}
                  packageType={packages.type}
                  cityName={packages.destination}
                  existingProperty={false}
                  roomTypes={[]}
                  packageLocation={""}
                  beginDate={undefined}
                  loader={setLoaderForPropertyChange}
                />
              )}
            </div>

            <div>
              <h2
                className="head"
                style={{
                  fontWeight: "600Px",
                  fontSize: "25px",
                  color: "#000000",
                }}
              >
                Terms and Conditions
              </h2>
              <span
                className="terms-and-conditions"
                style={{
                  fontWeight: "400Px",
                  fontSize: "14px",
                  color: "#000000",
                }}
                dangerouslySetInnerHTML={{
                  __html: packages?.termsAndConditions,
                }}
              />
            </div>
          </div>

          {showImageGalleryPopup && (
            <ImageGalleryPopup
              packageData={packages.mediaCollections}
              showImageGalleryPopup={showImageGalleryPopup}
              updateShowImageGalleryPopup={updateShowImageGalleryPopup}
            />
          )}
        </>
      )}

      {!showLoader && Object.keys(packages).length === 7 && (
        <div className="inner-page details-page">
          <div className="section">
            <div className="breadcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>›</li>
                <li>
                  <Link to={navData.link}>{navData.title}</Link>
                </li>
              </ul>
            </div>

            <div className="error-blk">
              <div className="head">Oops!</div>
              <div className="sub-head">
                Looks like the package {packages?.name} is no longer active.
              </div>
              <div className="content">
                <p>
                  We're sorry, the package you requested could not be loaded.
                  Please go back to the <Link to="/">homepage</Link>!
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {!showLoader && showError && (
        <div className="inner-page details-page">
          <div className="section">
            <div className="breadcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>›</li>
                <li>
                  <Link to={navData.link}>{navData.title}</Link>
                </li>
              </ul>
            </div>

            <div className="error-blk">
              <div className="image">
                <img
                  src={require("../../assets/images/404.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="head">Oops!</div>
              <div className="sub-head">Looks like something went wrong</div>
              <div className="content">
                <p>
                  We're sorry, the package you requested could not be found.
                  Please go back to the <Link to="/">homepage</Link>!
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {screenWidth <= 767 && showBookNow && (
        <div style={{ position: "fixed", bottom: 0 }}>
          {/* className="booknow-icon" */}
          <button
            onClick={handleBookNow}
            style={{
              backgroundColor: "#1c97f3",
              color: "white",
              height: "40px",
              width: "100vw",
              fontSize: "20px",
              border: "1px solid #1c97f3",
            }}
          >
            {`₹ ${formatMoney(packages?.pricing?.totalFare)} - Book now`}
          </button>
        </div>
      )}

      {(showLoader || loader || loaderForPropertyChange) && (
        <div className="loader-container">
          <div className="loader">
            <Bars color="#00BFFF" height={50} width={100} />
          </div>
        </div>
      )}

      {openValidationComponent && (
        <ValidationComponent
          closeModal={setOpenValidationComponent}
          validationMessage="You should be logged in to use this feature"
          showLoginButton={true}
          showCloseButton={true}
        />
      )}
    </>
  );
}

export default Details;
