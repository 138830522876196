import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { amenitiesIconMapping } from "src/constants/iconMapping";
import { experienceApiAxios } from "src/helpers/api_helper";
import { GET_PROPERTY_LISTING, RATELIST } from "src/helpers/url_helper";
import PropertyOverlay from "../propertyOverlay/PropertyOverlay";
import _ from "underscore";
import "./ItineraryStays.scss";
import { useDispatch, useSelector } from "react-redux";
import RoomTypesContainer from "../details/RoomTypesContainer";
import "../details/Details.scss";
import itineraryPlanStore from "src/store/itineraryPlanStore";
import { Property } from "src/models/models";
import ImageGalleryPopup from "../details/ImageGalleryPopup";
import Tooltip from "@mui/material/Tooltip";
import {
  getPackagePricing,
  clearPackagePricing,
} from "../../store/details/actions";
import moment from "moment";
import { DollarSymbol } from "src/icons/coreIcons";
import { toJS } from "mobx";

type Props = {};

const ItineraryStays = observer(
  ({
    cityName,
    existingProperty,
    roomTypes,
    packageLocation,
    beginDate,
    packageType,
    itineraryPlansData,
    packageId,
    loader,
    destinationIndex,
  }: {
    cityName: string;
    packageType: any;
    existingProperty: any;
    roomTypes: any[];
    packageLocation: string;
    beginDate: Date | undefined;
    itineraryPlansData: any[];
    packageId: any;
    loader: any;
    destinationIndex?: number;
  }) => {
    const [propertyDetails, setPropertyDetails] =
      useState<any>(existingProperty);
    const [propertyOverlay, setPropertyOverlay] = useState(false);
    const [hideDesc, sethideDesc] = useState(true);
    const [facilityLength, setfacilityLength] = useState(4);
    const [accordionArray, setaccordionArray] = useState<any[]>([]);
    const [mealPlanDesc, setmealPlanDesc] = useState<any[]>([]);
    const [rateClassDesc, setrateClassDesc] = useState<any[]>([]);
    const [selectRoomTypes, setSelectRoomTypes] = useState<any[]>([]);
    const [DynamicMealPlanList, setDynamicMealPlanList] = useState<any[]>([]);
    const [selectedCity, setSelectedCity] = useState<string>(cityName);
    const [noInventoryList, setnoInventoryList] = useState([]);
    const [selectedviewmoreid, setselectedviewmoreid] = useState("");
    const [policylists, setpolicylists] = useState<any[]>([]);
    const [selectedMealpolicy, setselectedMealpolicy] = useState<any>("");
    // const [customSelectFlag,setcustomSelectFlag] = useState(false);
    const [propertyConfig, setPropertyConfig] = useState<Property>({
      id: -1,
      dayIndexFrom: "",
      dayIndexTo: "",
      propertyId: "",
      propertyName: "",
      roomTypeId: "",
      roomTypeName: "",
      destination: "",
      cityId: 0,
      numRooms: 0,
      fromDate: "",
      toDate: "",
      notes: "",
    });
    const [imageOverLayArray, setImageOverLayArray] = useState<any[]>([]);
    const [disableSelect, setdisableSelect] = useState<any>(false);
    const [defaultRoomList, setdefaultRoomList] = useState<any>([
      { value: "", title: "Select number of rooms" },
      { value: 1, title: "1 Room" },
      { value: 2, title: "2 Rooms" },
      { value: 3, title: "3 Rooms" },
      { value: 4, title: "4 Rooms" },
    ]);
    const [selectedId, setSelectedId] = useState<any>("");
    const [selectedPropId, setSelectedPropId] = useState<any>("");
    let dispatch = useDispatch();

    const fetchPropertyDetails = async () => {
      experienceApiAxios
        .get(
          `${GET_PROPERTY_LISTING}?pageSize=1&currentPage=${1}&search=${cityName}`
        )
        .then((response) => {
          setPropertyDetails(response.data.propertyList[0]);
        });

      if (packageType === "packageType.Staycation") {
        setdisableSelect(true);
      } else if (packageType === "packageType.Holiday") {
        setdisableSelect(false);
      } else {
        setdisableSelect(false);
      }
    };

    const getIcon = (text: string) => {
      const textVar = text?.toLowerCase();
      let bestMatch = "";
      let bestMatchIndex = 0;
      let matchingIcons = amenitiesIconMapping.filter((icon: any) =>
        textVar?.includes(icon.keyword)
      );
      for (let i = 0; i < matchingIcons.length; i++) {
        if (matchingIcons[i].keyword !== undefined) {
          if (matchingIcons[i].keyword.length > bestMatch.length) {
            bestMatch = matchingIcons[i].keyword;
            bestMatchIndex = i;
          }
        }
      }
      if (matchingIcons.length === 0)
        return require(`../../assets/images/icons/highlights/default.png`);
      return require(`../../assets/images/icons/amenities/${matchingIcons[bestMatchIndex].icon}`);
    };

    const toggleAccordian = (id: string) => {
      if (accordionArray.includes(id)) {
        const tempArray = accordionArray.filter((ids) => ids != id);
        setaccordionArray(tempArray);
      } else {
        const addId = accordionArray.concat(id);
        setaccordionArray(addId);
      }
    };

    const [showCarousel, setShowCarousel] = useState(false);

    const updateShowImageGalleryPopup = (data: any) => {
      setShowCarousel(data);
    };

    const toggleCarousel = () => {
      setShowCarousel(!showCarousel);
    };

    const [startIndex, setStartIndex] = useState(0);

    // Function to handle moving to the previous set of images in the carousel
    const prevImages = () => {
      if (startIndex > 0) {
        setStartIndex(startIndex - 3);
      }
    };

    // Function to handle moving to the next set of images in the carousel
    const nextImages = (carouselImagesLength: any) => {
      if (startIndex + 3 < carouselImagesLength) {
        setStartIndex(startIndex + 3);
      }
    };

    useEffect(() => {
      itineraryPlanStore.setStateToInitialForm();
    }, [itineraryPlansData]);

    const getStayDates = () => {
      if (
        beginDate &&
        destinationIndex !== null &&
        destinationIndex !== undefined
      ) {
        let numberOfDays: number = 0;
        if (destinationIndex !== 0) {
          numberOfDays = itineraryPlanStore.visitDestinationList
            .slice(0, destinationIndex)
            .reduce((accumulator, currentObj) => {
              return accumulator + currentObj.stayDuration;
            }, 0);

          let startDate = new Date(beginDate);
          let endDate = new Date(beginDate);
          return ` - ${moment(
            startDate.setDate(startDate.getDate() + numberOfDays)
          ).format("DD-MM-YYYY")} to ${moment(
            startDate.setDate(
              endDate.getDate() +
                numberOfDays +
                itineraryPlanStore.visitDestinationList[destinationIndex]
                  .stayDuration
            )
          ).format("DD-MM-YYYY")}`;
        } else {
          let startDate = new Date(beginDate);
          let endDate = new Date(beginDate);
          return ` - ${moment(startDate.setDate(startDate.getDate())).format(
            "DD-MM-YYYY"
          )} to ${moment(
            startDate.setDate(
              endDate.getDate() +
                itineraryPlanStore.visitDestinationList[destinationIndex]
                  .stayDuration
            )
          ).format("DD-MM-YYYY")}`;
        }
      }

      return "";
    };

    const selectedRoomTypes = (
      id: any,
      name: any,
      propertyId: any,
      mealPlan: string,
      rateClass: string
    ) => {
      setpolicylists([]);
      const findRoomType = itineraryPlanStore.stayRoomTypes.find(
        (room) => room.propertyId == propertyId
      );
      if (findRoomType) {
        const deleteRoomType = selectRoomTypes.filter(
          (room) => room.propertyId != propertyId
        );
        const deleteRoomTypeFromStore = itineraryPlanStore.stayRoomTypes.filter(
          (room) => room.propertyId != propertyId
        );
        deleteRoomType.push({
          propertyId: propertyId,
          roomId: id,
          roomName: name,
          mealPlan: mealPlan,
          rateClass: rateClass,
        });
        deleteRoomTypeFromStore.push({
          propertyId: propertyId,
          roomId: id,
          roomName: name,
          mealPlan: mealPlan,
          rateClass: rateClass,
        });
        setPropertyConfig({
          ...propertyConfig,
          roomTypeId: id,
          roomTypeName: name,
        });
        setSelectRoomTypes(deleteRoomType);

        itineraryPlanStore.setStayRoomTypes(deleteRoomTypeFromStore);
      } else {
        let tempRoom = selectRoomTypes;
        tempRoom = tempRoom.concat({
          propertyId: propertyId,
          roomId: id,
          roomName: name,
          mealPlan: mealPlan,
          rateClass: rateClass,
        });
        let tempRoomStore = itineraryPlanStore.stayRoomTypes;
        tempRoomStore = tempRoomStore.concat({
          propertyId: propertyId,
          roomId: id,
          roomName: name,
          mealPlan: mealPlan,
          rateClass: rateClass,
        });
        setPropertyConfig({
          ...propertyConfig,
          roomTypeId: id,
          roomTypeName: name,
        });
        setSelectRoomTypes(tempRoom);

        itineraryPlanStore.setStayRoomTypes(tempRoomStore);
      }

      callAvailabiltyData(packageId);
    };

    const callAvailabiltyData = (packageId: any) => {
      let data: any = [];
      itineraryPlanStore.stayRoomTypes.forEach((ele: any, i: any) => {
        data.push({
          propertyId: ele.propertyId,
          roomTypeId: ele.roomId,
          fromDate:
            getFromdays(
              ele.propertyId,
              ele.roomId,
              itineraryPlanStore.rightContainerData.fromDate
            ) === 1
              ? getDateStrInYYYYMMDD(
                  new Date(itineraryPlanStore.rightContainerData.fromDate)
                )
              : addFromDays(
                  getFromdays(
                    ele.propertyId,
                    ele.roomId,
                    itineraryPlanStore.rightContainerData.fromDate
                  ),
                  itineraryPlanStore.rightContainerData.fromDate
                ),
          toDate: gettoDays(
            ele.propertyId,
            ele.roomId,
            itineraryPlanStore.rightContainerData.fromDate
          )
            ? addToDays(
                gettoDays(
                  ele.propertyId,
                  ele.roomId,
                  itineraryPlanStore.rightContainerData.fromDate
                ),
                itineraryPlanStore.rightContainerData.fromDate
              )
            : "",
          mealPlan: getMealrateClass(ele.propertyId, "mealPlan"),
          rateClass: getMealrateClass(ele.propertyId, "rateClass"),
          numRooms: getroomcount(ele.propertyId, ele.roomId, "selected"),
          numAdults: itineraryPlanStore.rightContainerData.numAdults,
          numChildren: itineraryPlanStore.rightContainerData.numChildren,
          childAges: itineraryPlanStore.rightContainerData.childAges,
        });
      });

      itineraryPlanStore.setavailabilitypayload(data);
      if (
        itineraryPlanStore.rightContainerData.fromDate &&
        itineraryPlanStore.rightContainerData.toDate
      ) {
        dispatch(getPackagePricing(data, "", "", packageId));
      }
    };
    const getFromdays = (propertyid: any, roomid: any, fromdate: any) => {
      let fromdatestay: any = "";
      itineraryPlansData.map((e: any, i: any) => {
        const indexfrom = e.dayIndexFrom;
        if (e?.propertyId === propertyid) {
          fromdatestay = e.dayIndexFrom;
        }
      });
      return fromdatestay;
    };

    const gettoDays = (propertyid: any, roomid: any, fromdate: any) => {
      let todatestay: any = "";
      itineraryPlansData.map((e: any, i: any) => {
        if (e?.propertyId === propertyid) {
          todatestay = e.dayIndexTo;
        }
      });
      return todatestay;
    };

    const addFromDays = (days: any, fromdate: any) => {
      if (fromdate) {
        const newDate = new Date(fromdate);
        newDate.setDate(fromdate?.getDate() + days - 1);
        return formatDateToYYYYMMDD(newDate);
      }

      return;
    };

    const addToDays = (days: any, fromdate: any) => {
      if (fromdate) {
        const newDate = new Date(fromdate);
        newDate.setDate(fromdate?.getDate() + days);
        return formatDateToYYYYMMDD(newDate);
      }

      return;
    };

    const formatDateToYYYYMMDD = (dateStr: any) => {
      const date = new Date(dateStr);

      const year = date.getFullYear();
      let month = (date.getMonth() + 1).toString(); // Months are 0-indexed
      let day = date.getDate().toString();

      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return year + "-" + month + "-" + day;
    };
    const getDateStrInYYYYMMDD = (date: Date) => {
      if (!date) {
        return "";
      }

      return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    };
    const selectRoomNum = (room: any) => {
      if (room) {
        const tempPropertyConfig = {
          ...propertyConfig,
          numRooms: parseInt(room),
        };
        setPropertyConfig(tempPropertyConfig);
        const propertyexisting = itineraryPlanStore.propertyList.find(
          (prop: any) => prop.cityId == tempPropertyConfig.cityId
        );
        if (propertyexisting) {
          const tempProp = itineraryPlanStore.propertyList.filter(
            (prop: any) => prop.cityId != tempPropertyConfig.cityId
          );
          tempProp.push(tempPropertyConfig);
          itineraryPlanStore.setPropertyList(tempProp);
        } else {
          let tempProperty: any[] = itineraryPlanStore.propertyList;
          tempProperty = tempProperty.concat(tempPropertyConfig);
          itineraryPlanStore.setPropertyList(tempProperty);
        }
      } else {
        return;
      }
    };

    const selectMealPlan = (mealPlan: string) => {
      if (mealPlan) {
        const tempPropertyConfig = {
          ...propertyConfig,
          mealPlan: mealPlan,
        };
        setPropertyConfig(tempPropertyConfig);
        const propertyexisting = itineraryPlanStore.propertyList.find(
          (prop: any) => prop.cityId == tempPropertyConfig.cityId
        );
        if (propertyexisting) {
          const tempProp = itineraryPlanStore.propertyList.filter(
            (prop: any) => prop.cityId != tempPropertyConfig.cityId
          );
          tempProp.push(tempPropertyConfig);
          itineraryPlanStore.setPropertyList(tempProp);
        } else {
          let tempProperty: any[] = itineraryPlanStore.propertyList;
          tempProperty = tempProperty.concat(tempPropertyConfig);
          itineraryPlanStore.setPropertyList(tempProperty);
        }
      } else {
        return;
      }
    };

    useEffect(() => {
      const existingProp = itineraryPlanStore.selectedProperties.find(
        (property: any) =>
          property.city?.name.toLocaleLowerCase() ==
          cityName.toLocaleLowerCase()
      );
      if (existingProp) {
        setPropertyDetails(existingProp);
        const roomType = itineraryPlanStore.stayRoomTypes.find(
          (room: any) => room.propertyId == existingProp.id
        );
        if (roomType) {
          setSelectRoomTypes([roomType]);
        }
      } else {
        if (itineraryPlansData.length === 0) {
          fetchPropertyDetails();
        } else {
          let roomList: any[] = [];
          itineraryPlansData?.map((e: any, i: any) => {
            roomList.push({
              propertyId: e.propertyId,
              roomId: e.roomTypeId,
              roomName: e.roomTypeName,
            });
            selectedRoomTypes(
              e.roomTypeId,
              e.roomTypeName,
              e.propertyId,
              e.mealPlan,
              e.rateClass
            );
          });
          setSelectRoomTypes(roomList);
        }
      }
    }, [cityName, itineraryPlansData]);

    useEffect(() => {
      const a: any[] = itineraryPlanStore.propertyList;
    }, [itineraryPlanStore.propertyList]);

    const countStartDays = (date: any, dayIndexTo: any) => {
      let startDate = moment(date, "YYYY-MM-DD");
      if (dayIndexTo != 1) {
        startDate.add(dayIndexTo - 1, "days");
      }
      let formattedDate = startDate.format("DD-MM-YYYY");
      return formattedDate;
    };

    const countEndDays = (date: any, dayIndexTo: any) => {
      let startDate = moment(date, "YYYY-MM-DD");
      if (dayIndexTo != 1) {
        startDate.add(dayIndexTo, "days");
      }
      let formattedDate = startDate.format("DD-MM-YYYY");
      return formattedDate;
    };

    const getMealPlanRatePlanMapping = (mealPlan: any, data: any) => {
      let opti: any[] = [];
      if (!itineraryPlanStore.calenderSelected) {
        mealPlanDesc?.forEach((element: any, i: any) => {
          if (element.code === mealPlan) {
            opti.push({
              value: "",
              title: "",
            });
          }
        });
      } else {
        if (itineraryPlanStore?.availabilityData?.propertyPricing?.length > 0) {
          itineraryPlanStore?.availabilityData?.propertyPricing?.forEach(
            (element: any, i: any) => {
              element.room.forEach((elementroom: any) => {
                if (elementroom.roomTypeId === mealPlan) {
                  element.availableMealPlan.forEach((ele: any) => {
                    opti.push({
                      value: ele.mealPlan + "-" + ele.rateClass,
                      title: ele.mealPlanDesc + "-" + ele.rateClassDesc,
                    });
                  });
                }
              });
            }
          );
        }
      }
      const tempinventoryfailed = opti.find(
        (x) => x.value === "-" && x.title === "-"
      );
      if (tempinventoryfailed) {
        opti = [];
        opti.push({
          value: "-",
          title: "No Inventory Available",
        });
      }
      return opti;
    };

    const compareDates = (policyDate: any) => {
      let result = "";
      if (policyDate) {
        const planPolicyDate = policyDate?.split("T")[0];
        const q = new Date();
        const m = q.getMonth() + 1;
        const d = q.getDay();
        const y = q.getFullYear();
        const date = new Date();
        const todaydate: any = getDateStrInYYYYMMDD(date);
        const mydate = new Date(planPolicyDate);

        if (todaydate > planPolicyDate) {
          result = "Booking is non-refundable";
        } else {
          result =
            "Free Cancellation Upto " +
            planPolicyDate?.split("-")[2] +
            "-" +
            planPolicyDate?.split("-")[1] +
            "-" +
            planPolicyDate?.split("-")[0];
        }
      } else {
        result = "Booking is non-refundable";
      }
      return result;
    };

    useEffect(() => {
      let data: any = itineraryPlanStore?.availabilityData;
      getMealPlanRatePlanMapping(undefined, data);
    }, [
      itineraryPlanStore.calenderSelected,
      itineraryPlanStore?.availabilityData,
    ]);
    useEffect(() => {
      if (propertyDetails) {
        const propertyConfig = {
          id: Math.floor(Math.random() * 100) * -1,
          dayIndexFrom: propertyDetails.checkinTime,
          dayIndexTo: propertyDetails.checkoutTime,
          propertyId: propertyDetails.id,
          propertyName: propertyDetails.name,
          roomTypeId: "",
          roomTypeName: "",
          destination: cityName,
          cityId: propertyDetails.cityId,
          numRooms: 0,
          fromDate: "",
          toDate: "",
          notes: "",
        };
        const existingProperty = itineraryPlanStore.selectedProperties.find(
          (property: any) => property.cityId == propertyDetails.cityId
        );
        if (existingProperty) {
          const tempProperty = itineraryPlanStore.selectedProperties.filter(
            (property: any) => property.cityId != propertyDetails.cityId
          );
          tempProperty.push(propertyDetails);
          itineraryPlanStore.setSelectedProperties(tempProperty);
        } else {
          let tempProperty = itineraryPlanStore.selectedProperties;
          tempProperty = tempProperty.concat(propertyDetails);
          itineraryPlanStore.setSelectedProperties(tempProperty);
        }

        if (propertyDetails) {
          const imageArray: any[] = [];
          propertyDetails.mediaFolder.files.map((file: any) => {
            const tempImg = {
              imageUrl: `https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${propertyDetails.mediaFolder.url}/${file.fileName}`,
              imageCaption: file.fileName,
            };
            imageArray.push(tempImg);
          });
          const mediaOverLayArray = [
            {
              collectionType: "Additional",
              propertyId: null,
              roomTypeId: null,
              images: imageArray,
            },
          ];
        }

        setPropertyConfig(propertyConfig);
      }
    }, [propertyDetails]);

    const handleMealPlanChange = (
      event: any,
      roomid: any,
      propertyId: any,
      dayIndexFrom: any,
      dayIndexTo: any
    ) => {
      let propertyResponse: any = [];
      let totalPrice: any = {
        totalFare: 0,
        basicCost: 0,
        extraPAXCost: 0,
      };
      let errorMessage = "";
      let updatedRoomStaypayload: any = [];

      for (let stayDetails of itineraryPlanStore.stayRoomTypes ?? []) {
        if (
          itineraryPlanStore?.availabilityData?.propertyPricing &&
          Object.keys(itineraryPlanStore?.availabilityData?.propertyPricing)
            .length > 0
        ) {
          for (let propertyPricing of itineraryPlanStore?.availabilityData
            ?.propertyPricing ?? []) {
            if (stayDetails?.propertyId === propertyPricing?.propertyId) {
              let mealPlan = stayDetails?.mealPlan;

              if (stayDetails?.propertyId === propertyId) {
                mealPlan = event.target.value.split("-")[0];
              }

              let getRatePlan = propertyPricing?.room?.find(
                (x: any) => x.mealPlan === mealPlan
              );
              if (getRatePlan) {
                propertyResponse.push({
                  propertyId: propertyPricing?.propertyId,
                  propertyName: propertyPricing?.propertyName,
                  ...getRatePlan,
                });
              } else {
                propertyResponse.push({
                  propertyId: propertyPricing?.propertyId,
                  propertyName: propertyPricing?.propertyName,
                  priceData: {
                    typeWisePriceBreakDown: [],
                    dayWisePriceBreakdown: [],
                  },
                });
              }
            }
          }

          for (let incl of itineraryPlanStore?.availabilityData?.packagePricing
            ?.inclusions ?? []) {
            totalPrice.totalFare += incl?.itemTotal ?? 0;
            totalPrice.basicCost += incl?.itemTotal ?? 0;
          }
        }
      }

      itineraryPlanStore.setPropertyResponseFilteredData(propertyResponse);
      const updatedRoomStay: any = [];

      for (let propertyFilteredPrice of propertyResponse) {
        if (
          !propertyFilteredPrice?.canProceed &&
          !errorMessage?.includes(propertyFilteredPrice?.errorMessage)
        ) {
          errorMessage += propertyFilteredPrice?.errorMessage ?? ""; //No Inventory Available Please Select Different Date.
          errorMessage += "\n";
        }

        for (let typewise of propertyFilteredPrice?.priceData
          ?.typeWisePriceBreakDown ?? []) {
          updatedRoomStay.push({
            propertyId: propertyFilteredPrice.propertyId,
            roomId: propertyFilteredPrice.roomTypeId,
            roomTypeName: propertyFilteredPrice.roomTypeName,
            mealPlan: propertyFilteredPrice.mealPlan,
            rateClass: propertyFilteredPrice.rateClass,
          });

          if (typewise?.extraAdult > 0) {
            totalPrice.extraPAXCost += typewise?.extraAdultPrice;
          }
          if (typewise?.extraChildren > 0) {
            totalPrice.extraPAXCost += typewise?.extraChildrenCost;
          }
          totalPrice.totalFare += typewise?.basicCost;
          totalPrice.basicCost += typewise?.basicCost;
          totalPrice.totalFare +=
            typewise?.extraAdultPrice + typewise?.extraChildrenCost;

          for (let inclusion of typewise?.inclusions ?? []) {
            totalPrice.totalFare += inclusion?.price;
            totalPrice.basicCost += inclusion?.price;
          }
        }
      }

      const policyCopy = policylists.filter(
        (x: any) => x.roomid !== roomid && x.propertyId !== propertyId
      );

      itineraryPlanStore?.availabilityData?.propertyPricing?.forEach(
        (element: any, index: any) => {
          if (propertyId === element.propertyId) {
            element?.room.forEach((ele: any, i: any) => {
              if (ele.roomTypeId === roomid) {
                policyCopy.push({
                  index: element.propertyName,
                  text: compareDates(
                    ele?.priceData?.typeWisePriceBreakDown[0]
                      ?.roomDistribution[0]?.cancellationPolicies[0]
                      ?.ifCancelledBy
                  ),
                  rateClass: event.target.value.split("-")[1],
                  mealPlan: event.target.value.split("-")[0],
                  propertyId: propertyId,
                  roomid: roomid,
                });
              }
            });
          }
        }
      );
      setpolicylists([...policyCopy]);
      itineraryPlanStore.setpolicyLists([...policyCopy]);

      // let totalPriceupdated: any = {
      //   totalFare: 0,
      //   basicCost: 0,
      //   extraPAXCost: 0,
      // };
      // let errorMessageupdated = "";

      // policyCopy.forEach((element) => {
      //   const pricedata = getPriceforMealplan(
      //     element.roomid,
      //     element.propertyId,
      //     element.mealPlan,
      //     element.rateClass
      //   );

      //   for (let typewise of pricedata) {
      //     if (typewise?.extraAdult > 0) {
      //       totalPriceupdated.extraPAXCost += typewise?.extraAdultPrice;
      //     }
      //     if (typewise?.extraChildren > 0) {
      //       totalPriceupdated.extraPAXCost += typewise?.extraChildrenCost;
      //     }
      //     totalPriceupdated.totalFare += typewise?.basicCost;
      //     totalPriceupdated.basicCost += typewise?.basicCost;
      //     totalPriceupdated.totalFare +=
      //       typewise?.extraAdultPrice + typewise?.extraChildrenCost;
      //   }
      // });

      ///*******************availability call change */

      itineraryPlanStore.setPriceDetails(
        itineraryPlanStore?.availabilityData?.priceTotal?.totalFare
      );

      let data: any = []; // selected roomdetail formation
      let dataTemp: any = {
        propertyId: propertyId,
        roomTypeId: roomid,
        fromDate:
          dayIndexFrom === 1
            ? getDateStrInYYYYMMDD(
                new Date(itineraryPlanStore.rightContainerData.fromDate)
              )
            : addFromDays(
                dayIndexFrom,
                itineraryPlanStore.rightContainerData.fromDate
              ),
        toDate: dayIndexTo
          ? addToDays(
              dayIndexTo,
              itineraryPlanStore.rightContainerData.fromDate
            )
          : "",
        numRooms: getroomcount(propertyId, roomid, "selected"),
        mealPlan: event.target.value.split("-")[0],
        rateClass: event.target.value.split("-")[1],
        numAdults: itineraryPlanStore.rightContainerData.numAdults,
        numChildren: itineraryPlanStore.rightContainerData.numChildren,
        childAges: itineraryPlanStore.rightContainerData.childAges,
      };
      data.push(dataTemp);

      itineraryPlanStore.stayRoomTypes.forEach((ele: any, i: any) => {
        // other property roomdetail formation
        if (ele.propertyId !== propertyId && ele.roomId !== roomid) {
          data.push({
            propertyId: ele.propertyId,
            roomTypeId: ele.roomId,
            fromDate:
              getFromdays(
                ele.propertyId,
                ele.roomId,
                itineraryPlanStore.rightContainerData.fromDate
              ) === 1
                ? getDateStrInYYYYMMDD(
                    new Date(itineraryPlanStore.rightContainerData.fromDate)
                  )
                : addFromDays(
                    getFromdays(
                      ele.propertyId,
                      ele.roomId,
                      itineraryPlanStore.rightContainerData.fromDate
                    ),
                    itineraryPlanStore.rightContainerData.fromDate
                  ),
            toDate: gettoDays(
              ele.propertyId,
              ele.roomId,
              itineraryPlanStore.rightContainerData.fromDate
            )
              ? addToDays(
                  gettoDays(
                    ele.propertyId,
                    ele.roomId,
                    itineraryPlanStore.rightContainerData.fromDate
                  ),
                  itineraryPlanStore.rightContainerData.fromDate
                )
              : "",
            mealPlan: getMealrateClass(ele.propertyId, "mealPlan"),
            rateClass: getMealrateClass(ele.propertyId, "rateClass"),
            numRooms: getroomcount(ele.propertyId, ele.roomId, "other"),
            numAdults: itineraryPlanStore.rightContainerData.numAdults,
            numChildren: itineraryPlanStore.rightContainerData.numChildren,
            childAges: itineraryPlanStore.rightContainerData.childAges,
          });
        }
      });

      itineraryPlanStore.setavailabilitypayload(data);
      dispatch(getPackagePricing(data, "", "", packageId));
    };
    const getMealrateClass = (propertyid: any, type: any) => {
      let tempplan: any = "";
      itineraryPlanStore?.availabilityData?.propertyPricing?.forEach(
        (element: any) => {
          if (element.propertyId === propertyid && type === "mealPlan") {
            tempplan = element.availableMealPlan[0].mealPlan;
          } else if (
            element.propertyId === propertyid &&
            type === "rateClass"
          ) {
            tempplan = element.availableMealPlan[0].rateClass;
          }
        }
      );
      return tempplan;
    };

    const getselectedMealrateClass = (propertyid: any, type: any) => {
      let tempplan: any = "";
      itineraryPlanStore?.availabilityData?.propertyPricing?.forEach(
        (element: any) => {
          if (element.propertyId === propertyid && type === "mealPlan") {
            tempplan = element.availableMealPlan[0].mealPlan;
          }
          if (element.propertyId === propertyid && type === "rateClass") {
            tempplan = element.availableMealPlan[0].rateClass;
          }
        }
      );

      return tempplan;
    };

    const getroomcount = (propertyid: any, roomid: any, type: any) => {
      let temproomcount: any = "";
      itineraryPlanStore?.availabilityData?.propertyPricing?.forEach(
        (element: any) => {
          if (element?.propertyId !== propertyid && type === "other") {
            if (element?.room[0]?.roomTypeId) {
              temproomcount = element?.room[0]?.numRooms;
            }
          } else if (
            element?.propertyId === propertyid &&
            type === "selected"
          ) {
            if (element?.room[0]?.roomTypeId) {
              temproomcount = element?.room[0]?.numRooms;
            }
          }
        }
      );
      return temproomcount;
    };

    useEffect(() => {
      const policyDataLists: any = [];
      if (policylists?.length == 0) {
        itineraryPlanStore?.availabilityData?.propertyPricing?.forEach(
          (element: any) => {
            element?.room?.forEach((ele: any) => {
              policyDataLists.push({
                index: "",
                text: compareDates(
                  ele?.priceData?.typeWisePriceBreakDown[0]?.roomDistribution[0]
                    ?.cancellationPolicies[0]?.ifCancelledBy
                )
                  ? compareDates(
                      ele?.priceData?.typeWisePriceBreakDown[0]
                        ?.roomDistribution[0]?.cancellationPolicies[0]
                        ?.ifCancelledBy
                    )
                  : "",
                propertyId: element.propertyId,
                roomid: ele.roomTypeId,
                mealPlan: ele.mealPlan,
                rateClass: ele.rateClass,
              });
            });
          }
        );
        setpolicylists(policyDataLists);
        itineraryPlanStore.setpolicyLists(policyDataLists);
      }
    }, [itineraryPlanStore.availabilityData]);

    const moreFacilitylist = (total: number, propertyid: any) => {
      setfacilityLength(total);
      setselectedviewmoreid(propertyid);
    };
    const selectRoomNumgetPrice = (
      selectedpropertyId: any,
      selectedroomId: any,
      roomCount: any,
      dayindexFrom: any,
      dayindexTo: any
    ) => {
      let data: any = [];
      let dataTemp: any = {
        propertyId: selectedpropertyId,
        roomTypeId: selectedroomId,
        fromDate:
          dayindexFrom === 1
            ? getDateStrInYYYYMMDD(
                new Date(itineraryPlanStore.rightContainerData.fromDate)
              )
            : addFromDays(
                dayindexFrom,
                itineraryPlanStore.rightContainerData.fromDate
              ),
        toDate: dayindexTo
          ? addToDays(
              dayindexTo,
              itineraryPlanStore.rightContainerData.fromDate
            )
          : "",
        numRooms: Number(roomCount),
        mealPlan: getselectedMealrateClass(selectedpropertyId, "mealPlan"),
        rateClass: getselectedMealrateClass(selectedpropertyId, "rateClass"),
        numAdults: itineraryPlanStore.rightContainerData.numAdults,
        numChildren: itineraryPlanStore.rightContainerData.numChildren,
        childAges: itineraryPlanStore.rightContainerData.childAges,
      };
      data.push(dataTemp);
      itineraryPlanStore.stayRoomTypes.forEach((ele: any, i: any) => {
        if (
          ele.propertyId !== selectedpropertyId &&
          ele.roomId !== selectedroomId
        ) {
          data.push({
            propertyId: ele.propertyId,
            roomTypeId: ele.roomId,
            fromDate:
              getFromdays(
                ele.propertyId,
                ele.roomId,
                itineraryPlanStore.rightContainerData.fromDate
              ) === 1
                ? getDateStrInYYYYMMDD(
                    new Date(itineraryPlanStore.rightContainerData.fromDate)
                  )
                : addFromDays(
                    getFromdays(
                      ele.propertyId,
                      ele.roomId,
                      itineraryPlanStore.rightContainerData.fromDate
                    ),
                    itineraryPlanStore.rightContainerData.fromDate
                  ),
            toDate: gettoDays(
              ele.propertyId,
              ele.roomId,
              itineraryPlanStore.rightContainerData.fromDate
            )
              ? addToDays(
                  gettoDays(
                    ele.propertyId,
                    ele.roomId,
                    itineraryPlanStore.rightContainerData.fromDate
                  ),
                  itineraryPlanStore.rightContainerData.fromDate
                )
              : "",
            mealPlan: getMealrateClass(ele.propertyId, "mealPlan"),
            rateClass: getMealrateClass(ele.propertyId, "rateClass"),
            numRooms: getroomcount(ele.propertyId, ele.roomId, "other"),
            numAdults: itineraryPlanStore.rightContainerData.numAdults,
            numChildren: itineraryPlanStore.rightContainerData.numChildren,
            childAges: itineraryPlanStore.rightContainerData.childAges,
          });
        }
      });
      itineraryPlanStore.setavailabilitypayload(data);
      dispatch(getPackagePricing(data, "", "", packageId));

      getRoomCountList();
    };

    useEffect(() => {
      getRoomCountList();
    }, [itineraryPlanStore.availabilityData]);

    const getRoomCountList = () => {
      let roomList: any[] = [];
      if (itineraryPlanStore?.availabilityData?.propertyPricing?.length > 0) {
        itineraryPlanStore?.availabilityData?.propertyPricing?.forEach(
          (element: any, i: any) => {
            element.room.forEach((ele: any) => {
              roomList.push({
                propertyId: element.propertyId,
                roomId: ele.roomTypeId,
                value: ele.numRooms,
                title: ele.numRooms + " Room",
              });
            });
          }
        );
      }

      itineraryPlanStore.setRoomCountDopdownList(roomList);
    };

    const getSelectedRoomCount = (propertyId: any, roomId: any) => {
      let Roomcount = 0;
      if (itineraryPlanStore?.roomCountList?.length > 0) {
        itineraryPlanStore?.roomCountList?.forEach((ele: any, i: any) => {
          if (ele.propertyId === propertyId && ele.roomId === roomId) {
            Roomcount = ele.value;
          }
        });
      }
      return Roomcount;
    };

    const getcheckincheckoutdates = (
      fromDate: any,
      fromIndex: any,
      toIndex: any
    ) => {
      let nnights: any = "";
      let totalStays: any = "";
      nnights = toIndex - fromIndex;

      totalStays = `${nnights + 1}N ${nnights + 2}D`;
      let datesstays: any = "";

      if (!itineraryPlanStore.calenderSelected) {
        datesstays = "";
        return totalStays;
      } else {
        datesstays =
          countStartDays(
            getDateStrInYYYYMMDD(
              new Date(itineraryPlanStore.rightContainerData.fromDate)
            ),
            fromIndex
          ) +
          " to " +
          countEndDays(
            getDateStrInYYYYMMDD(
              new Date(itineraryPlanStore.rightContainerData.fromDate)
            ),
            toIndex
          );
        return totalStays + " - " + datesstays;
      }
    };

    const getMealPlanForSelected = (propertyId: string, roomTypeId: string) => {
      let filteredPolicy = policylists.find(
        (x: any) => x.propertyId === propertyId && x.roomid === roomTypeId
      );
      if (filteredPolicy) {
        return filteredPolicy?.mealPlan + "-" + filteredPolicy?.rateClass;
      }
    };

    useEffect(() => {
      let roomList: any[] = [];
      itineraryPlanStore.detailPageOpportunityStays?.map((e: any, i: any) => {
        roomList.push({
          propertyId: e.propertyId,
          roomId: e.roomTypeId,
          roomName: e.roomTypeName,
        });
        selectedRoomTypes(
          e.roomTypeId,
          e.roomTypeName,
          e.propertyId,
          e.mealPlan,
          e.rateClass
        );
      });
      setSelectRoomTypes(roomList);
    }, [itineraryPlanStore.detailFlag]);
    const checkerrorlist = (roomid: any) => {
      let checkexists: boolean = false;
      if (
        itineraryPlanStore.inventoryerrorlist.find(
          (x: any) => x.roomid === roomid
        )
      ) {
        checkexists = true;
      } else {
        checkexists = false;
      }
      return checkexists;
    };

    const getPropertyImagesArray = (
      mediaFolderFiles: any,
      mediaFolderUrl: string
    ) => {
      const imageArray: any[] = [];
      mediaFolderFiles?.map((file: any) => {
        const tempImg = {
          imageUrl: `https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${mediaFolderUrl}/${file?.fileName}`,
          imageCaption: file.fileName,
        };
        imageArray.push(tempImg);
      });
      const mediaOverLayArray = [
        {
          collectionType: "Additional",
          propertyId: null,
          roomTypeId: null,
          images: imageArray,
        },
      ];

      setImageOverLayArray(mediaOverLayArray);
    };

    return (
      <>
        {itineraryPlansData.length > 0 &&
          itineraryPlansData?.map((e: any, i: any) => {
            return (
              <div className="stays-section">
                <div className="property-stay-section">
                  <div className="property-stay-img-div">
                    {e.property?.mediaFolder?.files.length > 0 && (
                      <div className="property-stay-thumbnail">
                        <img
                          className="property-stay-img"
                          src={`https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${e.property?.mediaFolder?.url}/${e?.property?.mediaFolder?.files[0]?.fileName}`}
                        />
                        <button
                          className="carousel-toggle-button"
                          onClick={() => {
                            toggleCarousel();
                            getPropertyImagesArray(
                              e.property?.mediaFolder?.files,
                              e.property?.mediaFolder?.url
                            );
                          }}
                        >
                          View more...
                        </button>
                      </div>
                    )}
                    <div className="carousel-container">
                      <button
                        className="carousel-arrow left"
                        disabled={startIndex == 0}
                        onClick={prevImages}
                      >
                        &lt;
                      </button>

                      <div className="carousel-images">
                        {e?.property?.mediaFolder?.files.length > 0 &&
                          e?.property?.mediaFolder?.files
                            .slice(startIndex, startIndex + 3)
                            .map((file: any, index: any) => {
                              return (
                                <img
                                  key={index}
                                  src={`https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${e.property?.mediaFolder?.url}/${file?.fileName}`}
                                  alt={file.fileName}
                                />
                              );
                            })}
                      </div>

                      <button
                        className="carousel-arrow right"
                        disabled={
                          startIndex + 3 >=
                          e.property?.mediaFolder?.files.length
                        }
                        onClick={() => {
                          nextImages(e.property.mediaFolder?.files.length);
                        }}
                      >
                        &gt;
                      </button>
                    </div>
                  </div>
                  <div className="property-stay-content-button">
                    <div className="property-stay-div">
                      <div className="property-stay-header-div">
                        <div className="property-stay-header-txt test">
                          <div>{e.property.name}</div>
                        </div>
                        <div className="city-date-div">
                          {e.property.city.name
                            ? e.property.city.name + " - "
                            : ""}
                          {getcheckincheckoutdates(
                            e.fromDate,
                            e.dayIndexFrom,
                            e.dayIndexTo
                          )}
                        </div>
                        <div className="property-stay-details-location-date">
                          <div className="property-stay-details-bubble">
                            <div className="location-background"></div>

                            <div className="stay-location-date-label">
                              location:
                            </div>
                            <div className="stay-location-date-div">
                              {e.property.address}
                            </div>
                          </div>
                        </div>
                      </div>
                      {e?.property?.description && (
                        <div
                          style={{ width: "583px" }}
                          className="property-stay-description test"
                          dangerouslySetInnerHTML={{
                            __html:
                              e?.property?.description?.length > 200
                                ? e?.property?.description?.substring(0, 325) +
                                  "..."
                                : e?.property?.description + "",
                          }}
                        ></div>
                      )}
                      {!e?.property?.description && (
                        <div
                          style={{ width: "583px" }}
                          className="property-stay-description test"
                        ></div>
                      )}
                    </div>
                    {/* <div>
                      {packageType === "packageType.Staycation" ? (
                        ""
                      ) : (
                        <button
                          className="property-stay-header-btn"
                          onClick={() => {
                            setSelectedCity(
                              e.property.city.name ?? packageLocation
                            );
                            setSelectedId(e.id);
                            setSelectedPropId(e.property.id);
                            setPropertyOverlay(!propertyOverlay);
                          }}
                        >
                          Select
                        </button>
                      )}
                    </div> */}
                  </div>

                  {showCarousel && (
                    <ImageGalleryPopup
                      packageData={imageOverLayArray}
                      showImageGalleryPopup={showCarousel}
                      updateShowImageGalleryPopup={updateShowImageGalleryPopup}
                    />
                  )}
                </div>
                <div className="property-facilities">
                  <ul className="inline-block">
                    {_.compact(e?.property?.facilities).length > 0 &&
                      _.compact(e?.property?.facilities)
                        ?.slice(0, facilityLength)
                        .map((item: any, index: number) => {
                          return (
                            <li
                              style={{ fontSize: "14px" }}
                              key={`package_hotel_facilities_${index}`}
                            >
                              <span style={{ width: "20px" }}>
                                <img
                                  src={getIcon(item?.name)}
                                  alt=""
                                  className="img-fluid"
                                />
                              </span>
                              {item?.name}&nbsp;
                              <span>
                                {item?.extractedJson?.toBePaid && " ($)"}
                              </span>
                            </li>
                          );
                        })}
                    {_.compact(e?.property?.facilities).length === 0 && (
                      <li key={`package_hotel_facilities_na`}>
                        {"Not Listed"}
                      </li>
                    )}
                    {propertyDetails?.mediaFolder?.files?.length ===
                      facilityLength &&
                    e?.property?.id === selectedviewmoreid &&
                    e?.property?.facilities?.length > 4 ? (
                      <li
                        style={{
                          fontSize: "14px",
                          cursor: "pointer",
                          color: "#2e8b98",
                        }}
                        onClick={() => {
                          moreFacilitylist(4, e.property.id);
                        }}
                      >
                        view less
                      </li>
                    ) : e?.property?.facilities?.length > 4 ? (
                      <li
                        style={{
                          fontSize: "14px",
                          cursor: "pointer",
                          color: "#2e8b98",
                        }}
                        onClick={() => {
                          moreFacilitylist(
                            propertyDetails?.mediaFolder?.files?.length,
                            e.property.id
                          );
                        }}
                      >
                        view more
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
                <div>
                  {e?.property?.roomTypes?.length > 0
                    ? e?.property?.roomTypes?.map((room: any, index: any) => {
                        const customSelectFlag =
                          e.roomTypeId === room.id &&
                          itineraryPlanStore.calenderSelected
                            ? true
                            : false;

                        const imgArr: any[] = [];
                        room.mediaFolder.files.map((file: any) => {
                          const tempImg = {
                            imageCaption: file.fileName,
                            imageUrl: `https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${e.property?.mediaFolder?.url}/room/${room?.mediaFolder?.url}/${file?.fileName}`,
                          };
                          imgArr.push(tempImg);
                        });
                        return (
                          <>
                            {/* <div className="roomList"> */}
                            <div
                              className="room-info test1"
                              style={
                                room?.roomTypes?.length - 1 == index
                                  ? { marginBottom: "24px" }
                                  : {}
                              }
                            >
                              <div className="roomselection-radiobtn">
                                <input
                                  checked={
                                    selectRoomTypes.find(
                                      (x: any) => x.roomId === room?.id
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    selectedRoomTypes(
                                      room.id,
                                      room.name,
                                      e.property.id,
                                      room.mealPlan,
                                      room.rateClass
                                    );
                                  }}
                                  value={room.id}
                                  name={room.id}
                                  type="radio"
                                />
                                {selectRoomTypes[0]?.roomId === room?.id}
                              </div>
                              <div className="roomimagecontainer">
                                <RoomTypesContainer imageArr={imgArr} />
                              </div>
                              <div className="room-details-div">
                                <div className="room-header">{room.name}</div>
                                <div className="room-details-text">
                                  {room?.beds[0]?.count === undefined
                                    ? ""
                                    : room?.beds[0]?.count + "-"}
                                  {room?.beds[0]?.description === undefined
                                    ? ""
                                    : room?.beds[0]?.description !== undefined
                                    ? room?.beds[0]?.description?.split(
                                        "bed"
                                      )[0] + "bed"
                                    : ""}
                                </div>
                                <div className="facilities-room-div">
                                  {room.amenities.slice(0, 9).map(
                                    (
                                      amenity: {
                                        name: string;
                                        amenityType: string;
                                        extractedJson: any;
                                      },
                                      index: number
                                    ) => {
                                      return (
                                        <>
                                          <span
                                            key={`package_room_amenities_${index}`}
                                            style={{
                                              width: "14px",
                                            }}
                                          >
                                            <img
                                              src={getIcon(amenity.amenityType)}
                                              alt=""
                                              className="img-fluid"
                                            />
                                          </span>
                                          <span>{amenity.name}&nbsp;</span>
                                          <span>
                                            {amenity?.extractedJson?.toBePaid &&
                                              " ($)"}
                                          </span>
                                        </>
                                      );
                                    }
                                  )}
                                </div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "15px",
                                }}
                              >
                                {/* {<span>{JSON.stringify(itineraryPlanStore.roomCountList)}</span>} */}
                                <div className="room-count-div">
                                  <div className="dropdown-label">Meals:</div>
                                  {!itineraryPlanStore.calenderSelected ? (
                                    <select
                                      disabled={
                                        !selectRoomTypes.find(
                                          (x: any) => x.roomId === room?.id
                                        )
                                          ? true
                                          : false &&
                                            !itineraryPlanStore.calenderSelected
                                      }
                                      className="room-type-dropdown f"
                                      style={{ backgroundPosition: "195px" }}
                                    >
                                      {!selectRoomTypes.find(
                                        (x: any) => x.roomId === room?.id
                                      ) ? (
                                        true
                                      ) : false ? (
                                        ""
                                      ) : (
                                        <option value="">
                                          Please select Travel Dates
                                        </option>
                                      )}
                                    </select>
                                  ) : (
                                    <select
                                      disabled={
                                        !selectRoomTypes.find(
                                          (x: any) => x.roomId === room?.id
                                        )
                                          ? true
                                          : false &&
                                            !itineraryPlanStore.calenderSelected
                                          ? true
                                          : false
                                      }
                                      className="room-type-dropdown s"
                                      style={{ backgroundPosition: "195px" }}
                                      value={getMealPlanForSelected(
                                        e.propertyId,
                                        room?.id
                                      )}
                                      onChange={(event) =>
                                        handleMealPlanChange(
                                          event,
                                          room.id,
                                          e.propertyId,
                                          e.dayIndexFrom,
                                          e.dayIndexTo
                                        )
                                      }
                                    >
                                      {getMealPlanRatePlanMapping(
                                        !itineraryPlanStore.calenderSelected
                                          ? e.mealPlan
                                          : room.id,
                                        undefined
                                      )?.length > 0 ? (
                                        <>
                                          {getMealPlanRatePlanMapping(
                                            !itineraryPlanStore.calenderSelected
                                              ? e.mealPlan
                                              : room.id,
                                            undefined
                                          )?.map((e: any, i: any) => {
                                            return (
                                              <option value={e?.value}>
                                                {e?.title}
                                              </option>
                                            );
                                          })}
                                        </>
                                      ) : (
                                        <option value=""></option>
                                      )}
                                    </select>
                                  )}
                                </div>
                                <div className="room-count-div">
                                  <div className="dropdown-label">Rooms:</div>
                                  {!itineraryPlanStore.calenderSelected ? (
                                    <select
                                      className="room-type-dropdown"
                                      disabled={
                                        !selectRoomTypes.find(
                                          (x: any) => x.roomId === room?.id
                                        )
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        selectRoomNum(e.target.value);
                                      }}
                                      style={{
                                        width: "100px",
                                        backgroundPosition: "195px",
                                      }}
                                    >
                                      {!selectRoomTypes.find(
                                        (x: any) => x.roomId === room?.id
                                      ) ? (
                                        true
                                      ) : false ? (
                                        ""
                                      ) : (
                                        <option value="">
                                          Select number of rooms
                                        </option>
                                      )}
                                    </select>
                                  ) : (
                                    <select
                                      className="room-type-dropdown"
                                      disabled={
                                        !selectRoomTypes.find(
                                          (x: any) => x.roomId === room?.id
                                        )
                                          ? true
                                          : false &&
                                            !itineraryPlanStore.calenderSelected
                                          ? true
                                          : false
                                      }
                                      style={{
                                        width: "100px",
                                        backgroundPosition: "195px",
                                      }}
                                      onChange={(event) => {
                                        selectRoomNumgetPrice(
                                          e?.propertyId,
                                          room.id,
                                          event.target.value,
                                          e.dayIndexFrom,
                                          e.dayIndexTo
                                        );
                                      }}
                                    >
                                      {!selectRoomTypes.find(
                                        (x: any) => x.roomId === room?.id
                                      ) ? (
                                        true
                                      ) : false ? (
                                        ""
                                      ) : getMealPlanRatePlanMapping(
                                          !itineraryPlanStore.calenderSelected
                                            ? e.mealPlan
                                            : room.id,
                                          undefined
                                        )?.find((x: any) => x.value === "") ? (
                                        <option value="">
                                          {"No Inventory Available"}
                                        </option>
                                      ) : checkerrorlist(room.id) ? (
                                        <option value="">
                                          {"No Inventory Available"}
                                        </option>
                                      ) : (
                                        defaultRoomList?.map(
                                          (list: any, i: any) => {
                                            return getSelectedRoomCount(
                                              e?.propertyId,
                                              room.id
                                            ) <= list.value ? (
                                              <option
                                                selected={
                                                  getSelectedRoomCount(
                                                    e?.propertyId,
                                                    room.id
                                                  ) === list.value
                                                }
                                                value={list.value}
                                              >
                                                {list.title}
                                              </option>
                                            ) : (
                                              ""
                                            );
                                          }
                                        )
                                      )}
                                    </select>
                                  )}
                                </div>

                                <span className="cancellation-status">
                                  {checkerrorlist(room.id)
                                    ? ""
                                    : policylists.find(
                                        (x: any) =>
                                          x?.propertyId === e.property.id &&
                                          x?.roomid === room.id
                                      )?.text ?? ""}
                                </span>
                              </div>
                            </div>
                          </>
                        );
                      })
                    : "No Rooms Available"}
                </div>
              </div>
            );
          })}

        {propertyOverlay && (
          <PropertyOverlay
            onClosePropertyOverlay={setPropertyOverlay}
            cityName={selectedCity ?? packageLocation}
            // cityName={e ?? packageLocation}
            newPropertyDetails={setPropertyDetails}
            propertyId={selectedPropId}
            opportunityStaysId={selectedId}
            removeRoomtype={setSelectRoomTypes}
            loader={loader}
          />
        )}

        {propertyDetails && itineraryPlansData.length === 0 && (
          <div className="stays-section">
            <div className="property-stay-section">
              <div className="property-stay-img-div">
                {roomTypes.length == 0
                  ? propertyDetails?.mediaFolder?.files.length > 0 && (
                      <div className="property-stay-thumbnail">
                        <img
                          className="property-stay-img"
                          src={`https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${propertyDetails.mediaFolder.url}/${propertyDetails.mediaFolder.files[0].fileName}`}
                        />
                        <button
                          className="carousel-toggle-button"
                          onClick={toggleCarousel}
                        >
                          View more...
                        </button>
                      </div>
                    )
                  : roomTypes.map((coverPic: any) => {
                      return (
                        <img
                          className="property-stay-img"
                          src={coverPic.roomType.images[0].imageUrl}
                        />
                      );
                    })}
                <div className="carousel-container">
                  {/* Left arrow */}
                  <button
                    className="carousel-arrow left"
                    disabled={startIndex == 0}
                    onClick={prevImages}
                  >
                    &lt;
                  </button>

                  <div className="carousel-images">
                    {propertyDetails?.mediaFolder?.files.length > 0 &&
                      propertyDetails?.mediaFolder?.files
                        .slice(startIndex, startIndex + 3)
                        .map((file: any, index: any) => {
                          return (
                            <img
                              key={index}
                              src={`https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${propertyDetails.mediaFolder.url}/${file.fileName}`}
                              alt={file.fileName}
                            />
                          );
                        })}
                  </div>

                  {/* Right arrow */}
                  <button
                    className="carousel-arrow right"
                    disabled={
                      startIndex + 3 >=
                      propertyDetails?.mediaFolder?.files.length
                    }
                    onClick={() => {
                      nextImages(propertyDetails.mediaFolder?.files.length);
                    }}
                  >
                    &gt;
                  </button>
                </div>
              </div>
              <div className="property-stay-content-button">
                <div className="property-stay-div">
                  <div className="property-stay-header-div">
                    <div className="property-stay-header-txt test1">
                      {propertyDetails.name}
                    </div>
                    <div className="city-date-div">
                      {`${cityName} - ${
                        itineraryPlanStore.visitDestinationList[
                          destinationIndex!
                        ].stayDuration
                      }N ${
                        itineraryPlanStore.visitDestinationList[
                          destinationIndex!
                        ].stayDuration! + 1
                      }D`}{" "}
                      {getStayDates()}
                    </div>
                    <div className="property-stay-details-location-date">
                      <div className="property-stay-details-bubble">
                        <div className="location-background"></div>

                        <div className="stay-location-date-label">
                          location:
                        </div>
                        <div className="stay-location-date-div">
                          {propertyDetails.address}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ width: "583px" }}
                    className="property-stay-description test1"
                  >
                    {propertyDetails?.descriptionText?.length > 200
                      ? propertyDetails?.descriptionText?.substring(0, 325) +
                        "..."
                      : propertyDetails?.descriptionText + ""}
                  </div>
                </div>
                <div>
                  {disableSelect ? (
                    ""
                  ) : (
                    <button
                      disabled={disableSelect}
                      className="property-stay-header-btn"
                      onClick={() => setPropertyOverlay(!propertyOverlay)}
                    >
                      Select
                    </button>
                  )}
                </div>
              </div>
              {propertyOverlay && (
                <PropertyOverlay
                  onClosePropertyOverlay={setPropertyOverlay}
                  cityName={cityName != "" ? cityName : packageLocation}
                  newPropertyDetails={setPropertyDetails}
                  propertyId={propertyDetails.id}
                  removeRoomtype={undefined}
                  opportunityStaysId={false}
                  loader={null}
                />
              )}
              {showCarousel && (
                <ImageGalleryPopup
                  packageData={imageOverLayArray}
                  showImageGalleryPopup={showCarousel}
                  updateShowImageGalleryPopup={updateShowImageGalleryPopup}
                />
              )}
            </div>
            <div className="property-facilities">
              <ul className="inline-block">
                {_.compact(propertyDetails.facilities).length > 0 &&
                  _.compact(propertyDetails.facilities)
                    ?.slice(0, facilityLength)
                    .map((item: any, index: number) => {
                      return (
                        <li
                          style={{ fontSize: "14px" }}
                          key={`package_hotel_facilities_${index}`}
                        >
                          <span style={{ width: "20px" }}>
                            <img
                              src={getIcon(item?.name)}
                              alt=""
                              className="img-fluid"
                            />
                          </span>
                          {item?.name}&nbsp;
                          <span>{item?.extractedJson?.toBePaid && " ($)"}</span>
                        </li>
                      );
                    })}
                {_.compact(propertyDetails.facilities).length === 0 && (
                  <li key={`package_hotel_facilities_na`}>{"Not Listed"}</li>
                )}
                {propertyDetails?.mediaFolder?.files.length ===
                  facilityLength &&
                propertyDetails?.id === selectedviewmoreid &&
                propertyDetails?.facilities.length > 4 ? (
                  <li
                    style={{
                      fontSize: "14px",
                      cursor: "pointer",
                      color: "#2e8b98",
                    }}
                    onClick={() => {
                      moreFacilitylist(4, propertyDetails.id);
                    }}
                  >
                    view less
                  </li>
                ) : propertyDetails?.facilities.length > 4 ? (
                  <li
                    style={{
                      fontSize: "14px",
                      cursor: "pointer",
                      color: "#2e8b98",
                    }}
                    onClick={() => {
                      moreFacilitylist(
                        propertyDetails.mediaFolder?.files.length,
                        propertyDetails.id
                      );
                    }}
                  >
                    view more
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
            <div>
              {roomTypes.length == 0
                ? propertyDetails.roomTypes &&
                  propertyDetails.roomTypes[0] &&
                  propertyDetails.roomTypes.map((room: any, index: any) => {
                    let roomNum = 0;
                    let roomMealType = "";
                    const roomFlag = selectRoomTypes.some(
                      (rooms) => rooms.roomId == room.id
                    );

                    if (roomFlag) {
                      const propertyFind = itineraryPlanStore.propertyList.find(
                        (property) => property.roomTypeId === room.id
                      );

                      roomNum = propertyFind?.numRooms ?? 0;
                      roomMealType = propertyFind?.mealPlan ?? "";
                    }
                    const imgArr: any[] = [];
                    room.mediaFolder.files.map((file: any) => {
                      const tempImg = {
                        imageCaption: file.fileName,
                        imageUrl: `https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${propertyDetails.mediaFolder.url}/room/${room.mediaFolder.url}/${file.fileName}`,
                      };
                      imgArr.push(tempImg);
                    });
                    return (
                      <>
                        {/* <div className="roomList"> */}
                        <div
                          className="room-info test1"
                          style={
                            propertyDetails.roomTypes.length - 1 == index
                              ? { marginBottom: "24px" }
                              : {}
                          }
                        >
                          <div className="roomselection-radiobtn">
                            <input
                              checked={roomFlag}
                              onChange={() => {
                                selectedRoomTypes(
                                  room.id,
                                  room.name,
                                  propertyDetails.id,
                                  room.mealPlan,
                                  room.rateClass
                                );
                              }}
                              type="radio"
                            />
                          </div>
                          <div>
                            <RoomTypesContainer imageArr={imgArr} />
                          </div>
                          <div className="room-details-div">
                            <div className="room-header">{room.name}</div>
                            <div className="room-details-text">
                              {room?.beds[0]?.count === undefined
                                ? ""
                                : room?.beds[0]?.count + "-"}
                              {room?.beds[0]?.description === undefined
                                ? ""
                                : room?.beds[0]?.description !== undefined
                                ? room?.beds[0]?.description?.split("bed")[0] +
                                  "bed"
                                : ""}
                            </div>
                            <div className="facilities-room-div">
                              {room.amenities.slice(0, 9).map(
                                (
                                  amenity: {
                                    name: string;
                                    amenityType: string;
                                    extractedJson: any;
                                  },
                                  index: number
                                ) => {
                                  return (
                                    <>
                                      <span
                                        key={`package_room_amenities_${index}`}
                                        style={{
                                          width: "14px",
                                        }}
                                      >
                                        <img
                                          src={getIcon(amenity.amenityType)}
                                          alt=""
                                          className="img-fluid"
                                        />
                                      </span>
                                      <span>{amenity.name}&nbsp;</span>
                                      <span>
                                        {amenity?.extractedJson?.toBePaid &&
                                          " ($)"}
                                      </span>
                                    </>
                                  );
                                }
                              )}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            <div className="room-count-div">
                              <div className="dropdown-label">Meals:</div>
                              <select
                                disabled={!roomFlag}
                                className="room-type-dropdown"
                                style={{ backgroundPosition: "195px" }}
                                value={roomMealType}
                                onChange={(e) => selectMealPlan(e.target.value)}
                              >
                                <option value={0}>Choose a meal plan</option>
                                {propertyDetails?.mealPlanList &&
                                  propertyDetails?.mealPlanList?.length > 0 &&
                                  propertyDetails?.mealPlanList
                                    .find(
                                      (mealPlan: { roomTypeId: any }) =>
                                        mealPlan.roomTypeId === room.id
                                    )
                                    .mealPlan?.map((mealPlanName: any) => {
                                      return (
                                        <option value={mealPlanName.code}>
                                          {mealPlanName.dexcription}
                                        </option>
                                      );
                                    })}
                              </select>
                            </div>
                            <div className="room-count-div">
                              <div className="dropdown-label">Rooms:</div>
                              <select
                                className="room-type-dropdown"
                                disabled={!roomFlag}
                                value={roomNum}
                                style={{
                                  width: "100px",
                                  backgroundPosition: "195px",
                                }}
                                onChange={(e) => {
                                  selectRoomNum(e.target.value);
                                }}
                              >
                                <option value={0}>
                                  Select number of rooms
                                </option>
                                <option value={1}>{"1 Room"}</option>
                                <option value={2}>{"2 Rooms"}</option>
                                <option value={3}>{"3 Rooms"}</option>
                                <option value={4}>{"4 Rooms"}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        {/* </div> */}
                        {accordionArray.includes(room.id) && (
                          <div className="room-media-gallery">
                            {room.mediaFolder.files.map((images: any) => {
                              return (
                                <div className="room-media-gallery-image">
                                  <img
                                    className="property-stay-img"
                                    src={`https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${propertyDetails.mediaFolder.url}/${images.fileName}`}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {/* </div> */}
                      </>
                    );
                  })
                : roomTypes &&
                  roomTypes[0] &&
                  roomTypes.map((room: any) => {
                    return (
                      <>
                        <div className="room-info test">
                          <div>
                            <input type="radio" />
                          </div>
                          <div style={{ width: "113.386px" }}>
                            <RoomTypesContainer
                              imageArr={room.roomType.images}
                            />
                          </div>
                          <div className="room-details-div">
                            <div className="room-header">
                              {room.roomType.name}
                            </div>
                            <p
                              className="room-details-text"
                              style={{ marginTop: "0px", minWidth: "164px" }}
                            >
                              {"2 Beds - 1 King, 1 Double"}
                            </p>
                          </div>
                          <div className="property-facilities-stays">
                            {room.roomType.amenities.slice(0, 5).map(
                              (
                                amenity: {
                                  name: string;
                                  icon: string;
                                  extractedJson: any;
                                },
                                index: number
                              ) => {
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "10px",
                                    }}
                                    key={`package_room_amenities_${index}`}
                                    className="stays-item"
                                  >
                                    <span style={{ width: "20px" }}>
                                      <img
                                        src={getIcon(amenity.icon)}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </span>
                                    <div>{amenity.name}&nbsp;</div>
                                    <span>
                                      {amenity?.extractedJson?.toBePaid &&
                                        " ($)"}
                                    </span>
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "5px",
                            }}
                          >
                            <div className="room-count-div">
                              <div className="dropdown-label">Meals:</div>
                              <select
                                className="room-type-dropdown"
                                style={{ backgroundPosition: "195px" }}
                              >
                                <option>Bed and Breakfast</option>
                                <option>Full board</option>
                                <option>Room only</option>
                              </select>
                            </div>
                            <div className="room-count-div">
                              <div className="dropdown-label">Rooms:</div>
                              <select
                                className="room-type-dropdown"
                                style={{
                                  backgroundPosition: "195px",
                                  margin: "auto",
                                }}
                              >
                                <option>{"1 Room"}</option>
                                <option>{"2 Rooms"}</option>
                                <option>{"3 Rooms"}</option>
                                <option>{"4 Rooms"}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        {accordionArray.includes(room.roomType.id) && (
                          <div className="room-media-gallery">
                            {room.roomType.images.map((images: any) => {
                              return (
                                <div className="room-media-gallery-image">
                                  <img
                                    className="property-stay-img"
                                    src={images.imageUrl}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {/* </div> */}
                      </>
                    );
                  })}
            </div>
          </div>
        )}
      </>
    );
  }
);

export default ItineraryStays;
