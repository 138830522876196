import { htmlToText } from "html-to-text";
import * as jose from "jose";
import hkdf from "@panva/hkdf";
import { CityModel } from "src/models/models";

export const formatMoney = (amount: string, decimalCount = 0, sections = 3) => {
  try {
    // var re = '\\d(?=(\\d{' + (sections) + '})+' + (decimalCount > 0 ? '\\.' : '$') + ')';
    // return parseInt(amount).toFixed(Math.max(0, ~~decimalCount)).replace(new RegExp(re, 'g'), '$&,');
    return parseInt(amount)
      .toFixed(Math.max(0, ~~decimalCount))
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
  } catch (e) {
    console.log(e);
  }
};

export const capitalizeFirstLetter = (string?: string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

export const toCamelCase = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export const createImageAltText = (imageCaption: string) => {
  if (
    imageCaption == null ||
    imageCaption === undefined ||
    imageCaption.length === 0
  ) {
    return "";
  }
  let imageCaptionText = imageCaption.split(".")[0];
  const imageCaptionParts = imageCaptionText.split("-");
  const capitalizedParts = imageCaptionParts.map(
    (part: string) => part.charAt(0).toUpperCase() + part.slice(1)
  );

  return capitalizedParts.join(" ");
};

export const mapSortParam = (param: string) => {
  let displayParam = "";
  switch (param) {
    case "sort.NewlyAdded":
      displayParam = "Newly added";
      break;
    case "sort.BestSelling":
      displayParam = "Best Selling";
      break;
    case "sort.VoyaahRecommended":
      displayParam = "Voyaah Recommended";
      break;
    case "sort.Discount":
      displayParam = "Discount";
      break;
    case "sort.PriceLowToHigh":
      displayParam = "Price low to high";
      break;
    case "sort.PriceHighToLow":
      displayParam = "Price high to low";
      break;
    case "sort.NameAtoZ":
      displayParam = "A to Z";
      break;
    case "sort.NameZtoA":
      displayParam = "Z to A";
      break;
    default:
      break;
  }
  return displayParam;
};

export const modifyURLWithBlurTag = (imageUrl: string) => {
  if (imageUrl) {
    const re = /(?=\.)/g;
    const stringArray = imageUrl.split(re);
    stringArray[stringArray.length - 1] = `-blur${
      stringArray[stringArray.length - 1]
    }`;

    if (stringArray[stringArray.length - 1] !== undefined) {
      return stringArray.join("");
    } else {
      console.log(`Unknown image extension: ${imageUrl}`);
    }
  }
};

const widths = [
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0.2796875, 0.2765625, 0.3546875, 0.5546875, 0.5546875,
  0.8890625, 0.665625, 0.190625, 0.3328125, 0.3328125, 0.3890625, 0.5828125,
  0.2765625, 0.3328125, 0.2765625, 0.3015625, 0.5546875, 0.5546875, 0.5546875,
  0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875,
  0.2765625, 0.2765625, 0.584375, 0.5828125, 0.584375, 0.5546875, 1.0140625,
  0.665625, 0.665625, 0.721875, 0.721875, 0.665625, 0.609375, 0.7765625,
  0.721875, 0.2765625, 0.5, 0.665625, 0.5546875, 0.8328125, 0.721875, 0.7765625,
  0.665625, 0.7765625, 0.721875, 0.665625, 0.609375, 0.721875, 0.665625,
  0.94375, 0.665625, 0.665625, 0.609375, 0.2765625, 0.3546875, 0.2765625,
  0.4765625, 0.5546875, 0.3328125, 0.5546875, 0.5546875, 0.5, 0.5546875,
  0.5546875, 0.2765625, 0.5546875, 0.5546875, 0.221875, 0.240625, 0.5, 0.221875,
  0.8328125, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.3328125, 0.5,
  0.2765625, 0.5546875, 0.5, 0.721875, 0.5, 0.5, 0.5, 0.3546875, 0.259375,
  0.353125, 0.5890625,
];
const avg = 0.5279276315789471;

export const measureText = (str: string, fontSize: any, scalingFactor: any) => {
  return (
    Array.from(str).reduce(
      (acc, cur) => acc + (widths[cur.charCodeAt(0)] ?? avg),
      0
    ) *
    fontSize *
    scalingFactor
  );
};

export const numLines = (
  para: any,
  fontSize: any,
  scalingFactor: any,
  widthInPx: any
) => {
  //    const html = '<h1>Hello World!</h1><p>This is some HTML formatted text.</p><p> and a list<ul><li> one </li><li> two</li></ul>';
  const plainText = htmlToText(para);
  // console.log(plainText); // Hello World!\nThis is some HTML formatted text.
  const planTextLines = plainText.split("\n");
  var totalLines = 0;
  for (let para of planTextLines) {
    // console.log("current para:", para, para.length);
    if (para.length === 0) {
      // console.log("skipping");
      continue;
    }
    var strToSplit = para.replace(/[.,;]/g, " ");
    var strings = strToSplit.split(" ");
    var wordSizes = [];
    for (let item of strings) {
      wordSizes.push(measureText(item, fontSize, scalingFactor));
    }
    var lines = 1;
    var lineWidthUsed = 0;
    for (let i = 0; i < wordSizes.length; i++) {
      if (lineWidthUsed + wordSizes[i] > widthInPx) {
        lines++;
        lineWidthUsed = wordSizes[i];
      } else {
        lineWidthUsed = lineWidthUsed + wordSizes[i];
      }
    }
    // console.log('Number of lines' , lines);
    totalLines = totalLines + lines;
  }
  // console.log('total lines ', totalLines);
  return totalLines;
};

export const ect = async (c: string, params: any) => {
  const CLIENT_TOKEN_AGE = 24 * 60 * 60;
  let ipv = c.split("."),
    d = new Date();
  let s = `${d.getUTCFullYear()}/${ipv[2]}/${ipv[0]}/${d.getUTCDate()}/${
    ipv[3]
  }/${ipv[1]}`;
  const token = {
      ...params,
      sub: c,
    },
    maxAge = CLIENT_TOKEN_AGE;

  const es = await hkdf(
    "sha256",
    process.env.REACT_APP_CLIENT_SECRET || "",
    "",
    s,
    32
  );
  return new jose.EncryptJWT(token)
    .setProtectedHeader({
      alg: "dir",
      enc: "A256GCM",
    })
    .setIssuedAt()
    .setExpirationTime(Date.now() + maxAge)
    .encrypt(es);
};

export function findMissingDates(
  includeDates: any[],
  startDate: string,
  endDate: string
) {
  const missingDates = [];

  const currentDate = new Date(startDate);
  const endDateTime = new Date(endDate).getTime();

  while (currentDate.getTime() < endDateTime) {
    const dateString = currentDate.toISOString().split("T")[0];
    const dateExists = includeDates?.some(
      (item: any) =>
        new Date(new Date(item).setHours(5, 30, 0, 0))
          .toISOString()
          .split("T")[0] === dateString
    );

    if (!dateExists) {
      missingDates.push(dateString);
    }

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return missingDates;
}

export const interestsMapping = {
  beaches: "Beaches",
  hills: "Hills & Mountains",
  backwaters: "Backwaters",
  wildlifes: "Wildlifes & Safari",
  adventures: "Adventures",
  wellness: "Wellness",
  romance: "Romance/Honeymoon",
  cruises: "Cruises",
  family: "Family Friendly",
  luxury: "Uber Luxury",
  unique: "Unique Stays",
  city: "City Breaks",
  smallGroups: "Small Groups",
  rail: "Rail Journeys/Discoveries",
  asia: "Asia",
  europe: "Europe",
  na: "North America",
  sa: "South America",
  africa: "Africa",
  anz: "Australia & New Zealand",
  si: "South India",
  ni: "North India",
  ei: "East India",
  wi: "West India",
  luxuryProperty: "Luxury",
  boutique: "Boutique",
  hotel: "Hotel",
  resort: "Resort",
  villa: "Villa",
  homestay: "Homestay",
  glamping: "Glamping",
};

export var themes = [
  { code: "theme.Beaches", description: "Beaches" },
  { code: "theme.Hills", description: "Hills & Mountains" },
  { code: "theme.Backwaters", description: "Backwaters" },
  { code: "theme.Wildlifes", description: "Wildlifes & Safari" },
  { code: "theme.Adventures", description: "Adventures" },
  { code: "theme.Wellness", description: "Wellness" },
  { code: "theme.Architecture", description: "Architecture/Historical" },
  { code: "theme.Romance", description: "Romance/Honeymoon" },
  { code: "theme.Cruises", description: "Cruises" },
  { code: "theme.Family", description: "Family Friendly" },
  { code: "theme.Luxury", description: "Uber Luxury" },
  { code: "theme.Unique", description: "Unique Stays" },
  { code: "theme.City", description: "City Breaks" },
  { code: "theme.SmallGroups", description: "Small Groups" },
  { code: "theme.Rail", description: "Rail Journeys/Discoveries" },
];

export var location = [
  { code: "location.Asia", description: "Asia" },
  { code: "location.Europe", description: "Europe" },
  { code: "location.NA", description: "North America" },
  { code: "location.SA", description: "South America" },
  { code: "location.Africa", description: "Africa" },
  { code: "location.ANZ", description: "Australia & New Zealand" },
  { code: "location.SI", description: "South India" },
  { code: "location.NI", description: "North India" },
  { code: "location.EI", description: "East India" },
  { code: "location.WI", description: "West India" },
];

export var property = [
  { code: "propertyType.Luxury", description: "Luxury" },
  { code: "propertyType.Boutique", description: "Boutique" },
  { code: "propertyType.Hotel", description: "Hotel" },
  { code: "propertyType.Resort", description: "Resort" },
  { code: "propertyType.Villa", description: "Villa" },
  { code: "propertyType.Homestay", description: "Homestay" },
  { code: "propertyType.Glamping", description: "Glamping" },
];

export const TransportMode = [
  { code: "transportMode.Train", description: "Train", averageSpeed: 100 },
  { code: "transportMode.Flight", description: "Flight", averageSpeed: 890 },
  { code: "transportMode.Car", description: "Car", averageSpeed: 60 },
  { code: "transportMode.Bus", description: "Bus", averageSpeed: 65 },
  {
    code: "transportMode.Cruise",
    description: "Boat/Cruise",
    averageSpeed: 48,
  },
  {
    code: "transportMode.TwoWheeler",
    description: "Two Wheeler",
    averageSpeed: 30,
  },
];

function toRadians(degrees: number): number {
  return degrees * (Math.PI / 180);
}

export const haversineDistance = (entry: CityModel, exit: CityModel) => {
  if (entry && exit) {
    const R = 6371; // Earth radius in kilometers
    const dLat = toRadians(
      parseFloat(exit.latitude ?? "0") - parseFloat(entry?.latitude ?? "0")
    );
    const dLon = toRadians(
      parseFloat(exit.longitude ?? "0") - parseFloat(entry.longitude ?? "0")
    );

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(parseFloat(entry.latitude ?? "0"))) *
        Math.cos(toRadians(parseFloat(exit.latitude ?? "0"))) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  }

  return 0;
};

export const haversineDistanceForCoOrds = (
  entryLat: any,
  entryLong: any,
  exitLat: any,
  exitLong: any
) => {
  const R = 6371; // Earth radius in kilometers
  const dLat = toRadians(
    parseFloat(exitLat ?? "0") - parseFloat(entryLat ?? "0")
  );
  const dLon = toRadians(
    parseFloat(exitLong ?? "0") - parseFloat(entryLong ?? "0")
  );

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(parseFloat(entryLat ?? "0"))) *
      Math.cos(toRadians(parseFloat(exitLat ?? "0"))) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
};

export const generateTimeSlots = () => {
  const timeSlots = [];
  for (let i = 0; i < 24; i++) {
    const hour = i % 12 === 0 ? 12 : i % 12;
    const period = i < 12 ? "AM" : "PM";
    timeSlots.push(`${hour} ${period}`);
  }

  return timeSlots;
};

export const returnPlaceOfInterestUrl = (imageName: string) => {
  return `https://${
    process.env.REACT_APP_CONTENT_S3_BUCKET
  }.s3.amazonaws.com/original_images/${imageName.substring(
    imageName?.indexOf("_") + 1
  )}`;
};

class Chromosome {
  constructor(public cityList: CityModel[]) {}

  calculateDistance(): number {
    let totalDistance = 0;
    for (let i = 0; i < this.cityList.length - 1; i++) {
      totalDistance += haversineDistance(
        this.cityList[i],
        this.cityList[i + 1]
      );
    }
    return totalDistance;
  }

  getFitness(): number {
    return 1 / this.calculateDistance();
  }
}

function shuffleArray(array: any[]): void {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

function generateInitialPopulation(
  populationSize: number,
  cities: CityModel[],
  startCityIndex: number,
  endCityIndex: number
): Chromosome[] {
  const [startCity, endCity] = [cities[startCityIndex], cities[endCityIndex]];
  let otherCities = cities.filter(
    (city) => city !== startCity && city !== endCity
  );
  let population: Chromosome[] = [];

  for (let i = 0; i < populationSize; i++) {
    shuffleArray(otherCities);
    let path = [startCity, ...otherCities, endCity];
    population.push(new Chromosome(path));
  }
  return population;
}

function tournamentSelection(
  population: Chromosome[],
  tournamentSize: number
): Chromosome {
  let tournament: Chromosome[] = [];
  for (let i = 0; i < tournamentSize; i++) {
    let randomIndex = Math.floor(Math.random() * population.length);
    tournament.push(population[randomIndex]);
  }
  tournament.sort((a, b) => b.getFitness() - a.getFitness());
  return tournament[0];
}

function orderedCrossover(
  parent1: Chromosome,
  parent2: Chromosome
): Chromosome {
  // Assuming the first and last cities are fixed as start and end points
  let childPath = [
    parent1.cityList[0],
    ...parent1.cityList.slice(1, parent1.cityList.length - 1),
    parent1.cityList[parent1.cityList.length - 1],
  ];

  let crossoverPoint1 = Math.floor(Math.random() * (childPath.length - 3)) + 1;
  let crossoverPoint2 =
    Math.floor(Math.random() * (childPath.length - crossoverPoint1 - 2)) +
    crossoverPoint1;

  for (let i = crossoverPoint1; i <= crossoverPoint2; i++) {
    childPath[i] = parent1.cityList[i];
  }

  parent2.cityList.forEach((city, index) => {
    if (
      !childPath.includes(city) &&
      index !== 0 &&
      index !== parent2.cityList.length - 1
    ) {
      for (let i = 1; i < childPath.length - 1; i++) {
        if (childPath[i] === null) {
          childPath[i] = city;
          break;
        }
      }
    }
  });

  return new Chromosome(childPath);
}

function mutate(chromosome: Chromosome, mutationRate: number) {
  for (let i = 1; i < chromosome.cityList.length - 1; i++) {
    if (Math.random() < mutationRate) {
      let j = Math.floor(Math.random() * (chromosome.cityList.length - 2)) + 1;
      if (i !== j) {
        [chromosome.cityList[i], chromosome.cityList[j]] = [
          chromosome.cityList[j],
          chromosome.cityList[i],
        ];
      }
    }
  }
}

export function runGA(
  cities: CityModel[],
  populationSize: number,
  generations: number,
  startCity: number = 0,
  endCity: number = 0
): any {
  let errorCities = cities.filter((X: any) => !X.latitude || !X.longitude);

  let population: Chromosome[] = [];
  if (errorCities.length === 0) {
    population = generateInitialPopulation(
      populationSize,
      cities,
      startCity,
      endCity
    );

    for (let gen = 0; gen < generations; gen++) {
      let newPopulation: Chromosome[] = [];

      for (let i = 0; i < populationSize; i++) {
        const parent1 = tournamentSelection(population, 5);
        const parent2 = tournamentSelection(population, 5);
        let offspring = orderedCrossover(parent1, parent2);
        mutate(offspring, 0.01);
        newPopulation.push(offspring);
      }

      population = newPopulation;
    }

    population.sort((a, b) => a.calculateDistance() - b.calculateDistance());
    return { error: false, population: population[0] }; // Return the best path
  } else {
    return { error: true, population: [] };
  }
}

export const getTimeInHourFormat = (selectedTime: string) => {
  const [time, modifier] = selectedTime.split(" ");

  let hours: any;
  if (time === "12") {
    hours = "00";
  } else {
    hours = time;
  }

  if (modifier === "PM") {
    hours = parseInt(hours!, 10) + 12;
  }

  return `${String(hours).padStart(2, "0")}:00:00`;
};

export const getTimeInMeridianFormat = (selectedTime: string) => {
  const [hour, minute, second] = selectedTime.split(":");

  let hourNumber = parseInt(hour);
  const amOrPm = hourNumber >= 12 ? "PM" : "AM";
  hourNumber = hourNumber % 12 || 12;

  return `${hourNumber} ${amOrPm}`;
};
