import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker, { ReactDatePicker } from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import moment from "moment";
import { Bars } from "react-loader-spinner";
import ReactGA from "react-ga4";
import { getAnalyticsEnabled } from "../../helpers/backend_helper";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../helpers/firebase";
import { addDays } from "date-fns";
import {
  findMissingDates,
  formatMoney,
  toCamelCase,
} from "../../helpers/utils";

import {
  getPackagePricing,
  clearPackagePricing,
} from "../../store/details/actions";

import itineraryPlanStore from "./../../store/itineraryPlanStore";
import { getTestEnabled } from "../../helpers/backend_helper";
import "./Details.scss";

const RightContainer = observer((props: any) => {
  const {
    packageData,
    selectedRoomType,
    stateData,
    urlCouponCode,
    availabilityData,
  } = props;
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [DayDiff, setDayDiff] = useState<any>();
  const [adultCount] = useState(8);
  const [childCount] = useState(8);
  const [maxAdultsAllowed, setMaxAdultsAllowed] = useState(0);
  const [maxChildrenAllowed, setMaxChildrenAllowed] = useState(0);
  const [maxPersonsAllowed, setMaxPersonsAllowed] = useState(0);
  const [selectedAdultCount, setSelectedAdultCount] = useState<any>(2);
  const [selectedChildrenCount, setSelectedChildrenCount] = useState<any>(0);
  const [maxRoomCount] = useState<any>(10);
  const [selectedRoomCount, setSelectedRoomCount] = useState<any>(1);
  const [maxChildAge, setMaxChildAge] = useState<any>(17);
  const [fare, setFare] = useState<any>(0);
  const [property, setProperty] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [packageDuration, setPackageDuration] = useState(0);
  const [userCouponCode, setUserCouponCode] = useState("");
  const [userCouponCodeErrorMessage, setUserCouponCodeErrorMessage] =
    useState("");
  const [userCouponDetails, setUserCouponDetails] = useState(false);
  const [childData, setChildData] = useState<any>([]);
  const [isCheckAvailabilityBtnEnabled, setIsCheckAvailabilityBtnEnabled] =
    useState(false);
  const [isContinueBtnEnabled, setIsContinueBtnEnabled] = useState(false);
  const [isCouponBtnEnabled, setIsCouponBtnEnabled] = useState(false);
  const [isAvailabilityChecked, setIsAvailabilityChecked] = useState(false);

  const checkInDateRef = useRef<ReactDatePicker>(null);
  const checkOutDateRef = useRef<ReactDatePicker>(null);

  const [includeDates, setIncludeDates] = useState<any[]>([]);
  const [includeDatesClone, setIncludeDatesClone] = useState<any[]>([]);
  const [showIncludeDates, setShowIncludeDates] = useState(true);
  const [includeDateIntervals, setIncludeDateIntervals] = useState<any[]>([]);
  const [isCouponCodeSuccess, setIsCouponCodeSuccess] = useState(false);

  const [cta, setCta] = useState<any[]>([]);
  const [ctd, setCtd] = useState<any[]>([]);
  const [stopSell, setStopSell] = useState<any[]>([]);
  const [minStay, setMinStay] = useState<any[]>([]);

  const [dateErrorMessage, setDateErrorMessage] = useState("");
  const [lastDateOfInventory, setLastDateOfInventory] = useState(false);
  const [inventoryFetchDays] = useState(89);
  const [isPartnerSite, setIsPartnerSite] = useState<boolean>(false);
  const [pointsEarnedFormat, setPointsEarnedFormat] = useState<string>("");

  const [totalPricing, setTotalPricing] = useState<any>({
    extraAdult: 0,
    extraAdultCost: 0,
    extraChildrenCost: 0,
    extraChildren: 0,
    totalFare: 0,
  });

  const [filterPropertPrice, setFilterPropertyPrice] = useState<any[]>([]);

  const { packageAvailability, packageAvailabilityLoader } = useSelector(
    (state: any) => state.PackageAvailabilityReducer
  );

  const { roomDetails } = useSelector((state: any) => state.RoomDetailReducer);

  const isTestingEnabled = getTestEnabled();
  const isAnalyticsEnabled = getAnalyticsEnabled();
  useEffect(() => {
    if (
      process.env.REACT_APP_PARTNER_SITE &&
      process.env.REACT_APP_PARTNER_SITE === "true"
    ) {
      setIsPartnerSite(true);
    }

    if (process.env.REACT_APP_POINT_EARNED_FORMAT) {
      setPointsEarnedFormat(process.env.REACT_APP_POINT_EARNED_FORMAT);
    }
    return () => {
      dispatch(clearPackagePricing());
      setUserCouponCode("");
    };
  }, []);

  useEffect(() => {
    const detailPropertyData = JSON.parse(
      `${sessionStorage.getItem("detailPropertyData")}`
    );

    if (packageData?.type === "packageType.Holiday") {
      const a = _.compact(
        _.map(packageAvailability, function (a) {
          if (a.lastDateOfInventory) {
            setLastDateOfInventory(true);
          }
          if (a.available === true) {
            return new Date(a.date).setHours(0, 0, 0, 0);
          }
        })
      );

      if (a.length > 0) {
        setShowIncludeDates(true);
      } else {
        setShowIncludeDates(false);
      }
      setIncludeDates(includeDates.concat(a).sort());
      setIncludeDatesClone(includeDatesClone.concat(a).sort());

      if (detailPropertyData?.calenderInterVal) {
        setIncludeDates(detailPropertyData?.calenderInterVal);
        setIncludeDatesClone(detailPropertyData?.calenderInterVal);
      }

      const b = _.compact(
        _.map(packageAvailability, function (a) {
          if (a.cta) {
            return new Date(a.date).toDateString();
          }
        })
      );
      setCta(cta.concat(b));
      let startDate = new Date(packageData?.travelValidFrom);
      // if (startDate < new Date()) {
      //   startDate = new Date();
      // }
      let endDate = new Date(packageData?.travelValidTo);
      const updatedLastDate = new Date(
        packageAvailability[packageAvailability?.length - 1]?.date
      );

      if (endDate < updatedLastDate) {
        endDate = updatedLastDate;
      }

      if (detailPropertyData?.calenderInterVal) {
        endDate = new Date(
          detailPropertyData?.calenderInterVal[
            detailPropertyData?.calenderInterVal.length - 1
          ]
        );
      }

      setIncludeDateIntervals([{ start: startDate, end: endDate }]);
    } else if (packageData?.type === "packageType.Staycation") {
      const a = _.compact(
        _.map(packageAvailability, function (a) {
          if (a.lastDateOfInventory) {
            setLastDateOfInventory(true);
          }
          if (a.available === true) {
            return new Date(a.date).setHours(0, 0, 0, 0);
          }
        })
      );
      if (a.length > 0) {
        setShowIncludeDates(true);
      } else {
        setShowIncludeDates(false);
      }

      setIncludeDates(includeDates.concat(a).sort());
      setIncludeDatesClone(includeDatesClone.concat(a).sort());

      if (detailPropertyData?.calenderInterVal) {
        setIncludeDates(detailPropertyData?.calenderInterVal);
        setIncludeDatesClone(detailPropertyData?.calenderInterVal);
      }

      const b = _.compact(
        _.map(packageAvailability, function (a) {
          if (a.cta) {
            return new Date(a.date).toDateString();
          }
        })
      );
      setCta(cta.concat(b));
      // setCta([new Date("2022-12-20T12:00:00+00:00").toDateString()]);

      const c = _.compact(
        _.map(packageAvailability, function (a) {
          if (a.ctd) {
            return new Date(a.date).toDateString();
          }
        })
      );
      setCtd(ctd.concat(c));
      // setCtd([new Date("2022-12-23T12:00:00+00:00").toDateString()]);

      const d = _.compact(
        _.map(packageAvailability, function (a) {
          if (a.stopSell) {
            return new Date(a.date).toDateString();
          }
        })
      );
      setStopSell(stopSell.concat(d));
      // setStopSell([
      //   new Date("2023-01-06T12:00:00+00:00").toDateString(),
      //   new Date("2023-01-10T12:00:00+00:00").toDateString(),
      // ]);
    }
  }, [packageAvailability, roomDetails.id]);

  useEffect(() => {
    const childDataSize = _.size(childData);
    const selectedChildCount = parseInt(selectedChildrenCount);
    if (selectedChildCount === 0) {
      setChildData([]);
    } else if (selectedChildCount > childDataSize) {
      const childDataObj = _.range(selectedChildCount - childDataSize).map(
        (item: any) => {
          return {
            id: `child_${childDataSize + item + 1}_age`,
            title: `Child ${childDataSize + item + 1} Age`,
            age: "1",
          };
        }
      );
      setChildData(childData.concat(childDataObj));
    } else if (selectedChildCount < childDataSize) {
      let childDataObj = [...childData];
      childDataObj.splice(
        childDataObj.length - (childDataSize - selectedChildCount)
      );
      setChildData(childDataObj);
    }
  }, [selectedChildrenCount]);

  useEffect(() => {
    const data = {
      packageData: packageData,
      startDate: startDate ? moment(startDate).format("MM-DD-YYYY") : "",
      endDate: endDate ? moment(endDate).format("MM-DD-YYYY") : "",
      noOfRooms: selectedRoomCount,
      noOfAdults: selectedAdultCount,
      noOfChilds: selectedChildrenCount,
      roomType: selectedRoomType,
      dayDiff: DayDiff,
      couponCode: `${userCouponCode ? userCouponCode : ""}`,
      vendorName: `${userCouponCode ? "newvoyaah" : ""}`,
      childData: childData,
    };
    setProperty(data);
  }, [
    packageData,
    startDate,
    endDate,
    selectedRoomCount,
    selectedAdultCount,
    selectedChildrenCount,
    selectedRoomType,
    DayDiff,
    userCouponCode,
    childData,
  ]);

  useEffect(() => {
    if (
      startDate &&
      endDate &&
      selectedAdultCount &&
      selectedChildrenCount >= 0 &&
      selectedRoomCount
    ) {
      setIsCheckAvailabilityBtnEnabled(true);
      setIsContinueBtnEnabled(true);
    }
  }, [
    startDate,
    endDate,
    selectedRoomCount,
    selectedAdultCount,
    selectedChildrenCount,
    selectedRoomType,
  ]);

  useEffect(() => {
    if (!stateData) {
      if (userCouponCode) {
        // if (!user) {
        //   setUserCouponCodeErrorMessage("Please login to apply coupon");
        // } else {
        if (isAvailabilityChecked) {
          setIsCouponBtnEnabled(true);
          setIsContinueBtnEnabled(false);
        } else if (!isAvailabilityChecked) {
          setUserCouponCodeErrorMessage(
            "Please check availability before applying coupon"
          );
        }
        // }
      } else {
        setUserCouponCodeErrorMessage("");
        setIsCouponBtnEnabled(false);
        if (isAvailabilityChecked) {
          setIsContinueBtnEnabled(true);
          setIsCheckAvailabilityBtnEnabled(false);
        } else if (
          !isAvailabilityChecked &&
          startDate &&
          endDate &&
          selectedAdultCount &&
          selectedChildrenCount >= 0 &&
          selectedRoomCount
        ) {
          setIsContinueBtnEnabled(false);
          setIsCheckAvailabilityBtnEnabled(true);
        } else {
          setIsContinueBtnEnabled(false);
          setIsCheckAvailabilityBtnEnabled(false);
        }
      }
    }
  }, [userCouponCode]);
  const countStartDays = (date: any, dayIndexTo: any) => {
    let startDate = moment(date, "YYY-MM-DD");
    if (dayIndexTo != 1) {
      startDate.add(dayIndexTo - 1, "days");
    }
    let formattedDate = startDate.format("YYYY-MM-DD");
    return formattedDate;
  };

  const getDateStrInYYYYMMDD = (date: Date) => {
    if (!date) {
      return "";
    }

    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  };

  const countEndDays = (date: any, dayIndexTo: any) => {
    let startDate = moment(date, "YYY-MM-DD");
    if (dayIndexTo != 1) {
      startDate.add(dayIndexTo, "days");
    }
    let formattedDate = startDate.format("YYYY-MM-DD");
    return formattedDate;
  };
  const handleCheckAvailabilityClick = useCallback(() => {
    if (
      packageData &&
      packageData.id &&
      startDate &&
      endDate &&
      selectedRoomCount &&
      itineraryPlanStore.stayRoomTypes.length > 0 &&
      selectedAdultCount &&
      ((packageData.type === "packageType.Staycation" && !selectedRoomType) ||
        (packageData.type === "packageType.Holiday" && !selectedRoomType))
    ) {
      setErrorMessage("");
      if (isAnalyticsEnabled) {
        ReactGA.event({
          action: `${window.location.pathname} price_enquiry`,
          category: `details_page`,
        });
      }
      itineraryPlanStore.rightContainerData.numRooms = selectedRoomCount;
      itineraryPlanStore.rightContainerData.numChildren = selectedChildrenCount;
      itineraryPlanStore.rightContainerData.numAdults = selectedAdultCount;
      itineraryPlanStore.rightContainerData.fromDate = startDate;
      itineraryPlanStore.rightContainerData.toDate = endDate;
      itineraryPlanStore.rightContainerData.childAges = childData?.map(
        (a: any) => parseInt(a.age)
      );
      itineraryPlanStore.setRightContainerData(
        itineraryPlanStore.rightContainerData
      );

      const data: any[] = [];
      let toDate = new Date(startDate).setHours(0, 0, 0, 0);
      let fromDate = new Date(startDate).setHours(0, 0, 0, 0);
      if (itineraryPlanStore.stayRoomTypes.length > 0) {
        itineraryPlanStore.stayRoomTypes.forEach((ele: any, i: any) => {
          let dayIndexFrom =
            packageData.itineraryPlans[0].opportunitystays[i]?.dayIndexFrom;
          let dayIndexTo =
            packageData.itineraryPlans[0].opportunitystays[i]?.dayIndexTo;

          fromDate = toDate;
          toDate =
            packageData.type === "packageType.Staycation"
              ? endDate
              : new Date(
                  new Date(fromDate).setDate(
                    new Date(fromDate).getDate() +
                      (dayIndexTo - dayIndexFrom) +
                      1
                  )
                ).setHours(0, 0, 0, 0);

          data.push({
            propertyId: ele.propertyId,
            roomTypeId: ele.roomId,
            fromDate: getDateStrInYYYYMMDD(new Date(fromDate)),
            toDate: getDateStrInYYYYMMDD(new Date(toDate)),
            numRooms: selectedRoomCount,
            numAdults: selectedAdultCount,
            numChildren: selectedChildrenCount,
            childAges: childData?.map((a: any) => parseInt(a.age)),
          });
        });
      } else {
        packageData.itineraryPlans[0].opportunitystays.forEach(
          (elem: any, i: any) => {
            fromDate = toDate;
            toDate =
              packageData.type === "packageType.Staycation"
                ? endDate
                : new Date(
                    new Date(fromDate).setDate(
                      new Date(fromDate).getDate() +
                        (elem?.dayIndexTo - elem?.dayIndexFrom) +
                        1
                    )
                  ).setHours(0, 0, 0, 0);

            data.push({
              propertyId: elem.propertyId,
              roomTypeId: elem.roomTypeId,
              fromDate: getDateStrInYYYYMMDD(new Date(fromDate)),
              toDate: getDateStrInYYYYMMDD(new Date(toDate)),
              numRooms: selectedRoomCount,
              numAdults: selectedAdultCount,
              numChildren: selectedChildrenCount,
              childAges: childData?.map((a: any) => parseInt(a.age)),
            });
          }
        );
      }
      itineraryPlanStore.setavailabilitypayload(data);
      itineraryPlanStore.setCalenderSelectedStatus(true);
      dispatch(getPackagePricing(data, "", "", packageData.id));
    } else {
      // setErrorMessage("Please check your selections");
    }
  }, [
    dispatch,
    packageData,
    startDate,
    endDate,
    selectedRoomCount,
    selectedAdultCount,
    selectedChildrenCount,
    selectedRoomType,
    userCouponCode,
    childData,
    itineraryPlanStore.stayRoomTypes,
  ]);
 
  const checkNAN = (data:any)=>{
    if(data === undefined || data === 'NaN'){
      return '';
    }else if(data !== 'NaN'){
      return data;
    }
  }
  useEffect(() => {
    const detailPropertyData = JSON.parse(
      `${sessionStorage.getItem("detailPropertyData")}`
    );

    if (packageData && packageData.id) {
      let fromDate = new Date();
      let toDate = new Date(
        new Date(fromDate).setDate(
          new Date(fromDate).getDate() + inventoryFetchDays
        )
      );

      let roomTypeId;
      if (
        detailPropertyData &&
        detailPropertyData.startDate &&
        detailPropertyData.endDate &&
        detailPropertyData.dayDiff &&
        detailPropertyData.calenderInterVal
      ) {
        const a = Math.floor(
          (inventoryFetchDays - detailPropertyData.dayDiff) / 2
        );

        fromDate = new Date(
          new Date(detailPropertyData.startDate.replace(/-/g, "/")).setDate(
            new Date(
              detailPropertyData.startDate.replace(/-/g, "/")
            ).getDate() - a
          )
        );
        if (fromDate < new Date()) {
          fromDate = new Date();
        }
        toDate = new Date(
          new Date(detailPropertyData.endDate.replace(/-/g, "/")).setDate(
            new Date(detailPropertyData.endDate.replace(/-/g, "/")).getDate() +
              a
          )
        );

        fromDate = new Date();
        toDate = new Date(
          new Date(fromDate).setDate(
            new Date(fromDate).getDate() + inventoryFetchDays
          )
        );
        roomTypeId = roomDetails.id;
      }

      if (fromDate > new Date(packageData.travelValidFrom)) {
        fromDate = new Date();
      } else {
        fromDate = new Date(packageData.travelValidFrom);
      }

      toDate = new Date(
        new Date(fromDate).setDate(
          new Date(fromDate).getDate() + inventoryFetchDays
        )
      );

      const localStorageData = localStorage.getItem("voyaahCustomerTracker");
      let customerProfileId = "";
      if (localStorageData) {
        customerProfileId = JSON.parse(localStorageData!).id;
      }

      if (packageData?.type === "packageType.Staycation") {
        const data = {
          packageId: packageData.id,
          roomTypeId:
            roomDetails?.id ??
            packageData?.itineraryPlans[0]?.opportunitystays[0]?.roomType.id,
          fromDate: fromDate.toISOString().slice(0, 10),
          toDate: toDate.toISOString().slice(0, 10),
        };
        // for staycation getpackageAvailabilty at first render
        setIncludeDates([]);
        setIncludeDatesClone([]);
        // dispatch(getPackageAvailability(data, customerProfileId));
      } else {
        // set package availability holidays case
        const firstPropertyId = packageData?.itinerary[0]?.property?.id;
        const data = {
          packageId: packageData.id,
          propertyId: firstPropertyId,
          fromDate: fromDate.toISOString().slice(0, 10),
          toDate: toDate.toISOString().slice(0, 10),
        };
        // dispatch(getPackageAvailability(data, customerProfileId));
      }
    }

    if (detailPropertyData) {
      showIncludeDates
        ? setStartDate(
            new Date(detailPropertyData?.startDate.replace(/-/g, "/"))
          )
        : setStartDate(null);
      showIncludeDates
        ? setEndDate(new Date(detailPropertyData?.endDate.replace(/-/g, "/")))
        : setEndDate(null);

      // setStartDate(new Date(detailPropertyData?.startDate.replace(/-/g, "/")));
      // setEndDate(new Date(detailPropertyData?.endDate.replace(/-/g, "/")));
      setSelectedAdultCount(detailPropertyData?.noOfAdults);
      setSelectedChildrenCount(detailPropertyData?.noOfChilds);
      setSelectedRoomCount(detailPropertyData?.noOfRooms);
      setChildData(detailPropertyData.childData);
      setUserCouponCode(detailPropertyData.couponCode);
      // handleCheckAvailabilityClick();
      // setErrorMessage("Please click on check availability & get updated price");
    }
    // let packageEndDate = new Date();
    let pd = packageData?.numNights;
    if (pd) {
      setPackageDuration(parseInt(pd));
      // packageEndDate.setDate(packageEndDate.getDate() + parseInt(pd));
      // setEndDate(packageEndDate);
    }
    setFare(packageData?.pricing?.totalFare);
    // TODO: Revisit This Logic
    // if (packageData?.packageValues?.maxChildAge) {
    //   setMaxChildAge(packageData?.packageValues?.maxChildAge);
    // }

    const urlCouponCode = localStorage.getItem("packageCouponCode");
    if (urlCouponCode) {
      const data = JSON.parse(urlCouponCode);
      if (data.packageId === packageData?.id) {
        setUserCouponCode(data.couponCode);
        handleApplyCouponCode();
      } else if (stateData?.couponCode) {
        setUserCouponCode(stateData.couponCode);
      } else {
        setUserCouponCode("");
      }
    }
    setErrorMessage("Please select dates");
  }, [packageData, roomDetails.id]);

  useEffect(() => {
    if (urlCouponCode) {
      setUserCouponCode(urlCouponCode);
      handleApplyCouponCode();
    } else if (stateData?.couponCode) {
      setUserCouponCode(stateData.couponCode);
    } else {
      setUserCouponCode("");
    }
  }, [urlCouponCode]);

  useEffect(() => {
    let maxAdultsAllowed =
      packageData?.itinerary?.[0]?.roomTypes?.[0]?.roomType?.numAdults;
    let maxChildrenAllowed =
      packageData?.itinerary?.[0]?.roomTypes?.[0]?.roomType?.numChildren;
    let maxPersonsAllowed =
      packageData?.itinerary?.[0]?.roomTypes?.[0]?.roomType?.maxGuests;

    setMaxAdultsAllowed(maxAdultsAllowed);
    setMaxChildrenAllowed(maxChildrenAllowed);
    setMaxPersonsAllowed(maxPersonsAllowed);
    setIsAvailabilityChecked(false);
    setLastDateOfInventory(false);
    setIncludeDatesClone([]);
    dispatch(clearPackagePricing());
  }, [packageData, selectedRoomType]);

  useEffect(() => {
    var diff = (endDate - startDate) / 1000 / 60 / 60 / 24;
    setDayDiff(Math.round(diff));

    const startDateObj = _.filter(packageAvailability, function (a: any) {
      return (
        new Date(a.date).setHours(0, 0, 0, 0) ===
        new Date(startDate).setHours(0, 0, 0, 0)
      );
    });
    // startDateObj["minStay"] = 3;

    const stopSellCheck = _.compact(
      _.map(stopSell, function (a) {
        if (
          startDate &&
          endDate &&
          new Date(a).getTime() >= startDate.getTime() &&
          new Date(a).getTime() <= endDate.getTime()
        ) {
          return new Date(a);
        }
      })
    );

    if (_.contains(cta, startDate?.toDateString())) {
      setDateErrorMessage(
        "Check-in is not allowed on selected date. Please change check-in date"
      );
    } else if (_.contains(ctd, endDate?.toDateString())) {
      setDateErrorMessage(
        "Check-out is not allowed on selected date. Please change check-out date"
      );
    } else if (stopSellCheck && stopSellCheck.length > 0) {
      setDateErrorMessage(
        "Booking is not available on selected date (" +
          moment(stopSellCheck[0]).format("DD/MM/YYYY") +
          "). Please change date"
      );
    } else if (
      startDateObj &&
      startDateObj.length > 0 &&
      startDateObj[0].minStay &&
      startDateObj[0].minStay > diff
    ) {
      setDateErrorMessage(
        "Minimum stay for this date is " +
          startDateObj[0].minStay +
          " Nights. Please change date"
      );
    } else {
      setDateErrorMessage("");
    }
  }, [startDate, endDate]);

  useEffect(() => {
    // this use Effect only run for setting end date in the checkout according to duration
    if (startDate) {
      let packageEndDate = new Date(startDate.valueOf());
      if (packageDuration) {
        packageEndDate.setDate(packageEndDate.getDate() + packageDuration);
        setEndDate(packageEndDate);
      }
    }
  }, [packageData, packageDuration, startDate]);

  useEffect(() => {
    let a = Math.ceil(parseInt(selectedAdultCount) / maxAdultsAllowed);
    a = isFinite(a) ? a : 0;
    let b = Math.ceil(parseInt(selectedChildrenCount) / maxChildrenAllowed);
    b = isFinite(b) ? b : 0;
    let c = Math.ceil(
      (parseInt(selectedAdultCount) + parseInt(selectedChildrenCount)) /
        maxPersonsAllowed
    );
    c = isFinite(c) ? c : 0;
    let noOfRooms = Math.max(a, b, c);
    if (noOfRooms > parseInt(selectedRoomCount)) {
      setSelectedRoomCount(noOfRooms);
    }
  }, [selectedAdultCount, selectedChildrenCount]);

  useEffect(() => {
    const checkInDate = startDate?.setHours(5, 30, 0, 0);
    const checkOutDate = endDate?.setHours(5, 30, 0, 0);
    const missingDates = findMissingDates(
      includeDates,
      checkInDate,
      checkOutDate
    );
    if (
      packageData?.type == "packageType.Staycation" &&
      missingDates?.length > 0
    ) {
      handleCheckAvailabilityClick();
    }
    if (startDate?.getTime() < endDate?.getTime() && missingDates?.length < 1) {
      handleCheckAvailabilityClick();
    }
    if (packageData?.type == "packageType.Holiday") {
      handleCheckAvailabilityClick();
    }
  }, [
    packageData,
    startDate,
    endDate,
    selectedRoomCount,
    selectedAdultCount,
    selectedChildrenCount,
    selectedRoomType,
    childData,
  ]);

  const { pricing, loader } = useSelector(
    (state: any) => state.PackageDetailsReducer
  );

  useEffect(() => {
    // itineraryPlanStore.setPriceDetails(pricing);

    let propertyResponse: any = [];
    let totalPrice: any = {
      totalFare: 0,
      basicCost: 0,
      extraPAXCost: 0,
    };
    let errorMessage = "";
    let temperrorlist:any = []
    for (let stayDetails of packageData?.itineraryPlans[0]?.opportunitystays) {
      if (pricing && Object.keys(pricing).length > 0) {
        for (let propertyPricing of pricing?.propertyPricing) {
          if (stayDetails?.propertyId === propertyPricing?.propertyId) {
            let getRatePlan = propertyPricing?.room?.find(
              (x: any) => x.mealPlan === stayDetails?.mealPlan
            );
            if (getRatePlan) {
              propertyResponse.push({
                propertyId: propertyPricing?.propertyId,
                propertyName: propertyPricing?.propertyName,
                ...getRatePlan,
              });
            } else {
              propertyResponse.push({
                propertyId: propertyPricing?.propertyId,
                propertyName: propertyPricing?.propertyName,
                priceData: {
                  typeWisePriceBreakDown: [],
                  dayWisePriceBreakdown: [],
                },
              });
            }
          }
          propertyPricing?.room.forEach((element:any) => {
            
            if(element?.errorMessage){
                  errorMessage += "No Inventory Available for "+propertyPricing.propertyName+" Please Select Different Date.";
                  errorMessage += "\n";
                  temperrorlist.push({"roomid":element?.roomTypeId})

    
                }
          });
          
        }
        if(pricing?.priceTotal?.totalFare){
          totalPrice.totalFare = pricing?.priceTotal?.totalFare?pricing?.priceTotal?.totalFare:'';
        }
       
      }
    }
    // const uniqulists = temperrorlist.filter((value:any,index:any)=>temperrorlist.indexOf(value)!==index)
    itineraryPlanStore.setinventoryerrorlist(temperrorlist);

    if((errorMessage.match(/No Inventory/g) || []).length>1){
      const tempmsg = errorMessage.split("No Inventory")
      errorMessage = "No Inventory "+tempmsg[1];
    }
    
    
    if(errorMessage !==''){
      itineraryPlanStore.seterrormsgrightcontainer(true);
    }else{
      itineraryPlanStore.seterrormsgrightcontainer(false);
    }
    setTotalPricing(totalPrice);
    itineraryPlanStore.setavailabilityData(pricing);
    itineraryPlanStore.setPriceDetails(totalPrice);
    setFilterPropertyPrice(propertyResponse);
    itineraryPlanStore.setPropertyResponseFilteredData(propertyResponse);
    errorMessage!==''?setErrorMessage(errorMessage):setErrorMessage('')
  }, [pricing]);

  useEffect(() => {}, [itineraryPlanStore.plannedPriceData]);

  const handleContinue = () => {
    if (
      property &&
      property.startDate &&
      property.endDate &&
      property.noOfRooms &&
      property.noOfAdults
    ) {
      if (
        (userCouponCode && isCouponCodeSuccess) ||
        (!userCouponCode && !isCouponCodeSuccess)
      ) {
        if (isAnalyticsEnabled) {
          ReactGA.event({
            action: "navigate_to_review",
            category: "details_page",
          });
        }

        dispatch(clearPackagePricing());
        moment(includeDatesClone[includeDatesClone.length - 1]).format(
          "MM-DD-YYYY"
        );
        property["calenderInterVal"] = includeDatesClone;
        // handleCheckAvailabilityClick();
        sessionStorage.setItem("detailPropertyData", JSON.stringify(property));
        navigate(`/review`, { state: property });
      } else {
        setUserCouponCodeErrorMessage("Please remove coupon code and proceed");
      }
    } else {
      if (!isAvailabilityChecked) {
        setErrorMessage("Please check availability to proceed");
      } else {
        setErrorMessage("Please check your selections 1");
      }
    }
  };

  const getCheckoutMinDate = () => {
    if (startDate) {
      let packageEndDate = new Date(startDate.valueOf());
      packageEndDate.setDate(packageEndDate.getDate() + packageDuration);
      return packageEndDate;
    }
  };

  const handleApplyCouponCode = () => {
    if (userCouponCode) {
      if (isAnalyticsEnabled) {
        ReactGA.event({
          action: `${window.location.pathname} coupon_details`,
          category: `details_page`,
        });
      }

      if (
        property &&
        property.startDate &&
        property.endDate &&
        property.noOfRooms &&
        property.noOfAdults
      ) {
        dispatch(getPackagePricing(property, "", "", ""));
      }
    } else {
      setUserCouponCodeErrorMessage("Please enter Discount Code");
    }
    // }
  };

  // useEffect(() => {
  //   if (couponCode && couponCode.status === "failed") {
  //     setUserCouponCodeErrorMessage(couponCode.message);
  //     setIsCouponCodeSuccess(false);
  //   } else if (couponCode && couponCode.status === "success") {
  //     setUserCouponCodeErrorMessage("");
  //     handleCheckAvailabilityClick();
  //     setIsCouponBtnEnabled(false);
  //     if (isAvailabilityChecked) {
  //       setIsContinueBtnEnabled(true);
  //     }
  //     setIsCouponCodeSuccess(true);
  //   }
  // }, [couponCode, handleCheckAvailabilityClick]);

  const clearUserCouponCode = () => {
    setIsCouponCodeSuccess(false);
    setUserCouponCode("");
    // handleCheckAvailabilityClick();
    setUserCouponCodeErrorMessage("");
    setUserCouponDetails(false);
    if (
      packageData &&
      packageData.id &&
      startDate &&
      endDate &&
      selectedRoomCount &&
      selectedAdultCount &&
      ((packageData.type === "packageType.Staycation" && !selectedRoomType) ||
        (packageData.type === "packageType.Holiday" && !selectedRoomType))
    ) {
      setErrorMessage("");
      if (isAnalyticsEnabled) {
        ReactGA.event({
          action: `${window.location.pathname} price_enquiry`,
          category: `details_page`,
        });
      }

      const data: any[] = [];
      packageData.itineraryPlans[0].opportunitystays.forEach(
        (elem: any, i: any) => {
          data.push({
            propertyId: elem.propertyId,
            roomTypeId: elem.roomTypeId,
            fromDate: startDate
              ? countStartDays(startDate, elem.dayIndexFrom)
              : "",
            toDate: endDate ? countEndDays(startDate, elem.dayIndexTo) : "",
            numRooms: selectedRoomCount,
            // "dayIndexFrom":elem.dayIndexFrom,
            // "dayIndexTo":elem.dayIndexTo,
            numAdults: selectedAdultCount,
            numChildren: selectedChildrenCount,
            childAges: childData?.map((a: any) => parseInt(a.age)),
          });
        }
      );

      dispatch(getPackagePricing(data, "", "", packageData.id));
    } else {
      setErrorMessage("Please check your selections");
    }
  };

  const onChildAgeChange = (data: any, newValue: any) => {
    let itemIndex = childData.findIndex((obj: any) => obj.id === data.id);
    let childDataObj = [...childData];
    childDataObj[itemIndex].age = newValue;
    setChildData(childDataObj);
  };

  const openCheckInDatePicker = () => {
    checkInDateRef.current?.setOpen(true);
  };

  const openCheckOutDatePicker = () => {
    checkOutDateRef.current?.setOpen(true);
  };

  const onMonthChange = (e: any) => {
    const isLastMonthofAvailableInventory =
      new Date(e).getMonth() + 1 ===
      new Date(includeDatesClone[includeDatesClone.length - 1]).getMonth() + 1;
    var firstDateOfMonth = new Date(e.getFullYear(), e.getMonth(), 1).setHours(
      0,
      0,
      0,
      0
    );
    var lastDateOfMonth = new Date(
      e.getFullYear(),
      e.getMonth() + 1,
      0
    ).setHours(0, 0, 0, 0);

    const isFirstDateOfMonthInIncludeDate =
      new Date(firstDateOfMonth) >= new Date(includeDatesClone[0]) &&
      new Date(firstDateOfMonth) <=
        new Date(includeDatesClone[includeDatesClone.length - 1]);

    const isLastDateOfMonthInIncludeDate =
      new Date(lastDateOfMonth) >= new Date(includeDatesClone[0]) &&
      new Date(lastDateOfMonth) <=
        new Date(includeDatesClone[includeDatesClone.length - 1]);

    if (
      isLastMonthofAvailableInventory &&
      !includeDatesClone[includeDatesClone.length - 1].lastDateOfInventory
    ) {
      const fromDate = new Date(
        includeDatesClone[includeDatesClone.length - 1]
      );
      const toDate = new Date(
        new Date(fromDate).setDate(
          new Date(fromDate).getDate() + inventoryFetchDays
        )
      );
      fetchPackageAvailability(fromDate, toDate);
    }
  };

  const fetchPackageAvailability = (fromDate: any, toDate: any) => {
    const localStorageData = localStorage.getItem("voyaahCustomerTracker");
    let customerProfileId = "";
    if (localStorageData) {
      customerProfileId = JSON.parse(localStorageData!).id;
    }
    if (packageData?.type === "packageType.Holiday") {
      const firstPropertyId = packageData?.itinerary[0]?.property?.id;
      const data = {
        packageId: packageData.id,
        // propertyId: firstPropertyId,
        fromDate: fromDate.toISOString().slice(0, 10),
        toDate: toDate.toISOString().slice(0, 10),
      };
      // dispatch(getPackageAvailability(data, customerProfileId));
    } else {
      const data = {
        packageId: packageData.id,
        // roomTypeId:
        //   roomDetails?.id ?? packageData.itinerary[0].roomTypes[0].roomType.id,
        fromDate: fromDate.toISOString().slice(0, 10),
        toDate: toDate.toISOString().slice(0, 10),
      };
      // dispatch(getPackageAvailability(data, customerProfileId));
    }
  };
  const handleChange = (date: any) => {
    const currentDate = new Date();
    const checkinDate = currentDate.getDate() + 1;

    const today = new Date();
    const tomorrow = new Date(today);
  };
  const onchangeSelectedRoomCount = (value: any) => {
    setSelectedRoomCount(value);
    itineraryPlanStore.rightContainerData.numRooms = value;
    itineraryPlanStore.setRightContainerData(
      itineraryPlanStore.rightContainerData
    );
  };
  const onChangeSelectedChildrenCount = (value: any) => {
    setSelectedChildrenCount(value);
    itineraryPlanStore.rightContainerData.numChildren = value;
    itineraryPlanStore.setRightContainerData(
      itineraryPlanStore.rightContainerData
    );
  };
  const onChangeSelectedAdultCount = (value: any) => {
    setSelectedAdultCount(value);
    itineraryPlanStore.rightContainerData.numAdults = value;
    itineraryPlanStore.setRightContainerData(
      itineraryPlanStore.rightContainerData
    );
  };
  const onchangeStartDate = (value: any) => {
    setStartDate(value);
    itineraryPlanStore.rightContainerData.fromDate = value;
    itineraryPlanStore.setRightContainerData(
      itineraryPlanStore.rightContainerData
    );
  };
  const onchangeEndDate = (value: any) => {
    setEndDate(value);
    itineraryPlanStore.rightContainerData.toDate = value;
    itineraryPlanStore.setRightContainerData(
      itineraryPlanStore.rightContainerData
    );
  };

  return (
    <>
      {/* {!showIncludeDates && ( */}
      {/* // <div className="details-content-right">
          // <div className="form-fields error">
            // <p className="error-msg show">{`This package is not available for the selected room type. Kindly try changing room type or choose another property.`}</p>
          // </div>
        </div>
      )} */}

      {
        <div style={{ height: "100%" }} className="details-content-right">
          <div className="head">Guest Details</div>
          <div className="form-fields">
            <div className="w50">
              <label>Check In</label>
              {packageData?.type !== "packageType.Holiday" && (
                <DatePicker
                  placeholderText="dd/mm/yyyy"
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  minDate={addDays(new Date(), 1)}
                  onChange={(date: Date) => {
                    onchangeStartDate(date);
                  }}
                  closeOnScroll={true}
                  ref={checkInDateRef}
                  onMonthChange={(e) => onMonthChange(e)}
                />
              )}
              {packageData?.type === "packageType.Holiday" && (
                <DatePicker
                  placeholderText="dd/mm/yyyy"
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  minDate={addDays(new Date(), 1)}
                  onChange={(date) => onchangeStartDate(date)}
                  closeOnScroll={true}
                  ref={checkInDateRef}
                  onMonthChange={(e) => onMonthChange(e)}
                />
              )}
              <span className="icon" onClick={openCheckInDatePicker}>
                <img
                  src={require("../../assets/images/check-in.png")}
                  alt=""
                  className="img-fluid"
                />
              </span>
            </div>
            <div className="w50">
              <label>Check Out</label>
              {packageData?.type !== "packageType.Holiday" && (
                <DatePicker
                  placeholderText="dd/mm/yyyy"
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  minDate={getCheckoutMinDate()}
                  onChange={(date: Date) => onchangeEndDate(date)}
                  disabled={packageData?.type === "packageType.Holiday"}
                  closeOnScroll={true}
                  ref={checkOutDateRef}
                  // includeDates={includeDates}
                  // includeDateIntervals={includeDateIntervals}
                />
              )}
              {packageData?.type === "packageType.Holiday" && (
                <DatePicker
                  placeholderText="dd/mm/yyyy"
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  minDate={getCheckoutMinDate()}
                  onChange={(date: Date) => onchangeEndDate(date)}
                  disabled={packageData?.type === "packageType.Holiday"}
                  closeOnScroll={true}
                  ref={checkOutDateRef}
                  includeDateIntervals={includeDateIntervals}
                  includeDates={includeDates}
                />
              )}
              <span className="icon" onClick={openCheckOutDatePicker}>
                <img
                  src={require("../../assets/images/check-out.png")}
                  alt=""
                  className="img-fluid"
                />
              </span>
            </div>
          </div>
          <div className="form-fields">
            <div className="w50">
              <label>Adults</label>
              <select
                value={selectedAdultCount}
                onChange={(e) => onChangeSelectedAdultCount(e.target.value)}
              >
                {_.range(adultCount).map((item: any) => {
                  return (
                    <option value={item + 1} key={`package_adult_${item}`}>
                      {`${item + 1} ${item === 0 ? " Adult" : " Adults"}`}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="w50">
              <label>
                Children
                {/* <span>{`Below ${maxChildAge} Years`}</span> */}
              </label>
              <select
                value={selectedChildrenCount}
                onChange={(e) => onChangeSelectedChildrenCount(e.target.value)}
              >
                {_.range(childCount + 1).map((item: any) => {
                  return (
                    <option value={item} key={`package_children_${item}`}>
                      {`${item} ${
                        item === 0 || item === 1 ? " Child" : " Children"
                      }`}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="form-fields">
            <div className="w50">
              <label>Rooms</label>
              <select
                value={selectedRoomCount}
                onChange={(e) => onchangeSelectedRoomCount(e.target.value)}
              >
                {_.range(maxRoomCount).map((item: any) => {
                  return (
                    <option value={item + 1} key={`package_room_${item}`}>
                      {item + 1}
                    </option>
                  );
                })}
              </select>
            </div>
            {childData.map((item: any) => {
              return (
                <div className="w50">
                  <label>{item.title}</label>
                  <select
                    value={item.age}
                    onChange={(e) => onChildAgeChange(item, e.target.value)}
                  >
                    {_.range(maxChildAge).map((item: any) => {
                      return (
                        <option
                          value={item + 1}
                          key={`package_children_${item}`}
                        >
                          {`${item + 1} ${item === 0 ? " Year" : " Years"}`}
                        </option>
                      );
                    })}
                  </select>
                </div>
              );
            })}
          </div>
          {dateErrorMessage && (
            <div className="form-fields error">
              <p className="error-msg show">{dateErrorMessage}</p>
            </div>
          )}
          {!dateErrorMessage && (
            <>
              <div style={{ clear: "both" }}></div>
              {/* <>
                <div className="form-fields btn">
                  <div
                    className={`green-btn ${
                      isCheckAvailabilityBtnEnabled ? "active" : ""
                    }`}
                    onClick={handleCheckAvailabilityClick}
                  >
                    Check Availability & Updated Price
                  </div>
                </div>
              </> */}
              {errorMessage && (
                <div className="form-fields error">
                  <p className="error-msg show">{errorMessage}</p>
                </div>
              )}

              {!errorMessage && (
                <>
                  {pricing?.priceDetails?.map((item: any, index: any) => {
                    return (
                      <div
                        {...(isTestingEnabled
                          ? {
                              id:
                                "total-amount-" +
                                toCamelCase(item?.itemDescription),
                            }
                          : {})}
                        className="total-amount"
                        key={`price-details-${index}`}
                      >
                        <div
                          {...(isTestingEnabled
                            ? {
                                id:
                                  "total-amount-" +
                                  toCamelCase(item?.itemDescription) +
                                  "-key",
                              }
                            : {})}
                          className="left w70"
                        >
                          {item?.itemDescription}
                        </div>
                        <div
                          {...(isTestingEnabled
                            ? {
                                id:
                                  "total-amount-" +
                                  toCamelCase(item?.itemDescription) +
                                  "-value",
                              }
                            : {})}
                          className="right lato-font"
                        >{`₹ ${formatMoney(
                          parseFloat(item?.itemTotal).toString()
                        )}`}</div>
                      </div>
                    );
                  })}

                  {totalPricing?.basicCost > 0 && (
                    <>
                      <div
                        {...(isTestingEnabled
                          ? {
                              id: "total-basic",
                            }
                          : {})}
                        className="total-amount"
                      >
                        <div
                          {...(isTestingEnabled
                            ? {
                                id: "total-amount-key",
                              }
                            : {})}
                          className="left w70 bold"
                        >{`Basic Cost `}</div>

                        <div
                          {...(isTestingEnabled
                            ? {
                                id: "total-basic-amout-value",
                              }
                            : {})}
                          className="right extra-bold lato-font"
                        >{`₹ ${checkNAN(formatMoney(
                          itineraryPlanStore.plannedPriceData.totalFare
                            ? itineraryPlanStore.plannedPriceData.totalFare
                            : totalPricing?.totalFare ?? ''
                        ))}`}</div>
                      </div>
                    </>
                  )}
                  

                  <div
                    {...(isTestingEnabled
                      ? {
                          id: "total-amount",
                        }
                      : {})}
                    className="total-amount border-bottom"
                  >
                    <div
                      {...(isTestingEnabled
                        ? {
                            id: "total-amount-key",
                          }
                        : {})}
                      className="left w70 bold"
                    >{`Total - ${
                      selectedAdultCount
                        ? selectedAdultCount +
                          (selectedAdultCount?.toString() === "1"
                            ? " Adult"
                            : " Adults")
                        : ""
                    } ${
                      selectedChildrenCount
                        ? "+ " +
                          selectedChildrenCount +
                          (selectedChildrenCount?.toString() === "1"
                            ? " Child"
                            : " Children")
                        : ""
                    } (${
                      DayDiff
                        ? DayDiff +
                          (DayDiff?.toString() === "1" ? " Night" : " Nights")
                        : ""
                    } 
                    )`}</div>
                    {itineraryPlanStore.calenderSelected ? (
                      <div
                        {...(isTestingEnabled
                          ? {
                              id: "total-amount-value",
                            }
                          : {})}
                        className="right extra-bold lato-font"
                      >{`₹
                      
                      ${checkNAN(formatMoney(
                        itineraryPlanStore.calenderSelected
                          ? itineraryPlanStore.plannedPriceData.totalFare
                          : ""
                          ? itineraryPlanStore.calenderSelected
                            ? itineraryPlanStore.plannedPriceData.totalFare
                            : ""
                          : itineraryPlanStore.calenderSelected
                          ? totalPricing?.totalFare
                          : "" || itineraryPlanStore.calenderSelected
                          ? packageData?.pricing?.totalFare
                          : ""
                      ))}`}</div>
                    ) : (
                      ""
                    )}
                  </div>
                  {itineraryPlanStore.calenderSelected ? (
                    <div className="total-amount">
                      <div
                        {...(isTestingEnabled
                          ? {
                              id: "packages-lblDiscountCode",
                            }
                          : {})}
                        className="left"
                      >
                        Discount Code
                      </div>
                      <div className="right bold">
                        <input
                          {...(isTestingEnabled
                            ? {
                                id: "packages-txtDiscountCode",
                              }
                            : {})}
                          type="text"
                          placeholder="Voyaah10"
                          value={userCouponCode}
                          onChange={(e) => setUserCouponCode(e.target.value)}
                        />
                        {userCouponCode && (
                          <img
                            {...(isTestingEnabled
                              ? {
                                  id: "packages-deliscountCode",
                                }
                              : {})}
                            src={require("../../assets/images/bin.png")}
                            alt=""
                            className="bin-image"
                            onClick={clearUserCouponCode}
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="form-fields btn">
                    {itineraryPlanStore.calenderSelected ? (
                      <div
                        {...(isTestingEnabled
                          ? {
                              id: "packages-applyDiscountCode",
                            }
                          : {})}
                        className={`green-btn ${
                          isCouponBtnEnabled ? "active" : ""
                        }`}
                        onClick={handleApplyCouponCode}
                      >
                        Apply
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* <div className="total-amount">
                    <div
                      {...(isTestingEnabled
                        ? {
                            id: "packages-lblDiscountCode",
                          }
                        : {})}
                      className="left"
                    >
                      Discount Code
                    </div>
                    <div className="right bold">
                      <input
                        {...(isTestingEnabled
                          ? {
                              id: "packages-txtDiscountCode",
                            }
                          : {})}
                        type="text"
                        placeholder="Voyaah10"
                        value={userCouponCode}
                        onChange={(e) => setUserCouponCode(e.target.value)}
                      />
                      {userCouponCode && (
                        <img
                          {...(isTestingEnabled
                            ? {
                                id: "packages-deliscountCode",
                              }
                            : {})}
                          src={require("../../assets/images/bin.png")}
                          alt=""
                          className="bin-image"
                          onClick={clearUserCouponCode}
                        />
                      )}
                    </div>
                  </div> */}
                  {/* <div className="form-fields btn">
                    <div
                      {...(isTestingEnabled
                        ? {
                            id: "packages-applyDiscountCode",
                          }
                        : {})}
                      className={`green-btn ${
                        isCouponBtnEnabled ? "active" : ""
                      }`}
                      onClick={handleApplyCouponCode}
                    >
                      Apply
                    </div>
                  </div> */}
                  {userCouponCodeErrorMessage && (
                    <div className="form-fields error">
                      <p className="error-msg show">
                        {userCouponCodeErrorMessage}
                      </p>
                    </div>
                  )}
                  {userCouponDetails && (
                    <>
                      <div
                        className={
                          "total-amount" +
                          (itineraryPlanStore.calenderSelected
                            ? "border-bottom"
                            : "")
                        }
                      >
                        <div
                          {...(isTestingEnabled
                            ? {
                                id: "packages-discount-key",
                              }
                            : {})}
                          className="left"
                        >
                          Discount
                        </div>
                        <div
                          {...(isTestingEnabled
                            ? {
                                id: "packages-discount-value",
                              }
                            : {})}
                          className="right lato-font"
                        >{`₹ ${formatMoney(
                          pricing?.priceTotals?.discount
                        )}`}</div>
                      </div>
                      {itineraryPlanStore.calenderSelected ? (
                        <div className="total-amount">
                          <div className="left bold">Grand Total</div>
                          <div className="right extra-bold lato-font">{`₹ ${checkNAN(formatMoney(
                            itineraryPlanStore.plannedPriceData.totalFare ?? ""
                          ))}`}</div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  {!userCouponDetails && (
                    <>
                      {itineraryPlanStore.calenderSelected ? (
                        <div className="total-amount">
                          <div
                            {...(isTestingEnabled
                              ? {
                                  id: "grand-total-key",
                                }
                              : {})}
                            className="left bold"
                          >
                            Grand Total
                          </div>
                          <div
                            {...(isTestingEnabled
                              ? {
                                  id: "grand-total-value",
                                }
                              : {})}
                            className="right extra-bold lato-font"
                          >{`₹ ${checkNAN(formatMoney(
                            itineraryPlanStore.plannedPriceData.totalFare ?? ""
                          ))}`}</div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  {isPartnerSite && (
                    <div
                      className="points-earned-detail color-black"
                      dangerouslySetInnerHTML={{
                        __html: pointsEarnedFormat.replace(
                          "{points}",
                          pricing?.pointsEarned
                        ),
                      }}
                    />
                  )}
                  <div className="form-fields btn">
                    {itineraryPlanStore.calenderSelected ? (
                      <div
                        className={`green-btn ${
                          isContinueBtnEnabled ||
                          itineraryPlanStore.plannedPriceData.totalFare === 0
                            ? "active"
                            : ""
                        }`}
                        onClick={handleContinue}
                      >
                        Continue
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      }

      {/* {(loader || packageAvailabilityLoader) && (
        <div className="loader-container">
          <div className="loader">
            <Bars color="#00BFFF" height={50} width={100} />
          </div>
        </div>
      )} */}
    </>
  );
});

export default RightContainer;
