import { useNavigate } from "react-router-dom";
import { formatMoney, modifyURLWithBlurTag } from "../../helpers/utils";
import ReactGA from "react-ga4";
import { getAnalyticsEnabled } from "./../../helpers/backend_helper";
import "./CardLayout.scss";
import ProgressiveImage from "react-progressive-graceful-image";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

function CardLayout(props: any) {
  const screenWidth = window.innerWidth;
  const navigate = useNavigate();
  const { cardType, campaign } = props;
  const [campaignObj, setCampaignObj] = useState<any>();

  useEffect(() => {
    if (
      props.data?.cardAttributes?.campaignCode &&
      props.data?.cardAttributes?.campaignCode?.length > 0 &&
      campaign.length > 0
    ) {
      let obj = campaign.find(
        (campaignData: { campaignCode: string }) =>
          campaignData.campaignCode === props.data?.cardAttributes?.campaignCode
      );
      if (obj) {
        setCampaignObj(obj);
      }
    }
  }, []);

  const navigateToCTA = () => {
    sessionStorage.setItem("homePageScrollPosition", `${window.pageYOffset}`);
    sessionStorage.removeItem("footerScrollPostion");
    // if (cardType === "discover" && props.data.cardAttributes.ctaUrl) {
    //   navigate(`${props.data.cardAttributes.ctaUrl}`);
    // } else if (cardType === "card") {
    //   if (props.data.cardAttributes?.itemType === "mediaItem.cardtype-package") {
    //     navigate(`/details/${props.data.cardAttributes.Package.reference}`);
    //   } else if (props.data.cardAttributes?.itemType === "mediaItem.cardtype-generic" && props.data.cardAttributes.ctaUrl) {
    //     navigate(`/search/${props.data.cardAttributes.ctaUrl}`);
    //   }
    // }
    if (getAnalyticsEnabled()) {
      ReactGA.event({
        action: `${cardType} ${props.data.cardAttributes.title} homepage`,
        category: `card_layout`,
      });
    }
  };

  return (
    <>
      {cardType === "discover" && (
        <>
          <Link to={`${props.data.cardAttributes.ctaUrl}`}>
            <span
              style={styles.cardBorder}
              className="image link card-image"
              onClick={navigateToCTA}
            >
              <span className="background-overlay"></span>
              <ProgressiveImage
                src={props.data.cardAttributes?.mainImage}
                placeholder={modifyURLWithBlurTag(
                  props.data.cardAttributes?.mainImage
                )}
              >
                {(src, loading) => (
                  <img
                    src={`${props.folderUrl + "/" + props.data.fileName}`}
                    alt={props.data.cardAttributes.altText}
                    className={`img-fluid${loading ? " loading" : " loaded"}`}
                  />
                )}
              </ProgressiveImage>
              {props.data.cardAttributes.title && (
                <span style={styles.textColor} className="name">
                  {props.data.cardAttributes.title}
                </span>
              )}
              {props.data.cardAttributes.subTitle && (
                <span style={styles.textColor} className="tag">
                  {props.data.cardAttributes.subTitle}
                </span>
              )}
            </span>
          </Link>
        </>
      )}
      {cardType === "card" && (
        <>
          <Link
            to={
              props.data.cardAttributes?.itemType ===
              "mediaItem.cardtype-package"
                ? `/packages/${props.data.cardAttributes.Package.reference}`
                : `/search/${props.data.cardAttributes.ctaUrl}`
            }
          >
            <span
              style={{ marginBottom: "-6px" }}
              className="image link card-image"
              onClick={navigateToCTA}
            >
              <span
                style={
                  props.data.cardAttributes?.itemType ===
                    "mediaItem.cardtype-package" && screenWidth < 767
                    ? styles.backgroundOverlayStyle
                    : {}
                }
                className="background-overlay"
              ></span>
              <ProgressiveImage
                src={props.imageSrc}
                placeholder={props.imageSrc}
                onError={(e) => {
                  console.log(`Error Loading Image: ${e}`);
                  return;
                }}
              >
                {(src, loading) => (
                  <img
                    style={
                      screenWidth < 767
                        ? {
                            width: "100vw",
                            height: props.height,
                            objectFit: "cover",
                          }
                        : {}
                    }
                    src={props.imageSrc}
                    alt={props.data.cardAttributes.altText}
                    className={`img-fluid${loading ? " loading" : " loaded"}`}
                  />
                )}
              </ProgressiveImage>
              {props.data?.cardAttributes.itemType ===
                "mediaItem.cardtype-generic" &&
                props.data?.cardAttributes.title.toString().toLowerCase() !==
                  "view all" && (
                  <span
                    style={screenWidth < 767 ? styles.cardTitleStyle : {}}
                    className={screenWidth < 767 ? `mobile-name` : `name`}
                  >
                    {" "}
                    {props.data?.cardAttributes.title}
                  </span>
                )}
              {props.data?.cardAttributes.Package.persuasionText && (
                <span className="tag">
                  {props.data?.cardAttributes.Package?.persuasionText}
                </span>
              )}
              {props.data?.cardAttributes.itemType ===
                "mediaItem.cardtype-generic" &&
                props.data?.cardAttributes.highlightText !== "" && (
                  <span className="tag">
                    {props.data?.cardAttributes.highlightText}
                  </span>
                )}
            </span>
            {props.data?.cardAttributes?.itemType ===
              "mediaItem.cardtype-campaign" &&
            props.data?.cardAttributes.title ? (
              <>
                <span
                  className="head viewall-card"
                  style={styles.textColor}
                  onClick={navigateToCTA}
                >
                  {" "}
                  {props.data?.cardAttributes.title}
                </span>
                <span className="days p-10" style={styles.textColor}>
                  {campaignObj?.discountName || ""}
                </span>
              </>
            ) : (
              ""
            )}
            {props.data?.cardAttributes?.itemType === "mediaItem.viewAll" ? (
              <>
                <span
                  className="head viewall-card"
                  style={styles.textColor}
                  onClick={navigateToCTA}
                >
                  {" "}
                  {props.data?.cardAttributes.title}
                </span>
                <span className="days p-10" style={styles.textColor}>
                  {props.data.cardAttributes.subTitle || ""}
                </span>
              </>
            ) : (
              ""
            )}
          </Link>
          {props.data?.cardAttributes?.itemType ===
            "mediaItem.cardtype-package" &&
            props.data?.cardAttributes?.Package?.id && (
              <>
                <Link
                  to={
                    props.data.cardAttributes?.itemType ===
                    "mediaItem.cardtype-package"
                      ? `/packages/${props.data.cardAttributes.Package.reference}`
                      : `/search/${props.data.cardAttributes.ctaUrl}`
                  }
                >
                  <span
                    className="head link p-10"
                    style={styles.textColor}
                    onClick={navigateToCTA}
                  >
                    {`${props.data.cardAttributes.Package?.name}${
                      props.data.type === "cardtype-package"
                        ? ", " + props.data.cardAttributes.Package?.destination
                        : ""
                    }`}
                  </span>
                </Link>
                <span className="days p-10">{`${props.data.cardAttributes.Package?.numNights} N & ${props.data.cardAttributes.Package?.numDays} D | ${props.data.cardAttributes.Package?.numAdults} Adults`}</span>
                <span className="price lato-font p-10 pb-10">
                  {props.data.cardAttributes.Package?.mrp && (
                    <span className="offer">
                      {`₹ ${formatMoney(
                        props.data.cardAttributes.Package?.mrp
                      )}`}
                    </span>
                  )}
                  {props.data.cardAttributes.Package?.packagePrice && (
                    <span>{`₹ ${formatMoney(
                      props.data.cardAttributes.Package?.packagePrice
                    )}`}</span>
                  )}
                </span>
              </>
            )}
        </>
      )}
    </>
  );
}
const styles = {
  mobileScreen: {
    width: "100vw",
    height: "170px",
  },
  singleCardMobileScreen: {
    width: "100vw",
    height: "66vw",
    objectFit: "cover",
  },
  cardTitleStyle: {
    zIndex: 2,
    color: "white",
  },
  backgroundOverlayStyle: {
    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 0.1) 60.75%)",
    zIndex: 1,
    opacity: "0.7",
    borderRadius: "15px",
  },
  textColor: {
    color: "white",
  },
  cardBorder: { marginBottom: "-6px", margin: "4px" },
  cardBoderViewAll: {},
};

export default CardLayout;
