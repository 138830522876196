import { observer } from "mobx-react-lite";

const ClearConfirmationComponent = observer(
  ({ closeModal, clearContents }: { closeModal: any; clearContents: any }) => {
    return (
      <div className="confirmation-overlay">
        <div className="confirmation-popup">
          <div className="confirmation-cancel">
            <button
              className="confirmation-cancel-btn"
              onClick={() => closeModal(false)}
            >
              &#x2715;
            </button>
          </div>
          <div className="confirmation-content">
            <div className="confirmation-content-text">
              <p className="content-text-first">
                All destination data, dates and stays information will be
                cleared, are you sure you want to clear?
              </p>
            </div>
          </div>
          <div className="validation-btn-div">
            <button
              className="confirmation-okay-btn"
              style={{ color: "white" }}
              onClick={() => {
                clearContents();
                closeModal(false);
              }}
            >
              Proceed
            </button>
            <button
              className="confirmation-okay-btn"
              style={{ color: "white" }}
              onClick={() => closeModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export default ClearConfirmationComponent;
