import React, { useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  auth,
  registerWithPhoneAndOTP,
  verifyPhoneNumber,
} from "../../helpers/firebase";
import { RecaptchaVerifier } from "firebase/auth";
import regexData from "../../constants/regexData";
import "./Register.scss";
import ReactGA from "react-ga4";
import { getAnalyticsEnabled } from "../../helpers/backend_helper";
import ReactPixel from "react-facebook-pixel";
import { createCustomerProfile } from "../../store/customer/actions";

function Register() {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [expandForm, setExpandForm] = useState(false);
  const [otp, setOtp] = useState("");
  const [verified, setVerified] = useState(false);
  const [readAgreement, setReadAgreement] = useState(false);
  const [counter, setCounter] = useState(0);
  const [otpTry, setOtpTry] = useState(0);
  const [appVerified, setAppVerified] = useState(false);
  const [showPasswordText, setShowPasswordText] = useState(false);

  const [user, loading] = useAuthState(auth);

  const navigate = useNavigate();

  const { customerProfileResponse } = useSelector(
    (state) => state.CustomerProfileReducer
  );

  //Element References
  const fNameRef = useRef(null);
  const lNameRef = useRef(null);
  const emailRef = useRef(null);
  const numberRef = useRef(null);
  const passwordRef = useRef(null);

  const firstNameContainerRef = useRef(null);
  const firstNameErrorRef = useRef(null);
  const lastNameContainerRef = useRef(null);
  const lastNameErrorRef = useRef(null);
  const emailContainerRef = useRef(null);
  const emailErrorRef = useRef(null);
  const phoneNumberContainerRef = useRef(null);
  const phoneNumberErrorRef = useRef(null);
  const passwordContainerRef = useRef(null);
  const passwordErrorRef = useRef(null);
  const readAgreementErrorRef = useRef(null);
  const otpErrorRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Register | Voyaah";
    ReactPixel.fbq("init", process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq("track", "PageView");
  }, []);

  useEffect(() => {
    if (loading) return;
    if (user && verified) navigate("/");
  }, [user, loading, verified, navigate]);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    if (customerProfileResponse != undefined) {
      const customerTracker = localStorage.getItem("voyaahCustomerTracker");
      const customerConsentStore = localStorage.getItem(
        "voyaahCustomerConsent"
      );

      if (!customerTracker) {
        const customerProfile = {
          id: customerProfileResponse,
        };

        localStorage.setItem(
          "voyaahCustomerTracker",
          JSON.stringify(customerProfile)
        );
      }

      if (!customerConsentStore) {
        const customerConsent = {
          essentialCookieEnabled: true,
          analyticsCookieEnabled: true,
          personalizationCookieEnabled: true,
        };

        localStorage.setItem(
          "voyaahCustomerConsent",
          JSON.stringify(customerConsent)
        );
      }
    }
  }, [customerProfileResponse]);

  useEffect(() => {
    let customerTracker = localStorage.getItem("voyaahCustomerTracker");
    if (customerTracker == null && !loading) {
      const consentTracker = {
        customerProfileId: null,
        accountId: user?.uid ?? null,
        essentialConsent: true,
        analyticsConsent: true,
        personalizationConsent: true,
      };

      dispatch(createCustomerProfile(consentTracker));
    }
  }, [dispatch, loading]);

  useEffect(() => {
    window.appVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          console.log(`reCaptcha Response`);
        },
        "expired-callback": () => {
          //reCaptcha Response ID expires in about 1 min.
          console.log(`reCaptcha expired`);
          setAppVerified(false);
        },
      },
      auth
    );
    if (!appVerified) {
      window.appVerifier.verify();
    }
    return () => {
      window.appVerifier.clear();
    };
  }, [appVerified]);

  function handleSignUp() {
    const fullName = firstName + " " + lastName;
    setAppVerified(false);
    if (window.appVerifier) {
      try {
        registerWithPhoneAndOTP(
          fullName,
          email,
          password,
          phoneNumber,
          window.appVerifier
        )
          .then((userExist) => {
            if (userExist) {
              readAgreementErrorRef.current.classList.add("error");
              readAgreementErrorRef.current.classList.add("show");
              readAgreementErrorRef.current.innerHTML =
                "User already exists with provided credentials. Please try different set of credentials.";
            } else if (!userExist) {
              setExpandForm(true);
              setCounter(59);
            }
          })
          .catch((error) => {
            alert(error.message);
          });
      } catch (error) {
        alert(error);
      }
    }
  }

  function onVerifyCodeSubmit() {
    if (otp === "") {
      otpErrorRef.current.classList.add("error");
      otpErrorRef.current.classList.add("show");
      otpErrorRef.current.innerHTML =
        "Please enter OTP you received on provided number.";
    } else {
      const name = `${firstName} ${lastName}`;
      if (getAnalyticsEnabled()) {
        ReactGA.event({
          action: `Register User ${name}`,
          category: `registration_page`,
        });
      }

      verifyPhoneNumber(otp, name, email, password, phoneNumber).catch(
        function (error) {
          if (
            error.message ===
            "Firebase: Error (auth/invalid-verification-code)."
          ) {
            otpErrorRef.current.classList.add("error");
            otpErrorRef.current.classList.add("show");
            otpErrorRef.current.innerHTML = "Invalid Code";
          }
        }
      );
    }
  }

  const goBack = () => {
    setCounter(0);
    setExpandForm(false);
    if (getAnalyticsEnabled()) {
      ReactGA.event({
        action: `Go Back from register page`,
        category: `registration_page`,
      });
    }
  };

  const resendOTP = () => {
    setAppVerified(false);
    if (otpTry < 3) {
      const name = `${firstName} ${lastName}`;
      if (window.appVerifier) {
        setCounter(30);
        registerWithPhoneAndOTP(
          name,
          email,
          password,
          `+91${phoneNumber}`,
          window.appVerifier
        );
        if (getAnalyticsEnabled()) {
          ReactGA.event({
            action: `Resend OTP ${name}`,
            category: `registration_page`,
          });
        }
      } else {
        console.log("appverifier not found.");
      }
      setOtpTry(otpTry + 1);
    } else {
      otpErrorRef.current.classList.add("error");
      otpErrorRef.current.classList.add("show");
      otpErrorRef.current.innerHTML =
        "Please try signing up with different phone number or email ID.";
      setExpandForm(false);
      setOtpTry(0);
    }
  };

  const resetData = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setPassword("");
    clearError();
  };

  const clearError = () => {
    firstNameContainerRef.current.classList.remove("error");
    firstNameErrorRef.current.classList.remove("show");
    lastNameContainerRef.current.classList.remove("error");
    lastNameErrorRef.current.classList.remove("show");
    emailContainerRef.current.classList.remove("error");
    emailErrorRef.current.classList.remove("show");
    phoneNumberContainerRef.current.classList.remove("error");
    phoneNumberErrorRef.current.classList.remove("show");
    passwordContainerRef.current.classList.remove("error");
    passwordErrorRef.current.classList.remove("show");
    readAgreementErrorRef.current.classList.remove("show");
  };

  const validateRegistrationDetails = () => {
    const nameRegex = new RegExp(regexData.name);
    const emailRegex = new RegExp(regexData.email);
    const numberRegex = new RegExp(regexData.mobileNumberCompact);

    clearError();

    switch (true) {
      case !firstName:
        firstNameContainerRef.current.classList.add("error");
        firstNameErrorRef.current.classList.add("show");
        firstNameErrorRef.current.innerHTML = "Enter first name";
        fNameRef.current.focus();
        break;

      case !nameRegex.test(firstName):
        firstNameContainerRef.current.classList.add("error");
        firstNameErrorRef.current.classList.add("show");
        firstNameErrorRef.current.innerHTML = "Enter valid first name";
        fNameRef.current.focus();
        break;

      case !lastName:
        lastNameContainerRef.current.classList.add("error");
        lastNameErrorRef.current.classList.add("show");
        lastNameErrorRef.current.innerHTML = "Enter last name";
        lNameRef.current.focus();
        break;

      case !nameRegex.test(lastName):
        lastNameContainerRef.current.classList.add("error");
        lastNameErrorRef.current.classList.add("show");
        lastNameErrorRef.current.innerHTML = "Enter valid last name";
        fNameRef.current.focus();
        break;

      case !email:
        emailContainerRef.current.classList.add("error");
        emailErrorRef.current.classList.add("show");
        emailErrorRef.current.innerHTML = "Enter email";
        emailRef.current.focus();
        break;

      case !emailRegex.test(email):
        emailContainerRef.current.classList.add("error");
        emailErrorRef.current.classList.add("show");
        emailErrorRef.current.innerHTML = "Enter valid email";
        emailRef.current.focus();
        break;

      case !phoneNumber:
        phoneNumberContainerRef.current.classList.add("error");
        phoneNumberErrorRef.current.classList.add("show");
        phoneNumberErrorRef.current.innerHTML = "Enter mobile";
        numberRef.current.focus();
        break;

      case !numberRegex.test(phoneNumber):
        phoneNumberContainerRef.current.classList.add("error");
        phoneNumberErrorRef.current.classList.add("show");
        phoneNumberErrorRef.current.innerHTML = "Enter valid mobile";
        numberRef.current.focus();
        break;

      case !password:
        passwordContainerRef.current.classList.add("error");
        passwordErrorRef.current.classList.add("show");
        passwordErrorRef.current.innerHTML = "Enter password";
        passwordRef.current.focus();
        break;

      case !readAgreement:
        readAgreementErrorRef.current.classList.add("error");
        readAgreementErrorRef.current.classList.add("show");
        readAgreementErrorRef.current.innerHTML =
          "Please read the user agreement";
        break;

      default:
        setVerified(true);
        handleSignUp();
        break;
    }
  };

  return (
    <div className="login-section">
      <div id="recaptcha-container" />
      <div className="section">
        <div className="heading">
          Discover the world in a<br /> new way
        </div>
        <div className="login-container">
          <div className="login-text">
            Already A Member? <Link to="/login">Log In</Link>
          </div>
          <div className="head">Create an account</div>
          {!expandForm && (
            <div className="form">
              <div className="single-line">
                <div className="form-fields left" ref={firstNameContainerRef}>
                  <label>First Name</label>
                  <input
                    ref={fNameRef}
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="Enter First Name"
                    maxLength={50}
                  />
                  <p className="error-msg" ref={firstNameErrorRef}>
                    Error Text
                  </p>
                </div>
                <div className="form-fields right" ref={lastNameContainerRef}>
                  <label>Last Name</label>
                  <input
                    ref={lNameRef}
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Enter Last Name"
                    maxLength={50}
                  />
                  <p className="error-msg" ref={lastNameErrorRef}>
                    Error Text
                  </p>
                </div>
              </div>
              <div className="form-fields" ref={emailContainerRef}>
                <label>Email</label>
                <input
                  ref={emailRef}
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter Email"
                  maxLength={50}
                  required
                />
                <p className="error-msg" ref={emailErrorRef}>
                  Error Text
                </p>
              </div>
              <div className="form-fields" ref={phoneNumberContainerRef}>
                <label>Mobile</label>
                <input
                  type="text"
                  ref={numberRef}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Enter Mobile (10 Digits)"
                  maxLength={10}
                  pattern={"[0-9]{10}"}
                />
                <p className="error-msg" ref={phoneNumberErrorRef}>
                  Error Text
                </p>
              </div>
              <div className="form-fields" ref={passwordContainerRef}>
                <label>Password</label>
                <input
                  type={showPasswordText ? "text" : "password"}
                  ref={passwordRef}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter Password"
                  maxLength={50}
                />
                <span className="icon">
                  <img
                    src={require("../../assets/images/eye-icon.png")}
                    alt=""
                    className="img-fluid"
                    onClick={() => setShowPasswordText(!showPasswordText)}
                  />
                </span>
                <p className="error-msg" ref={passwordErrorRef}>
                  Error Text
                </p>
              </div>
              <div className="form-fields no-bg w100">
                <div className="left">
                  <input
                    type="checkbox"
                    name="remember"
                    className="checkbox"
                    onClick={() => setReadAgreement(!readAgreement)}
                  />{" "}
                  I understand and agree with the{" "}
                  <a href="/privacy-policy" rel="noreferrer" target="_blank">
                    Privacy User Agreement
                  </a>{" "}
                  and{" "}
                  <a href="/terms-of-use" rel="noreferrer" target="_blank">
                    Terms of Service
                  </a>{" "}
                  of Voyaah.
                </div>
                <p className="error-msg" ref={readAgreementErrorRef}>
                  Error Text
                </p>
              </div>
              <div className="form-fields no-bg">
                <div className="left">
                  <input
                    type="button"
                    value="Reset"
                    className="gray-btn mr-5"
                    onClick={resetData}
                  />
                </div>
                <div className="right">
                  <input
                    type="button"
                    value="Generate OTP"
                    className="green-btn ml-5"
                    onClick={validateRegistrationDetails}
                  />
                </div>
              </div>
            </div>
          )}
          {expandForm && (
            <div className="form">
              <div id="recaptcha-container-resend" />
              <div className="form-fields">
                <label>OTP sent to {phoneNumber}</label>
                <input
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="Enter OTP"
                  maxLength={6}
                />
              </div>
              <div className="form-fields no-bg">
                <p className="error-msg" ref={otpErrorRef}>
                  Error Text
                </p>
                <input
                  type="button"
                  value="Submit"
                  className="gray-btn mr-5"
                  onClick={onVerifyCodeSubmit}
                />
              </div>
              <div className="form-fields no-bg">
                <button
                  className="resend-btn"
                  onClick={resendOTP}
                  disabled={counter > 0}
                >
                  Resend OTP in{" "}
                  <span style={{ color: "green", fontWeight: "bold" }}>
                    {" "}
                    {counter} sec
                  </span>
                </button>
              </div>
              <div className="form-fields no-bg">
                <input
                  type="button"
                  value="Change Phone Number"
                  className="gray-btn mr-5"
                  onClick={goBack}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Register;
