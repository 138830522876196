import {
  GET_BOOKING_DETAILS,
  GET_BOOKING_DETAILS_SUCCESS,
  GET_BOOKING_DETAILS_FAIL,
  CLEAR_BOOKING_DETAILS,
  GET_BOOKING_LIST,
  GET_BOOKING_LIST_SUCCESS,
  GET_BOOKING_LIST_FAIL,
  UPLOAD_BOOKING_DOCUMENT,
  UPLOAD_BOOKING_DOCUMENT_SUCCESS,
  UPLOAD_BOOKING_DOCUMENT_FAIL,
  GET_CLIENT_IP,
  GET_CLIENT_IP_SUCCESS,
  GET_CLIENT_IP_FAIL,
  UPDATE_BOOKING_STATUS,
  UPDATE_BOOKING_STATUS_SUCCESS,
  UPDATE_BOOKING_STATUS_FAIL,
  CLEAR_BOOKING_STATUS,
  GET_PAYMENT_DETAILS,
  GET_PAYMENT_DETAILS_SUCCESS,
  GET_PAYMENT_DETAILS_FAIL,
} from "./actionTypes";

export const getBookingDetails = (
  data: any,
  couponCode: any,
  customerProfileId: string
) => {
  return {
    type: GET_BOOKING_DETAILS,
    payload: data,
    couponCode: couponCode,
    customerProfileId: customerProfileId,
  };
};

export const getBookingDetailsSuccess = (data: any) => {
  return {
    type: GET_BOOKING_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getBookingDetailsFail = (error: any) => {
  return {
    type: GET_BOOKING_DETAILS_FAIL,
    payload: error,
  };
};

export const clearBookingDetails = () => {
  return {
    type: CLEAR_BOOKING_DETAILS,
  };
};

export const getBookingList = () => {
  return {
    type: GET_BOOKING_LIST,
  };
};

export const getBookingListSuccess = (data: any) => {
  return {
    type: GET_BOOKING_LIST_SUCCESS,
    payload: data,
  };
};

export const getBookingListFail = (error: any) => {
  return {
    type: GET_BOOKING_LIST_FAIL,
    payload: error,
  };
};

export const uploadBookingDocument = (data: any, orderId: any) => {
  return {
    type: UPLOAD_BOOKING_DOCUMENT,
    payload: data,
    orderId: orderId,
  };
};

export const uploadBookingDocumentSuccess = (data: any) => {
  return {
    type: UPLOAD_BOOKING_DOCUMENT_SUCCESS,
    payload: data,
  };
};

export const uploadBookingDocumentFail = (error: any) => {
  return {
    type: UPLOAD_BOOKING_DOCUMENT_FAIL,
    payload: error,
  };
};

export const getClientIp = () => {
  return {
    type: GET_CLIENT_IP,
  };
};

export const getClientIpSuccess = (data: any) => {
  return {
    type: GET_CLIENT_IP_SUCCESS,
    payload: data,
  };
};

export const getClientIpFail = (error: any) => {
  return {
    type: GET_CLIENT_IP_FAIL,
    payload: error,
  };
};

export const updateBookingStatus = (data: any) => {
  return {
    type: UPDATE_BOOKING_STATUS,
    payload: data,
  };
};

export const updateBookingStatusSuccess = (data: any) => {
  return {
    type: UPDATE_BOOKING_STATUS_SUCCESS,
    payload: data,
  };
};

export const updateBookingStatusFail = (error: any) => {
  return {
    type: UPDATE_BOOKING_STATUS_FAIL,
    payload: error,
  };
};

export const clearBookingStatus = () => {
  return {
    type: CLEAR_BOOKING_STATUS,
  };
};

export const getPaymentDetails = (
  orderId: any,
  channelOfSale: any,
  customerProfileId: string
) => {
  return {
    type: GET_PAYMENT_DETAILS,
    payload: orderId,
    channelOfSale: channelOfSale,
    customerProfileId: customerProfileId,
  };
};

export const getPaymentDetailsSuccess = (data: any) => {
  return {
    type: GET_PAYMENT_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getPaymentDetailsFail = (error: any) => {
  return {
    type: GET_PAYMENT_DETAILS_FAIL,
    payload: error,
  };
};
