import {
  CustomerQuoteModel,
  DaywiseItineraryModel,
  InclusionsModel,
  ItineraryPlanModel,
  ItineraryRouteModel,
  OpportunityStayModel,
  PlaceToVisit,
  Property,
  QuotePriceModel,
  StayInclusionModel,
  VisitDestinationModel,
  initOpportunityStay,
  initQuotePriceModel,
} from "../models/models";
import { makeAutoObservable, toJS } from "mobx";

class ItineraryPlanStore {
  itineraryPlans: ItineraryPlanModel[] = [];
  itineraryParentId: string = "";
  errormsgrightcontainer: boolean = false;
  availabilitypayload:any =[];
  inventoryerrorlist:any = [];
  selectedItineraryPlan: ItineraryPlanModel = {
    id: 0,
    name: "Name your holiday",
    opportunityId: "",
    summary: "",
    notes: "",
    inclusions: "",
    exclusions: "",
    arrivalAirportName: "",
    departureAirportName: "",
    arrivalAirportId: 0,
    departureAirportId: 0,
    supplierQuoteRequest: [],
    supplierQuoteResponse: [],
    quotePrice: initQuotePriceModel(),
    opportunitystays: [],
    daywiseItinerary: [],
    costs: [],
    optionalExtra: [],
    customerQuote: [],
    visitDestinations: [],
  };

  opportunityId: string = "";
  stayList: OpportunityStayModel[] = [];
  roomCountList: any[] = [];
  costs: InclusionsModel[] = [];
  optionalExtraList: InclusionsModel[] = [];
  daywiseItineraryList: DaywiseItineraryModel[] = [];
  selectedStay: OpportunityStayModel = initOpportunityStay();
  priceTotal: QuotePriceModel = initQuotePriceModel();
  markUpPrice: number = 0;
  softDeletedData: any = {
    stayId: [],
    optionalExtra: [],
    costElement: [],
  };
  contentUrl: string = "";
  contentBucket: string = "";

  customerQuoteList: CustomerQuoteModel[] = [];
  visitDestinationList: VisitDestinationModel[] = [];
  numberOfDays: number = 0;
  itineraryRoutes: ItineraryRouteModel[] = [];

  itineraryPlanRoutesList: any[] = [];

  placesToVisit: PlaceToVisit[] = [];
  placesToVisitEdit: boolean = false;
  customItineraryViewOnly: boolean = true;
  selectedItineraryPlanNameEdit: boolean = false;
  propertyList: Property[] = [];
  selectedProperties: any[] = [];
  plannedPriceData: any = "";
  availabilityData: any = [];
  stayRoomTypes: any[] = [];
  calenderSelected: boolean = false;
  opportunityStays: any[] = [];
  dayCityMapping: string[] = [];
  policyLists: any[] = [];
  propertyResponseData: any[] = [];
  rightContainerData: any = {};
  editPlanId: number = 0;
  editParentId: string = "";
  existingProperties: { cityName: string; property: any }[] = [];
  detailPageOpportunityStays: any[] = [];
  detailFlag: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }

  setDetailPageOpportunityStays(data: any[]) {
    this.detailPageOpportunityStays = data;
  }

  changeOpportunityStayProperty(property: any, id: any) {
    const updatedArray = this.detailPageOpportunityStays.map((item) =>
      item.id === id ? { ...item, property: property } : item
    );
    this.detailPageOpportunityStays = updatedArray;
  }

  setDetailFlag(data: boolean) {
    this.detailFlag = data;
  }

  setExistingProperties(
    existingProperties: { cityName: string; property: any }[]
  ) {
    this.existingProperties = existingProperties;
  }
  setinventoryerrorlist(data:any){
    this.inventoryerrorlist = data;
  }
  setEditPlanParentIdentifiers(editPlanId: number, editParentId: string) {
    this.editParentId = editParentId;
    this.editPlanId = editPlanId;
  }

  setStateToInitialForm() {
    this.propertyList = [];
    this.availabilityData = [];
    this.selectedProperties = [];
    this.plannedPriceData = "";
    this.stayRoomTypes = [];
    this.existingProperties = [];
    this.visitDestinationList = [];
    this.placesToVisit = [];
    this.roomCountList = [];
    this.availabilitypayload = [];
    this.errormsgrightcontainer = false;
    this.propertyResponseData = [];
    this.rightContainerData = {
      fromDate: "",
      toDate: "",
      numRooms: "",
      numAdults: "",
      numChildren: "",
      childAges: "",
    };
    this.selectedItineraryPlan = {
      id: 0,
      name: "Name your holiday",
      opportunityId: "",
      summary: "",
      notes: "",
      inclusions: "",
      exclusions: "",
      arrivalAirportName: "",
      departureAirportName: "",
      arrivalAirportId: 0,
      departureAirportId: 0,
      supplierQuoteRequest: [],
      supplierQuoteResponse: [],
      quotePrice: initQuotePriceModel(),
      opportunitystays: [],
      daywiseItinerary: [],
      costs: [],
      optionalExtra: [],
      customerQuote: [],
      visitDestinations: [],
    };
    this.policyLists = [];
    this.calenderSelected = false;
  }

  setOpportunityStays(items: any[]) {
    this.opportunityStays = items;
  }

  setPropertyList(propertyList: Property[]) {
    const cityOrderMap = this.visitDestinationList.reduce(
      (acc: any, city, index) => {
        acc[city.cityName!] = index;
        return acc;
      },
      {}
    );

    const sortedProperties = propertyList.sort((a, b) => {
      return cityOrderMap[a.destination] - cityOrderMap[b.destination];
    });

    this.propertyList = sortedProperties;
  }

  setSelectedProperties(property: any[]) {
    this.selectedProperties = property;
  }
  seterrormsgrightcontainer(value:boolean){
    this.errormsgrightcontainer = value;
  }
  setavailabilitypayload(data:any){
    this.availabilitypayload = data;
  }
  setRoomCountDopdownList(list: any) {
    this.roomCountList = list;
  }
  setPropertyResponseFilteredData(data: any) {
    this.propertyResponseData = data;
  }
  setPriceDetails(priceDetails: any) {
    this.plannedPriceData = priceDetails;
  }
  setCalenderSelectedStatus(status: any) {
    this.calenderSelected = status;
  }
  setavailabilityData(data: any) {
    this.availabilityData = data;
  }
  setRightContainerData(data: any) {
    this.rightContainerData = data;
  }
  setStayRoomTypes(room: any[]) {
    this.stayRoomTypes = room;
  }
  setpolicyLists(lists: any[]) {
    this.policyLists = lists;
  }

  setContentUrl(url: string) {
    this.contentUrl = url;
  }

  setContentBucket(bucket: string) {
    this.contentBucket = bucket;
  }

  setMarkupPrice(markup: number) {
    this.markUpPrice = markup;
  }

  setOpportunityId(opportunityId: string) {
    this.opportunityId = opportunityId;
  }

  setPlacesToVisitEdit(editValue: boolean) {
    this.placesToVisitEdit = editValue;
  }

  setSelectedItineraryPlanNameEdit(editValue: boolean) {
    this.selectedItineraryPlanNameEdit = editValue;
  }

  addNewPlaceToVisit(placeToVisit: PlaceToVisit) {
    let existingPlacesToVisit = [...this.placesToVisit];
    existingPlacesToVisit.push(placeToVisit);
    this.placesToVisit = existingPlacesToVisit;
  }

  updateSelectedPlaceToVisit(placeToVisit: PlaceToVisit) {
    let existingPlaceToVisitList = this.placesToVisit.filter(
      (x: any) => x.id !== placeToVisit.id
    );

    existingPlaceToVisitList.push(placeToVisit);
    existingPlaceToVisitList.sort((a, b) => a.positionIndex - b.positionIndex);
    this.placesToVisit = existingPlaceToVisitList;
  }

  deleteSelectedPlaceToVisit(placeToVisit: PlaceToVisit) {
    let existingPlaceToVisitList = this.placesToVisit.filter(
      (x: any) => x.id !== placeToVisit.id
    );

    existingPlaceToVisitList.sort((a, b) => a.positionIndex - b.positionIndex);

    this.placesToVisit = existingPlaceToVisitList;
  }

  moveSelectedPlaceUp(index: number) {
    if (index !== 0) {
      const placesToVisitModify = [...this.placesToVisit];

      const positionalIndexBefore =
        placesToVisitModify[index - 1].positionIndex;
      const positionalIndexCurrent = placesToVisitModify[index].positionIndex;
      [placesToVisitModify[index], placesToVisitModify[index - 1]] = [
        placesToVisitModify[index - 1],
        placesToVisitModify[index],
      ];

      placesToVisitModify[index - 1].positionIndex = positionalIndexBefore;
      placesToVisitModify[index].positionIndex = positionalIndexCurrent;

      this.placesToVisit = placesToVisitModify;
    }
  }

  moveSelectedPlaceDown(index: number) {
    if (index !== this.placesToVisit.length) {
      const placesToVisitModify = [...this.placesToVisit];

      const positionalIndexBefore =
        placesToVisitModify[index + 1].positionIndex;
      const positionalIndexCurrent = placesToVisitModify[index].positionIndex;
      [placesToVisitModify[index], placesToVisitModify[index + 1]] = [
        placesToVisitModify[index + 1],
        placesToVisitModify[index],
      ];

      placesToVisitModify[index + 1].positionIndex = positionalIndexBefore;
      placesToVisitModify[index].positionIndex = positionalIndexCurrent;

      this.placesToVisit = placesToVisitModify;
    }
  }

  setItineraryPlanRoutesList(routeList: any) {
    this.itineraryPlanRoutesList = routeList;
  }

  setPlacesToVisit(placesToVisit: PlaceToVisit[]) {
    this.placesToVisit = placesToVisit;
  }

  setItineraryPlans(
    itineraryPlans: ItineraryPlanModel[],
    routePlanList: any[],
    setSelectedPlan: boolean = false
  ) {
    itineraryPlans.sort((a: any, b: any) => a.id - b.id);
    this.itineraryPlans = itineraryPlans;
    if (itineraryPlans.length > 0) {
      if (!setSelectedPlan) {
        this.setSelectedItineraryPlan(itineraryPlans[0].id, false);
      }
      let routePlan = routePlanList.find(
        (x: any) => x.itineraryPlanId === itineraryPlans[0].id
      );
      if (routePlan) {
        this.setItineraryRoutes([...routePlan?.routes]);
      }
    }
  }

  setAllItineraryPlansWithSelected(
    itineraryPlans: ItineraryPlanModel[],
    routePlanList: any[],
    id: number
  ) {
    itineraryPlans.sort((a: any, b: any) => a.id - b.id);
    this.itineraryPlans = itineraryPlans;
    if (itineraryPlans.length > 0) {
      this.setSelectedItineraryPlan(id, false);

      let routePlan = routePlanList.find(
        (x: any) => x.itineraryPlanId === itineraryPlans[0].id
      );
      if (routePlan) {
        this.setItineraryRoutes([...routePlan?.routes]);
      }
    }
  }

  updateItineraryPlanList(
    id: number,
    stayList: OpportunityStayModel[],
    priceData: QuotePriceModel,
    inclusionList: InclusionsModel[],
    optionalExtra: InclusionsModel[],
    daywiseItineraries: DaywiseItineraryModel[],
    customerQuoteList: CustomerQuoteModel[]
  ) {
    stayList?.sort((a, b) => a.id - b.id);

    let existingPlans = this.itineraryPlans.filter((x: any) => x.id !== id);
    let updateList = this.itineraryPlans.find((x: any) => x.id === id);
    if (updateList) {
      updateList.opportunitystays = stayList;
      updateList.optionalExtra = optionalExtra;
      updateList.customerQuote = customerQuoteList;
      updateList.costs = inclusionList;
      updateList.quotePrice = priceData;
      updateList.daywiseItinerary = daywiseItineraries;

      let planRouteList = this.itineraryPlanRoutesList.find(
        (y: any) => y.itineraryPlanId === id
      );
      if (planRouteList) {
        this.setItineraryRoutes([...planRouteList.routes]);

        updateList = {
          ...updateList,
          arrivalAirportName: planRouteList.arrivalAirportName,
          departureAirportName: planRouteList.departureAirportName,
        };
      }

      existingPlans.push(updateList);
      this.selectedItineraryPlan = updateList;
      this.itineraryPlans = existingPlans;
    }
  }

  /* ***********************************************************
   * *********** ITINERARY PLAN - STORE SECTION ****************
   * ***********************************************************
   */
  createItineraryPlan() {
    const existingItineraryPlans = this.itineraryPlans;
    const newItineraryPlan = {
      id: this.itineraryPlans.length * -1,
      name: "",
      opportunityId: this.opportunityId,
      summary: "",
      notes: "",
      inclusions: "",
      exclusions: "",
      supplierQuoteRequest: [],
      supplierQuoteResponse: [],
      quotePrice: initQuotePriceModel(),
      opportunitystays: [],
      costs: [],
      optionalExtra: [],
      customerQuote: [],
      daywiseItinerary: [],
      visitDestinations: [],
    };
    existingItineraryPlans.push(newItineraryPlan);
    this.itineraryPlans = existingItineraryPlans;
    this.selectedItineraryPlan = newItineraryPlan;
  }

  /* ***********************************************************
   * ******* Update BasedDetail in ItineraryPlan SECTION *******
   * ***********************************************************
   */

  updateSelectedItineraryPlanBaseDetails(itineraryPlan: ItineraryPlanModel) {
    let existingSelectedData = { ...this.selectedItineraryPlan };
    existingSelectedData.exclusions = itineraryPlan?.exclusions;
    existingSelectedData.inclusions = itineraryPlan?.inclusions;
    existingSelectedData.inclusionsHighlight =
      itineraryPlan?.inclusionsHighlight;
    existingSelectedData.notes = itineraryPlan?.notes;
    existingSelectedData.summary = itineraryPlan?.summary;
    const updateList = this.itineraryPlans;
    const existingList = updateList.filter(
      (x: any) => x.id !== itineraryPlan.id
    );
    existingList.push(existingSelectedData);
    this.itineraryPlans = existingList;
    this.selectedItineraryPlan = existingSelectedData;
  }

  /* ***********************************************************
   * *********** SELECTED ITINERARY PLAN - STORE SECTION *******
   * ***********************************************************
   */
  updateSelectedItineraryPlan(itineraryPlan: ItineraryPlanModel) {
    this.selectedItineraryPlan = itineraryPlan;
    const updateList = this.itineraryPlans;
    const existingList = updateList.filter(
      (x: any) => x.id !== itineraryPlan.id
    );
    existingList.push(itineraryPlan);

    this.itineraryPlans = existingList;
  }

  setSelectedItineraryPlan(id: number, priceBreakUpEmpty: boolean = true) {
    let filteredItineraryPlan = this.itineraryPlans.filter(
      (x: any) => x.id === id
    )[0];
    this.setDaywiseItineraryList(filteredItineraryPlan.daywiseItinerary);

    this.setOptionaExtraList(filteredItineraryPlan.optionalExtra);
    this.setInclusionList(filteredItineraryPlan.costs);
    this.setCustomerQuoteList(filteredItineraryPlan.customerQuote);

    const stayList = filteredItineraryPlan?.opportunitystays.map(
      (x: OpportunityStayModel) => {
        x.propertyName =
          x.property !== null ? x?.property?.name : x.propertyName;
        x.roomTypeName =
          x.roomType !== null ? x?.roomType?.name : x.roomTypeName;
        x.propertyId = x?.property?.id ?? "";
        x.roomTypeId = x?.roomType?.id ?? "";
        return x;
      }
    );

    stayList?.sort((a, b) => a.id - b.id);

    let quotePrice = filteredItineraryPlan.quotePrice;
    this.setMarkupPrice(quotePrice?.markUp ?? 0);

    if (priceBreakUpEmpty) {
      if (quotePrice?.quotePriceDetails) {
        quotePrice.quotePriceDetails = [];
      }

      for (let inclusion of filteredItineraryPlan.costs) {
        if (inclusion) {
          quotePrice.quotePriceDetails.push({
            id: Math.floor(Math.random() * 100),
            itemTypeDesc: inclusion.description ?? "",
            itemCount: inclusion.unitCount,
            unitRate: inclusion.unitRate,
            itemTotal: inclusion.itemTotal,
            itemTypeCode: "pricing.Inclusions",
            quotePriceId: quotePrice.id,
          });
        }
      }
    }
    this.visitDestinationList = filteredItineraryPlan.visitDestinations;
    this.setVisitDestinations(filteredItineraryPlan.visitDestinations);

    let planRouteList = this.itineraryPlanRoutesList.find(
      (y: any) => y.itineraryPlanId === id
    );
    if (planRouteList) {
      this.setItineraryRoutes([...planRouteList.routes]);

      filteredItineraryPlan = {
        ...filteredItineraryPlan,
        arrivalAirportName: planRouteList.arrivalAirportName,
        departureAirportName: planRouteList.departureAirportName,
      };
    }
    this.setOpportunityStay(stayList);
    this.setPriceData(quotePrice);
    this.selectedItineraryPlan = filteredItineraryPlan;
  }

  setSelectedItineraryPlanSupplierQuote(
    id: number,
    supplierQuoteRequest: any[],
    supplierQuoteReceived: any[]
  ) {
    let existingPlans = this.itineraryPlans.filter((x: any) => x.id !== id);
    let updateList = this.itineraryPlans.find((x: any) => x.id === id);
    if (updateList) {
      updateList.supplierQuoteRequest = supplierQuoteRequest;
      updateList.supplierQuoteResponse = supplierQuoteReceived;

      existingPlans.push(updateList);
      this.itineraryPlans = existingPlans;
    }

    let selectedItineraryPlan = { ...this.selectedItineraryPlan };
    selectedItineraryPlan.supplierQuoteRequest = supplierQuoteRequest;
    selectedItineraryPlan.supplierQuoteResponse = supplierQuoteReceived;
    this.selectedItineraryPlan = selectedItineraryPlan;
  }

  deleteSelectedItineraryPlan(id: number) {
    const updateList = this.itineraryPlans;
    const existingList = updateList.filter((x: any) => x.id !== id);
    this.itineraryPlans = existingList;
    this.setSelectedItineraryPlan(existingList?.[0]?.id, false);
  }

  /* ***********************************************************
   * *********** Itinerary Stays - STORE SECTION ****************
   * ***********************************************************
   */
  setOpportunityStay(stays: OpportunityStayModel[]) {
    this.stayList = stays;
  }

  addNewStay(stay: OpportunityStayModel) {
    let existingStayList = [...this.stayList];
    existingStayList.push(stay);
    existingStayList.sort((a, b) => a.id - b.id);

    this.stayList = existingStayList;
  }

  updateSelectedStay(stay: OpportunityStayModel) {
    let existingStayList = this.stayList.filter((x: any) => x.id !== stay.id);
    existingStayList.push(stay);
    existingStayList.sort((a, b) => a.id - b.id);
    this.stayList = existingStayList;
  }

  deleteSelectedStay(id: number) {
    let existingStayList = this.stayList.filter((x: any) => x.id !== id);
    existingStayList?.sort((a, b) => a.id - b.id);
    this.stayList = existingStayList;
    if (id > 0) {
      this.setSoftDeletedData("stayId", id);
    }
  }

  /* ***********************************************************
   * ****** Itinerary Stay('Property Update') - STORE SECTION **
   * ***********************************************************
   */
  updatedPropertyDetail(stayId: number, property: any) {
    let existingStayList = this.stayList.filter((x: any) => x.id !== stayId);
    let updateEntry = this.stayList.filter((x: any) => x.id === stayId)[0];
    if (updateEntry) {
      updateEntry.propertyId = property.id;
      updateEntry.propertyName = property.name;
      updateEntry.property = property;
      updateEntry.roomTypeId = "";
      updateEntry.roomTypeName = "";
      existingStayList.push(updateEntry);
    }
    existingStayList.sort((a, b) => a.id - b.id);
    this.stayList = existingStayList;
  }

  /* ***********************************************************
   * *********** Optional Extra - STORE SECTION ****************
   * ***********************************************************
   */
  setOptionaExtraList(extras: InclusionsModel[]) {
    this.optionalExtraList = extras;
  }

  setOptionalExtra(inclusion: InclusionsModel) {
    let existingOptionalExtra = this.optionalExtraList.filter(
      (x: any) => x.id !== inclusion.id
    );
    existingOptionalExtra.push(inclusion);
    this.optionalExtraList = existingOptionalExtra;
  }

  deleteOptionalExtra(id: number) {
    let filteredData = [...this.optionalExtraList];
    filteredData = filteredData.filter((x: any) => x.id !== id);
    this.optionalExtraList = filteredData;
    if (id > 0) {
      this.setSoftDeletedData("optionalExtra", id);
    }
  }

  /* ***********************************************************
   * ******* Itinerary Plan inclusions - STORE SECTION *********
   * ***********************************************************
   */
  setInclusionList(inclusions: InclusionsModel[]) {
    this.costs = inclusions;
    let priceBreakUp = this.priceTotal?.quotePriceDetails?.filter(
      (x: any) => x.itemTypeCode !== "pricing.Inclusions"
    );
    // calculate totalFare
    let totalFare = 0;
    priceBreakUp?.map((i: any) => (totalFare += Number(i.itemTotal)));
    if (priceBreakUp) {
      for (let inclusion of inclusions) {
        totalFare += Number(inclusion.itemTotal);
        priceBreakUp.push({
          id: inclusion.id,
          quotePriceId: this.priceTotal?.id ?? 0,
          itemTypeCode: "pricing.Inclusions",
          itemTypeDesc: inclusion?.description,
          unitRate: Number(inclusion.unitRate),
          itemCount: inclusion.unitCount,
          itemTotal: Number(inclusion.itemTotal),
        });
      }
      this.setPriceData({
        ...this.priceTotal,
        totalFare: totalFare,
        quotePriceDetails: priceBreakUp,
      });
    } else {
      this.setPriceData({
        ...this.priceTotal,
        totalFare: totalFare,
        quotePriceDetails: [],
      });
    }
  }

  deleteInclusion(id: number) {
    let filteredData = [...this.costs];
    filteredData = filteredData.filter((x: any) => x.id !== id);
    this.costs = filteredData;
    if (id > 0) {
      this.setSoftDeletedData("costElement", id);
    }
    let priceBreakUp =
      this.priceTotal?.quotePriceDetails?.filter((x: any) => x.id !== id) ?? [];
    let totalFare = 0;
    priceBreakUp.map((i: any) => (totalFare += Number(i.itemTotal)));
    this.setPriceData({
      ...this.priceTotal,
      totalFare: totalFare,
      quotePriceDetails: priceBreakUp,
    });
  }

  setOpportunityInclusion(inclusion: InclusionsModel) {
    let existingInclusions = this.costs.filter(
      (x: any) => x.id !== inclusion.id
    );
    existingInclusions.push(inclusion);
    this.costs = existingInclusions;

    // Adding to quoteDetails
    let priceBreakUp = this.priceTotal?.quotePriceDetails?.filter(
      (x: any) => x.id !== inclusion.id
    );
    let totalFare = Number(inclusion.itemTotal);
    priceBreakUp?.map((i: any) => (totalFare += Number(i.itemTotal)));
    if (priceBreakUp) {
      priceBreakUp.push({
        id: inclusion.id,
        quotePriceId: this.priceTotal?.id ?? 0,
        itemTypeCode: "pricing.Inclusions",
        itemTypeDesc: inclusion?.description,
        unitRate: Number(inclusion.unitRate),
        itemCount: inclusion.unitCount,
        itemTotal: Number(inclusion.itemTotal),
      });
      this.setPriceData({
        ...this.priceTotal,
        totalFare: totalFare,
        quotePriceDetails: priceBreakUp,
      });
    } else {
      this.setPriceData({
        ...this.priceTotal,
        totalFare: totalFare,
        quotePriceDetails: [],
      });
    }
  }

  /* ***********************************************************
   * ******* Itinerary Plan PRICING - STORE SECTION *********
   * ***********************************************************
   */
  setPriceData(priceData: QuotePriceModel) {
    this.priceTotal = priceData;
  }

  updatePriceTotal(quotePrice: QuotePriceModel) {
    let existingPriceData = { ...quotePrice };

    existingPriceData.discount = quotePrice?.discount ?? 0;
    existingPriceData.gstAmount = quotePrice?.gstAmount ?? 0;
    existingPriceData.tcsAmount = quotePrice?.tcsAmount ?? 0;
    existingPriceData.markUp = quotePrice?.markUp ?? 0;

    this.priceTotal = existingPriceData;
  }

  setItineraryAndPricingData(
    stayList: OpportunityStayModel[],
    priceData: QuotePriceModel,
    inclusionList: InclusionsModel[],
    optionalExtra: InclusionsModel[],
    daywiseItineraryData: DaywiseItineraryModel[],
    customerQuoteList: CustomerQuoteModel[]
  ) {
    stayList.sort((a, b) => a.id - b.id);
    stayList = stayList.map((x: OpportunityStayModel) => {
      x.propertyName = x.property !== null ? x?.property?.name : x.propertyName;
      x.roomTypeName = x.roomType !== null ? x?.roomType?.name : x.roomTypeName;
      x.propertyId = x?.property?.id ?? "";
      x.roomTypeId = x?.roomType?.id ?? "";
      return x;
    });

    this.setOpportunityStay(stayList);
    this.setPriceData(priceData);
    this.setInclusionList(inclusionList);
    this.setOptionaExtraList(optionalExtra);
    // this.daywiseItineraryList = daywiseItineraryData
    // if(daywiseItineraryData?.length > 0) {
    //     this.selectedItineraryData = daywiseItineraryData[0]
    // }
    this.setCustomerQuoteList(customerQuoteList);
    this.softDeletedData = {
      stayId: [],
      optionalExtra: [],
      costElement: [],
      stayInclusion: [],
    };
  }

  /* ***********************************************************
   * *********** VISIT DESTINATION - STORE SECTION *************
   * ***********************************************************
   */
  setVisitDestinations(destinations: VisitDestinationModel[]) {
    let destinationData: VisitDestinationModel[] = [];
    for (let destination of destinations) {
      destinationData.push({
        ...destination,
        cityName: destination?.city?.name ?? "",
      });
    }
    this.visitDestinationList = destinationData;
  }

  setVisitDestinationsReorder(destinations: VisitDestinationModel[]) {
    let destinationData: VisitDestinationModel[] = [];
    for (let i = 0; i < destinations.length; i++) {
      destinationData.push({ ...destinations[i], index: i + 1 });
    }
    this.visitDestinationList = destinationData;

    let count = 0;
    this.visitDestinationList.forEach((visitDestination) => {
      count = count + visitDestination.stayDuration;
    });

    this.numberOfDays = count + 1;

    let currentDay = 1;
    this.visitDestinationList.forEach((city) => {
      for (let i = 0; i <= city.stayDuration; i++) {
        this.dayCityMapping[currentDay] = city.cityName!;
        currentDay++;
      }
    });
  }

  addNewDestination(destination: VisitDestinationModel) {
    let existingLength = this.visitDestinationList.length;
    let existingDestinations = [...this.visitDestinationList];
    if (destination.index === 1 && existingLength > 0) {
      existingDestinations[0].index = destination.index;
      existingDestinations[0].cityName = destination.cityName;
      existingDestinations[0].cityId = destination.cityId;
      existingDestinations[0].city = destination.city;
    } else if (
      (destination.index === 1 && existingLength === 0) ||
      (destination.index === 2 && existingLength === 1)
    ) {
      existingDestinations.push(destination);
    } else if (destination.index === existingLength) {
      existingDestinations[existingLength - 1].index = destination.index;
      existingDestinations[existingLength - 1].cityName = destination.cityName;
      existingDestinations[existingLength - 1].cityId = destination.cityId;
      existingDestinations[existingLength - 1].city = destination.city;
    } else {
      existingDestinations[existingLength - 1].index = destination.index;
      existingDestinations.push({
        ...destination,
        index: destination.index - 1,
      });
    }
    existingDestinations.sort((a: any, b: any) => a.index - b.index);
    this.setVisitDestinationsReorder(existingDestinations);
  }

  updateDestinationList(
    destinationId: number,
    destination: VisitDestinationModel
  ) {
    let existingDestinations = this.visitDestinationList.filter(
      (x: any) => x.id !== destinationId
    );
    let updatedEntry = this.visitDestinationList.find(
      (x: any) => x.id === destinationId
    );
    if (updatedEntry) {
      updatedEntry.cityName = destination.cityName;
      updatedEntry.city = destination.city;
      updatedEntry.cityId = destination.cityId;
      updatedEntry.stayDuration = destination.stayDuration;
      updatedEntry.index = destination.index;

      existingDestinations.push(updatedEntry);
    }
    existingDestinations.sort((a: any, b: any) => a.index - b.index);

    if (destination.stayDuration === 0) {
      const updatedPropertyList = this.propertyList.filter(
        (property) => property.cityId !== destination.cityId
      );

      this.propertyList = updatedPropertyList;
    }

    this.visitDestinationList = existingDestinations;

    let count = 0;
    this.visitDestinationList.forEach((visitDestination) => {
      count = count + visitDestination.stayDuration;
    });

    this.numberOfDays = count + 1;

    let currentDay = 1;
    this.visitDestinationList.forEach((city) => {
      for (let i = 0; i <= city.stayDuration; i++) {
        this.dayCityMapping[currentDay] = city.cityName!;
        currentDay++;
      }
    });
  }

  deleteDestination(destinationId: number) {
    let existingDestinations = this.visitDestinationList.filter(
      (y: any) => y.id !== destinationId
    );
    this.setVisitDestinationsReorder(existingDestinations);
  }

  applyToArrivalAndDeparture() {
    let selectedPlan = { ...this.selectedItineraryPlan };
    selectedPlan.arrivalAirportId = this.visitDestinationList[0]?.cityId;
    selectedPlan.departureAirportId =
      this.visitDestinationList[this.visitDestinationList.length - 1]?.cityId;
    selectedPlan.arrivalAirportName = this.visitDestinationList[0]?.cityName;
    selectedPlan.departureAirportName =
      this.visitDestinationList[this.visitDestinationList.length - 1]?.cityName;

    this.selectedItineraryPlan = selectedPlan;
  }

  /* ***********************************************************
   * *********** ITINERARY ROUTE - STORE SECTION ***************
   * ***********************************************************
   */
  setItineraryRoutes(routes: ItineraryRouteModel[]) {
    let routePlans: ItineraryRouteModel[] = [];
    for (let route of routes) {
      if (!routePlans.find((y: any) => y.id === route?.id)) {
        routePlans.push(route);
      }
    }
    this.itineraryRoutes = routePlans;
  }

  addNewItineraryRoute(
    distance: number,
    fromDestId: number,
    toDestId: number,
    modeChange: string
  ) {
    let existingRoutes = [...this.itineraryRoutes];
    existingRoutes.push({
      id: Math.floor(Math.random() * 100) * -1,
      distance: distance,
      transportMode: modeChange,
      fromDestinationId: fromDestId,
      toDestinationId: toDestId,
    });
    this.itineraryRoutes = existingRoutes;
  }

  updateItineraryRoute(updateId: number, route: ItineraryRouteModel) {
    let existingRoute = this.itineraryRoutes.filter(
      (y: any) => y.id !== updateId
    );
    let updatedRoute = this.itineraryRoutes.find((y: any) => y.id === updateId);
    if (updatedRoute) {
      let updatedRouteObj = { ...updatedRoute };

      updatedRouteObj.transportMode = route.transportMode;
      updatedRouteObj.distance = route.distance;
      existingRoute.push(updatedRouteObj);
    }
    this.itineraryRoutes = existingRoute;
  }

  /* ***********************************************************
   * *********** CUSTOMER QUOTE - STORE SECTION ****************
   * ***********************************************************
   */
  setDaywiseItineraryList(daywiseItineraries: DaywiseItineraryModel[]) {
    this.daywiseItineraryList = daywiseItineraries;
    // if(daywiseItineraries?.length > 0) {
    //     this.selectedItineraryData = daywiseItineraries[0]
    // } else {
    //     this.selectedItineraryData = {}
    // }
  }

  reOrderAndUpdateDaywiseItineraryList(
    daywiseItineraries: DaywiseItineraryModel[]
  ) {
    let newArr: DaywiseItineraryModel[] = [];
    for (let i = 0; i < daywiseItineraries?.length; i++) {
      newArr.push({ ...daywiseItineraries[i], dayIndex: i + 1 });
    }
    this.daywiseItineraryList = newArr;
    // if(daywiseItineraries?.length > 0) {
    //     this.selectedItineraryData = daywiseItineraries[0]
    // } else {
    //     this.selectedItineraryData = {}
    // }
  }

  /* ***********************************************************
   * *********** CUSTOMER QUOTE - STORE SECTION ****************
   * ***********************************************************
   */
  setCustomerQuoteList(customerQuoteList: CustomerQuoteModel[]) {
    this.customerQuoteList = customerQuoteList;
  }

  addCustomerQuote(customerQuote: CustomerQuoteModel) {
    let existingQuote = [...this.customerQuoteList];
    existingQuote.push(customerQuote);
    this.customerQuoteList = existingQuote;
  }

  deleteCustomerQuote(customerQuoteId: number) {
    let existingQuote = [...this.customerQuoteList];
    existingQuote = existingQuote.filter((x: any) => x.id !== customerQuoteId);
    this.customerQuoteList = existingQuote;
  }

  updateCustomerQuote(
    customerQuoteId: number,
    customerQuote: CustomerQuoteModel
  ) {
    let existingQuote = [...this.customerQuoteList];
    existingQuote = existingQuote.filter((x: any) => x.id !== customerQuoteId);
    existingQuote.push(customerQuote);
    this.customerQuoteList = existingQuote;
  }

  /* ***********************************************************
   * ********* Stay Inclusion Update - STORE SECTION ***********
   * ***********************************************************
   */
  addStayInclusion(inclusion: StayInclusionModel) {
    let existingStays = this.stayList.filter(
      (x: any) => x.id !== inclusion.stayId
    );
    let updateEntry = this.stayList.find((x: any) => x.id === inclusion.stayId);
    if (updateEntry) {
      let existingInclusions = updateEntry?.inclusions ?? [];
      existingInclusions.push(inclusion);
      updateEntry.inclusions = [...existingInclusions];
      existingStays.push(updateEntry);

      existingStays?.sort((a, b) => a.id - b.id);
    }

    this.stayList = existingStays;
  }

  updateStayInclusion(inclusions: StayInclusionModel[]) {
    let existingStays = this.stayList.filter(
      (x: any) => x.id !== inclusions[0].stayId
    );
    let updateEntry = this.stayList.find(
      (x: any) => x.id === inclusions[0].stayId
    );
    if (updateEntry) {
      updateEntry.inclusions = [...inclusions];
      existingStays.push(updateEntry);

      existingStays?.sort((a, b) => a.id - b.id);
    }
    this.stayList = existingStays;
  }

  deleteStayInclusion(inclusionId: number, stayId: any) {
    let existingStays = this.stayList.filter((x: any) => x.id !== stayId);
    let updateEntry = this.stayList.find((x: any) => x.id === stayId);
    if (updateEntry) {
      let existingInclusions =
        updateEntry?.inclusions?.filter((y: any) => y.id !== inclusionId) ?? [];
      updateEntry.inclusions = [...existingInclusions];
      existingStays.push(updateEntry);

      existingStays?.sort((a, b) => a.id - b.id);
    }
    this.stayList = existingStays;

    if (inclusionId > 0) {
      this.setSoftDeletedData("stayInclusion", inclusionId);
    }
  }

  /* ***********************************************************
   * *********** SOFT DELETE - STORE SECTION *******************
   * ***********************************************************
   */
  setSoftDeletedData(key: string, id: number) {
    let existingData = { ...this.softDeletedData };
    let interestedData = [...this.softDeletedData[key]];
    interestedData.push(id);
    existingData[key] = interestedData;
    this.softDeletedData = existingData;
  }

  /* ***********************************************************
   * *********** AFTER SAVE(Update) - STORE SECTION ************
   * ***********************************************************
   */
  updateAfterSave(itineraryPlan: ItineraryPlanModel, id: number) {
    const updateList = this.itineraryPlans.filter(
      (x: any) => x.id !== itineraryPlan.id
    );
    itineraryPlan.id = id;
    updateList.push(itineraryPlan);
    this.itineraryPlans = updateList;
    this.setSelectedItineraryPlan(id, false);
  }

  createCopyOfItineraryPlan(copiedId: number, copiedData: any, routes: any[]) {
    let existingRouteList = this.itineraryPlanRoutesList;
    existingRouteList.push({
      itineraryPlanId: copiedId,
      routes: routes,
      arrivalAirportName: copiedData?.arrivalAirport?.name,
      departureAirportName: copiedData?.departureAirport?.name,
    });
    this.itineraryPlanRoutesList = existingRouteList;

    let existingPlans = this.itineraryPlans;
    if (copiedData) {
      existingPlans.push(copiedData);
    }
    existingPlans.sort((a: any, b: any) => a.id - b.id);
    this.itineraryPlans = existingPlans;

    this.setSelectedItineraryPlan(copiedId, false);
    this.setSelectedItineraryPlanSupplierQuote(copiedData, [], []);
  }
}

const itineraryPlanStore = new ItineraryPlanStore();

export default itineraryPlanStore;
