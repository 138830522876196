import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { amenitiesIconMapping } from "src/constants/iconMapping";
import { experienceApiAxios } from "src/helpers/api_helper";
import { GET_PROPERTY_LISTING, RATELIST } from "src/helpers/url_helper";
import PropertyOverlay from "../propertyOverlay/PropertyOverlay";
import _ from "underscore";
import "./ItineraryStays.scss";
import RoomTypesContainer from "../details/RoomTypesContainer";
import "../details/Details.scss";
import itineraryPlanStore from "src/store/itineraryPlanStore";
import { Property } from "src/models/models";
import ImageGalleryPopup from "../details/ImageGalleryPopup";
import moment from "moment";
import { toJS } from "mobx";

const CustomItineraryStays = observer(
  ({
    cityName,
    existingProperty,
    roomTypes,
    packageLocation,
    beginDate,
    packageType,
    destinationIndex,
  }: {
    cityName: string;
    packageType: any;
    existingProperty: any;
    roomTypes: any[];
    packageLocation: string;
    beginDate: Date | undefined;
    destinationIndex?: number;
  }) => {
    const [propertyDetails, setPropertyDetails] =
      useState<any>(existingProperty);
    const [propertyOverlay, setPropertyOverlay] = useState(false);
    const [facilityLength, setfacilityLength] = useState(4);
    const [selectRoomTypes, setSelectRoomTypes] = useState<any[]>([]);
    const [selectedviewmoreid, setselectedviewmoreid] = useState("");
    const [propertyConfig, setPropertyConfig] = useState<Property>({
      id: -1,
      dayIndexFrom: 0,
      dayIndexTo: 0,
      propertyId: "",
      propertyName: "",
      roomTypeId: "",
      roomTypeName: "",
      destination: "",
      cityId: 0,
      numRooms: 0,
      fromDate: "",
      toDate: "",
      notes: "",
    });
    const [imageOverLayArray, setImageOverLayArray] = useState<any[]>([]);
    const [disableSelect, setdisableSelect] = useState<any>(false);

    const fetchPropertyDetails = async () => {
      if (cityName) {
        experienceApiAxios
          .get(
            `${GET_PROPERTY_LISTING}?pageSize=1&currentPage=${1}&search=${cityName}`
          )
          .then((response) => {
            setPropertyDetails(response.data.propertyList[0]);
          });
      }

      if (packageType === "packageType.Staycation") {
        setdisableSelect(true);
      } else if (packageType === "packageType.Holiday") {
        setdisableSelect(false);
      } else {
        setdisableSelect(false);
      }
    };

    const getIcon = (text: string) => {
      const textVar = text?.toLowerCase();
      let bestMatch = "";
      let bestMatchIndex = 0;
      let matchingIcons = amenitiesIconMapping.filter((icon: any) =>
        textVar?.includes(icon.keyword)
      );
      for (let i = 0; i < matchingIcons.length; i++) {
        if (matchingIcons[i].keyword !== undefined) {
          if (matchingIcons[i].keyword.length > bestMatch.length) {
            bestMatch = matchingIcons[i].keyword;
            bestMatchIndex = i;
          }
        }
      }
      if (matchingIcons.length === 0)
        return require(`../../assets/images/icons/highlights/default.png`);
      return require(`../../assets/images/icons/amenities/${matchingIcons[bestMatchIndex].icon}`);
    };

    const [showCarousel, setShowCarousel] = useState(false);

    const updateShowImageGalleryPopup = (data: any) => {
      setShowCarousel(data);
    };

    const toggleCarousel = () => {
      setShowCarousel(!showCarousel);
    };

    const [startIndex, setStartIndex] = useState(0);

    // Function to handle moving to the previous set of images in the carousel
    const prevImages = () => {
      if (startIndex > 0) {
        setStartIndex(startIndex - 3);
      }
    };

    // Function to handle moving to the next set of images in the carousel
    const nextImages = (carouselImagesLength: any) => {
      if (startIndex + 3 < carouselImagesLength) {
        setStartIndex(startIndex + 3);
      }
    };

    const getStayDates = () => {
      if (
        beginDate &&
        destinationIndex !== null &&
        destinationIndex !== undefined
      ) {
        let numberOfDays: number = 0;
        if (destinationIndex !== 0) {
          numberOfDays = itineraryPlanStore.visitDestinationList
            .slice(0, destinationIndex)
            .reduce((accumulator, currentObj) => {
              return accumulator + currentObj.stayDuration;
            }, 0);

          let startDate = new Date(beginDate);
          let endDate = new Date(beginDate);
          return ` - ${moment(
            startDate.setDate(startDate.getDate() + numberOfDays)
          ).format("DD-MM-YYYY")} to ${moment(
            startDate.setDate(
              endDate.getDate() +
                numberOfDays +
                itineraryPlanStore.visitDestinationList[destinationIndex]
                  .stayDuration
            )
          ).format("DD-MM-YYYY")}`;
        } else {
          let startDate = new Date(beginDate);
          let endDate = new Date(beginDate);
          return ` - ${moment(startDate.setDate(startDate.getDate())).format(
            "DD-MM-YYYY"
          )} to ${moment(
            startDate.setDate(
              endDate.getDate() +
                itineraryPlanStore.visitDestinationList[destinationIndex]
                  .stayDuration
            )
          ).format("DD-MM-YYYY")}`;
        }
      }

      return "";
    };

    const selectedRoomTypes = (
      id: any,
      name: any,
      propertyId: any,
      mealPlan: string
    ) => {
      const findRoomType = itineraryPlanStore.stayRoomTypes.find(
        (room) => room.destinationIndex == destinationIndex
      );
      if (findRoomType) {
        const deleteRoomType = selectRoomTypes.filter(
          (room) => room.destinationIndex != destinationIndex
        );
        const deleteRoomTypeFromStore = itineraryPlanStore.stayRoomTypes.filter(
          (room) => room.destinationIndex != destinationIndex
        );
        deleteRoomType.push({
          propertyId: propertyId,
          roomId: id,
          roomName: name,
          mealPlan: mealPlan,
          destinationIndex: destinationIndex,
        });
        deleteRoomTypeFromStore.push({
          propertyId: propertyId,
          roomId: id,
          roomName: name,
          mealPlan: mealPlan,
          destinationIndex: destinationIndex,
        });
        setPropertyConfig({
          ...propertyConfig,
          roomTypeId: id,
          roomTypeName: name,
        });
        setSelectRoomTypes(deleteRoomType);
        itineraryPlanStore.setStayRoomTypes(deleteRoomTypeFromStore);
      } else {
        let tempRoom = selectRoomTypes;
        tempRoom = tempRoom.concat({
          propertyId: propertyId,
          roomId: id,
          roomName: name,
          mealPlan: mealPlan,
          destinationIndex: destinationIndex,
        });
        let tempRoomStore = itineraryPlanStore.stayRoomTypes;
        tempRoomStore = tempRoomStore.concat({
          propertyId: propertyId,
          roomId: id,
          roomName: name,
          mealPlan: mealPlan,
          destinationIndex: destinationIndex,
        });
        setPropertyConfig({
          ...propertyConfig,
          roomTypeId: id,
          roomTypeName: name,
        });
        setSelectRoomTypes(tempRoom);
        itineraryPlanStore.setStayRoomTypes(tempRoomStore);
      }
    };

    const selectRoomNum = (room: any) => {
      if (room) {
        const tempPropertyConfig = {
          ...propertyConfig,
          destinationIndex: destinationIndex,
          numRooms: parseInt(room),
        };
        setPropertyConfig(tempPropertyConfig);
        const propertyexisting = itineraryPlanStore.propertyList.find(
          (prop: Property) => prop.destinationIndex == destinationIndex
        );
        if (propertyexisting) {
          const tempProp = itineraryPlanStore.propertyList.filter(
            (prop: Property) => prop.destinationIndex != destinationIndex
          );
          tempProp.push(tempPropertyConfig);
          itineraryPlanStore.setPropertyList(tempProp);
        } else {
          let tempProperty: any[] = itineraryPlanStore.propertyList;
          tempProperty = tempProperty.concat(tempPropertyConfig);
          itineraryPlanStore.setPropertyList(tempProperty);
        }
      } else {
        return;
      }
    };

    const selectMealPlan = (mealPlan: string) => {
      if (mealPlan) {
        const tempPropertyConfig = {
          ...propertyConfig,
          destinationIndex: destinationIndex,
          mealPlan: mealPlan,
        };
        setPropertyConfig(tempPropertyConfig);
        const propertyexisting = itineraryPlanStore.propertyList.find(
          (prop: any) => prop.destinationIndex === destinationIndex
        );
        if (propertyexisting) {
          const tempProp = itineraryPlanStore.propertyList.filter(
            (prop: any) => prop.destinationIndex !== destinationIndex
          );
          tempProp.push(tempPropertyConfig);
          itineraryPlanStore.setPropertyList(tempProp);
        } else {
          let tempProperty: any[] = itineraryPlanStore.propertyList;
          tempProperty = tempProperty.concat(tempPropertyConfig);
          itineraryPlanStore.setPropertyList(tempProperty);
        }
      } else {
        return;
      }
    };

    useEffect(() => {
      const existingProp = itineraryPlanStore.selectedProperties.find(
        (property: any) =>
          property.city?.name.toLocaleLowerCase() ==
            cityName.toLocaleLowerCase() &&
          property.destinationIndex == destinationIndex
      );
      if (existingProp) {
        setPropertyDetails(existingProp);
        const roomType = itineraryPlanStore.stayRoomTypes.find(
          (room: any) => room.destinationIndex === existingProp.destinationIndex
        );
        if (roomType) {
          setSelectRoomTypes([roomType]);
        }
      } else {
        fetchPropertyDetails();
      }
    }, [cityName]);

    useEffect(() => {
      if (propertyDetails) {
        const propertyConfigObj = {
          id: Math.floor(Math.random() * 100) * -1,
          dayIndexFrom: 0,
          dayIndexTo: 0,
          propertyId: propertyDetails.id,
          propertyName: propertyDetails.name,
          roomTypeId: "",
          roomTypeName: "",
          destination: cityName,
          cityId: propertyDetails.cityId,
          numRooms: 0,
          fromDate: "",
          toDate: "",
          notes: "",
        };
        const existingProperty = itineraryPlanStore.selectedProperties.find(
          (property: any) => property.destinationIndex == destinationIndex
        );

        if (existingProperty) {
          const tempProperty = itineraryPlanStore.selectedProperties.filter(
            (property: any) => property.destinationIndex != destinationIndex
          );
          tempProperty.push({
            ...propertyDetails,
            destinationIndex: destinationIndex,
          });
          itineraryPlanStore.setSelectedProperties(tempProperty);
        } else {
          let tempProperty = itineraryPlanStore.selectedProperties;
          tempProperty = tempProperty.concat({
            ...propertyDetails,
            destinationIndex: destinationIndex,
          });
          itineraryPlanStore.setSelectedProperties(tempProperty);
        }

        if (propertyDetails) {
          const imageArray: any[] = [];
          propertyDetails.mediaFolder.files.map((file: any) => {
            const tempImg = {
              imageUrl: `https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${propertyDetails.mediaFolder.url}/${file.fileName}`,
              imageCaption: file.fileName,
            };
            imageArray.push(tempImg);
          });
          const mediaOverLayArray = [
            {
              collectionType: "Additional",
              propertyId: null,
              roomTypeId: null,
              images: imageArray,
            },
          ];
          setImageOverLayArray(mediaOverLayArray);
        }

        setPropertyConfig(propertyConfigObj);
      }
    }, [propertyDetails]);

    const moreFacilitylist = (total: number, propertyid: any) => {
      setfacilityLength(total);
      setselectedviewmoreid(propertyid);
    };

    return (
      <>
        {propertyDetails && (
          <div className="stays-section">
            <div className="property-stay-section">
              <div className="property-stay-img-div">
                {roomTypes.length == 0
                  ? propertyDetails?.mediaFolder?.files.length > 0 && (
                      <div className="property-stay-thumbnail">
                        <img
                          className="property-stay-img"
                          src={`https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${propertyDetails.mediaFolder.url}/${propertyDetails.mediaFolder.files[0].fileName}`}
                        />
                        <button
                          className="carousel-toggle-button"
                          onClick={toggleCarousel}
                        >
                          View more...
                        </button>
                      </div>
                    )
                  : roomTypes.map((coverPic: any) => {
                      return (
                        <img
                          className="property-stay-img"
                          src={coverPic.roomType.images[0].imageUrl}
                        />
                      );
                    })}
                <div className="carousel-container">
                  {/* Left arrow */}
                  <button
                    className="carousel-arrow left"
                    disabled={startIndex == 0}
                    onClick={prevImages}
                  >
                    &lt;
                  </button>

                  <div className="carousel-images">
                    {propertyDetails?.mediaFolder?.files.length > 0 &&
                      propertyDetails?.mediaFolder?.files
                        .slice(startIndex, startIndex + 3)
                        .map((file: any, index: any) => {
                          return (
                            <img
                              key={index}
                              src={`https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${propertyDetails.mediaFolder.url}/${file.fileName}`}
                              alt={file.fileName}
                            />
                          );
                        })}
                  </div>

                  {/* Right arrow */}
                  <button
                    className="carousel-arrow right"
                    disabled={
                      startIndex + 3 >=
                      propertyDetails?.mediaFolder?.files.length
                    }
                    onClick={() => {
                      nextImages(propertyDetails.mediaFolder?.files.length);
                    }}
                  >
                    &gt;
                  </button>
                </div>
              </div>
              <div className="property-stay-content-button">
                <div className="property-stay-div">
                  <div className="property-stay-header-div">
                    <div className="property-stay-header-txt test1">
                      {propertyDetails.name}
                    </div>
                    <div className="city-date-div">
                      {`${cityName} - ${
                        itineraryPlanStore.visitDestinationList[
                          destinationIndex!
                        ].stayDuration
                      }N ${
                        itineraryPlanStore.visitDestinationList[
                          destinationIndex!
                        ].stayDuration! + 1
                      }D`}{" "}
                      {getStayDates()}
                    </div>
                    <div className="property-stay-details-location-date">
                      <div className="property-stay-details-bubble">
                        <div className="location-background"></div>

                        <div className="stay-location-date-label">
                          location:
                        </div>
                        <div className="stay-location-date-div">
                          {propertyDetails.address}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ width: "583px" }}
                    className="property-stay-description test1"
                  >
                    {propertyDetails?.descriptionText?.length > 200
                      ? propertyDetails?.descriptionText?.substring(0, 325) +
                        "..."
                      : propertyDetails?.descriptionText + ""}
                  </div>
                </div>
                <div>
                  {disableSelect ? (
                    ""
                  ) : (
                    <button
                      disabled={disableSelect}
                      className="property-stay-header-btn"
                      onClick={() => setPropertyOverlay(!propertyOverlay)}
                    >
                      Select
                    </button>
                  )}
                </div>
              </div>
              {propertyOverlay && (
                <PropertyOverlay
                  onClosePropertyOverlay={setPropertyOverlay}
                  cityName={cityName != "" ? cityName : packageLocation}
                  newPropertyDetails={setPropertyDetails}
                  propertyId={propertyDetails.id}
                  removeRoomtype={undefined}
                  opportunityStaysId={false}
                  loader={null}
                  destinationIndex={destinationIndex}
                />
              )}

              {showCarousel && (
                <ImageGalleryPopup
                  packageData={imageOverLayArray}
                  showImageGalleryPopup={showCarousel}
                  updateShowImageGalleryPopup={updateShowImageGalleryPopup}
                />
              )}
            </div>
            <div className="property-facilities">
              <ul className="inline-block">
                {_.compact(propertyDetails.facilities).length > 0 &&
                  _.compact(propertyDetails.facilities)
                    ?.slice(0, facilityLength)
                    .map((item: any, index: number) => {
                      return (
                        <li
                          style={{ fontSize: "14px" }}
                          key={`package_hotel_facilities_${index}`}
                        >
                          <span style={{ width: "20px" }}>
                            <img
                              src={getIcon(item?.name)}
                              alt=""
                              className="img-fluid"
                            />
                          </span>
                          {item?.name}&nbsp;
                          <span>{item?.extractedJson?.toBePaid && " ($)"}</span>
                        </li>
                      );
                    })}
                {_.compact(propertyDetails.facilities).length === 0 && (
                  <li key={`package_hotel_facilities_na`}>{"Not Listed"}</li>
                )}
                {propertyDetails?.mediaFolder?.files.length ===
                  facilityLength &&
                propertyDetails?.id === selectedviewmoreid &&
                propertyDetails?.facilities.length > 4 ? (
                  <li
                    style={{
                      fontSize: "14px",
                      cursor: "pointer",
                      color: "#2e8b98",
                    }}
                    onClick={() => {
                      moreFacilitylist(4, propertyDetails.id);
                    }}
                  >
                    view less
                  </li>
                ) : propertyDetails?.facilities.length > 4 ? (
                  <li
                    style={{
                      fontSize: "14px",
                      cursor: "pointer",
                      color: "#2e8b98",
                    }}
                    onClick={() => {
                      moreFacilitylist(
                        propertyDetails.mediaFolder?.files.length,
                        propertyDetails.id
                      );
                    }}
                  >
                    view more
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
            <div>
              {roomTypes.length == 0
                ? propertyDetails.roomTypes &&
                  propertyDetails.roomTypes[0] &&
                  propertyDetails.roomTypes.map((room: any, index: any) => {
                    let roomNum = 0;
                    let roomMealType = "";
                    const roomFlag = selectRoomTypes.some(
                      (rooms) => rooms.roomId == room.id
                    );

                    if (roomFlag) {
                      const propertyFind = itineraryPlanStore.propertyList.find(
                        (property) =>
                          property.destinationIndex === destinationIndex
                      );

                      roomNum = propertyFind?.numRooms ?? 0;
                      roomMealType = propertyFind?.mealPlan ?? "";
                    }
                    const imgArr: any[] = [];
                    room.mediaFolder.files.map((file: any) => {
                      const tempImg = {
                        imageCaption: file.fileName,
                        imageUrl: `https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${propertyDetails.mediaFolder.url}/room/${room.mediaFolder.url}/${file.fileName}`,
                      };
                      imgArr.push(tempImg);
                    });
                    return (
                      <>
                        {/* <div className="roomList"> */}
                        <div
                          className="room-info test1"
                          style={
                            propertyDetails.roomTypes.length - 1 == index
                              ? { marginBottom: "24px" }
                              : {}
                          }
                        >
                          <div className="roomselection-radiobtn">
                            <input
                              checked={roomFlag}
                              onChange={() => {
                                selectedRoomTypes(
                                  room.id,
                                  room.name,
                                  propertyDetails.id,
                                  room.mealPlan
                                );
                              }}
                              type="radio"
                            />
                          </div>
                          <div>
                            <RoomTypesContainer imageArr={imgArr} />
                          </div>
                          <div className="room-details-div">
                            <div className="room-header">{room.name}</div>
                            <div className="room-details-text">
                              {room?.beds[0]?.count === undefined
                                ? ""
                                : room?.beds[0]?.count + "-"}
                              {room?.beds[0]?.description === undefined
                                ? ""
                                : room?.beds[0]?.description !== undefined
                                ? room?.beds[0]?.description?.split("bed")[0] +
                                  "bed"
                                : ""}
                            </div>
                            <div className="facilities-room-div">
                              {room.amenities.slice(0, 9).map(
                                (
                                  amenity: {
                                    name: string;
                                    amenityType: string;
                                    extractedJson: any;
                                  },
                                  index: number
                                ) => {
                                  return (
                                    <>
                                      <span
                                        key={`package_room_amenities_${index}`}
                                        style={{
                                          width: "14px",
                                        }}
                                      >
                                        <img
                                          src={getIcon(amenity.amenityType)}
                                          alt=""
                                          className="img-fluid"
                                        />
                                      </span>
                                      <span>{amenity.name}&nbsp;</span>
                                      <span>
                                        {amenity?.extractedJson?.toBePaid &&
                                          " ($)"}
                                      </span>
                                    </>
                                  );
                                }
                              )}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            <div className="room-count-div">
                              <div className="dropdown-label">Meals:</div>
                              <select
                                disabled={!roomFlag}
                                className="room-type-dropdown"
                                style={{ backgroundPosition: "195px" }}
                                value={roomMealType}
                                onChange={(e) => selectMealPlan(e.target.value)}
                              >
                                <option value={0}>Choose a meal plan</option>
                                {propertyDetails?.mealPlanList &&
                                  propertyDetails?.mealPlanList?.length > 0 &&
                                  propertyDetails?.mealPlanList
                                    .find(
                                      (mealPlan: { roomTypeId: any }) =>
                                        mealPlan.roomTypeId === room.id
                                    )
                                    .mealPlan?.map((mealPlanName: any) => {
                                      return (
                                        <option value={mealPlanName.code}>
                                          {mealPlanName.dexcription}
                                        </option>
                                      );
                                    })}
                              </select>
                            </div>
                            <div className="room-count-div">
                              <div className="dropdown-label">Rooms:</div>
                              <select
                                className="room-type-dropdown"
                                disabled={!roomFlag}
                                value={roomNum}
                                style={{
                                  width: "100px",
                                  backgroundPosition: "195px",
                                }}
                                onChange={(e) => {
                                  selectRoomNum(e.target.value);
                                }}
                              >
                                <option value={0}>
                                  Select number of rooms
                                </option>
                                <option value={1}>{"1 Room"}</option>
                                <option value={2}>{"2 Rooms"}</option>
                                <option value={3}>{"3 Rooms"}</option>
                                <option value={4}>{"4 Rooms"}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                : roomTypes &&
                  roomTypes[0] &&
                  roomTypes.map((room: any) => {
                    return (
                      <>
                        <div className="room-info test">
                          <div>
                            <input type="radio" />
                          </div>
                          <div style={{ width: "113.386px" }}>
                            <RoomTypesContainer
                              imageArr={room.roomType.images}
                            />
                          </div>
                          <div className="room-details-div">
                            <div className="room-header">
                              {room.roomType.name}
                            </div>
                            <p
                              className="room-details-text"
                              style={{ marginTop: "0px", minWidth: "164px" }}
                            >
                              {"2 Beds - 1 King, 1 Double"}
                            </p>
                          </div>
                          <div className="property-facilities-stays">
                            {room.roomType.amenities.slice(0, 5).map(
                              (
                                amenity: {
                                  name: string;
                                  icon: string;
                                  extractedJson: any;
                                },
                                index: number
                              ) => {
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "10px",
                                    }}
                                    key={`package_room_amenities_${index}`}
                                    className="stays-item"
                                  >
                                    <span style={{ width: "20px" }}>
                                      <img
                                        src={getIcon(amenity.icon)}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </span>
                                    <div>{amenity.name}&nbsp;</div>
                                    <span>
                                      {amenity?.extractedJson?.toBePaid &&
                                        " ($)"}
                                    </span>
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "5px",
                            }}
                          >
                            <div className="room-count-div">
                              <div className="dropdown-label">Meals:</div>
                              <select
                                className="room-type-dropdown"
                                style={{ backgroundPosition: "195px" }}
                              >
                                <option>Bed and Breakfast</option>
                                <option>Full board</option>
                                <option>Room only</option>
                              </select>
                            </div>
                            <div className="room-count-div">
                              <div className="dropdown-label">Rooms:</div>
                              <select
                                className="room-type-dropdown"
                                style={{
                                  backgroundPosition: "195px",
                                  margin: "auto",
                                }}
                              >
                                <option>{"1 Room"}</option>
                                <option>{"2 Rooms"}</option>
                                <option>{"3 Rooms"}</option>
                                <option>{"4 Rooms"}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        {/* </div> */}
                      </>
                    );
                  })}
            </div>
          </div>
        )}

        {!propertyDetails && (
          <div className="stays-section">
            <div className="city-date-div" style={{ textAlign: "center" }}>
              {`There are no stays in ${cityName} for ${
                itineraryPlanStore.visitDestinationList[destinationIndex!]
                  .stayDuration
              }N ${
                itineraryPlanStore.visitDestinationList[destinationIndex!]
                  .stayDuration! + 1
              }D`}{" "}
              {getStayDates()}
            </div>
          </div>
        )}
      </>
    );
  }
);

export default CustomItineraryStays;
