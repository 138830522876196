import moment from "moment";
import React, { useEffect, useState } from "react";
import { formatMoney } from "../../helpers/utils";

import "./MyBooking.scss";
import { forEach } from "underscore";
import { experienceApiAxios } from "src/helpers/api_helper";
import { DESTINATIONS, GET_BOOKING_CONFIRMATION } from "src/helpers/url_helper";
import { getHeader } from "src/helpers/backend_helper";
import SaveShareValidationComponent from "../validationComponent/SaveShareValidationComponent";

function MyBookingCard(props: any) {
  const {
    item,
    showCancelBookingPopup,
    showModifyBookingPopup,
    showContactUsPopup,
  } = props;
  const bookingInThePast =
    new Date(item?.toDate).getTime() < new Date().getTime();
  const [isPartnerSite, setIsPartnerSite] = useState<boolean>(false);
  const [pointsEarnedFormat, setPointsEarnedFormat] = useState<string>("");
  const [togglePaymentsView, setTogglePaymentsView] = useState(true);
  const [openValidation, setOpenValidation] = useState(false);

  useEffect(() => {
    if (
      process.env.REACT_APP_PARTNER_SITE &&
      process.env.REACT_APP_PARTNER_SITE === "true"
    ) {
      setIsPartnerSite(true);
    }
    if (process.env.REACT_APP_POINT_EARNED_FORMAT) {
      setPointsEarnedFormat(process.env.REACT_APP_POINT_EARNED_FORMAT);
    }
  });

  useEffect(() => {}, [item]);

  const initiatePayment = (url: any) => {
    window.open(url, "_self");
  };

  const fetchPendingAmount = (item: any) => {
    const bookedPrice: number = item.priceTotal?.bookedPrice;
    let paidAmount = 0;
    item.paymentTransaction.map(
      (item: { transactionStatus: string; amount: any }) => {
        if (item.transactionStatus == "transactionStatus.Completed") {
          var amountPaid = Number(item.amount);
          paidAmount = paidAmount + amountPaid;
        }
      }
    );

    return formatMoney(`${bookedPrice - paidAmount}`);
  };

  const getBookingConfirmation = async () => {
    setOpenValidation(true);
    const headerResponse = await getHeader();
    const bookingConfirmation = await experienceApiAxios.get(
      `${GET_BOOKING_CONFIRMATION}/${item.bookingId}`,
      {
        headers: headerResponse,
      }
    );

    if (bookingConfirmation.status === 200) {
      return;
    }

    return;
  };

  return (
    <div className="my-booking-section">
      <div className="my-booking">
        <div className="image">
          <img
            src={
              item?.mainImage || require("../../assets/images/my-booking.png")
            }
            alt=""
            className="img-fluid"
          />
        </div>
        <div className="content">
          <div className="subhead">Hotel Room</div>
          <div className="head">{item.packageName || "NA"}</div>
          <div className="amount lato-font">
            {`Booking amount: ₹${formatMoney(item.priceTotal?.bookedPrice)}`}
            <br />
            {`Pending Amount: ₹${fetchPendingAmount(item)}`}
          </div>
          {isPartnerSite &&
            item.channelOfSale === "channelOfSale.ClubVistara" && (
              <div
                className="points-earned-detail color-black"
                style={{ margin: "6px 0px" }}
                dangerouslySetInnerHTML={{
                  __html: pointsEarnedFormat.replace(
                    "{points}",
                    item.pointsEarned
                  ),
                }}
              />
            )}
          <div className="guests">Guests</div>
          <div className="number">{`${item.numAdults ? item.numAdults : "0"} ${
            item.numAdults > 1 ? "Adults" : "Adult"
          } ${
            item.numChildren > 0
              ? "& " +
                item.numChildren +
                (item.numChildren > 1 ? " Children" : " Child")
              : ""
          } (${item.numRooms ? item.numRooms : "0"} ${
            item.numRooms > 1 ? "Rooms" : "Room"
          })`}</div>
          <div className="amount lato-font">{`Travel Date: ${moment(
            item?.fromDate
          ).format("DD/MM/YYYY")} - ${moment(item?.toDate).format(
            "DD/MM/YYYY"
          )}`}</div>
          <div className="btns">
            <div
              className={bookingInThePast ? "modify disable-action" : "modify"}
              id="modify-btn"
              onClick={() => {
                if (!bookingInThePast) {
                  showModifyBookingPopup("modify", item.bookingId);
                }
              }}
            >
              <span className="icon"></span> Modify
            </div>
            <div
              className={bookingInThePast ? "cancel disable-action" : "cancel"}
              onClick={() => {
                if (!bookingInThePast) {
                  showCancelBookingPopup("cancel", item.bookingId);
                }
              }}
            >
              Cancel Booking
            </div>
            <div
              className={bookingInThePast ? "cancel disable-action" : "cancel"}
              onClick={() => {
                getBookingConfirmation();
              }}
            >
              Get booking confirmation
            </div>
            {item?.bookingStatus === "bookingStatus.confirmed" && (
              <div className={"booking-status pointer"}>Confirmed</div>
            )}
          </div>
        </div>
      </div>
      {item?.paymentTransaction?.length > 0 && (
        <div className="add-on-payment-div">
          <div
            className="payment-btn"
            onClick={() => {
              setTogglePaymentsView(!togglePaymentsView);
            }}
          >
            Payments
            <span className="icon"></span>
            <span
              className={`arrow ${togglePaymentsView ? "active" : ""}`}
            ></span>
          </div>
          {togglePaymentsView && (
            <div className="panel">
              <div className="payment-btn-div">
                {item?.paymentTransaction.map(
                  (paymentItem: any, index: any) => {
                    if (paymentItem.transactionType === "Adhoc") {
                      return (
                        <div className="payment-btn-dropdown deposit-div">
                          <button
                            id={paymentItem.transactionId}
                            className={
                              paymentItem.transactionStatus ===
                              "transactionStatus.Completed"
                                ? "add-on-payment-btn completed"
                                : "add-on-payment-btn deposit"
                            }
                            disabled={
                              paymentItem.transactionStatus ===
                              "transactionStatus.Completed"
                            }
                            onClick={() => {
                              initiatePayment(
                                paymentItem.payments[0].paymentUrl
                              );
                            }}
                          >
                            <div className="inner-add-on-payment-btn">
                              <div className="payment-amount">
                                {paymentItem.transactionType
                                  .replace(/([A-Z])/g, " $1")
                                  .trim()}
                                : &#8377;{paymentItem.amount}
                              </div>
                              <div className="payment-due">
                                {paymentItem.transactionStatus ===
                                "transactionStatus.Completed"
                                  ? "Payment Complete"
                                  : `(Due on ${moment(
                                      paymentItem?.dueDate
                                    ).format("DD/MM/YYYY")})`}
                              </div>
                            </div>
                          </button>
                        </div>
                      );
                    } else {
                      return (
                        <div className="payment-btn-dropdown deposit-div">
                          <button
                            id={paymentItem.transactionId}
                            className={
                              paymentItem.transactionStatus ===
                                "transactionStatus.Completed" ||
                              bookingInThePast
                                ? "add-on-payment-btn completed"
                                : "add-on-payment-btn deposit"
                            }
                            disabled={
                              paymentItem.transactionStatus ===
                                "transactionStatus.Completed" ||
                              bookingInThePast
                            }
                            onClick={() => {
                              initiatePayment(
                                paymentItem.payments[0].paymentUrl
                              );
                            }}
                          >
                            <div className="inner-add-on-payment-btn">
                              <div className="payment-amount">
                                {paymentItem.transactionType
                                  .replace(/([A-Z])/g, " $1")
                                  .trim()}
                                : &#8377;{paymentItem.amount}
                              </div>
                              <div className="payment-due">
                                {paymentItem.transactionStatus ===
                                "transactionStatus.Completed"
                                  ? "Payment Complete"
                                  : `(Due on ${moment(
                                      paymentItem?.dueDate
                                    ).format("DD/MM/YYYY")})`}
                              </div>
                            </div>
                          </button>
                        </div>
                      );
                    }
                  }
                )}
              </div>
            </div>
          )}
        </div>
      )}
      <div className="booking-id-div">
        <div className="booking-id">
          Voyaah Booking ID: <span>{item.bookingId}</span>
        </div>
        <div className="booking-id">
          Channel Of Sale: <span>{item?.channelOfSale.split(".")[1]}</span>
        </div>
      </div>
      {openValidation && (
        <SaveShareValidationComponent
          closeModal={setOpenValidation}
          validationMessage="An email has been sent regarding your confirmation"
        />
      )}
    </div>
  );
}

export default MyBookingCard;
