import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { Bars } from "react-loader-spinner";
import ReactGA from "react-ga4";
import { getAnalyticsEnabled } from "../../helpers/backend_helper";
import { getPaymentDetails } from "../../store/booking/actions";

import "./PaymentCapture.scss";
import _ from "underscore";
import ReactPixel from "react-facebook-pixel";

function PaymentCapture() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [bookingStatus, setBookingStatus] = useState(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [channelOfSale, setChannelOfSale] = useState("");
  const [orderIdOfflineBooking, setOrderIdOfflineBooking] = useState("");
  const [indexToShow, setIndexToShow] = useState<number>(0);

  const { paymentDetails, bookingLoader } = useSelector(
    (state: any) => state.BookingDetailsReducer
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    document.title = "Payment Capture | Voyaah";
    let params = new URLSearchParams(location.search);
    const orderAmount = parseFloat(params.get("amount")!);
    const orderCurrency = params.get("currency");

    ReactPixel.fbq("init", process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq("track", "PageView");
    ReactPixel.fbq("track", "Purchase", {
      value: orderAmount,
      currency: orderCurrency,
    });
    if (getAnalyticsEnabled()) {
      ReactGA.event({
        action: `{send_to: ${process.env.REACT_APP_GA_MEASUREMENT_ID}/${process.env.REACT_APP_GTAG_CONVERSION_ID}, transaction_id: '' }`,
        category: `conversion`,
      });
    }
  }, []);

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    let orderId;
    const channelOfSale = params.get("merchant_param1");
    setChannelOfSale(channelOfSale!);
    if (channelOfSale === "channelOfSale.Offline") {
      orderId = params.get("merchant_param3");
      setOrderIdOfflineBooking(params.get("order_id")!);
    } else {
      orderId = params.get("order_id");
    }

    if (orderId) {
      const localStorageData = localStorage.getItem("voyaahCustomerTracker");
      let customerProfileId = "";
      if (localStorageData) {
        customerProfileId = JSON.parse(localStorageData!).id;
      }
      dispatch(getPaymentDetails(orderId, channelOfSale, customerProfileId));
    }
  }, [location, dispatch]);

  useEffect(() => {
    if (!_.isEmpty(paymentDetails)) {
      let bookingStatus: any;
      if (channelOfSale === "channelOfSale.Offline") {
        paymentDetails.paymentTransaction.forEach((item: any) => {
          if (item?.payments[0]?.id === orderIdOfflineBooking) {
            bookingStatus =
              item?.payments[0]?.paymentStatus === "paymentStatus.Completed";
          }
        });
      } else {
        bookingStatus =
          paymentDetails.bookingStatus === "bookingStatus.paymentComplete" ||
          paymentDetails.bookingStatus === "bookingStatus.confirmed";
      }

      setBookingStatus(bookingStatus!);

      if (bookingStatus) {
        if (getAnalyticsEnabled()) {
          ReactGA.event({
            action: "booking_success",
            category: "payment_capture_page",
          });
        }
      } else {
        if (getAnalyticsEnabled()) {
          ReactGA.event({
            action: "booking_failed",
            category: "payment_capture_page",
          });
        }
      }

      const travelStartDate = new Date(paymentDetails.fromDate);
      const formattedTravelStartDate = moment(travelStartDate).format("MMM DD");
      setStartDate(formattedTravelStartDate);

      let travelEndDate = new Date(paymentDetails.toDate);
      const formattedTravelEndDate =
        moment(travelEndDate).format("MMM DD, YYYY");
      setEndDate(formattedTravelEndDate);
    }
  }, [paymentDetails]);

  const navigateToHome = () => {
    navigate(`/`);
  };

  return (
    <>
      <div className="inner-page details-page">
        <div className="section">
          <div className="breadcrum">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>›</li>
              <li>Payment Capture</li>
            </ul>
          </div>

          {!_.isEmpty(paymentDetails) && (
            <div className="payment-section-blk">
              <div className="img">
                <img
                  src={require(`../../assets/images/${
                    bookingStatus ? "success" : "error"
                  }.png`)}
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="head">{`Payment ${
                bookingStatus ? "Successful" : "Failed"
              }`}</div>
              <div className="content">
                <p>
                  {`${
                    bookingStatus
                      ? "All done! We will send you the booking confirmation in next 48 hours"
                      : "We are sorry, your payment isn't getting processed for the below selected package."
                  }`}
                </p>
                <div className="date-location">
                  <div className="image">
                    <img
                      src={
                        paymentDetails?.mainImage ||
                        require("../../assets/images/image24.png")
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="detail">
                    <p className="head">{`${paymentDetails?.packageName}`}</p>
                    <p className="des">
                      <span>Date</span> {`${startDate} to ${endDate}`}
                    </p>
                    {/* <p className="des">
                  <span>Location</span> {`Destination`}
                </p> */}
                    <p className="des">
                      <span>Order Id</span> {`${paymentDetails?.bookingId}`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="rate-comment-section">
                {bookingStatus && (
                  <div className="rate-comments-div">
                    {paymentDetails?.roomDetails?.length > 0 && (
                      <table className="rate-comments-table">
                        <tr className="rate-comment-table-head">
                          <th style={{ width: "40px" }}>No</th>
                          <th>Property details</th>
                          <th style={{ width: "100px" }}>Check in</th>
                          <th style={{ width: "100px" }}>Check out</th>
                          <th style={{ width: "100px" }}>Room details</th>
                          <th style={{ width: "60px" }}>Room #</th>
                        </tr>
                        {paymentDetails?.roomDetails?.map(
                          (roomDetail: any, index: number) => {
                            return (
                              <tr className="rate-comment-row">
                                <td style={{ textAlign: "center" }}>
                                  {index + 1}
                                </td>
                                <td>
                                  <div>
                                    <b>{`${roomDetail?.property?.name}, ${roomDetail?.property?.location}`}</b>
                                    {roomDetail?.bookedRooms[0]?.rateComment
                                      .length > 0 ? (
                                      roomDetail?.bookedRooms[0]?.rateComment
                                        ?.length > 150 ? (
                                        <div
                                          title={
                                            roomDetail?.bookedRooms[0]
                                              ?.rateComment
                                          }
                                        >
                                          {roomDetail?.bookedRooms[0]?.rateComment?.substring(
                                            0,
                                            indexToShow === roomDetail.id
                                              ? roomDetail?.bookedRooms[0]
                                                  ?.rateComment?.length
                                              : 150
                                          )}
                                          {indexToShow !== roomDetail.id && (
                                            <span
                                              style={{
                                                color: "#2e8b98",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                setIndexToShow(roomDetail.id);
                                              }}
                                            >
                                              &nbsp;more ...
                                            </span>
                                          )}
                                          {indexToShow === roomDetail.id && (
                                            <span
                                              style={{
                                                color: "#2e8b98",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                setIndexToShow(-1);
                                              }}
                                            >
                                              &nbsp;less ...
                                            </span>
                                          )}
                                        </div>
                                      ) : (
                                        <div
                                          title={
                                            roomDetail?.bookedRooms[0]
                                              ?.rateComment
                                          }
                                        >
                                          {roomDetail?.bookedRooms[0]
                                            ?.rateComment + ""}
                                        </div>
                                      )
                                    ) : (
                                      <div
                                        title="Free cancellation before 30 days of
                                        travel date"
                                      >
                                        Free cancellation before 30 days of
                                        travel date
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {moment(roomDetail?.fromDate).format(
                                    "MMM DD, YYYY"
                                  ) ?? ""}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {moment(roomDetail?.toDate).format(
                                    "MMM DD, YYYY"
                                  ) ?? ""}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {roomDetail?.name}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {roomDetail?.numRooms}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </table>
                    )}
                  </div>
                )}
                <p>
                  {`${
                    bookingStatus
                      ? "Your booking is under process. Please wait for 48 hours for the final confirmation"
                      : "For processing request offline you can reach our travel expert on 022-48930225."
                  }`}
                </p>

                <div className="green-btn" onClick={navigateToHome}>
                  Explore More
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {bookingLoader && (
        <div className="loader-container">
          <div className="loader">
            <Bars color="#00BFFF" height={50} width={100} />
          </div>
        </div>
      )}
    </>
  );
}

export default PaymentCapture;
