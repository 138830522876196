import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";
import {
  generateTimeSlots,
  haversineDistanceForCoOrds,
} from "src/helpers/utils";
import { OptionsOrGroups } from "react-select";
import PlacesOfInterestOverlay from "../placesOfInterestOverlay/PlacesOfInterestOverlay";
import itineraryPlanStore from "src/store/itineraryPlanStore";
import {
  Interests,
  PlaceToVisit,
  PlaceToVisitActivity,
} from "src/models/models";
import {
  ConfirmationCheck,
  DeleteIcon,
  LocationAdd,
  LocationIcon,
  PlusIcon,
  SaveIcon,
} from "src/icons/coreIcons";
import { toJS } from "mobx";
import GoogleMapComponent from "./GoogleMapComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { experienceApiAxios } from "src/helpers/api_helper";
import { DESTINATIONS } from "src/helpers/url_helper";
import Tooltip from "@mui/material/Tooltip";
import { getHeader } from "src/helpers/backend_helper";

type waypoints = {
  lat: number;
  lng: number;
};

const ItineraryPlacesToVisit = observer(
  ({
    data,
    index,
    deletePlaceOfInterest,
  }: {
    data: PlaceToVisit;
    index: number;
    deletePlaceOfInterest: any;
  }) => {
    const timeSlots = generateTimeSlots();
    const [places, setPlaces] = useState<PlaceToVisit>(data);
    const [sectionOpen, setSectionOpen] = useState(true);
    const [placesOfInterestOverlay, setPlacesOfInterestOverlay] =
      useState(false);
    const dropDownOptions: OptionsOrGroups<Interests, any> | undefined = [];
    const [cityInfo, setCityInfo] = useState<{
      cityId: number;
      cityName: string;
      activityIndex: number;
    }>({
      cityId: -1,
      cityName: "",
      activityIndex: -1,
    });

    const [errors, setErrors] = useState<any>({});
    const [overlayText, setOverlayText] = useState<any>("");
    const [mapCoords, setMapCoords] = useState<waypoints[]>([]);
    const [placesOfInterestData, setPlacesOfInterestData] = useState<any>();

    useEffect(() => {
      const fetchData = async () => {
        try {
          const headerResponse = await getHeader();
          const destinationPromises = places.cityList.map(async (city) => {
            const result = await experienceApiAxios.get(
              `${DESTINATIONS}/${city.cityId}`,
              {
                headers: headerResponse,
              }
            );

            if (result.status === 200) {
              return {
                ...city,
                placesToVisit: result.data.destinationData.placeOfInterest,
              };
            }
          });

          const fetchedDestinationPromises = await Promise.all(
            destinationPromises
          );

          setPlacesOfInterestData(fetchedDestinationPromises);
        } catch (error) {
          console.error("Error fetching data", error);
        }
      };

      fetchData();
      generateMap();
    }, [places]);

    const generateMap = () => {
      const externalCities: { lat: number; lng: number }[] = [];
      itineraryPlanStore.placesToVisit[index - 1].activities.forEach(
        (activity) => {
          activity.interests?.forEach((interest) => {
            if (interest.external) {
              const externalCity = {
                lat: parseFloat(interest.latitude!),
                lng: parseFloat(interest.longitude!),
              };

              externalCities.push(externalCity);
            }
          });
        }
      );

      setMapCoords(externalCities);
    };

    const customStyles = {
      multiValue: (styles: any, { data }: any) => {
        return {
          ...styles,
          backgroundColor: "#badade",
          borderRadius: "15px",
          paddingRight: "7px",
          paddingLeft: "7px",
        };
      },
      multiValueLabel: (styles: any, { data }: any) => {
        return {
          ...styles,
          color: "#000",
        };
      },
    };

    const addNewActivity = () => {
      const newActivity = {
        from: "10 AM",
        to: "7 PM",
        placeName: itineraryPlanStore.dayCityMapping[places.dayIndex],
        interests: [],
      };

      const existingCityList = places.cityList;

      const result = itineraryPlanStore.visitDestinationList.find(
        (destination) =>
          destination.cityName ===
          itineraryPlanStore.dayCityMapping[places.dayIndex]
      );

      const cityListObj = {
        cityId: result?.cityId!,
        cityName: result?.cityName!,
        city: result?.city!,
      };

      existingCityList[existingCityList.length] = cityListObj;

      const uniqueCity = new Set();
      const uniqueCities = existingCityList.filter((city) => {
        if (uniqueCity.has(city.cityId)) {
          return false;
        } else {
          uniqueCity.add(city.cityId);
          return true;
        }
      });

      const existingActivities: PlaceToVisitActivity[] = places.activities;
      existingActivities.push(newActivity);
      const updatedPlace: PlaceToVisit = {
        ...places,
        cityList: uniqueCities,
        activities: existingActivities,
      };

      setPlaces(updatedPlace);
      itineraryPlanStore.updateSelectedPlaceToVisit(updatedPlace);
    };

    const deleteActivity = (activityIndex: number) => {
      const existingActivities: PlaceToVisitActivity[] =
        places.activities.filter((activity, index) => index !== activityIndex);

      const updatedPlace: PlaceToVisit = {
        ...places,
        activities: existingActivities,
      };

      if (existingActivities.length === 0) {
        deletePlaceOfInterest(places);
      } else {
        setPlaces(updatedPlace);
        itineraryPlanStore.updateSelectedPlaceToVisit(updatedPlace);
      }
    };

    const handlePlaceName = (placeName: string) => {
      const updatedPlace: PlaceToVisit = {
        ...places,
        name: placeName,
      };

      setPlaces(updatedPlace);
      itineraryPlanStore.updateSelectedPlaceToVisit(updatedPlace);
    };

    const addPlaceOfInterest = (
      imageUrl: string,
      placeId: number,
      placeName: string,
      latitude: string,
      longitude: string,
      activityIndex: number
    ) => {
      const placeActivities: PlaceToVisitActivity[] = places.activities;
      const updatedActivities = placeActivities.map((activity, index) => {
        if (index === activityIndex) {
          const activityInterests = activity.interests;
          activityInterests?.push({
            imageUrl: imageUrl,
            placeId: placeId,
            label: placeName,
            value: placeName,
            latitude: latitude,
            longitude: longitude,
            external: true,
          });
          return { ...activity, interests: activityInterests };
        } else {
          return activity;
        }
      });

      setPlaces({ ...places, activities: updatedActivities });
      itineraryPlanStore.updateSelectedPlaceToVisit({
        ...places,
        activities: updatedActivities,
      });
    };

    const removePlaceOfInterest = (
      placeName: string,
      activityIndex: number
    ) => {
      const updatedActivities = places.activities.map((activity, index) => {
        if (index === activityIndex) {
          const activityInterests = activity.interests?.filter(
            (interest) => interest.label !== placeName
          );
          return { ...activity, interests: activityInterests };
        } else {
          return activity;
        }
      });

      setPlaces({ ...places, activities: updatedActivities });
      itineraryPlanStore.updateSelectedPlaceToVisit({
        ...places,
        activities: updatedActivities,
      });
    };

    const handleChangeOfInterests = (data: any, activityIndex: number) => {
      const placeActivities: PlaceToVisitActivity[] = places.activities;
      const updatedActivities = placeActivities.map((activity, index) => {
        if (index === activityIndex) {
          return { ...activity, interests: data };
        } else {
          return activity;
        }
      });

      setPlaces({ ...places, activities: updatedActivities });
      itineraryPlanStore.updateSelectedPlaceToVisit({
        ...places,
        activities: updatedActivities,
      });

      generateMap();
    };

    const movePlaceUp = () => {
      itineraryPlanStore.moveSelectedPlaceUp(index - 1);
    };

    const movePlaceDown = () => {
      itineraryPlanStore.moveSelectedPlaceDown(index - 1);
    };

    const onClosePlacesOfInterestOverlay = () => {
      setPlacesOfInterestOverlay(false);
      generateMap();
    };

    const checkInterestAddedForPopularDestinations = (placeName: string) => {
      let isAdded = false;
      itineraryPlanStore.placesToVisit.forEach((place) => {
        place.activities?.forEach((activity) => {
          activity.interests?.forEach((interest) => {
            if (interest.label === placeName) {
              isAdded = true;
            }
          });
        });
      });

      return isAdded;
    };

    return (
      <div className="places-to-visit-section">
        <div className="accordian-header-section">
          <div className="accordian-header">
            <img
              src={require("../../assets/images/disable-right.png")}
              className={`accordian-image ${sectionOpen ? "rotate" : ""}`}
              onClick={() => setSectionOpen(!sectionOpen)}
              alt="accordian-toggle"
            />
            {itineraryPlanStore.placesToVisitEdit && (
              <div className="accordian-header-div">
                <select
                  className="day-index-select"
                  value={places.dayIndex}
                  onChange={(e) => {
                    const dayIndex = parseInt(e.target.value);
                    const updatedPlace: PlaceToVisit = {
                      ...places,
                      dayIndex: dayIndex,
                    };

                    setPlaces(updatedPlace);
                    itineraryPlanStore.updateSelectedPlaceToVisit(updatedPlace);

                    places.activities.forEach((activity, activityIndex) => {
                      if (activity.placeName.length === 0) {
                        setErrors({
                          ...errors,
                          [activityIndex]: false,
                        });
                        const updatedActivities = places.activities.map(
                          (activity: PlaceToVisitActivity, idx) => {
                            if (idx === activityIndex) {
                              return {
                                ...activity,
                                interests: [],
                                placeName:
                                  itineraryPlanStore.dayCityMapping[dayIndex],
                              };
                            } else {
                              return activity;
                            }
                          }
                        );

                        const existingCityList = places.cityList;

                        const result =
                          itineraryPlanStore.visitDestinationList.find(
                            (destination) =>
                              destination.cityName ===
                              itineraryPlanStore.dayCityMapping[dayIndex]
                          );

                        const cityListObj = {
                          cityId: result?.cityId!,
                          cityName: result?.cityName!,
                          city: result?.city!,
                        };

                        existingCityList[activityIndex] = cityListObj;
                        const uniqueCity = new Set();
                        const uniqueCities = existingCityList.filter((city) => {
                          if (uniqueCity.has(city.cityId)) {
                            return false;
                          } else {
                            uniqueCity.add(city.cityId);
                            return true;
                          }
                        });

                        setPlaces({
                          ...places,
                          activities: updatedActivities,
                          cityList: uniqueCities,
                        });

                        itineraryPlanStore.updateSelectedPlaceToVisit({
                          ...places,
                          activities: updatedActivities,
                          cityList: uniqueCities,
                        });
                      }
                    });
                  }}
                >
                  {Array.from(
                    { length: itineraryPlanStore.numberOfDays },
                    (v, i) => i + 1
                  ).map((number) => {
                    return <option value={number}>Day - {number}</option>;
                  })}
                </select>
                <input
                  type="text"
                  className="accordian-header-input"
                  placeholder="Enter Itinerary name for the day"
                  value={places.name || ""}
                  onChange={(e) => {
                    handlePlaceName(e.target.value);
                  }}
                />
                {places.dayIndex === itineraryPlanStore.numberOfDays && (
                  <div className="last-day-div">Last day of the trip</div>
                )}
              </div>
            )}
            {!itineraryPlanStore.placesToVisitEdit && (
              <h3 className="accordian-header-text">
                Day {places.dayIndex} - {places.name}
              </h3>
            )}
          </div>
          <div>
            {sectionOpen && itineraryPlanStore.placesToVisitEdit && (
              <div className="add-delete-btn-div">
                <button
                  className="arrow-up-down-btn"
                  onClick={() => {
                    movePlaceUp();
                  }}
                  title="Move this up to arrange your places to visit list"
                >
                  <FontAwesomeIcon className="icon-img" icon={faArrowUp} />
                </button>
                &nbsp;
                <button
                  className="arrow-up-down-btn"
                  onClick={() => {
                    movePlaceDown();
                  }}
                  title="Move this down to arrange your places to visit list"
                >
                  <FontAwesomeIcon className="icon-img" icon={faArrowDown} />
                </button>
              </div>
            )}
          </div>
        </div>
        {sectionOpen && places.activities.length > 0 && (
          <div className="places-to-visit-content-section">
            {places.activities.map(
              (place: PlaceToVisitActivity, activityIndex: number) => {
                return (
                  <div className="places-to-visit-div">
                    <div className="place-to-visit-item">
                      <div className="from-to-city-div">
                        <div className="from-to-div">
                          <label className="timing-label">From/To: </label>
                          <div className="from-to-input-div">
                            <select
                              className="ptv-time-from"
                              value={place.from || ""}
                              disabled={!itineraryPlanStore.placesToVisitEdit}
                              onChange={(e) => {
                                const updatedActivities = places.activities.map(
                                  (activity: PlaceToVisitActivity, idx) => {
                                    if (idx === activityIndex) {
                                      return {
                                        ...activity,
                                        from: e.target.value,
                                      };
                                    } else {
                                      return activity;
                                    }
                                  }
                                );

                                setPlaces({
                                  ...places,
                                  activities: updatedActivities,
                                });

                                itineraryPlanStore.updateSelectedPlaceToVisit({
                                  ...places,
                                  activities: updatedActivities,
                                });
                              }}
                            >
                              {timeSlots.map((time, index) => (
                                <option key={index} value={time}>
                                  {time}
                                </option>
                              ))}
                            </select>
                            <select
                              className="ptv-time-to"
                              value={place.to || ""}
                              disabled={!itineraryPlanStore.placesToVisitEdit}
                              onChange={(e) => {
                                const updatedActivities = places.activities.map(
                                  (activity: PlaceToVisitActivity, idx) => {
                                    if (idx === activityIndex) {
                                      return {
                                        ...activity,
                                        to: e.target.value,
                                      };
                                    } else {
                                      return activity;
                                    }
                                  }
                                );

                                setPlaces({
                                  ...places,
                                  activities: updatedActivities,
                                });

                                itineraryPlanStore.updateSelectedPlaceToVisit({
                                  ...places,
                                  activities: updatedActivities,
                                });
                              }}
                            >
                              {timeSlots.map((time, index) => (
                                <option key={index} value={time}>
                                  {time}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="cities-div">
                          <label className="cities-label">
                            Places to visit:
                          </label>
                          <div className="city-input-div">
                            <select
                              className="ptv-city-list"
                              value={place.placeName || ""}
                              disabled={!itineraryPlanStore.placesToVisitEdit}
                              onChange={(e) => {
                                setErrors({
                                  ...errors,
                                  [activityIndex]: false,
                                });
                                const updatedActivities = places.activities.map(
                                  (activity: PlaceToVisitActivity, idx) => {
                                    if (idx === activityIndex) {
                                      return {
                                        ...activity,
                                        interests: [],
                                        placeName: e.target.value,
                                      };
                                    } else {
                                      return activity;
                                    }
                                  }
                                );

                                const existingCityList = places.cityList;

                                const result =
                                  itineraryPlanStore.visitDestinationList.find(
                                    (destination) =>
                                      destination.cityName === e.target.value
                                  );

                                const cityListObj = {
                                  cityId: result?.cityId!,
                                  cityName: result?.cityName!,
                                  city: result?.city!,
                                };

                                existingCityList[activityIndex] = cityListObj;
                                const uniqueCity = new Set();
                                const uniqueCities = existingCityList.filter(
                                  (city) => {
                                    if (uniqueCity.has(city.cityId)) {
                                      return false;
                                    } else {
                                      uniqueCity.add(city.cityId);
                                      return true;
                                    }
                                  }
                                );

                                setPlaces({
                                  ...places,
                                  activities: updatedActivities,
                                  cityList: uniqueCities,
                                });

                                itineraryPlanStore.updateSelectedPlaceToVisit({
                                  ...places,
                                  activities: updatedActivities,
                                  cityList: uniqueCities,
                                });
                              }}
                            >
                              <option key={null} value={""}>
                                Select a City
                              </option>
                              {itineraryPlanStore.visitDestinationList.map(
                                (city: any) => {
                                  if (city && city?.cityName) {
                                    return (
                                      <option
                                        value={city.cityName}
                                        key={city.cityId}
                                      >
                                        {city.cityName}
                                      </option>
                                    );
                                  }
                                }
                              )}
                            </select>
                            {errors[activityIndex] && (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                  marginTop: "5px",
                                }}
                              >
                                Select a city from the dropdown
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="places-of-interest-div">
                        <CreatableSelect
                          isMulti
                          className="places-of-interest"
                          components={makeAnimated()}
                          value={place.interests}
                          styles={customStyles}
                          options={dropDownOptions}
                          isDisabled={!itineraryPlanStore.placesToVisitEdit}
                          onChange={(data) => {
                            handleChangeOfInterests(data, activityIndex);
                          }}
                        />
                      </div>
                      <div className="places-btn-div">
                        <Tooltip
                          title={
                            place.placeName
                              ? `Select places from ${place.placeName}`
                              : "Select a city from the dropdown"
                          }
                          arrow
                          placement="top"
                        >
                          <button
                            className="add-new-place-btn"
                            onClick={() => {
                              if (place.placeName) {
                                setOverlayText(
                                  `Day ${places.dayIndex} - ${places.name}: ${place.from} - ${place.to}`
                                );
                                setPlacesOfInterestOverlay(true);
                                const selectedCity =
                                  itineraryPlanStore.visitDestinationList.find(
                                    (city) => city.cityName === place.placeName
                                  );

                                setCityInfo({
                                  cityId: selectedCity?.cityId!,
                                  cityName: selectedCity?.cityName!,
                                  activityIndex: activityIndex,
                                });
                              } else {
                                setErrors({ ...errors, [activityIndex]: true });
                              }
                            }}
                            disabled={!itineraryPlanStore.placesToVisitEdit}
                          >
                            Select places
                          </button>
                        </Tooltip>

                        <div className="event-btn-div">
                          <button
                            className="add-event-btn"
                            id={`opp_routing${index}_btnDelete`}
                            disabled={!itineraryPlanStore.placesToVisitEdit}
                            onClick={() => {
                              addNewActivity();
                            }}
                          >
                            <PlusIcon />
                          </button>
                          <button
                            className="remove-event-btn"
                            id={`opp_routing${index}_btnDelete`}
                            disabled={!itineraryPlanStore.placesToVisitEdit}
                            onClick={() => {
                              deleteActivity(activityIndex);
                            }}
                          >
                            <DeleteIcon />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
            {placesOfInterestData && placesOfInterestData.length >= 1 && (
              <div>
                {placesOfInterestData.map((placeOfInterestObj: any) => {
                  if (
                    placeOfInterestObj.placesToVisit &&
                    itineraryPlanStore.placesToVisitEdit &&
                    placeOfInterestObj.placesToVisit.length > 0
                  ) {
                    return (
                      <div className="popular-destinations-div">
                        <h3>
                          Popular destinations in {placeOfInterestObj.cityName}
                        </h3>
                        <div className="places-options">
                          {placeOfInterestObj.placesToVisit.map(
                            (placeObj: any, index: number) => {
                              if (index < 5) {
                                return (
                                  <div className="place-option">
                                    {checkInterestAddedForPopularDestinations(
                                      placeObj.name
                                    ) ? (
                                      <SaveIcon />
                                    ) : (
                                      <LocationIcon />
                                    )}
                                    <div className="place-name-option">
                                      <p className="place-name-text">
                                        {placeObj.name}
                                      </p>
                                      <p className="place-distance-text">
                                        {Math.ceil(
                                          haversineDistanceForCoOrds(
                                            placeOfInterestObj.city.latitude,
                                            placeOfInterestObj.city.longitude,
                                            placeObj.latitude,
                                            placeObj.longitude
                                          )
                                        )}{" "}
                                        kms from {placeOfInterestObj.cityName}
                                      </p>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          )}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            )}
            {mapCoords && mapCoords.length >= 2 && (
              <GoogleMapComponent waypoints={mapCoords} />
            )}
          </div>
        )}
        {}
        {sectionOpen && places.activities.length == 0 && <></>}
        {placesOfInterestOverlay && (
          <PlacesOfInterestOverlay
            onClosePlacesOfInterestOverlay={onClosePlacesOfInterestOverlay}
            cityName={cityInfo.cityName}
            cityId={cityInfo.cityId}
            activityIndex={cityInfo.activityIndex}
            addPlaceOfInterest={addPlaceOfInterest}
            removePlaceOfInterest={removePlaceOfInterest}
            interests={
              places.activities[cityInfo.activityIndex].interests ?? []
            }
            overlayText={overlayText}
            placeIndex={index - 1}
          />
        )}
      </div>
    );
  }
);

export default ItineraryPlacesToVisit;
