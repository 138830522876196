import { takeLatest, put, call } from "redux-saga/effects";

import {
  GET_BOOKING_DETAILS,
  GET_BOOKING_LIST,
  UPLOAD_BOOKING_DOCUMENT,
  GET_CLIENT_IP,
  UPDATE_BOOKING_STATUS,
  GET_PAYMENT_DETAILS,
} from "./actionTypes";

import {
  getBookingDetailsSuccess,
  getBookingDetailsFail,
  getBookingListSuccess,
  getBookingListFail,
  uploadBookingDocumentSuccess,
  uploadBookingDocumentFail,
  getClientIpSuccess,
  getClientIpFail,
  updateBookingStatusSuccess,
  updateBookingStatusFail,
  getPaymentDetailsSuccess,
  getPaymentDetailsFail,
} from "./actions";

import {
  getBookingDetails,
  getBookingList,
  uploadBookingDocument,
  getClientIpAddress,
  updateBookingStatus,
  getPaymentDetails,
} from "../../helpers/backend_helper";

function* onGetBookingDetails({
  payload: data,
  couponCode,
  customerProfileId,
}) {
  try {
    const response = yield call(
      getBookingDetails,
      data,
      couponCode,
      customerProfileId
    );
    yield put(getBookingDetailsSuccess(response));
  } catch (error) {
    yield put(getBookingDetailsFail(error.response));
  }
}

function* onGetBookingList() {
  try {
    const response = yield call(getBookingList);
    yield put(getBookingListSuccess(response));
  } catch (error) {
    yield put(getBookingListFail(error.response));
  }
}

function* onUploadBookingDocument({ payload: data, orderId }) {
  try {
    var formData = new FormData();
    // formData.append("order_id", data.orderId);
    // formData.append("files", data.files[0]);
    for (let i = 0; i < data.length; i++) {
      formData.append(data[i].name, data[i].file);
    }
    const response = yield call(uploadBookingDocument, formData, orderId);
    yield put(uploadBookingDocumentSuccess(response));
  } catch (error) {
    yield put(uploadBookingDocumentFail(error.response));
  }
}

function* onGetClientIp() {
  try {
    const response = yield call(getClientIpAddress);
    const data = { ip: response.clientIP };
    yield put(getClientIpSuccess(data));
  } catch (error) {
    yield put(getClientIpFail(error.response));
  }
}

function* onUpdateBookingStatus({ payload: data }) {
  try {
    const response = yield call(updateBookingStatus, data);
    yield put(updateBookingStatusSuccess(response));
  } catch (error) {
    yield put(updateBookingStatusFail(error.response));
  }
}

function* onGetPaymentDetails({
  payload: orderId,
  channelOfSale,
  customerProfileId,
}) {
  try {
    const response = yield call(
      getPaymentDetails,
      orderId,
      channelOfSale,
      customerProfileId
    );
    yield put(getPaymentDetailsSuccess(response));
  } catch (error) {
    yield put(getPaymentDetailsFail(error.response));
  }
}

function* BookingDetailsSaga() {
  yield takeLatest(GET_BOOKING_DETAILS, onGetBookingDetails);
  yield takeLatest(GET_BOOKING_LIST, onGetBookingList);
  yield takeLatest(UPLOAD_BOOKING_DOCUMENT, onUploadBookingDocument);
  yield takeLatest(GET_CLIENT_IP, onGetClientIp);
  yield takeLatest(UPDATE_BOOKING_STATUS, onUpdateBookingStatus);
  yield takeLatest(GET_PAYMENT_DETAILS, onGetPaymentDetails);
}

export default BookingDetailsSaga;
