import { observer } from "mobx-react-lite";

const SaveShareValidationComponent = observer(
  ({
    closeModal,
    validationMessage,
  }: {
    closeModal: any;
    validationMessage: string;
  }) => {
    return (
      <div className="confirmation-overlay">
        <div className="confirmation-popup">
          <div className="confirmation-cancel">
            <button
              className="confirmation-cancel-btn"
              onClick={() => closeModal(false)}
            >
              &#x2715;
            </button>
          </div>
          <div className="confirmation-content">
            <div className="confirmation-content-text">
              <p className="content-text-first">{validationMessage}</p>
            </div>
          </div>
          <div className="validation-btn-div">
            <button
              className="confirmation-okay-btn"
              style={{ color: "white" }}
              onClick={() => closeModal(false)}
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export default SaveShareValidationComponent;
