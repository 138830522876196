import { observer } from "mobx-react-lite";
import React from "react";
import { ConfirmationCheck } from "src/icons/coreIcons";

type Props = {};

const ConfirmationComponent = observer(
  ({ clearDataAfterConfirmation }: { clearDataAfterConfirmation: any }) => {
    return (
      <div className="confirmation-overlay">
        <div className="confirmation-popup">
          <div className="confirmation-cancel">
            <button
              className="confirmation-cancel-btn"
              onClick={() => clearDataAfterConfirmation()}
            >
              &#x2715;
            </button>
          </div>
          <div className="confirmation-content">
            <ConfirmationCheck />
            <div className="confirmation-content-text">
              <p className="content-text-first">
                Your custom itinerary has been submitted for a quote
              </p>
              <p className="content-text-second">
                Our team will get back to you shortly to move this forward
              </p>
            </div>
          </div>
          <div className="confirmation-okay">
            <button
              className="confirmation-okay-btn"
              onClick={() => clearDataAfterConfirmation()}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export default ConfirmationComponent;
