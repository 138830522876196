import {
  GET_ITINERARY_LIST,
  GET_ITINERARY_LIST_FAIL,
  GET_ITINERARY_LIST_SUCCESS,
} from "./actionTypes";

export const getItineraryListing = () => {
  return {
    type: GET_ITINERARY_LIST,
  };
};

export const getItineraryListingSuccess = (data: any) => {
  return {
    type: GET_ITINERARY_LIST_SUCCESS,
    payload: data,
  };
};

export const getItineraryListingFail = (error: any) => {
  return {
    type: GET_ITINERARY_LIST_FAIL,
    payload: error,
  };
};
