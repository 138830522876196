export interface CountryModel {
  code: string;
  name: string;
  typicalModeOfTransport: string;
}

export interface StateModel {
  code: string;
  name: string;
  countryCode: string;
}

export interface CityModel {
  id: number;
  name: string;
  cityCode: string;
  latitude?: string;
  longitude?: string;
  countryCode: string;
  modesOfTravel?: string[];
  country?: CountryModel;
  state?: StateModel;
}

export interface ItineraryRouteModel {
  id: number;
  distance: number;
  transportMode: string;
  fromDestinationId: number;
  toDestinationId: number;
  fromDestination?: VisitDestinationModel;
  toDestination?: VisitDestinationModel;
}

export interface OpportunityModel {
  id: string;
  fromDate: Date;
  toDate: Date;
  brief: string;
  source: string;
  cityId?: number;
  destination?: string;
  numDays: number;
  numNights: number;
  numAdults: number;
  numChildren: number;
  customerEmail?: string;
  customerName: string;
  customerPhoneNo?: string;
  numRooms: number;
  statusCode: string;
  createdAt: Date;
  createdBy?: string;
  updatedAt: Date;
  updatedBy?: string;
  salesOwnerId?: string;
  productOwnerId?: string;
  type: string;
  productStatus?: string;
  city?: any;
  salesOwner?: {
    id: string;
    name: string;
    email: string;
  };
  productOwner?: {
    id: string;
    name: string;
    email: string;
  };
  opportunityTraveller: OpportunityTravellerModel[];
  opportunityComment: OpportunityCommentModel[];
  opportunityInterest: OpportunityInterestModel[];
  itineraryPlans: ItineraryPlanModel[];
}

export interface OpportunityTravellerModel {
  id: number;
  name: string;
  age?: number;
  primary?: boolean;
  type: string;
  createdBy?: string;
  updatedBy?: string;
}

export interface OpportunityInterestModel {
  id: number;
  interestType: string;
  interestGroup: string;
  opportunityId: string;
  createdBy?: string;
  updatedBy?: string;
}

export interface OpportunityCommentModel {
  id: number;
  text: string;
  statusCode: string;
  createdAt: Date;
  commentBy?: {
    id: string;
    name: string;
    email: string;
  };
}
export interface ItineraryPlanModel {
  id: number;
  name: string;
  exclusions?: string;
  inclusions?: string;
  notes?: string;
  inclusionsHighlight?: string;
  summary?: string;
  opportunityId: string;
  arrivalAirportId?: number;
  departureAirportId?: number;
  arrivalAirportName?: string;
  departureAirportName?: string;
  supplierQuoteRequest: SupplierRequestModel[];
  supplierQuoteResponse: SupplierResponseModel[];
  opportunitystays: OpportunityStayModel[];
  costs: InclusionsModel[];
  optionalExtra: InclusionsModel[];
  quotePrice: QuotePriceModel;
  customerQuote: CustomerQuoteModel[];
  daywiseItinerary: DaywiseItineraryModel[];
  visitDestinations: VisitDestinationModel[];
}

export interface SupplierRequestModel {
  id: number;
  name: string;
  emailAddress: string;
  ccAddress?: string;
  subject: string;
  requestText: string;
  createdAt?: Date;
  createdBy?: string;
  updatedBy?: string;
  itineraryPlanId: number;
}

export interface SupplierDocumentModel {
  id: number;
  fileName: string;
  type: string;
  format: string;
  createdBy?: string;
  updatedBy?: string;
}

export interface SupplierResponseModel {
  id: number;
  name: string;
  responseText: string;
  supplierDocument: SupplierDocumentModel[];
  createdAt?: Date;
  createdBy?: string;
  updatedBy?: string;
  itineraryPlanId: number;
}

export interface CustomerQuoteDocModel {
  id: number;
  fileName: string;
  type: string;
  createdBy?: string;
  updatedBy?: string;
}

export interface CustomerQuoteModel {
  id: number;
  name: string;
  emailAddress: string;
  subject: string;
  ccAddress: string;
  messageText: string;
  createdBy?: string;
  updatedBy?: string;
  itineraryPlanId: number;
  customerQuoteDocument?: CustomerQuoteDocModel[];
}

export interface GeneratedCustomerPDFModel {
  id: number;
  generatedUrl?: string;
  fileName: string;
  isGenerated: boolean;
  status?: string;
}

export interface LeadsHistory {
  opportunityId: string;
  destination: string;
  numDays: number;
  numNights: number;
  statusCode: string;
  price: number;
  isEdit?: boolean;
}

export interface OpportunityStayModel {
  id: number;
  dayIndexFrom: number;
  dayIndexTo: number;
  fromDate: Date;
  toDate: Date;
  propertyId?: string;
  propertyName?: string;
  roomTypeId?: string;
  roomTypeName?: string;
  createdBy?: string;
  destination?: string;
  numRooms: number;
  cityId?: number;
  createdAt: Date;
  updatedBy?: string;
  updatedAt?: Date;
  property?: any;
  roomType?: any;
  itineraryPlanId: number;
  city?: any;
  cityName?: string;
  errorMessage?: string;
  notes?: string;
  inclusions: StayInclusionModel[];
}

export interface StayInclusionModel {
  id: number;
  element: string;
  description: string;
  icon?: string;
  stayId?: number;
  createdAt: Date;
  updatedBy?: string;
  updatedAt?: Date;
}

export interface UserModel {
  id: string;
  name: string;
  email: string;
  reportingManager?: {
    id?: string;
    name?: string;
    email?: string;
  };
  roles?: string[];
  fullPermissionAccess: boolean;
  reportees?: any[];
}

export interface StaticDataModel {
  statusCode: string;
  description?: string;
}

export interface FileData {
  icon?: any;
  status?: any;
  name: string;
  file?: any;
}

export interface StayCityData {
  stayId: number;
  cityName: string;
}

export interface s3FileResponse {
  code: number;
  fileUrl?: string;
  errorMessage?: string;
}

export interface PriceDetailModel {
  id: number;
  itemTypeCode: string;
  itemTypeDesc: string;
  unitRate: number;
  itemCount: number;
  itemTotal: number;
}

export interface PriceTotalModel {
  id: number;
  totalFare: number;
  discount: number;
  gstAmount: number;
  tcsAmount: number;
  totalTax: number;
  markUp?: number;
  currencyCode?: string;
}

export interface BookableInclusionsModel {
  id: number;
  rateType: string;
  unitRate: number;
  unitCount: number;
  itemTotal: number;
  description: string;
  createdAt: Date;
  updatedBy?: string;
  updatedAt?: Date;
}

export interface StateModel {
  code: string;
  name: string;
  countryCode: string;
}

export interface OverlayComponent {
  title: string;
  url: string;
  data: any[];
  headers: string[];
}

export interface InclusionsModel extends BookableInclusionsModel {
  id: number;
  rateType: string;
  unitRate: number;
  unitCount: number;
  itemTotal: number;
  description: string;
  createdAt: Date;
  updatedBy?: string;
  updatedAt?: Date;
  itineraryPlanId?: number;
}

export interface QuotePriceModel extends PriceTotalModel {
  quotePrice: number;
  quotePriceDetails: QuotePriceDetailModel[];
}

export interface QuotePriceDetailModel extends PriceDetailModel {
  quotePriceId: number;
}

export interface DaywiseItineraryModel {
  id: number;
  dayIndex: number;
  title: string;
  description: string;
  imagePath?: string;
}

export interface Attachment {
  filename: string;
  content: Buffer;
}

export interface Property {
  id: number;
  dayIndexFrom: number | string;
  dayIndexTo: number | string;
  propertyId: string;
  propertyName: string;
  roomTypeId?: string;
  roomTypeName?: string;
  destination: string;
  cityId: number;
  mealPlan?: string;
  numRooms?: number;
  fromDate?: string;
  toDate?: string;
  notes?: string;
  destinationIndex?: number;
}

/**
 * OpportunityModel - init function to intialize default values
 */
export function initOpportunity(
  options?: Partial<OpportunityModel>
): OpportunityModel {
  const defaults = {
    id: "",
    name: "",
    fromDate: new Date(),
    toDate: new Date(),
    brief: "",
    source: "opportunitySource.Direct",
    numDays: 0,
    numNights: 0,
    numAdults: 0,
    numChildren: 0,
    customerEmail: "",
    customerName: "",
    numRooms: 1,
    type: "",
    productStatus: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    statusCode: "opportunityStatus.Hot",
    opportunityTraveller: [],
    opportunityComment: [],
    itineraryPlans: [],
    opportunityInterest: [],
  };
  return {
    ...defaults,
    ...options,
  };
}

/**
 * OpportunityTravellerModel - init function to intialize default values
 */
export function initOpportunityTraveller(
  options?: Partial<OpportunityTravellerModel>
): OpportunityTravellerModel {
  const defaults = {
    id: 0,
    name: "",
    age: 0,
    primary: false,
    type: "Adult",
  };
  return {
    ...defaults,
    ...options,
  };
}

/**
 * OpportunityCommentModel - init function to intialize default values
 */
export function initOpportunityComment(
  options?: Partial<OpportunityCommentModel>
): OpportunityCommentModel {
  const defaults = {
    id: 0,
    text: "",
    createdAt: new Date(),
    statusCode: "opportunityStatus.Hot",
  };
  return {
    ...defaults,
    ...options,
  };
}

/**
 * SupplierRequestQuoteModel - init function to intialize default values
 */
export function initSupplierQuoteRequest(
  options?: Partial<SupplierRequestModel>
): SupplierRequestModel {
  const defaults = {
    id: 0,
    name: "",
    emailAddress: "",
    subject: "",
    requestText: "",
    ccAddress: "",
    itineraryPlanId: 0,
  };
  return {
    ...defaults,
    ...options,
  };
}

/**
 * OpportunityStayModel - init function to intialize default values
 */
export function initOpportunityStay(
  options?: Partial<OpportunityStayModel>
): OpportunityStayModel {
  const defaults = {
    id: Math.floor(Math.random() * 100) * -1,
    dayIndexFrom: 0,
    dayIndexTo: 1,
    itineraryPlanId: 0,
    fromDate: new Date(),
    toDate: new Date(),
    createdAt: new Date(),
    editEnabled: true,
    numRooms: 1,
    inclusions: [],
  };
  return {
    ...defaults,
    ...options,
  };
}

/**
 * InclusionsModel - init function to intialize default values
 */
export function initInclusionsModel(
  options?: Partial<InclusionsModel>
): InclusionsModel {
  const defaults = {
    id: Math.floor(Math.random() * 100) * -1,
    rateType: "",
    unitRate: 0,
    unitCount: 0,
    itemTotal: 0,
    createdAt: new Date(),
    description: "",
    itineraryPlanId: 0,
  };
  return {
    ...defaults,
    ...options,
  };
}

/**
 * PriceModel - init function to intialize default values
 */
export function initQuotePriceModel(
  options?: Partial<QuotePriceModel>
): QuotePriceModel {
  const defaults = {
    id: Math.floor(Math.random() * 100) * -1,
    currencyCode: "INR",
    totalFare: 0,
    quotePrice: 0,
    discount: 0,
    gstAmount: 0,
    tcsAmount: 0,
    totalTax: 0,
    markUp: 0,
    createdAt: new Date(),
    itineraryPlanId: 0,
    quotePriceDetails: [],
  };
  return {
    ...defaults,
    ...options,
  };
}

/**
 * DaywiseItineraryModel - init function to intialize default values
 */
export function initDaywiseItineraryModel(
  options?: Partial<DaywiseItineraryModel>
): DaywiseItineraryModel {
  const defaults = {
    id: 0,
    dayIndex: 0,
    title: "",
    description: "",
    createdAt: new Date(),
  };
  return {
    ...defaults,
    ...options,
  };
}

/**
 * StayInclusionModel - init function to intialize default values
 */
export function initStayInclusionModel(
  options?: Partial<StayInclusionModel>
): StayInclusionModel {
  const defaults = {
    id: 0,
    stayId: 0,
    icon: "",
    element: "",
    description: "",
    createdAt: new Date(),
  };
  return {
    ...defaults,
    ...options,
  };
}
export function initCustomerQuoteModel(
  options?: Partial<CustomerQuoteModel>
): CustomerQuoteModel {
  const defaults = {
    id: 0,
    name: "",
    emailAddress: "",
    subject: "",
    messageText: "",
    createdBy: "",
    updatedBy: "",
    ccAddress: "",
    itineraryPlanId: 0,
    customerQuoteDocument: [],
  };
  return {
    ...defaults,
    ...options,
  };
}

export function initCustomerQuoteDocModel(
  options?: Partial<CustomerQuoteDocModel>
): CustomerQuoteDocModel {
  const defaults = {
    id: 0,
    fileName: "",
    type: "",
    format: "",
    createdBy: "",
    updatedBy: "",
  };
  return {
    ...defaults,
    ...options,
  };
}

export const generatedPdfStatus = [
  {
    statusCode: "customerPdf.InProgress",
    statusText: "File is generating",
    statusBadge: "bg-primary",
  },
  {
    statusCode: "customerPdf.Error",
    statusText: "Error Occured while generating",
    statusBadge: "bg-error",
  },
  {
    statusCode: "customerPdf.Success",
    statusText: "PDF generated successfully",
    statusBadge: "bg-success",
  },
];

export interface VisitDestinationModel {
  id: number;
  index: number;
  stayDuration: number;
  cityId: number;
  cityName?: string;
  city?: CityModel;
  itineraryPlanId: number;
  routePlan?: ItineraryRouteModel;
}

export interface ItineraryRouteModel {
  id: number;
  distance: number;
  transportMode: string;
  fromDestinationId: number;
  toDestinationId: number;
  fromDestination?: VisitDestinationModel;
  toDestination?: VisitDestinationModel;
}

export interface Interests {
  imageUrl: string;
  placeId: number;
  label: string;
  value: string;
  external?: boolean;
  latitude?: string;
  longitude?: string;
}

export interface PlaceToVisitActivity {
  from: string;
  to: string;
  placeName: string;
  interests?: Interests[];
}

export interface PlaceToVisit {
  id: string;
  name: string;
  dayIndex: number;
  positionIndex: number;
  cityList: { cityId: number; cityName: string; city?: CityModel }[];
  activities: PlaceToVisitActivity[];
}

export function initVisitDestinationModel(
  options?: Partial<VisitDestinationModel>
): VisitDestinationModel {
  const defaults = {
    id: 0,
    index: 0,
    stayDuration: 0,
    cityId: 0,
    cityName: "",
    itineraryPlanId: 0,
  };
  return {
    ...defaults,
    ...options,
  };
}
export function initItineraryRouteModel(
  options?: Partial<ItineraryRouteModel>
): ItineraryRouteModel {
  const defaults = {
    id: 0,
    distance: 0,
    transportMode: "",
    fromDestinationId: 0,
    toDestinationId: 0,
  };
  return {
    ...defaults,
    ...options,
  };
}

export interface QuoteVisitDestinationModel {
  id: number;
  index: number;
  stayDuration: number;
  cityId: number;
  cityName?: string;
  arrivalRoutes?: any[];
  destinationRoutes?: any[];
}

export interface ItineraryPlaceSlot {
  id: number;
  itineraryTimeSlotId: number;
  placeOfInterestId: number;
}

export interface ItineraryTimeSlot {
  id: number;
  description: string;
  startTime: string;
  endTime: string;
  daywiseItineraryId: number;
  itineraryPlaceSlot: ItineraryPlaceSlot[];
}

export interface QuoteDaywiseItineraryModel {
  id: number;
  dayIndex: number;
  title: string;
  description: string;
  imagePath?: string;
  itineraryTimeSlots?: ItineraryTimeSlot[];
}

export interface ItineraryBaseData {
  customerName: string;
  customerEmail: string;
  numAdults: number;
  numChildren: number;
  fromDate?: string;
  toDate?: string;
  fromDateObject?: any;
  toDateObject?: any;
}

export interface QuoteItineraryPlanModel {
  id: number;
  name: string;
  parentId?: any;
  parentType?: string;
  planReference?: string | null;
  baseData?: ItineraryBaseData;
  arrivalAirportId: number;
  departureAirportId: number;
  opportunitystays?: Property[];
  routePlan?: ItineraryRouteModel[];
  daywiseItinerary?: QuoteDaywiseItineraryModel[];
  visitDestinations?: QuoteVisitDestinationModel[];
}

export interface QuoteObject {
  numRooms: number;
  brief: string;
  numDays?: number;
  numNights?: number;
  numAdults: number;
  numChildren: number;
  customerName: string;
  customerPhoneNo: string;
  source: string;
  statusCode: string;
  customerEmail: string;
  type?: string;
  cityId?: number;
  destination?: string;
  fromDate?: string;
  toDate?: string;
  opportunityTraveller?: any[];
  itineraryPlans?: QuoteItineraryPlanModel[];
}
