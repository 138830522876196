import React, { useState } from "react";

import Itinerary from "./Itinerary";
import Inclusion from "./Inclusion";
import Highlights from "./Highlights";
import HotelFacilities from "./HotelFacilities";
import RoomTypes from "./RoomTypes";

import "./Details.scss";
import TravelAccommodation from "./TravelAccommodation";
import PackageDescription from "./PackageDescription";

import ItineraryStays from "../../containers/customItinerary/ItineraryStays";

function LeftContainer(props: any) {
  const { packageData, updateSelectedRoomType } = props;
  const [descriptionCharLimit] = useState(150);
  const [showFullDescription, setShowFullDescription] = useState(false);

  return (
    <div className="details-content-left">
      <div className="content">
        {packageData && packageData.description && (
          <PackageDescription description={packageData?.description} />
        )}
        <Inclusion inclusions={packageData?.inclusions} />
        <Highlights highlights={packageData?.highlights} />
        {packageData.type === "packageType.Holiday" && (
          <>
            <Itinerary
              pdfData={packageData}
              pdfDataDetail={packageData}
              dayWiseItinerary={packageData?.itineraryItems}
            />
            {/* <TravelAccommodation rooms={packageData?.itinerary}
              mediaCollection={packageData?.mediaCollections} /> */}
          </>
        )}
        {packageData.type !== "packageType.Holiday" && (
          <>
            {/* {packageData?.itinerary && (
              <>
                <RoomTypes
                  // rooms={packageData?.itinerary[0]?.roomTypes}
                  rooms={packageData}
                  updateSelectedRoomType={updateSelectedRoomType}
                />
                <HotelFacilities
                  facilities={packageData?.itinerary[0]?.property.facilities}
                />
              </>
            )} */}
          </>
        )}

        {/* <h2 className="head">Terms and Conditions</h2>
        <span
          className="terms-and-conditions"
          dangerouslySetInnerHTML={{
            __html: packageData?.termsAndConditions,
          }}
        /> */}
      </div>
    </div>
  );
}

export default LeftContainer;
