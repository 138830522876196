import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { amenitiesIconMapping } from "src/constants/iconMapping";
import { experienceApiAxios } from "src/helpers/api_helper";
import { GET_PROPERTY_LISTING } from "src/helpers/url_helper";
import PropertyOverlay from "../propertyOverlay/PropertyOverlay";
import _ from "underscore";
import "./ItineraryStays.scss";
import RoomTypesContainer from "../details/RoomTypesContainer";
import "../details/Details.scss";
import itineraryPlanStore from "src/store/itineraryPlanStore";
import { Property } from "src/models/models";
import ImageGalleryPopup from "../details/ImageGalleryPopup";
import { DollarSymbol } from "src/icons/coreIcons";
import moment from "moment";

const ItineraryStays = observer(
  ({
    cityName,
    existingProperty,
    packageLocation,
    packageType,
    roomInfo,
    propertyConfigInfo,
    destinationIndex,
    beginDate,
  }: {
    cityName: string;
    packageType: any;
    existingProperty: any;
    packageLocation: string;
    roomInfo: any[];
    propertyConfigInfo: Property | undefined;
    destinationIndex?: number;
    beginDate: Date | undefined;
  }) => {
    const [propertyDetails, setPropertyDetails] =
      useState<any>(existingProperty);
    const [propertyOverlay, setPropertyOverlay] = useState(false);
    const [selectedviewmoreid, setselectedviewmoreid] = useState("");
    const [facilityLength, setfacilityLength] = useState(4);
    const [selectRoomTypes, setSelectRoomTypes] = useState<any[]>(roomInfo);
    const [propertyConfig, setPropertyConfig] = useState<Property>({
      id: -1,
      dayIndexFrom: propertyConfigInfo?.dayIndexFrom ?? "",
      dayIndexTo: propertyConfigInfo?.dayIndexTo ?? "",
      propertyId: propertyConfigInfo?.propertyId ?? "",
      propertyName: propertyConfigInfo?.propertyName ?? "",
      roomTypeId: propertyConfigInfo?.roomTypeId ?? "",
      roomTypeName: propertyConfigInfo?.roomTypeName ?? "",
      destination: propertyConfigInfo?.destination ?? "",
      cityId: propertyConfigInfo?.cityId ?? 0,
      numRooms: propertyConfigInfo?.numRooms ?? 0,
      fromDate: "",
      toDate: "",
      notes: "",
    });
    const [imageOverLayArray, setImageOverLayArray] = useState<any[]>([]);

    const fetchPropertyDetails = async () => {
      if (cityName) {
        experienceApiAxios
          .get(
            `${GET_PROPERTY_LISTING}?pageSize=1&currentPage=${1}&search=${
              propertyDetails?.name ?? cityName
            }`
          )
          .then((response) => {
            setPropertyDetails(response.data.propertyList[0]);
          });
      }
    };

    const getIcon = (text: string) => {
      const textVar = text?.toLowerCase();
      let bestMatch = "";
      let bestMatchIndex = 0;
      let matchingIcons = amenitiesIconMapping.filter((icon: any) =>
        textVar?.includes(icon.keyword)
      );
      for (let i = 0; i < matchingIcons.length; i++) {
        if (matchingIcons[i].keyword !== undefined) {
          if (matchingIcons[i].keyword.length > bestMatch.length) {
            bestMatch = matchingIcons[i].keyword;
            bestMatchIndex = i;
          }
        }
      }
      if (matchingIcons.length === 0)
        return require(`../../assets/images/icons/highlights/default.png`);
      return require(`../../assets/images/icons/amenities/${matchingIcons[bestMatchIndex].icon}`);
    };

    const [showCarousel, setShowCarousel] = useState(false);

    const updateShowImageGalleryPopup = (data: any) => {
      setShowCarousel(data);
    };

    const toggleCarousel = () => {
      setShowCarousel(!showCarousel);
    };

    const [startIndex, setStartIndex] = useState(0);

    // Function to handle moving to the previous set of images in the carousel
    const prevImages = () => {
      if (startIndex > 0) {
        setStartIndex(startIndex - 3);
      }
    };

    // Function to handle moving to the next set of images in the carousel
    const nextImages = (carouselImagesLength: any) => {
      if (startIndex + 3 < carouselImagesLength) {
        setStartIndex(startIndex + 3);
      }
    };

    const selectedRoomTypes = (id: any, name: any, propertyId: any) => {
      const findRoomType = itineraryPlanStore.stayRoomTypes.find(
        (room) => room.propertyId === propertyId
      );
      if (findRoomType) {
        const deleteRoomType = selectRoomTypes.filter(
          (room) => room.propertyId !== propertyId
        );
        const deleteRoomTypeFromStore = itineraryPlanStore.stayRoomTypes.filter(
          (room) => room.propertyId !== propertyId
        );
        deleteRoomType.push({
          propertyId: propertyId,
          roomId: id,
          roomName: name,
        });
        deleteRoomTypeFromStore.push({
          propertyId: propertyId,
          roomId: id,
          roomName: name,
        });
        setPropertyConfig({
          ...propertyConfig,
          roomTypeId: id,
          roomTypeName: name,
        });
        setSelectRoomTypes(deleteRoomType);
        itineraryPlanStore.setStayRoomTypes(deleteRoomTypeFromStore);
      } else {
        let tempRoom = selectRoomTypes;
        tempRoom = tempRoom.concat({
          propertyId: propertyId,
          roomId: id,
          roomName: name,
        });
        let tempRoomStore = itineraryPlanStore.stayRoomTypes;
        tempRoomStore = tempRoomStore.concat({
          propertyId: propertyId,
          roomId: id,
          roomName: name,
        });
        setPropertyConfig({
          ...propertyConfig,
          roomTypeId: id,
          roomTypeName: name,
        });
        setSelectRoomTypes(tempRoom);
        itineraryPlanStore.setStayRoomTypes(tempRoomStore);
      }
    };

    const getStayDates = () => {
      if (
        beginDate &&
        destinationIndex !== null &&
        destinationIndex !== undefined
      ) {
        let numberOfDays: number = 0;
        if (destinationIndex !== 0) {
          numberOfDays = itineraryPlanStore.visitDestinationList
            .slice(0, destinationIndex)
            .reduce((accumulator, currentObj) => {
              return accumulator + currentObj.stayDuration;
            }, 0);

          let startDate = new Date(beginDate);
          let endDate = new Date(beginDate);
          return ` (${moment(
            startDate.setDate(startDate.getDate() + numberOfDays)
          ).format("DD-MM-YYYY")} to ${moment(
            startDate.setDate(
              endDate.getDate() +
                numberOfDays +
                itineraryPlanStore.visitDestinationList[destinationIndex]
                  .stayDuration
            )
          ).format("DD-MM-YYYY")})`;
        } else {
          let startDate = new Date(beginDate);
          let endDate = new Date(beginDate);
          return ` (${moment(startDate.setDate(startDate.getDate())).format(
            "DD-MM-YYYY"
          )} to ${moment(
            startDate.setDate(
              endDate.getDate() +
                itineraryPlanStore.visitDestinationList[destinationIndex]
                  .stayDuration
            )
          ).format("DD-MM-YYYY")})`;
        }
      }

      return "";
    };

    const selectRoomNum = (room: any) => {
      if (room) {
        const tempPropertyConfig = {
          ...propertyConfig,
          numRooms: parseInt(room),
        };
        setPropertyConfig(tempPropertyConfig);
        const propertyexisting = itineraryPlanStore.propertyList.find(
          (prop: any) => prop.cityId === tempPropertyConfig.cityId
        );
        if (propertyexisting) {
          const tempProp = itineraryPlanStore.propertyList.filter(
            (prop: any) => prop.cityId !== tempPropertyConfig.cityId
          );
          tempProp.push(tempPropertyConfig);
          itineraryPlanStore.setPropertyList(tempProp);
        } else {
          let tempProperty: any[] = itineraryPlanStore.propertyList;
          tempProperty = tempProperty.concat(tempPropertyConfig);
          itineraryPlanStore.setPropertyList(tempProperty);
        }
      } else {
        return;
      }
    };

    useEffect(() => {
      const existingProp = itineraryPlanStore.selectedProperties.find(
        (property: any) =>
          property.city?.name.toLocaleLowerCase() ===
          cityName.toLocaleLowerCase()
      );
      if (existingProp) {
        setPropertyDetails(existingProp);
        const roomType = itineraryPlanStore.stayRoomTypes.find(
          (room: any) => room.propertyId === existingProp.id
        );
        if (roomType) {
          setSelectRoomTypes([roomType]);
        }
      } else {
        fetchPropertyDetails();
      }
    }, [cityName]);

    useEffect(() => {
      if (propertyDetails) {
        const propertyConfigObj = {
          id: propertyConfig?.id ?? Math.floor(Math.random() * 100) * -1,
          dayIndexFrom: propertyDetails.checkinTime,
          dayIndexTo: propertyDetails.checkoutTime,
          propertyId: propertyDetails.id,
          propertyName: propertyDetails.name,
          roomTypeId: propertyConfig.roomTypeId ?? "",
          roomTypeName: propertyConfig.roomTypeName ?? "",
          destination: cityName,
          cityId: propertyDetails.cityId,
          numRooms: propertyConfig?.numRooms ?? 0,
          fromDate: "",
          toDate: "",
          notes: "",
        };
        const existingProperty = itineraryPlanStore.selectedProperties.find(
          (property: any) => property.cityId === propertyDetails.cityId
        );
        if (existingProperty) {
          const tempProperty = itineraryPlanStore.selectedProperties.filter(
            (property: any) => property.cityId !== propertyDetails.cityId
          );
          tempProperty.push(propertyDetails);
          itineraryPlanStore.setSelectedProperties(tempProperty);
        } else {
          let tempProperty = itineraryPlanStore.selectedProperties;
          tempProperty = tempProperty.concat(propertyDetails);
          itineraryPlanStore.setSelectedProperties(tempProperty);
        }

        if (propertyDetails) {
          const imageArray: any[] = [];
          propertyDetails.mediaFolder.files.forEach((file: any) => {
            const tempImg = {
              imageUrl: `https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${propertyDetails.mediaFolder.url}/${file.fileName}`,
              imageCaption: file.fileName,
            };
            imageArray.push(tempImg);
          });

          const mediaOverLayArray = [
            {
              collectionType: "Additional",
              propertyId: null,
              roomTypeId: null,
              images: imageArray,
            },
          ];

          setImageOverLayArray(mediaOverLayArray);
        }

        setPropertyConfig(propertyConfigObj);
      }
    }, [propertyDetails]);

    const selectMealPlan = (mealPlan: string) => {
      if (mealPlan) {
        const tempPropertyConfig = {
          ...propertyConfig,
          mealPlan: mealPlan,
        };
        setPropertyConfig(tempPropertyConfig);
        const propertyexisting = itineraryPlanStore.propertyList.find(
          (prop: any) => prop.cityId === tempPropertyConfig.cityId
        );
        if (propertyexisting) {
          const tempProp = itineraryPlanStore.propertyList.filter(
            (prop: any) => prop.cityId !== tempPropertyConfig.cityId
          );
          tempProp.push(tempPropertyConfig);
          itineraryPlanStore.setPropertyList(tempProp);
        } else {
          let tempProperty: any[] = itineraryPlanStore.propertyList;
          tempProperty = tempProperty.concat(tempPropertyConfig);
          itineraryPlanStore.setPropertyList(tempProperty);
        }
      } else {
        return;
      }
    };

    const moreFacilitylist = (total: number, propertyid: any) => {
      setfacilityLength(total);
      setselectedviewmoreid(propertyid);
    };

    return (
      <div key={cityName}>
        {propertyDetails && (
          <div className="stays-section">
            <div className="property-stay-section">
              <div className="property-stay-img-div">
                {propertyDetails?.mediaFolder?.files.length > 0 && (
                  <div className="property-stay-thumbnail">
                    <img
                      className="property-stay-img"
                      src={`https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${propertyDetails.mediaFolder.url}/${propertyDetails.mediaFolder.files[0].fileName}`}
                    />
                    <button
                      className="carousel-toggle-button"
                      onClick={toggleCarousel}
                    >
                      View more...
                    </button>
                  </div>
                )}
                <div className="carousel-container">
                  {/* Left arrow */}
                  <button
                    className="carousel-arrow left"
                    disabled={startIndex === 0}
                    onClick={prevImages}
                  >
                    &lt;
                  </button>

                  <div className="carousel-images">
                    {propertyDetails?.mediaFolder?.files.length > 0 &&
                      propertyDetails?.mediaFolder?.files
                        .slice(startIndex, startIndex + 3)
                        .map((file: any, index: any) => {
                          return (
                            <img
                              key={index}
                              src={`https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${propertyDetails.mediaFolder.url}/${file.fileName}`}
                              alt={file.fileName}
                            />
                          );
                        })}
                  </div>

                  <button
                    className="carousel-arrow right"
                    disabled={
                      startIndex + 3 >=
                      propertyDetails?.mediaFolder?.files.length
                    }
                    onClick={() => {
                      nextImages(propertyDetails.mediaFolder?.files.length);
                    }}
                  >
                    &gt;
                  </button>
                </div>
              </div>
              <div className="property-stay-content-button">
                <div className="property-stay-div">
                  <div className="property-stay-header-div">
                    <div className="property-stay-header-txt test1">
                      {propertyDetails.name}
                    </div>
                    <div className="city-date-div">
                      {`${cityName} - ${
                        itineraryPlanStore.visitDestinationList[
                          destinationIndex!
                        ].stayDuration
                      }N ${
                        itineraryPlanStore.visitDestinationList[
                          destinationIndex!
                        ].stayDuration! + 1
                      }D`}{" "}
                      {getStayDates()}
                    </div>
                    <div className="property-stay-details-location-date">
                      <div className="property-stay-details-bubble">
                        <div className="location-background"></div>

                        <div className="stay-location-date-label">
                          location:
                        </div>
                        <div className="stay-location-date-div">
                          {propertyDetails.address}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ width: "583px" }}
                    className="property-stay-description test1"
                  >
                    {propertyDetails?.descriptionText?.length > 200
                      ? propertyDetails?.descriptionText?.substring(0, 325) +
                        "..."
                      : propertyDetails?.descriptionText + ""}
                  </div>
                </div>
                <div>
                  <button
                    className="property-stay-header-btn"
                    onClick={() => setPropertyOverlay(!propertyOverlay)}
                  >
                    Select
                  </button>
                </div>
              </div>
              {propertyOverlay && (
                <PropertyOverlay
                  onClosePropertyOverlay={setPropertyOverlay}
                  cityName={cityName !== "" ? cityName : packageLocation}
                  newPropertyDetails={setPropertyDetails}
                  propertyId={propertyDetails.id}
                  removeRoomtype={undefined}
                  opportunityStaysId={false}
                  loader={null}
                />
              )}
              {showCarousel && (
                <ImageGalleryPopup
                  packageData={imageOverLayArray}
                  showImageGalleryPopup={showCarousel}
                  updateShowImageGalleryPopup={updateShowImageGalleryPopup}
                />
              )}
            </div>
            <div className="property-facilities">
              <ul className="inline-block">
                {_.compact(propertyDetails.facilities).length > 0 &&
                  _.compact(propertyDetails.facilities)
                    ?.slice(0, facilityLength)
                    .map((item: any, index: number) => {
                      return (
                        <li
                          style={{ fontSize: "14px" }}
                          key={`package_hotel_facilities_${index}`}
                        >
                          <span style={{ width: "20px" }}>
                            <img
                              src={getIcon(item?.name)}
                              alt=""
                              className="img-fluid"
                            />
                          </span>
                          {item?.name}&nbsp;
                          <span>{item?.extractedJson?.toBePaid && "($)"}</span>
                        </li>
                      );
                    })}
                {_.compact(propertyDetails.facilities).length === 0 && (
                  <li key={`package_hotel_facilities_na`}>{"Not Listed"}</li>
                )}
                {propertyDetails?.mediaFolder?.files.length ===
                  facilityLength &&
                propertyDetails?.id === selectedviewmoreid &&
                propertyDetails?.facilities.length > 4 ? (
                  <li
                    style={{
                      fontSize: "14px",
                      cursor: "pointer",
                      color: "#2e8b98",
                    }}
                    onClick={() => {
                      moreFacilitylist(4, propertyDetails.id);
                    }}
                  >
                    view less
                  </li>
                ) : propertyDetails?.facilities.length > 4 ? (
                  <li
                    style={{
                      fontSize: "14px",
                      cursor: "pointer",
                      color: "#2e8b98",
                    }}
                    onClick={() => {
                      moreFacilitylist(
                        propertyDetails.mediaFolder?.files.length,
                        propertyDetails.id
                      );
                    }}
                  >
                    view more
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
            <div>
              {propertyDetails.roomTypes &&
                propertyDetails.roomTypes[0] &&
                propertyDetails.roomTypes.map((room: any, index: any) => {
                  let roomNum = 0;
                  let roomMealType = "";
                  const roomFlag = selectRoomTypes.some(
                    (rooms) => rooms.roomId === room.id
                  );

                  if (roomFlag) {
                    const propertyFind = itineraryPlanStore.propertyList.find(
                      (property) => property.roomTypeId === room.id
                    );

                    roomNum = propertyFind?.numRooms ?? 0;
                    roomMealType = propertyFind?.mealPlan ?? "";
                  }
                  const imgArr: any[] = [];
                  room.mediaFolder.files.forEach((file: any) => {
                    const tempImg = {
                      imageCaption: file.fileName,
                      imageUrl: `https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${propertyDetails.mediaFolder.url}/room/${room.mediaFolder.url}/${file.fileName}`,
                    };
                    imgArr.push(tempImg);
                  });
                  return (
                    <>
                      <div
                        className="room-info test1"
                        style={
                          propertyDetails.roomTypes.length - 1 === index
                            ? { marginBottom: "24px" }
                            : {}
                        }
                      >
                        <div>
                          <input
                            checked={roomFlag}
                            onChange={() => {
                              selectedRoomTypes(
                                room.id,
                                room.name,
                                propertyDetails.id
                              );
                            }}
                            type="radio"
                          />
                        </div>
                        <div style={{ width: "226px", display: "flex" }}>
                          <RoomTypesContainer imageArr={imgArr} />
                        </div>
                        <div className="room-details-div">
                          <div className="room-header">{room.name}</div>
                          <div className="room-details-text">
                            {room?.beds[0]?.count === undefined
                              ? ""
                              : room?.beds[0]?.count + "-"}
                            {room?.beds[0]?.description === undefined
                              ? ""
                              : room?.beds[0]?.description !== undefined
                              ? room?.beds[0]?.description?.split("bed")[0] +
                                "bed"
                              : ""}
                          </div>
                          <div className="facilities-room-div">
                            {room.amenities.slice(0, 9).map(
                              (
                                amenity: {
                                  name: string;
                                  amenityType: string;
                                  extractedJson: any;
                                },
                                index: number
                              ) => {
                                return (
                                  <>
                                    <span
                                      key={`package_room_amenities_${index}`}
                                      style={{
                                        width: "14px",
                                      }}
                                    >
                                      <img
                                        src={getIcon(amenity.amenityType)}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </span>
                                    <span>{amenity.name}&nbsp;</span>
                                    <span>
                                      {amenity?.extractedJson?.toBePaid &&
                                        "($)"}
                                    </span>
                                  </>
                                );
                              }
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "15px",
                          }}
                        >
                          <div className="room-count-div">
                            <div className="dropdown-label">Meals:</div>
                            <select
                              disabled={!roomFlag}
                              className="room-type-dropdown"
                              style={{ backgroundPosition: "195px" }}
                              value={roomMealType}
                              onChange={(e) => selectMealPlan(e.target.value)}
                            >
                              <option value={0}>Choose a meal plan</option>
                              {propertyDetails?.mealPlanList &&
                                propertyDetails?.mealPlanList?.length > 0 &&
                                propertyDetails?.mealPlanList
                                  .find(
                                    (mealPlan: { roomTypeId: any }) =>
                                      mealPlan.roomTypeId === room.id
                                  )
                                  .mealPlan?.map((mealPlanName: any) => {
                                    return (
                                      <option value={mealPlanName.code}>
                                        {mealPlanName.dexcription}
                                      </option>
                                    );
                                  })}
                            </select>
                          </div>
                          <div className="room-count-div">
                            <div className="dropdown-label">Rooms:</div>
                            <select
                              className="room-type-dropdown"
                              value={roomNum}
                              disabled={!roomFlag}
                              style={{
                                width: "100px",
                                backgroundPosition: "195px",
                              }}
                              onChange={(e) => {
                                selectRoomNum(e.target.value);
                              }}
                            >
                              <option value="">Select number of rooms</option>
                              <option value={1}>{"1 Room"}</option>
                              <option value={2}>{"2 Rooms"}</option>
                              <option value={3}>{"3 Rooms"}</option>
                              <option value={4}>{"4 Rooms"}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        )}
        {!propertyDetails && (
          <div className="stays-section">
            <div className="city-date-div" style={{ textAlign: "center" }}>
              {`There are no stays in ${cityName} for ${
                itineraryPlanStore.visitDestinationList[destinationIndex!]
                  .stayDuration
              }N ${
                itineraryPlanStore.visitDestinationList[destinationIndex!]
                  .stayDuration! + 1
              }D`}{" "}
              {getStayDates()}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default ItineraryStays;
