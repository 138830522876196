import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import { Bars } from "react-loader-spinner";
import ReactPixel from "react-facebook-pixel";
import { getItineraryListing } from "../../store/itinerary/actions";
import ItineraryCard from "./ItineraryCard";

const MyItineraries = () => {
  let dispatch = useDispatch();
  const [itineraryListData, setItineraryListData] = useState<any[]>([]);

  const { response, loader } = useSelector(
    (state: any) => state.ItineraryListingReducer
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactPixel.fbq("init", process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq("track", "PageView");
  }, []);

  useEffect(() => {
    dispatch(getItineraryListing());
  }, [dispatch]);

  useEffect(() => {
    if (response) {
      setItineraryListData(response.itineraryPlans);
    }
  }, [response]);

  return (
    <div>
      <div className="my-account-content">
        <div className="my-booking-blk">
          {itineraryListData &&
            itineraryListData !== undefined &&
            itineraryListData.length > 0 &&
            itineraryListData?.map((item: any, index: number) => {
              return <ItineraryCard item={item} key={index} />;
            })}
          {itineraryListData &&
            itineraryListData !== undefined &&
            itineraryListData.length === 0 && (
              <div className="info-txt">No record found</div>
            )}
        </div>
      </div>
      {loader && (
        <div className="loader-container">
          <div className="loader">
            <Bars color="#00BFFF" height={50} width={100} />
          </div>
        </div>
      )}
    </div>
  );
};

export default MyItineraries;
