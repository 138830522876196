import { observer } from "mobx-react-lite";

const DurationConfirmationComponent = observer(
  ({
    confirmationText,
    closeModal,
    proceedAction,
  }: {
    confirmationText: string;
    closeModal: any;
    proceedAction: any;
  }) => {
    return (
      <div className="confirmation-overlay">
        <div className="confirmation-popup">
          <div className="confirmation-cancel">
            <button
              className="confirmation-cancel-btn"
              onClick={() => closeModal(false)}
            >
              &#x2715;
            </button>
          </div>
          <div className="confirmation-content">
            <div className="confirmation-content-text">
              <p className="content-text-first">{confirmationText}</p>
            </div>
          </div>
          <div className="validation-btn-div">
            <button
              className="confirmation-okay-btn"
              style={{ color: "white" }}
              onClick={() => {
                proceedAction();
                closeModal(false);
              }}
            >
              Proceed
            </button>
            <button
              className="confirmation-okay-btn"
              style={{ color: "white" }}
              onClick={() => closeModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export default DurationConfirmationComponent;
