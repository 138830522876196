import { takeLatest, put, call } from "redux-saga/effects";
import { getItineraryList } from "src/helpers/backend_helper";
import { GET_ITINERARY_LIST } from "./actionTypes";

import { getItineraryListingSuccess, getItineraryListingFail } from "./actions";

function* onGetItineraryList() {
  try {
    const response = yield call(getItineraryList);
    yield put(getItineraryListingSuccess(response));
  } catch (error) {
    yield put(getItineraryListingFail(error.response));
  }
}

function* ItineraryListingSaga() {
  yield takeLatest(GET_ITINERARY_LIST, onGetItineraryList);
}

export default ItineraryListingSaga;
