import cuid from "cuid";
import moment from "moment";
import makeAnimated from "react-select/animated";
import { observer } from "mobx-react-lite";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./CustomItinerary.scss";
import {
  CityModel,
  Interests,
  ItineraryPlaceSlot,
  ItineraryRouteModel,
  ItineraryTimeSlot,
  PlaceToVisit,
  PlaceToVisitActivity,
  Property,
  QuoteDaywiseItineraryModel,
  QuoteItineraryPlanModel,
  QuoteObject,
  QuoteVisitDestinationModel,
  VisitDestinationModel,
  initVisitDestinationModel,
} from "src/models/models";
import itineraryPlanStore from "src/store/itineraryPlanStore";
import { experienceApiAxios } from "src/helpers/api_helper";
import {
  CUSTOMER,
  DESTINATIONS,
  ITINERARY,
  OPPORTUNITIES,
} from "src/helpers/url_helper";
import {
  AddDestinationPlus,
  CustomItineraryShare,
  CustomerItinerarySave,
  EditIcon,
  OptimizeStars,
  RemoveDestinationMinus,
  SaveIcon,
  StepperIcon,
} from "src/icons/coreIcons";
import {
  TransportMode,
  getTimeInHourFormat,
  getTimeInMeridianFormat,
  haversineDistance,
  runGA,
} from "src/helpers/utils";
import ItineraryStays from "./ItineraryStays";
import ItineraryPlacesToVisit from "./ItineraryPlacesToVisit";
import { Chip, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ConfirmationComponent from "./ConfirmationComponent";
import Select from "react-select";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../helpers/firebase";
import ValidationComponent from "../validationComponent/ValidationComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import usePrompt from "src/hooks/usePrompt";
import SaveShareValidationComponent from "../validationComponent/SaveShareValidationComponent";
import { getHeader } from "src/helpers/backend_helper";
import ClearConfirmationComponent from "../validationComponent/ClearConfirmationComponent";
import AccessConfirmationComponent from "../validationComponent/AccessConfirmationComponent";
import ItineraryStaysEdit from "./ItineraryStaysEdit";
import { toJS } from "mobx";
import DurationConfirmationComponent from "../validationComponent/DurationConfirmationComponent";

const CustomItineraryEdit = observer(() => {
  const params = useParams();
  const navigate = useNavigate();
  const packageIdentifer = params.id;
  const [user] = useAuthState(auth);
  const [adultCount, setAdultCount] = useState(2);
  const [childCount, setChildCount] = useState(0);
  const [dayCounter, setDayCounter] = useState(0);
  const [newDuration, setNewDuration] = useState(0);
  const [destinationDuration, setDestinationDuration] =
    useState<VisitDestinationModel | null>(null);
  const [differenceInDays, setDifferenceInDays] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [customItineraryEndDate, setCustomItineraryEndDate] = useState<any>();
  const [customItineraryStartDate, setCustomItineraryStartDate] =
    useState<any>();
  const [cityList, setCityList] = useState<any[]>([]);
  const [destinationId, setDestinationId] = useState(0);
  const [cityValue, setCityValue] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [activeCityList, setActiveCityList] = useState<any[]>([]);
  const [voyaahCities, setVoyaahCities] = useState<CityModel[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [suggestedDepatureCities, setSuggestedDepartureCities] = useState<
    CityModel[]
  >([]);
  const [suggestedArrivalCities, setSuggestedArrivalCities] = useState<
    CityModel[]
  >([]);
  const [isOptimized, setIsOptimized] = useState(true);
  const [openValidationComponent, setOpenValidationComponent] = useState(false);
  const [openClearValidationComponent, setOpenClearValidationComponent] =
    useState(false);
  const [openDurationValidationComponent, setOpenDurationValidationComponent] =
    useState(false);
  const [openAccessDeniedComponent, setOpenAccessDeniedComponent] =
    useState(false);
  const [openSaveValidationComponent, setOpenSaveValidationComponent] =
    useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [durationMessage, setDurationMessage] = useState("");
  const [saveValidationMessage, setSaveValidationMessage] = useState("");
  const [errors, setErrors] = useState<{
    cities: boolean;
    from: boolean;
    to: boolean;
    dateMismatch: boolean;
  }>({
    cities: false,
    from: false,
    to: false,
    dateMismatch: false,
  });

  const generateUniqueNegativeId = () => {
    let id = -1;
    do {
      id = Math.floor(Math.random() * 100) * -1;
    } while (
      itineraryPlanStore.visitDestinationList.some(
        (destination) => destination.id === id
      )
    );

    return id;
  };

  const customStyles = {
    multiValue: (styles: any, { data }: any) => {
      return {
        ...styles,
        backgroundColor: "#badade",
        borderRadius: "15px",
        paddingRight: "7px",
        paddingLeft: "7px",
      };
    },
    multiValueLabel: (styles: any, { data }: any) => {
      return {
        ...styles,
        color: "#000",
      };
    },
  };

  usePrompt("Would you like to leave the page?", true);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Custom Itinerary | Voyaah";
    ReactPixel.fbq("init", process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq("track", "PageView");

    clearDataAfterConfirmation();

    let activeVoyaahCityList: any[] = [];

    const fetchItineraryData = async () => {
      const headerResponse = await getHeader();
      const itineraryData = await experienceApiAxios.get(
        `${ITINERARY}/${packageIdentifer}`,
        {
          headers: headerResponse,
        }
      );

      if (itineraryData.status === 200) {
        const activeCities = await experienceApiAxios.get(
          `${DESTINATIONS}?isActive=true`,
          {
            headers: headerResponse,
          }
        );
        if (activeCities.status === 200) {
          const activeCityArray: {
            label: string;
            value: string;
          }[] = [];
          activeVoyaahCityList = activeCities.data;
          setVoyaahCities(activeCities.data);
          activeCities.data.forEach((city: any) => {
            const activeCityObj = {
              label: city.name,
              value: city.name,
              city: city,
            };

            activeCityArray.push(activeCityObj);
          });

          setActiveCityList(activeCityArray);
        }

        clearDataAfterConfirmation();
        const itineraryObj = itineraryData.data.itineraryPlans[0];

        setCustomItineraryStartDate(
          new Date(itineraryObj.extractedJson?.fromDateObject)
        );

        setCustomItineraryEndDate(
          new Date(itineraryObj.extractedJson?.toDateObject)
        );

        itineraryPlanStore.setEditPlanParentIdentifiers(
          itineraryObj.id,
          itineraryObj.parentId
        );

        itineraryPlanStore.updateSelectedItineraryPlan({
          ...itineraryPlanStore.selectedItineraryPlan,
          name: itineraryObj.name,
          id: itineraryObj.id,
          arrivalAirportName: itineraryObj.arrivalAirport?.name ?? "",
          departureAirportName: itineraryObj.departureAirport?.name ?? "",
          arrivalAirportId: itineraryObj.arrivalAirport?.id ?? 0,
          departureAirportId: itineraryObj.departureAirport?.id ?? 0,
        });

        if (itineraryObj.arrivalAirport && itineraryObj.departureAirport) {
          const selectedOptionsArray = [
            {
              value: itineraryObj.departureAirport.name,
              label: itineraryObj.departureAirport.name,
            },
            {
              value: itineraryObj.arrivalAirport.name,
              label: itineraryObj.arrivalAirport.name,
            },
          ];

          setSelectedOptions(selectedOptionsArray);
        }

        updateItineraryDestinationFromVoyaahList(
          "departure",
          activeVoyaahCityList
        );
        updateItineraryDestinationFromVoyaahList(
          "arrival",
          activeVoyaahCityList
        );

        const visitDestinations: any[] = itineraryObj.visitDestinations;

        itineraryPlanStore.updateDestinationList(
          itineraryPlanStore.visitDestinationList[0].id,
          {
            ...itineraryPlanStore.visitDestinationList[0],
            stayDuration: visitDestinations[0].stayDuration,
          }
        );

        itineraryPlanStore.updateDestinationList(
          itineraryPlanStore.visitDestinationList[
            itineraryPlanStore.visitDestinationList.length - 1
          ].id,
          {
            ...itineraryPlanStore.visitDestinationList[
              itineraryPlanStore.visitDestinationList.length - 1
            ],
            stayDuration:
              visitDestinations[visitDestinations.length - 1].stayDuration,
          }
        );

        const oppStaysArr: any[] = itineraryObj.opportunitystays;
        const propertyListArr: Property[] = [];
        const stayRoomTypesArr: any[] = [];
        const existingPropertyList: { cityName: string; property: any }[] = [];
        const selectedProperties: any[] = [];

        oppStaysArr.forEach((oppStay) => {
          const propertyObj: Property = {
            id: oppStay.id,
            dayIndexFrom: oppStay.dayIndexFrom,
            dayIndexTo: oppStay.dayINdexTo,
            propertyId: oppStay.propertyId,
            propertyName: oppStay.propertyName,
            roomTypeId: oppStay.roomTypeId,
            roomTypeName: oppStay.roomTypeId,
            mealPlan: oppStay.mealPlan,
            destination: oppStay.destination,
            cityId: oppStay.cityId,
            numRooms: oppStay.numRooms,
          };

          const stayObj = {
            cityName: oppStay.destination,
            propertyId: oppStay.propertyId,
            roomId: oppStay.roomTypeId,
            roomName: oppStay.roomTypeId,
          };

          const existingPropertyObj: { cityName: string; property: any } = {
            cityName: oppStay.destination,
            property: oppStay.property,
          };

          propertyListArr.push(propertyObj);
          stayRoomTypesArr.push(stayObj);
          existingPropertyList.push(existingPropertyObj);
          selectedProperties.push(oppStay.property);
        });

        itineraryPlanStore.setPropertyList(propertyListArr);
        itineraryPlanStore.setStayRoomTypes(stayRoomTypesArr);
        itineraryPlanStore.setExistingProperties(existingPropertyList);
        itineraryPlanStore.setSelectedProperties(selectedProperties);

        visitDestinations.forEach((visitDestination, index) => {
          if (index !== 0 && index !== visitDestinations.length - 1) {
            itineraryPlanStore.addNewDestination(
              initVisitDestinationModel({
                itineraryPlanId: itineraryPlanStore.selectedItineraryPlan.id,
                index: itineraryPlanStore.visitDestinationList.length + 1,
                id: generateUniqueNegativeId(),
                stayDuration: visitDestination.stayDuration,
                city: visitDestination.city,
                cityId: visitDestination.city.id,
                cityName: visitDestination.city.name,
              })
            );
          }
        });

        updateSelectedCities();
        suggestPlacesForDestinationCity();
        suggestPlacesForArrivalCity();

        visitDestinations.forEach((destination, index) => {
          if (index > 1) {
            updateTransportMode(
              index - 1,
              index,
              destination.arrivalRoutes[0]?.transportMode ??
                destination.destinationRoutes[0]?.transportMode
            );
          }
        });

        const daywiseItineraryArr: QuoteDaywiseItineraryModel[] =
          itineraryObj.daywiseItinerary;

        daywiseItineraryArr.forEach((daywiseItinerary) => {
          const cityList: {
            cityId: number;
            cityName: string;
            city?: CityModel;
          }[] = [];
          const activities: PlaceToVisitActivity[] = [];
          daywiseItinerary.itineraryTimeSlots?.forEach((itineraryTimeSlot) => {
            const interestsArr: Interests[] = [];
            let cityOfPlace: CityModel = {
              id: 0,
              name: "",
              cityCode: "",
              countryCode: "",
            };
            itineraryTimeSlot.itineraryPlaceSlot.forEach((slot: any, index) => {
              const interestObj: Interests = {
                imageUrl: "",
                placeId: slot.placeOfInterestId,
                label: slot.placeOfInterest.name,
                value: slot.placeOfInterest.name,
                external: true,
                latitude: slot.placeOfInterest.latitude,
                longitude: slot.placeOfInterest.longitude,
              };

              interestsArr.push(interestObj);

              if (index === 0) {
                cityOfPlace = slot.placeOfInterest.city;
              }
            });

            const activityObj: PlaceToVisitActivity = {
              interests: interestsArr,
              placeName: cityOfPlace?.name ?? "",
              from: getTimeInMeridianFormat(itineraryTimeSlot.startTime),
              to: getTimeInMeridianFormat(itineraryTimeSlot.endTime),
            };

            activities.push(activityObj);
            cityList.push({
              cityId: cityOfPlace.id,
              cityName: cityOfPlace.name,
              city: cityOfPlace,
            });
          });

          const seen = new Set();

          const newPlaceToVisit: PlaceToVisit = {
            id: cuid(),
            name: daywiseItinerary.title,
            dayIndex: daywiseItinerary?.dayIndex ?? 1,
            positionIndex: getPositionIndex(),
            cityList: cityList.filter((city) => {
              const duplicate = seen.has(city.cityName);
              seen.add(city.cityName);
              return !duplicate;
            }),
            activities: activities,
          };

          itineraryPlanStore.addNewPlaceToVisit(newPlaceToVisit);
        });
      }
    };

    if (packageIdentifer) {
      fetchItineraryData();
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      const message =
        "You will lose changes if you navigate to another screen, do you want to proceed?";
      event.returnValue = message;
      return message;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!user) {
      setOpenValidationComponent(true);
      setValidationMessage("You should be logged in to access this feature");
    } else {
      setOpenValidationComponent(false);

      const fetchItineraryInformation = async () => {
        const headerResponse = await getHeader();
        const itineraryData = await experienceApiAxios.get(
          `${ITINERARY}/${packageIdentifer}`,
          {
            headers: headerResponse,
          }
        );

        if (itineraryData.status === 200) {
          const itineraryObj = itineraryData.data.itineraryPlans[0];
          if (itineraryObj?.extractedJson?.customerEmail !== user?.email) {
            setOpenAccessDeniedComponent(true);
          }
        }
      };
      fetchItineraryInformation();
    }
  }, [user]);

  const checkIfPlanOptimized = () => {
    let cityList: CityModel[] = [];

    for (let destination of itineraryPlanStore.visitDestinationList) {
      cityList.push({ ...destination?.city!, id: destination.id });
    }

    let optimizedRoute: any[] = [];
    const GAResponse = runGA(cityList, 250, 250, 0, cityList?.length - 1);

    if (GAResponse?.error) {
      return;
    }
    let bestPath = GAResponse?.population;

    for (let i = 0; i < bestPath.cityList.length; i++) {
      let filteredData = itineraryPlanStore.visitDestinationList?.find(
        (x: any) => x.id === bestPath.cityList[i].id
      );

      if (filteredData) {
        optimizedRoute.push({ ...filteredData, index: i + 1 });
      }
    }

    if (
      itineraryPlanStore.visitDestinationList.every(
        (destination, index) =>
          destination.cityName === optimizedRoute[index].cityName
      )
    ) {
      setIsOptimized(true);
      return true;
    } else {
      setIsOptimized(false);
      return false;
    }
  };

  const clearDataAfterConfirmation = () => {
    setShowConfirmation(false);
    setAdultCount(2);
    setChildCount(0);
    setCityList([]);
    setCityValue("");
    setDestinationId(0);
    setCustomItineraryEndDate(null);
    setCustomItineraryStartDate(null);
    setSelectedOptions([]);
    setSuggestedDepartureCities([]);
    setSuggestedArrivalCities([]);
    itineraryPlanStore.setStateToInitialForm();
  };

  const handleSaveItinerary = async (type: string) => {
    if (
      !customItineraryStartDate &&
      !customItineraryStartDate &&
      itineraryPlanStore.visitDestinationList.length === 0
    ) {
      setErrors({ ...errors, cities: true, from: true, to: true });
      return;
    }
    if (itineraryPlanStore.visitDestinationList.length === 0) {
      setErrors({ ...errors, cities: true });
      return;
    }
    if (!customItineraryStartDate) {
      setErrors({ ...errors, from: true });
      return;
    } else if (!customItineraryEndDate) {
      setErrors({ ...errors, to: true });
      return;
    }

    const cuidGenerated = packageIdentifer ?? cuid();

    const itineraryPlanObj: QuoteItineraryPlanModel = {
      id: itineraryPlanStore.selectedItineraryPlan.id,
      name: itineraryPlanStore.selectedItineraryPlan.name,
      planReference: packageIdentifer,
      parentType: "itineraryType.Customer",
      parentId: itineraryPlanStore.editParentId,
      baseData: {
        customerName: user?.displayName! ?? user?.email!,
        customerEmail: user?.email!,
        numAdults: adultCount,
        numChildren: childCount,
        fromDate: moment(customItineraryStartDate).format("YYYY-MM-DD"),
        toDate: moment(customItineraryEndDate).format("YYYY-MM-DD"),
        fromDateObject: customItineraryStartDate,
        toDateObject: customItineraryEndDate,
      },
      arrivalAirportId:
        itineraryPlanStore.selectedItineraryPlan.arrivalAirportId!,
      departureAirportId:
        itineraryPlanStore.selectedItineraryPlan.departureAirportId!,
    };

    const visitDestinations: QuoteVisitDestinationModel[] = [];
    const itineraryRoutes: ItineraryRouteModel[] = [];
    const daywiseItinerary: QuoteDaywiseItineraryModel[] = [];

    itineraryPlanStore.visitDestinationList.forEach(
      (visitDestination, index) => {
        const visitDestinationObj: QuoteVisitDestinationModel = {
          id: visitDestination.id,
          index: visitDestination.index,
          cityId: visitDestination.cityId,
          cityName: visitDestination.cityName,
          stayDuration: visitDestination.stayDuration,
          arrivalRoutes: [],
          destinationRoutes: [],
        };

        if (index > 0) {
          const itineraryRouteObj: ItineraryRouteModel = {
            id: Math.floor(Math.random() * 100) * -1,
            distance: parseFloat(getDistanceCalculation(index - 1, index)),
            transportMode: getModeOfTransport(index - 1, index),
            fromDestinationId:
              itineraryPlanStore.visitDestinationList[index - 1].id,
            toDestinationId: itineraryPlanStore.visitDestinationList[index].id,
          };

          itineraryRoutes.push(itineraryRouteObj);
        }

        visitDestinations.push(visitDestinationObj);
      }
    );

    itineraryPlanObj.visitDestinations = visitDestinations;
    itineraryPlanObj.routePlan = itineraryRoutes;

    itineraryPlanStore.placesToVisit.forEach((place, index) => {
      const daywiseItineraryId: number = Math.floor(Math.random() * 100) * -1;
      const itineraryTimeSlots: ItineraryTimeSlot[] = [];

      let imageUrl = "";

      place.activities.forEach((activity) => {
        const itineraryTimeSlotId = Math.floor(Math.random() * 100) * -1;
        const itineraryPlaceSlot: ItineraryPlaceSlot[] = [];

        let interestString = "";

        activity.interests?.forEach((interest) => {
          if (interest.external) {
            if (
              interest.imageUrl &&
              interest.imageUrl.length > 0 &&
              imageUrl.length === 0
            ) {
              imageUrl = interest.imageUrl;
            }
            interestString = interestString + `${interest.label}, `;
            const itineraryPlaceSlotObj = {
              id: Math.floor(Math.random() * 100) * -1,
              placeOfInterestId: interest.placeId,
              itineraryTimeSlotId: itineraryTimeSlotId,
            };

            itineraryPlaceSlot.push(itineraryPlaceSlotObj);
          }
        });

        let descriptionString = `${activity.placeName} - ${interestString}`;

        const itineraryTimeSlot: ItineraryTimeSlot = {
          id: itineraryTimeSlotId,
          description: descriptionString,
          startTime: getTimeInHourFormat(activity.from),
          endTime: getTimeInHourFormat(activity.to),
          daywiseItineraryId: daywiseItineraryId,
          itineraryPlaceSlot: itineraryPlaceSlot,
        };

        itineraryTimeSlots.push(itineraryTimeSlot);
      });

      const daywiseItineraryObj: QuoteDaywiseItineraryModel = {
        id: daywiseItineraryId,
        dayIndex: place.dayIndex ?? index + 1,
        title: place.name,
        description: "",
        imagePath: imageUrl,
        itineraryTimeSlots: itineraryTimeSlots,
      };

      daywiseItinerary.push(daywiseItineraryObj);
    });

    itineraryPlanObj.daywiseItinerary = daywiseItinerary;

    const filteredCityDurations =
      itineraryPlanStore.visitDestinationList.filter(
        (city) => city.stayDuration > 0
      );

    const opportunitystays: Property[] = [];

    if (customItineraryStartDate) {
      let currentDateForQuote = new Date(customItineraryStartDate);

      itineraryPlanStore.propertyList.forEach((property) => {
        const cityDuration = filteredCityDurations.find(
          (city) => city.cityName === property.destination
        );

        if (cityDuration) {
          const calculatedFromDate =
            moment(currentDateForQuote).format("YYYY-MM-DD");
          currentDateForQuote.setDate(
            currentDateForQuote.getDate() + cityDuration.stayDuration
          );
          const opportunityStaysObj: Property = {
            ...property,
            fromDate: calculatedFromDate,
            toDate: moment(currentDateForQuote).format("YYYY-MM-DD"),
          };

          opportunitystays.push(opportunityStaysObj);
        }
      });
    }

    itineraryPlanObj.opportunitystays = opportunitystays;

    const headerResponse = await getHeader();
    const response = await experienceApiAxios.post(
      `${ITINERARY}`,
      itineraryPlanObj,
      {
        headers: headerResponse,
      }
    );

    if (response.status === 200) {
      if (type === "save") {
        navigate(`/custom-itinerary/${packageIdentifer}/edit`, {
          state: packageIdentifer,
        });
        setSaveValidationMessage(
          "Your itinerary has been saved, you can view it in your profile and continue editing."
        );
      } else if (type === "share") {
        await navigator.clipboard.writeText(
          `${window.location.href}`.replace("/edit", "")
        );
        setSaveValidationMessage(
          "Your itinerary has been saved and is ready to share, the link to it has been copied to your clipboard"
        );
      }

      setOpenSaveValidationComponent(true);
    }
  };

  const handleGetMeAQuote = async () => {
    if (
      !customItineraryStartDate &&
      !customItineraryStartDate &&
      itineraryPlanStore.visitDestinationList.length === 0
    ) {
      setErrors({ ...errors, cities: true, from: true, to: true });
      return;
    }
    if (itineraryPlanStore.visitDestinationList.length === 0) {
      setErrors({ ...errors, cities: true });
      return;
    }
    if (!customItineraryStartDate) {
      setErrors({ ...errors, from: true });
      return;
    } else if (!customItineraryEndDate) {
      setErrors({ ...errors, to: true });
      return;
    }

    const quoteObject: QuoteObject = {
      numRooms: 1,
      brief: "<p><br></p>",
      numAdults: adultCount,
      numChildren: childCount,
      customerName: user?.displayName! ?? user?.email!,
      customerPhoneNo: user?.phoneNumber! ?? 0,
      source: "opportunitySource.Website",
      statusCode: "opportunityStatus.Hot",
      customerEmail: user?.email!,
    };

    if (customItineraryStartDate != null && customItineraryEndDate != null) {
      const timeDifference =
        new Date(customItineraryEndDate).getTime() -
        new Date(customItineraryStartDate).getTime();
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24)) + 1;

      quoteObject.fromDate = moment(customItineraryStartDate).format(
        "YYYY-MM-DD"
      );
      quoteObject.toDate = moment(customItineraryEndDate).format("YYYY-MM-DD");
      quoteObject.numDays = daysDifference;
      quoteObject.numNights = daysDifference - 1;
    } else {
      return;
    }

    if (itineraryPlanStore.visitDestinationList.length >= 2) {
      quoteObject.cityId =
        itineraryPlanStore.selectedItineraryPlan.departureAirportId;
      quoteObject.destination =
        itineraryPlanStore.selectedItineraryPlan.departureAirportName;

      const departureCountryCode =
        itineraryPlanStore.visitDestinationList[0].city?.countryCode;
      const arrivalCountryCode =
        itineraryPlanStore.visitDestinationList[
          itineraryPlanStore.visitDestinationList.length - 1
        ].city?.countryCode;

      if (
        departureCountryCode === arrivalCountryCode &&
        arrivalCountryCode === "IN"
      ) {
        quoteObject.type = "opportunityType.Domestic";
      } else {
        quoteObject.type = "opportunityType.International";
      }

      const itineraryPlanObj: QuoteItineraryPlanModel = {
        id: itineraryPlanStore.selectedItineraryPlan.id,
        name: itineraryPlanStore.selectedItineraryPlan.name,
        arrivalAirportId:
          itineraryPlanStore.selectedItineraryPlan.arrivalAirportId!,
        departureAirportId:
          itineraryPlanStore.selectedItineraryPlan.departureAirportId!,
      };

      const visitDestinations: QuoteVisitDestinationModel[] = [];
      const itineraryRoutes: ItineraryRouteModel[] = [];
      const daywiseItinerary: QuoteDaywiseItineraryModel[] = [];

      itineraryPlanStore.visitDestinationList.forEach(
        (visitDestination, index) => {
          const visitDestinationObj: QuoteVisitDestinationModel = {
            id: visitDestination.id,
            index: visitDestination.index,
            cityId: visitDestination.cityId,
            cityName: visitDestination.cityName,
            stayDuration: visitDestination.stayDuration,
            arrivalRoutes: [],
            destinationRoutes: [],
          };

          if (index > 0) {
            const itineraryRouteObj: ItineraryRouteModel = {
              id: Math.floor(Math.random() * 100) * -1,
              distance: parseFloat(getDistanceCalculation(index - 1, index)),
              transportMode: getModeOfTransport(index - 1, index),
              fromDestinationId:
                itineraryPlanStore.visitDestinationList[index - 1].id,
              toDestinationId:
                itineraryPlanStore.visitDestinationList[index].id,
            };

            itineraryRoutes.push(itineraryRouteObj);
          }

          visitDestinations.push(visitDestinationObj);
        }
      );

      itineraryPlanObj.visitDestinations = visitDestinations;
      itineraryPlanObj.routePlan = itineraryRoutes;

      itineraryPlanStore.placesToVisit.forEach((place, index) => {
        const daywiseItineraryId: number = Math.floor(Math.random() * 100) * -1;
        const itineraryTimeSlots: ItineraryTimeSlot[] = [];

        let imageUrl = "";

        place.activities.forEach((activity) => {
          const itineraryTimeSlotId = Math.floor(Math.random() * 100) * -1;
          const itineraryPlaceSlot: ItineraryPlaceSlot[] = [];

          let interestString = "";

          activity.interests?.forEach((interest) => {
            if (interest.external) {
              if (
                interest.imageUrl &&
                interest.imageUrl.length > 0 &&
                imageUrl.length === 0
              ) {
                imageUrl = interest.imageUrl;
              }
              interestString = interestString + `${interest.label}, `;
              const itineraryPlaceSlotObj = {
                id: Math.floor(Math.random() * 100) * -1,
                placeOfInterestId: interest.placeId,
                itineraryTimeSlotId: itineraryTimeSlotId,
              };

              itineraryPlaceSlot.push(itineraryPlaceSlotObj);
            }
          });

          let descriptionString = `${activity.placeName} - ${interestString}`;

          const itineraryTimeSlot: ItineraryTimeSlot = {
            id: itineraryTimeSlotId,
            description: descriptionString,
            startTime: getTimeInHourFormat(activity.from),
            endTime: getTimeInHourFormat(activity.to),
            daywiseItineraryId: daywiseItineraryId,
            itineraryPlaceSlot: itineraryPlaceSlot,
          };

          itineraryTimeSlots.push(itineraryTimeSlot);
        });

        const daywiseItineraryObj: QuoteDaywiseItineraryModel = {
          id: daywiseItineraryId,
          dayIndex: index + 1,
          title: `Day ${index + 1} - ${place.name}`,
          description: "",
          imagePath: imageUrl,
          itineraryTimeSlots: itineraryTimeSlots,
        };

        daywiseItinerary.push(daywiseItineraryObj);
      });

      itineraryPlanObj.daywiseItinerary = daywiseItinerary;

      const filteredCityDurations =
        itineraryPlanStore.visitDestinationList.filter(
          (city) => city.stayDuration > 0
        );

      const opportunitystays: Property[] = [];

      if (customItineraryStartDate) {
        let currentDateForQuote = new Date(customItineraryStartDate);

        itineraryPlanStore.propertyList.forEach((property) => {
          const cityDuration = filteredCityDurations.find(
            (city) => city.cityName === property.destination
          );

          if (cityDuration) {
            const calculatedFromDate =
              moment(currentDateForQuote).format("YYYY-MM-DD");
            currentDateForQuote.setDate(
              currentDateForQuote.getDate() + cityDuration.stayDuration
            );
            const opportunityStaysObj: Property = {
              ...property,
              fromDate: calculatedFromDate,
              toDate: moment(currentDateForQuote).format("YYYY-MM-DD"),
            };

            opportunitystays.push(opportunityStaysObj);
          }
        });
      }

      itineraryPlanObj.opportunitystays = opportunitystays;

      quoteObject.opportunityTraveller = [];
      quoteObject.itineraryPlans = [{ ...itineraryPlanObj }];

      const headerResponse = await getHeader();
      const response = await experienceApiAxios.post(
        `${OPPORTUNITIES}`,
        quoteObject,
        {
          headers: headerResponse,
        }
      );

      if (response.status === 200) {
        setShowConfirmation(true);
      }
    } else {
      return;
    }
  };

  const returnOptimizedRoute = () => {
    let cityList: CityModel[] = [];

    for (let destination of itineraryPlanStore.visitDestinationList) {
      cityList.push({ ...destination?.city!, id: destination.id });
    }

    let optimizedRoute: any[] = [];
    const GAResponse = runGA(cityList, 250, 250, 0, cityList?.length - 1);

    if (GAResponse?.error) {
      return;
    }
    let bestPath = GAResponse?.population;

    for (let i = 0; i < bestPath.cityList.length; i++) {
      let filteredData = itineraryPlanStore.visitDestinationList?.find(
        (x: any) => x.id === bestPath.cityList[i].id
      );

      if (filteredData) {
        optimizedRoute.push({ ...filteredData, index: i + 1 });
      }
    }

    itineraryPlanStore.setVisitDestinations(optimizedRoute);
    updateSelectedCities();
    setIsOptimized(true);
  };

  const suggestPlaces = async () => {
    suggestedDepatureCities
      .filter(
        (city) =>
          !itineraryPlanStore.visitDestinationList.some(
            (destination) => destination.cityName === city.name
          )
      )
      .forEach((suggestedCity, index) => {
        if (index < 2) {
          itineraryPlanStore.addNewDestination(
            initVisitDestinationModel({
              itineraryPlanId: itineraryPlanStore.selectedItineraryPlan.id,
              index: itineraryPlanStore.visitDestinationList.length + 1,
              id: generateUniqueNegativeId(),
              cityId: suggestedCity?.id,
              city: suggestedCity,
              cityName: suggestedCity?.name,
              stayDuration: 2,
            })
          );
        }
      });

    suggestedArrivalCities
      .filter(
        (city) =>
          !itineraryPlanStore.visitDestinationList.some(
            (destination) => destination.cityName === city.name
          )
      )
      .forEach((suggestedCity, index) => {
        if (index < 2) {
          itineraryPlanStore.addNewDestination(
            initVisitDestinationModel({
              itineraryPlanId: itineraryPlanStore.selectedItineraryPlan.id,
              index: itineraryPlanStore.visitDestinationList.length + 1,
              id: generateUniqueNegativeId(),
              cityId: suggestedCity?.id,
              city: suggestedCity,
              cityName: suggestedCity?.name,
              stayDuration: 2,
            })
          );
        }
      });

    if (!checkIfPlanOptimized()) {
      returnOptimizedRoute();
    }

    updateSelectedCities();
  };

  const checkDurationChange = () => {
    itineraryPlanStore.updateDestinationList(destinationDuration?.id!, {
      ...destinationDuration!,
      stayDuration: newDuration!,
    });

    const excludeDays: number[] = [];
    for (
      let indexPos = dayCounter;
      indexPos <= dayCounter + differenceInDays - 1;
      indexPos++
    ) {
      excludeDays.push(indexPos);
    }

    let tempPlacesToVisit: PlaceToVisit[] = [];

    tempPlacesToVisit = itineraryPlanStore.placesToVisit.filter(
      (place) => !excludeDays.includes(place.dayIndex)
    );

    tempPlacesToVisit.forEach((tempObj) => {
      if (tempObj.dayIndex > dayCounter + differenceInDays - 1) {
        tempObj.dayIndex = tempObj.dayIndex - differenceInDays;
      }
    });

    itineraryPlanStore.setPlacesToVisit(tempPlacesToVisit);
  };

  const suggestPlacesForDestinationCity = async () => {
    const departureCountryCode =
      itineraryPlanStore.visitDestinationList[0].city?.countryCode;

    const headerResponse = await getHeader();

    if (user) {
      const userId = user.uid;
      let customerTracker = localStorage.getItem("voyaahCustomerTracker");
      let customerProfileId = "";
      if (customerTracker) {
        customerProfileId = JSON.parse(customerTracker!).id;
      }

      const response: any = await experienceApiAxios.get(
        `${CUSTOMER}/places?countryCode=${departureCountryCode}&accountId=${userId}&customerProfileId=${customerProfileId}`,
        {
          headers: headerResponse,
        }
      );

      if (response.status === 200) {
        const cityResponse: CityModel[] = response.data;
        setSuggestedDepartureCities(cityResponse);
      }
    } else {
      const response: any = await experienceApiAxios.get(
        `${DESTINATIONS}/cities/${departureCountryCode}?fetchCount=20`,
        {
          headers: headerResponse,
        }
      );

      if (response.status === 200) {
        const cityResponse: CityModel[] = response.data;
        setSuggestedDepartureCities(cityResponse);
      }
    }
  };

  const suggestPlacesForArrivalCity = async () => {
    const arrivalCountryCode =
      itineraryPlanStore.visitDestinationList[
        itineraryPlanStore.visitDestinationList.length - 1
      ].city?.countryCode;

    if (
      arrivalCountryCode ===
      itineraryPlanStore.visitDestinationList[0].city?.countryCode
    ) {
      setSuggestedArrivalCities([]);
      return;
    }

    const headerResponse = await getHeader();

    if (user) {
      const userId = user.uid;
      let customerTracker = localStorage.getItem("voyaahCustomerTracker");
      let customerProfileId = "";
      if (customerTracker) {
        customerProfileId = JSON.parse(customerTracker!).id;
      }

      const response: any = await experienceApiAxios.get(
        `${CUSTOMER}/places?countryCode=${arrivalCountryCode}&accountId=${userId}&customerProfileId=${customerProfileId}`,
        {
          headers: headerResponse,
        }
      );

      if (response.status === 200) {
        const cityResponse: CityModel[] = response.data;
        setSuggestedArrivalCities(cityResponse);
      }
    } else {
      const response: any = await experienceApiAxios.get(
        `${DESTINATIONS}/cities/${arrivalCountryCode}?fetchCount=20`,
        {
          headers: headerResponse,
        }
      );

      if (response.status === 200) {
        const cityResponse: CityModel[] = response.data;
        setSuggestedArrivalCities(cityResponse);
      }
    }
  };

  const fetchDestinations = async (cityName: string) => {
    const headerResponse = await getHeader();
    experienceApiAxios
      .get(
        `${DESTINATIONS}?pageSize=40&currentPage=1&search=${cityName}&isActive=true`,
        {
          headers: headerResponse,
        }
      )
      .then((response) => {
        setCityList(response.data);
      });
  };

  const updateItineraryDestination = (dest: string) => {
    let updatedCityName =
      dest === "arrival"
        ? itineraryPlanStore.selectedItineraryPlan.arrivalAirportName
        : itineraryPlanStore.selectedItineraryPlan.departureAirportName;

    if (updatedCityName && updatedCityName.length > 2) {
      let filteredData = cityList.find(
        (x: any) => x?.name?.toLowerCase() === updatedCityName?.toLowerCase()
      );
      if (filteredData) {
        if (dest === "arrival") {
          itineraryPlanStore.updateSelectedItineraryPlan({
            ...itineraryPlanStore.selectedItineraryPlan,
            arrivalAirportId: filteredData.id,
            arrivalAirportName: filteredData?.name,
          });
          itineraryPlanStore.addNewDestination(
            initVisitDestinationModel({
              itineraryPlanId: itineraryPlanStore.selectedItineraryPlan.id,
              index:
                itineraryPlanStore.visitDestinationList.length === 1
                  ? 2
                  : itineraryPlanStore.visitDestinationList.length,
              id: generateUniqueNegativeId(),
              cityId: filteredData?.id,
              city: filteredData,
              cityName: filteredData?.name,
              stayDuration: 2,
            })
          );
        } else {
          itineraryPlanStore.updateSelectedItineraryPlan({
            ...itineraryPlanStore.selectedItineraryPlan,
            departureAirportId: filteredData.id,
            departureAirportName: filteredData?.name,
          });
          itineraryPlanStore.addNewDestination(
            initVisitDestinationModel({
              itineraryPlanId: itineraryPlanStore.selectedItineraryPlan.id,
              index: 1,
              id: generateUniqueNegativeId(),
              cityId: filteredData.id,
              city: filteredData,
              cityName: filteredData?.name,
              stayDuration: 2,
            })
          );
        }
      }
    } else {
      dest === "arrival"
        ? itineraryPlanStore.updateSelectedItineraryPlan({
            ...itineraryPlanStore.selectedItineraryPlan,
            arrivalAirportId: 0,
            arrivalAirportName: "",
          })
        : itineraryPlanStore.updateSelectedItineraryPlan({
            ...itineraryPlanStore.selectedItineraryPlan,
            departureAirportId: 0,
            departureAirportName: "",
          });
    }
  };

  const updateItineraryDestinationFromVoyaahList = (
    dest: string,
    voyaahCityList: CityModel[]
  ) => {
    let updatedCityName =
      dest === "arrival"
        ? itineraryPlanStore.selectedItineraryPlan.arrivalAirportName
        : itineraryPlanStore.selectedItineraryPlan.departureAirportName;

    if (updatedCityName && updatedCityName.length > 2) {
      let filteredData = voyaahCityList.find(
        (x: any) => x?.name?.toLowerCase() === updatedCityName?.toLowerCase()
      );
      if (filteredData) {
        if (dest === "arrival") {
          itineraryPlanStore.updateSelectedItineraryPlan({
            ...itineraryPlanStore.selectedItineraryPlan,
            arrivalAirportId: filteredData.id,
            arrivalAirportName: filteredData?.name,
          });
          itineraryPlanStore.addNewDestination(
            initVisitDestinationModel({
              itineraryPlanId: itineraryPlanStore.selectedItineraryPlan.id,
              index:
                itineraryPlanStore.visitDestinationList.length === 1
                  ? 2
                  : itineraryPlanStore.visitDestinationList.length,
              id: generateUniqueNegativeId(),
              cityId: filteredData?.id,
              city: filteredData,
              cityName: filteredData?.name,
              stayDuration: 2,
            })
          );
        } else {
          itineraryPlanStore.updateSelectedItineraryPlan({
            ...itineraryPlanStore.selectedItineraryPlan,
            departureAirportId: filteredData.id,
            departureAirportName: filteredData?.name,
          });
          itineraryPlanStore.addNewDestination(
            initVisitDestinationModel({
              itineraryPlanId: itineraryPlanStore.selectedItineraryPlan.id,
              index: 1,
              id: generateUniqueNegativeId(),
              cityId: filteredData.id,
              city: filteredData,
              cityName: filteredData?.name,
              stayDuration: 2,
            })
          );
        }
      }
    } else {
      dest === "arrival"
        ? itineraryPlanStore.updateSelectedItineraryPlan({
            ...itineraryPlanStore.selectedItineraryPlan,
            arrivalAirportId: 0,
            arrivalAirportName: "",
          })
        : itineraryPlanStore.updateSelectedItineraryPlan({
            ...itineraryPlanStore.selectedItineraryPlan,
            departureAirportId: 0,
            departureAirportName: "",
          });
    }
  };

  const updateSelectedCities = () => {
    const curatedSelectedCities: any[] = [];

    itineraryPlanStore.visitDestinationList.forEach((destination, index) => {
      if (
        index === 0 ||
        index === itineraryPlanStore.visitDestinationList.length - 1
      ) {
        const selectedCityObj = {
          label: destination.cityName,
          value: destination.cityName,
          city: destination.city,
        };

        curatedSelectedCities.push(selectedCityObj);
      }
    });

    itineraryPlanStore.visitDestinationList.forEach((destination, index) => {
      if (
        index !== 0 &&
        index !== itineraryPlanStore.visitDestinationList.length - 1
      ) {
        const selectedCityObj = {
          label: destination.cityName,
          value: destination.cityName,
          city: destination.city,
        };

        curatedSelectedCities.push(selectedCityObj);
      }
    });

    setSelectedOptions(curatedSelectedCities);
  };

  const updateCity = (item: VisitDestinationModel) => {
    if (item.cityName && item.cityName.length > 2) {
      let filteredData = cityList.find(
        (x: any) => x?.name?.toLowerCase() === item.cityName?.toLowerCase()
      );
      if (filteredData) {
        itineraryPlanStore.updateDestinationList(item.id, {
          ...item,
          cityId: filteredData.id,
          city: filteredData,
          cityName: filteredData?.name,
        });
      }
    } else {
      itineraryPlanStore.updateDestinationList(item.id, {
        ...item,
        cityId: 0,
        city: undefined,
        cityName: "",
      });
    }

    updateSelectedCities();
  };

  const getDistanceOnly = (fromIndex: number, toIndex: number) => {
    let fromDest = itineraryPlanStore.visitDestinationList[fromIndex];
    let toDest = itineraryPlanStore.visitDestinationList[toIndex];

    let distanceInKms =
      fromDest?.city && toDest?.city
        ? haversineDistance(fromDest?.city, toDest?.city)
        : 0;
    return `${Math.ceil(distanceInKms)} kms`;
  };

  const getTimeCalculation = (
    transport: string,
    fromIndex: number,
    toIndex: number
  ) => {
    let fromDest = itineraryPlanStore.visitDestinationList[fromIndex];
    let toDest = itineraryPlanStore.visitDestinationList[toIndex];

    let distanceInKms =
      fromDest?.city && toDest?.city
        ? haversineDistance(fromDest?.city, toDest?.city)
        : 0;
    let distanceCalculated = ``;

    distanceCalculated +=
      distanceInKms /
        (TransportMode.find((x: any) => x.code === transport)?.averageSpeed ??
          1) >=
      1
        ? ` ${Math.ceil(
            distanceInKms /
              (TransportMode.find((x: any) => x.code === transport)
                ?.averageSpeed ?? 1)
          )} hours`
        : ` ${Math.ceil(
            (distanceInKms /
              (TransportMode.find((x: any) => x.code === transport)
                ?.averageSpeed ?? 1)) *
              60
          )} minutes`;

    return distanceCalculated;
  };

  const getDistanceCalculation = (fromIndex: number, toIndex: number) => {
    let fromDest = itineraryPlanStore.visitDestinationList[fromIndex];
    let toDest = itineraryPlanStore.visitDestinationList[toIndex];

    let distanceInKms =
      fromDest?.city && toDest?.city
        ? haversineDistance(fromDest?.city, toDest?.city)
        : 0;
    let distanceCalculated = `${Math.ceil(distanceInKms)} kms, `;

    let existingRoute = itineraryPlanStore.itineraryRoutes.find(
      (x: any) =>
        x.fromDestinationId === fromDest.id && x.toDestinationId === toDest.id
    );
    const transport = existingRoute?.transportMode ?? "transportMode.Car";

    if (existingRoute) {
      distanceCalculated +=
        distanceInKms /
          (TransportMode.find(
            (x: any) => x.code === existingRoute?.transportMode
          )?.averageSpeed ?? 1) >=
        1
          ? ` ${Math.ceil(
              distanceInKms /
                (TransportMode.find((x: any) => x.code === transport)
                  ?.averageSpeed ?? 1)
            )} hours`
          : ` ${Math.ceil(
              (distanceInKms /
                (TransportMode.find(
                  (x: any) => x.code === existingRoute?.transportMode
                )?.averageSpeed ?? 1)) *
                60
            )} minutes`;
    } else {
      distanceCalculated +=
        distanceInKms /
          (TransportMode.find((x: any) => x.code === transport)?.averageSpeed ??
            1) >=
        1
          ? ` ${Math.ceil(
              distanceInKms /
                (TransportMode.find((x: any) => x.code === transport)
                  ?.averageSpeed ?? 1)
            )} hours`
          : ` ${Math.ceil(
              (distanceInKms /
                (TransportMode.find((x: any) => x.code === transport)
                  ?.averageSpeed ?? 1)) *
                60
            )} minutes`;
    }
    return distanceCalculated;
  };

  const getDefaultModeOfTransport = (
    fromDest: VisitDestinationModel,
    toDest: VisitDestinationModel,
    fromIndex: number,
    toIndex: number
  ) => {
    if (fromDest.city?.country?.code === toDest.city?.country?.code) {
      return (
        getAvailableTransportOptions(fromIndex, toIndex)[0]?.code ??
        fromDest.city?.country?.typicalModeOfTransport ??
        "transportMode.Car"
      );
    } else {
      if (getAvailableTransportOptions(fromIndex, toIndex)) {
        if (
          getAvailableTransportOptions(fromIndex, toIndex).find(
            (mode) => mode.code === "transportMode.Flight"
          )
        ) {
          return "transportMode.Flight";
        } else {
          return getAvailableTransportOptions(fromIndex, toIndex)[0]?.code;
        }
      }
      return (
        getAvailableTransportOptions(fromIndex, toIndex)[0]?.code ??
        "transportMode.Car"
      );
    }
  };

  const getAvailableTransportOptions = (fromIndex: number, toIndex: number) => {
    let fromDest = itineraryPlanStore.visitDestinationList[fromIndex];
    let toDest = itineraryPlanStore.visitDestinationList[toIndex];

    let transportModeArray: {
      code: string;
      description: string;
      averageSpeed: number;
    }[] = [];

    if (toDest.city?.modesOfTravel && fromDest.city?.modesOfTravel) {
      if (
        toDest.city?.modesOfTravel.length === 0 &&
        fromDest.city?.modesOfTravel.length === 0
      ) {
        transportModeArray = [...TransportMode];
      } else if (
        toDest.city?.modesOfTravel.length !== 0 &&
        fromDest.city?.modesOfTravel.length !== 0
      ) {
        const intersectionModes = toDest.city?.modesOfTravel.filter((mode) =>
          fromDest.city?.modesOfTravel?.some((fromMode) => fromMode === mode)
        );

        intersectionModes?.forEach((element) => {
          const transportModeObj:
            | {
                code: string;
                description: string;
                averageSpeed: number;
              }
            | undefined = TransportMode.find(
            (transportMode) => transportMode.code === element
          );

          if (transportModeObj) {
            transportModeArray.push(transportModeObj);
          }
        });
      } else if (toDest.city?.modesOfTravel.length !== 0) {
        toDest.city?.modesOfTravel?.forEach((element) => {
          const transportModeObj:
            | {
                code: string;
                description: string;
                averageSpeed: number;
              }
            | undefined = TransportMode.find(
            (transportMode) => transportMode.code === element
          );

          if (transportModeObj) {
            transportModeArray.push(transportModeObj);
          }
        });
      } else if (fromDest.city?.modesOfTravel.length !== 0) {
        fromDest.city?.modesOfTravel?.forEach((element) => {
          const transportModeObj:
            | {
                code: string;
                description: string;
                averageSpeed: number;
              }
            | undefined = TransportMode.find(
            (transportMode) => transportMode.code === element
          );

          if (transportModeObj) {
            transportModeArray.push(transportModeObj);
          }
        });
      }
    } else {
      transportModeArray = [...TransportMode];
    }

    return transportModeArray;
  };

  const getModeOfTransport = (fromIndex: number, toIndex: number) => {
    let fromDest = itineraryPlanStore.visitDestinationList[fromIndex];
    let toDest = itineraryPlanStore.visitDestinationList[toIndex];
    let existingRoute = itineraryPlanStore.itineraryRoutes.find(
      (x: any) =>
        x.fromDestinationId === fromDest.id && x.toDestinationId === toDest.id
    );

    return existingRoute
      ? existingRoute?.transportMode
      : getDefaultModeOfTransport(fromDest, toDest, fromIndex, toIndex);
  };

  const updateTransportMode = (
    fromIndex: number,
    toIndex: number,
    modeChange: string
  ) => {
    let fromDest = itineraryPlanStore.visitDestinationList[fromIndex];
    let toDest = itineraryPlanStore.visitDestinationList[toIndex];
    let existingRoute = itineraryPlanStore.itineraryRoutes.find(
      (x: any) =>
        x.fromDestinationId === fromDest.id && x.toDestinationId === toDest.id
    );

    if (existingRoute) {
      itineraryPlanStore.updateItineraryRoute(existingRoute.id, {
        ...existingRoute,
        transportMode: modeChange,
      });
    } else {
      itineraryPlanStore.addNewItineraryRoute(
        fromDest?.city && toDest?.city
          ? haversineDistance(fromDest?.city, toDest?.city)
          : 0,
        fromDest.id,
        toDest.id,
        modeChange ?? "transportMode.Car"
      );
    }
  };

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (e: any) => {
    if (
      !e.destination ||
      e.destination.index === 0 ||
      e.destination.index ===
        itineraryPlanStore.visitDestinationList.length - 1 ||
      e.source.index === 0 ||
      e.source.index === itineraryPlanStore.visitDestinationList.length - 1
    ) {
      return;
    }
    const existingItems = [...itineraryPlanStore.visitDestinationList];
    const sorted = reorder(
      existingItems,
      e.source.index,
      e.destination.index
    ) as VisitDestinationModel[];
    itineraryPlanStore.setVisitDestinationsReorder(sorted);
    updateSelectedCities();
    checkIfPlanOptimized();
  };

  const getPositionIndex = () => {
    if (itineraryPlanStore.placesToVisit.length) {
      return (
        itineraryPlanStore.placesToVisit[
          itineraryPlanStore.placesToVisit.length - 1
        ].positionIndex + 1
      );
    }

    return 0;
  };

  const addNewPlaceOfInterest = () => {
    let dayIndex: number = 1;

    if (itineraryPlanStore.placesToVisit.length !== 0) {
      dayIndex = Math.min(
        itineraryPlanStore.placesToVisit[
          itineraryPlanStore.placesToVisit.length - 1
        ].dayIndex + 1,
        itineraryPlanStore.numberOfDays
      );
    }

    const existingCityList = [];

    const result = itineraryPlanStore.visitDestinationList.find(
      (destination) =>
        destination.cityName === itineraryPlanStore.dayCityMapping[dayIndex]
    );

    const cityListObj = {
      cityId: result?.cityId!,
      cityName: result?.cityName!,
      city: result?.city!,
    };

    existingCityList[0] = cityListObj;
    const uniqueCity = new Set();
    const uniqueCities = existingCityList.filter((city) => {
      if (uniqueCity.has(city.cityId)) {
        return false;
      } else {
        uniqueCity.add(city.cityId);
        return true;
      }
    });

    const newPlaceToVisit: PlaceToVisit = {
      id: cuid(),
      name: "",
      dayIndex: dayIndex,
      positionIndex: getPositionIndex(),
      cityList: uniqueCities,
      activities: [
        {
          from: "10 AM",
          to: "7 PM",
          placeName: itineraryPlanStore.dayCityMapping[dayIndex],
          interests: [],
        },
      ],
    };

    itineraryPlanStore.addNewPlaceToVisit(newPlaceToVisit);
  };

  const deletePlaceOfInterest = (placeOfInterest: PlaceToVisit) => {
    itineraryPlanStore.deleteSelectedPlaceToVisit(placeOfInterest);
  };

  const handleChangeActiveCity = (selectedItems: any) => {
    if (selectedItems.length === 0) {
      clearDataAfterConfirmation();
    }
    if (selectedItems.length === 1) {
      itineraryPlanStore.updateSelectedItineraryPlan({
        ...itineraryPlanStore.selectedItineraryPlan,
        departureAirportName: selectedItems[0].label,
      });
      updateItineraryDestinationFromVoyaahList("departure", voyaahCities);

      itineraryPlanStore.updateSelectedItineraryPlan({
        ...itineraryPlanStore.selectedItineraryPlan,
        arrivalAirportName: selectedItems[0].label,
      });
      updateItineraryDestinationFromVoyaahList("arrival", voyaahCities);

      suggestPlacesForDestinationCity();
      setSuggestedArrivalCities([]);
      if (itineraryPlanStore.placesToVisit.length === 0) {
        addNewPlaceOfInterest();
      }
    } else if (
      selectedItems.length === 2 &&
      itineraryPlanStore.visitDestinationList.length === selectedItems.length
    ) {
      itineraryPlanStore.updateSelectedItineraryPlan({
        ...itineraryPlanStore.selectedItineraryPlan,
        arrivalAirportName: selectedItems[1].label,
      });
      updateItineraryDestinationFromVoyaahList("arrival", voyaahCities);

      suggestPlacesForDestinationCity();
      suggestPlacesForArrivalCity();
    } else {
      /* When we add an item here, the number will be more than the cities in the store
      similarly when we remove an item, the number here will be less than the cities in the store */
      if (
        selectedItems.length > itineraryPlanStore.visitDestinationList.length
      ) {
        selectedItems?.forEach(
          (item: { label: string | undefined; city: any }) => {
            if (
              !itineraryPlanStore.visitDestinationList.find(
                (destination) => destination.cityName === item.label
              )
            ) {
              itineraryPlanStore.addNewDestination(
                initVisitDestinationModel({
                  itineraryPlanId: itineraryPlanStore.selectedItineraryPlan.id,
                  index: itineraryPlanStore.visitDestinationList.length + 1,
                  id: generateUniqueNegativeId(),
                  cityId: item.city?.id,
                  city: item.city,
                  cityName: item.city?.name,
                  stayDuration: 2,
                })
              );
            }
          }
        );
      } else {
        itineraryPlanStore.visitDestinationList.forEach((destination) => {
          if (
            !selectedItems.find(
              (item: { label: string | undefined }) =>
                item.label === destination.cityName
            )
          ) {
            itineraryPlanStore.deleteDestination(destination.id);

            if (customItineraryStartDate && selectedItems.length !== 0) {
              const endDate = new Date(customItineraryStartDate);
              setCustomItineraryEndDate(
                endDate.setDate(
                  endDate.getDate() + itineraryPlanStore.numberOfDays - 1
                )
              );
            }
          }
        });
      }

      checkIfPlanOptimized();
    }
    setSelectedOptions(selectedItems);
    setErrors({ ...errors, cities: false });

    if (customItineraryStartDate && selectedItems.length !== 0) {
      const endDate = new Date(customItineraryStartDate);
      setCustomItineraryEndDate(
        endDate.setDate(endDate.getDate() + itineraryPlanStore.numberOfDays - 1)
      );
    }
  };

  useEffect(() => {
    let destinationData = itineraryPlanStore.visitDestinationList?.find(
      (x: any) => x.id === destinationId
    );
    if (destinationData) {
      const timeout = setTimeout(() => {
        fetchDestinations(destinationData?.cityName ?? "");
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [itineraryPlanStore?.visitDestinationList]);

  useEffect(() => {
    if (itineraryPlanStore?.selectedItineraryPlan?.arrivalAirportName) {
      const timeout = setTimeout(() => {
        fetchDestinations(
          itineraryPlanStore?.selectedItineraryPlan?.arrivalAirportName ?? ""
        );
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [itineraryPlanStore?.selectedItineraryPlan?.arrivalAirportName]);

  useEffect(() => {
    if (itineraryPlanStore?.selectedItineraryPlan?.departureAirportName) {
      const timeout = setTimeout(() => {
        fetchDestinations(
          itineraryPlanStore?.selectedItineraryPlan?.departureAirportName ?? ""
        );
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [itineraryPlanStore?.selectedItineraryPlan?.departureAirportName]);

  useEffect(() => {}, [
    itineraryPlanStore.visitDestinationList,
    itineraryPlanStore.itineraryRoutes,
    itineraryPlanStore.stayList,
    itineraryPlanStore.selectedItineraryPlan,
    itineraryPlanStore.itineraryPlanRoutesList,
    destinationId,
  ]);

  useEffect(() => {}, [
    itineraryPlanStore.placesToVisit,
    itineraryPlanStore.propertyList,
  ]);

  return (
    <>
      <div className="inner-page custom-itinerary-page">
        <div className="ci-header-section">
          <div className="breadcrumb-section">
            <div className="breadcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li style={{ color: "#000" }}>›</li>
                <li style={{ color: "#000" }}>Custom Itinerary</li>
              </ul>
            </div>
          </div>

          <div className="inner-heading-div">
            <div className="heading-text-div">
              {!itineraryPlanStore.selectedItineraryPlanNameEdit && (
                <div className="heading-edit-text-div">
                  <h3 className="head heading-txt" style={{ color: "#000" }}>
                    {itineraryPlanStore.selectedItineraryPlan.name}
                  </h3>
                  <button
                    className="heading-edit-btn"
                    onClick={() =>
                      itineraryPlanStore.setSelectedItineraryPlanNameEdit(true)
                    }
                  >
                    <EditIcon />
                  </button>
                </div>
              )}
              {itineraryPlanStore.selectedItineraryPlanNameEdit && (
                <div className="heading-edit-text-div">
                  <input
                    className="itinerary-plan-name"
                    type="text"
                    placeholder="Enter Itinerary Plan name"
                    id="itineraryPlan_txtArrival"
                    value={itineraryPlanStore.selectedItineraryPlan.name || ""}
                    onChange={(e) => {
                      itineraryPlanStore.updateSelectedItineraryPlan({
                        ...itineraryPlanStore.selectedItineraryPlan,
                        name: e.target.value,
                      });
                    }}
                  />
                  <button
                    className="heading-edit-btn"
                    onClick={() => {
                      if (
                        itineraryPlanStore.selectedItineraryPlan.name.length ===
                        0
                      ) {
                        itineraryPlanStore.updateSelectedItineraryPlan({
                          ...itineraryPlanStore.selectedItineraryPlan,
                          name: "Name your holiday",
                        });
                      }
                      itineraryPlanStore.setSelectedItineraryPlanNameEdit(
                        false
                      );
                    }}
                    title="save"
                    style={{ color: "green" }}
                  >
                    <SaveIcon />
                  </button>
                </div>
              )}
              <div className="arrival-departure-div">
                {itineraryPlanStore.visitDestinationList.length > 1 && (
                  <p
                    className="arrival-departure-text"
                    style={{ color: "#000" }}
                  >
                    {itineraryPlanStore.visitDestinationList[0].cityName}-
                    {
                      itineraryPlanStore.visitDestinationList[
                        itineraryPlanStore.visitDestinationList.length - 1
                      ].cityName
                    }
                  </p>
                )}
              </div>
            </div>
            <div className="header-btn-grp">
              <button
                className="itinerary-plan-button-plain"
                style={{ textWrap: "nowrap" }}
                onClick={() => {
                  navigate("/custom-itinerary");
                }}
              >
                <AddIcon />
                Create a new itinerary
              </button>
              <button
                className="itinerary-plan-button-plain"
                onClick={() => {
                  handleSaveItinerary("share");
                }}
              >
                <CustomItineraryShare />
                Share
              </button>
              <button
                className="itinerary-plan-button-plain"
                onClick={() => {
                  handleSaveItinerary("save");
                }}
              >
                <CustomerItinerarySave />
                Save
              </button>
              <button
                className="itinerary-plan-button"
                onClick={() => {
                  if (user) {
                    handleGetMeAQuote();
                  } else {
                    setValidationMessage(
                      "You should be logged in to access this feature"
                    );
                    setOpenValidationComponent(true);
                  }
                }}
              >
                Get me a quote
              </button>
            </div>
          </div>

          <div className="dates-people-selection-div">
            <div>
              <label className="label-element">Cities</label>
              <Select
                isMulti
                name="cityTags"
                options={activeCityList}
                isClearable={selectedOptions.some((v) => !v.isFixed)}
                components={makeAnimated()}
                styles={customStyles}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleChangeActiveCity}
                closeMenuOnSelect={true}
                value={selectedOptions}
                placeholder="Enter city names"
              />
              {errors.cities && (
                <p className="city-mandatory-text">
                  Choose two cities as departure and arrival
                </p>
              )}
            </div>
            <div className="dates-selection-div">
              <div className="dates-selection-from">
                <label className="label-element">From</label>
                <DatePicker
                  className="date-picker"
                  placeholderText="dd/mm/yyyy"
                  dateFormat="dd/MM/yyyy"
                  selected={customItineraryStartDate}
                  minDate={moment().toDate()}
                  onChange={(date: Date) => {
                    setCustomItineraryStartDate(date);
                    setErrors({ ...errors, from: false });
                    if (itineraryPlanStore.visitDestinationList.length === 0) {
                      setCustomItineraryEndDate(date);
                    } else {
                      if (date) {
                        let endDate = new Date(date);
                        setCustomItineraryEndDate(
                          endDate.setDate(
                            date.getDate() + itineraryPlanStore.numberOfDays - 1
                          )
                        );
                      } else {
                        setCustomItineraryEndDate(null);
                      }
                    }
                  }}
                  closeOnScroll={true}
                />
                {errors.from && (
                  <p className="dates-mandatory-text">From date required</p>
                )}
              </div>
              <div className="dates-selection-to">
                <label className="label-element">To</label>
                <DatePicker
                  className="date-picker"
                  placeholderText="dd/mm/yyyy"
                  dateFormat="dd/MM/yyyy"
                  selected={customItineraryEndDate}
                  minDate={customItineraryStartDate ?? moment().toDate()}
                  onChange={(date: Date) => {
                    setCustomItineraryEndDate(date);
                    setErrors({ ...errors, to: false });
                  }}
                  closeOnScroll={true}
                  disabled
                />
              </div>
            </div>
            <div className="people-selection-div">
              <div className="adults-selection-div">
                <label className="label-element">Adults</label>
                <div className="adults-selection">
                  <button
                    className="people-selection-btn"
                    onClick={() => {
                      if (adultCount > 0) {
                        setAdultCount(adultCount - 1);
                      }
                    }}
                  >
                    &#45;
                  </button>
                  <p className="count-text">{adultCount}</p>
                  <button
                    className="people-selection-btn"
                    onClick={() => setAdultCount(adultCount + 1)}
                  >
                    &#43;
                  </button>
                </div>
              </div>
              <div className="children-selection-div">
                <label className="label-element">Children</label>
                <div className="children-selection">
                  <button
                    className="people-selection-btn"
                    onClick={() => {
                      if (childCount > 0) {
                        setChildCount(childCount - 1);
                      }
                    }}
                  >
                    &#45;
                  </button>
                  <p className="count-text">{childCount}</p>
                  <button
                    className="people-selection-btn"
                    onClick={() => setChildCount(childCount + 1)}
                  >
                    &#43;
                  </button>
                </div>
              </div>
            </div>
          </div>

          {suggestedDepatureCities && suggestedDepatureCities.length > 0 && (
            <div className="cities-input-section">
              <div className="suggested-places-txt">
                Suggested places to visit:
              </div>
              <div className="chips-section">
                <Stack direction="row" spacing={1} alignContent={"center"}>
                  {suggestedDepatureCities
                    .filter(
                      (city) =>
                        !itineraryPlanStore.visitDestinationList.some(
                          (destination) => destination.cityName === city.name
                        )
                    )
                    .map((suggestedCity, index) => {
                      if (index < 3) {
                        return (
                          <Chip
                            label={suggestedCity.name}
                            key={index}
                            sx={{
                              backgroundColor: "#badade",
                              color: "black",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            onDelete={() => {
                              itineraryPlanStore.addNewDestination(
                                initVisitDestinationModel({
                                  itineraryPlanId:
                                    itineraryPlanStore.selectedItineraryPlan.id,
                                  index:
                                    itineraryPlanStore.visitDestinationList
                                      .length + 1,
                                  id: generateUniqueNegativeId(),
                                  cityId: suggestedCity?.id,
                                  city: suggestedCity,
                                  cityName: suggestedCity?.name,
                                  stayDuration: 2,
                                })
                              );

                              checkIfPlanOptimized();
                              updateSelectedCities();

                              if (customItineraryStartDate) {
                                const endDate = new Date(
                                  customItineraryStartDate
                                );
                                setCustomItineraryEndDate(
                                  endDate.setDate(
                                    endDate.getDate() +
                                      itineraryPlanStore.numberOfDays -
                                      1
                                  )
                                );
                              }
                            }}
                            deleteIcon={<AddIcon />}
                          />
                        );
                      }
                    })}
                  {suggestedArrivalCities
                    .filter(
                      (city) =>
                        !itineraryPlanStore.visitDestinationList.some(
                          (destination) => destination.cityName === city.name
                        )
                    )
                    .map((suggestedCity, index) => {
                      if (index < 3) {
                        return (
                          <Chip
                            label={suggestedCity.name}
                            key={index}
                            sx={{
                              backgroundColor: "#badade",
                              color: "black",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            onDelete={() => {
                              itineraryPlanStore.addNewDestination(
                                initVisitDestinationModel({
                                  itineraryPlanId:
                                    itineraryPlanStore.selectedItineraryPlan.id,
                                  index:
                                    itineraryPlanStore.visitDestinationList
                                      .length + 1,
                                  id: generateUniqueNegativeId(),
                                  cityId: suggestedCity?.id,
                                  city: suggestedCity,
                                  cityName: suggestedCity?.name,
                                  stayDuration: 2,
                                })
                              );

                              checkIfPlanOptimized();
                              updateSelectedCities();
                            }}
                            deleteIcon={<AddIcon />}
                          />
                        );
                      }
                    })}
                </Stack>
              </div>
            </div>
          )}
        </div>

        {itineraryPlanStore.visitDestinationList &&
          itineraryPlanStore.visitDestinationList.length > 0 && (
            <div className="outer-itinerary-div">
              <div className="inner-itinerary-div">
                <div className="arrival-departure-input-section">
                  <div className="arrival-departure-input-div">
                    <div className="departure-input-section">
                      <label
                        className="arrival-destination-div-label"
                        style={{ textAlign: "end" }}
                      >
                        Entry point
                      </label>
                      <input
                        className="departure-text"
                        type="text"
                        placeholder="Enter departure city"
                        id="itineraryPlan_txtArrival"
                        aria-describedby="name"
                        onBlur={() => {
                          updateItineraryDestination("departure");
                          updateSelectedCities();
                        }}
                        value={
                          itineraryPlanStore.selectedItineraryPlan
                            .departureAirportName || ""
                        }
                        list="departure-datalistOptions"
                        onChange={(e) => {
                          itineraryPlanStore.updateSelectedItineraryPlan({
                            ...itineraryPlanStore.selectedItineraryPlan,
                            departureAirportName: e.target.value,
                          });
                        }}
                      />
                      <datalist id="departure-datalistOptions">
                        {cityList?.map((item: any, index: any) => {
                          if (
                            item.state &&
                            item.state.name &&
                            item.state.name.length > 0
                          ) {
                            return (
                              <option value={item.name} key={index}>
                                {`${item.name}, ${item.state?.name}, ${item.country?.name}`}
                              </option>
                            );
                          } else {
                            return (
                              <option value={item.name} key={index}>
                                {`${item.name}, ${item.country?.name}`}
                              </option>
                            );
                          }
                        })}
                      </datalist>
                    </div>
                    <div className="arrival-input-section">
                      <label
                        className="arrival-destination-div-label"
                        style={{ textAlign: "end" }}
                      >
                        Exit point
                      </label>
                      <input
                        className="arrival-text"
                        type="text"
                        placeholder="Enter arrival city"
                        id="itineraryPlan_txtArrival"
                        aria-describedby="name"
                        list="arrival-datalistOptions"
                        onBlur={() => {
                          updateItineraryDestination("arrival");
                          updateSelectedCities();
                        }}
                        value={
                          itineraryPlanStore.selectedItineraryPlan
                            .arrivalAirportName || ""
                        }
                        onChange={(e) => {
                          itineraryPlanStore.updateSelectedItineraryPlan({
                            ...itineraryPlanStore.selectedItineraryPlan,
                            arrivalAirportName: e.target.value,
                          });
                        }}
                      />
                      <datalist id="arrival-datalistOptions">
                        {cityList?.map((item: any, index: any) => {
                          if (
                            item.state &&
                            item.state.name &&
                            item.state.name.length > 0
                          ) {
                            return (
                              <option value={item.name} key={index}>
                                {`${item.name}, ${item.state?.name}, ${item.country?.name}`}
                              </option>
                            );
                          } else {
                            return (
                              <option value={item.name} key={index}>
                                {`${item.name}, ${item.country?.name}`}
                              </option>
                            );
                          }
                        })}
                      </datalist>
                    </div>
                  </div>

                  <div className="clear-reset-btn-div">
                    <button
                      className="apply-to-stays-btn"
                      onClick={() => setOpenClearValidationComponent(true)}
                    >
                      Clear/Reset
                    </button>
                  </div>
                </div>
                <div className="itinerary-planning-div">
                  <div className="itinerary-planning-cities">
                    <div className="itinerary-planning-cities-heading-div">
                      <h4 className="itinerary-planning-cities-heading">
                        Plan
                      </h4>
                    </div>

                    <div className="cities-list-div">
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="Table">
                          {(provided) => (
                            <table
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              <tbody>
                                {itineraryPlanStore.visitDestinationList.map(
                                  (item, index) => {
                                    if (
                                      itineraryPlanStore.visitDestinationList
                                        .length === 2 &&
                                      itineraryPlanStore.visitDestinationList[0]
                                        .cityName ===
                                        itineraryPlanStore
                                          .visitDestinationList[1].cityName &&
                                      index === 0
                                    ) {
                                      <Draggable
                                        key={item.id.toString()}
                                        draggableId={item.id.toString()}
                                        index={index}
                                        isDragDisabled={false}
                                      >
                                        {(provided) => (
                                          <tr
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className="plan-row"
                                          >
                                            <td className="col-sm-1 slider-div">
                                              {" "}
                                              {!(
                                                index === 0 ||
                                                index ===
                                                  itineraryPlanStore
                                                    .visitDestinationList
                                                    .length -
                                                    1
                                              ) && (
                                                <FontAwesomeIcon
                                                  icon={faBars}
                                                />
                                              )}
                                            </td>

                                            <td
                                              className="col-sm-3 col-md-3 col-lg-3"
                                              style={{ padding: "5px" }}
                                            >
                                              <input
                                                type="text"
                                                id={`opp_routing${index}_txtDest`}
                                                className={`cityname-itinerary`}
                                                aria-describedby="propertyZipCode"
                                                disabled={
                                                  index === 0 ||
                                                  index ===
                                                    itineraryPlanStore
                                                      .visitDestinationList
                                                      .length -
                                                      1
                                                }
                                                value={item?.cityName}
                                                onChange={(e) => {
                                                  setDestinationId(item?.id);
                                                  itineraryPlanStore.updateDestinationList(
                                                    item.id,
                                                    {
                                                      ...item,
                                                      cityName: e.target.value,
                                                    }
                                                  );
                                                }}
                                                onBlur={() => {
                                                  updateCity(item);
                                                  checkIfPlanOptimized();
                                                }}
                                                list={`datalistOptions${index}`}
                                                placeholder={`Enter Destination`}
                                              />
                                              <datalist
                                                id={`datalistOptions${index}`}
                                              >
                                                {cityList?.map(
                                                  (item: any, index: any) => {
                                                    if (
                                                      item.state &&
                                                      item.state.name &&
                                                      item.state.name.length > 0
                                                    ) {
                                                      return (
                                                        <option
                                                          value={item.name}
                                                          key={index}
                                                        >
                                                          {`${item.name}, ${item.state?.name}, ${item.country?.name}`}
                                                        </option>
                                                      );
                                                    } else {
                                                      return (
                                                        <option
                                                          value={item.name}
                                                          key={index}
                                                        >
                                                          {`${item.name}, ${item.country?.name}`}
                                                        </option>
                                                      );
                                                    }
                                                  }
                                                )}
                                              </datalist>
                                            </td>
                                            <td
                                              className="col-sm-2"
                                              style={{ padding: "5px" }}
                                            >
                                              <select
                                                className={`nights-itinerary`}
                                                id={`opp_routing${index}_selDuration`}
                                                disabled={false}
                                                onChange={(e) => {
                                                  if (
                                                    item.cityName &&
                                                    item.stayDuration >
                                                      parseInt(e.target.value)
                                                  ) {
                                                    let dayCount = 0;
                                                    itineraryPlanStore.visitDestinationList.forEach(
                                                      (
                                                        destination,
                                                        itemIndex
                                                      ) => {
                                                        if (itemIndex < index) {
                                                          dayCount =
                                                            dayCount +
                                                            destination.stayDuration;
                                                        } else if (
                                                          itemIndex === index
                                                        ) {
                                                          dayCount =
                                                            dayCount +
                                                            parseInt(
                                                              e.target.value
                                                            );
                                                        }
                                                      }
                                                    );

                                                    dayCount = dayCount + 1;

                                                    let isPresent = false;

                                                    for (
                                                      let indexPos = dayCount;
                                                      indexPos <=
                                                      dayCount +
                                                        item.stayDuration -
                                                        parseInt(
                                                          e.target.value
                                                        ) -
                                                        1;
                                                      indexPos++
                                                    ) {
                                                      const isPlacePresent =
                                                        itineraryPlanStore.placesToVisit.find(
                                                          (place) =>
                                                            place.dayIndex ===
                                                            indexPos
                                                        );
                                                      if (isPlacePresent) {
                                                        isPresent = true;
                                                      }
                                                    }

                                                    if (isPresent) {
                                                      setDayCounter(dayCount);
                                                      setDifferenceInDays(
                                                        item.stayDuration -
                                                          parseInt(
                                                            e.target.value
                                                          )
                                                      );
                                                      setOpenDurationValidationComponent(
                                                        true
                                                      );
                                                      setDestinationDuration(
                                                        item
                                                      );
                                                      setNewDuration(
                                                        parseInt(e.target.value)
                                                      );

                                                      let durationStr =
                                                        "The things to do filled on Day ";
                                                      for (
                                                        let indexPos = dayCount;
                                                        indexPos <=
                                                        dayCount +
                                                          item.stayDuration -
                                                          parseInt(
                                                            e.target.value
                                                          ) -
                                                          1;
                                                        indexPos++
                                                      ) {
                                                        durationStr =
                                                          durationStr +
                                                          indexPos +
                                                          " ";
                                                      }

                                                      durationStr =
                                                        durationStr +
                                                        "will be cleared, do you want to proceed?";

                                                      setDurationMessage(
                                                        durationStr
                                                      );
                                                    } else {
                                                      itineraryPlanStore.updateDestinationList(
                                                        item.id,
                                                        {
                                                          ...item,
                                                          stayDuration:
                                                            parseInt(
                                                              e.target.value
                                                            ),
                                                        }
                                                      );
                                                    }
                                                  } else if (
                                                    item.stayDuration <
                                                    parseInt(e.target.value)
                                                  ) {
                                                    itineraryPlanStore.updateDestinationList(
                                                      item.id,
                                                      {
                                                        ...item,
                                                        stayDuration: parseInt(
                                                          e.target.value
                                                        ),
                                                      }
                                                    );
                                                  }

                                                  if (
                                                    customItineraryStartDate
                                                  ) {
                                                    const endDate = new Date(
                                                      customItineraryStartDate
                                                    );
                                                    setCustomItineraryEndDate(
                                                      endDate.setDate(
                                                        endDate.getDate() +
                                                          itineraryPlanStore.numberOfDays -
                                                          1
                                                      )
                                                    );
                                                  }
                                                }}
                                                value={item.stayDuration}
                                              >
                                                {[
                                                  0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
                                                  10, 11, 12, 13, 14, 15, 16,
                                                  17, 18, 19, 20,
                                                ].map((item: any) => {
                                                  return (
                                                    <option
                                                      key={`numNights${item}`}
                                                      value={item}
                                                    >
                                                      {`${item} Nights`}
                                                    </option>
                                                  );
                                                })}
                                              </select>
                                            </td>
                                            <td
                                              className="col-sm-1"
                                              style={{
                                                textAlign: "end",
                                                padding: "5px",
                                              }}
                                            >
                                              {!(
                                                index === 0 ||
                                                index ===
                                                  itineraryPlanStore
                                                    .visitDestinationList
                                                    .length -
                                                    1
                                              ) && (
                                                <button
                                                  className="btn btn-icon"
                                                  style={{
                                                    marginRight: "2px",
                                                    border: "none",
                                                    background: "none",
                                                    color: "black",
                                                  }}
                                                  id={`opp_routing${index}_btnDelete`}
                                                  disabled={
                                                    index === 0 ||
                                                    index ===
                                                      itineraryPlanStore
                                                        .visitDestinationList
                                                        .length -
                                                        1
                                                  }
                                                  onClick={() => {
                                                    itineraryPlanStore.deleteDestination(
                                                      item.id
                                                    );

                                                    updateSelectedCities();

                                                    if (
                                                      customItineraryStartDate
                                                    ) {
                                                      const endDate = new Date(
                                                        customItineraryStartDate
                                                      );
                                                      setCustomItineraryEndDate(
                                                        endDate.setDate(
                                                          endDate.getDate() +
                                                            itineraryPlanStore.numberOfDays -
                                                            1
                                                        )
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <RemoveDestinationMinus />
                                                </button>
                                              )}
                                            </td>
                                          </tr>
                                        )}
                                      </Draggable>;
                                    } else {
                                      return (
                                        <Draggable
                                          key={item.id.toString()}
                                          draggableId={item.id.toString()}
                                          index={index}
                                          isDragDisabled={false}
                                        >
                                          {(provided) => (
                                            <tr
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              className="plan-row"
                                            >
                                              <td className="col-sm-1 slider-div">
                                                {" "}
                                                {!(
                                                  index === 0 ||
                                                  index ===
                                                    itineraryPlanStore
                                                      .visitDestinationList
                                                      .length -
                                                      1
                                                ) && (
                                                  <FontAwesomeIcon
                                                    icon={faBars}
                                                  />
                                                )}
                                              </td>

                                              <td
                                                className="col-sm-3 col-md-3 col-lg-3"
                                                style={{ padding: "5px" }}
                                              >
                                                <input
                                                  type="text"
                                                  id={`opp_routing${index}_txtDest`}
                                                  className={`cityname-itinerary`}
                                                  aria-describedby="propertyZipCode"
                                                  disabled={
                                                    index === 0 ||
                                                    index ===
                                                      itineraryPlanStore
                                                        .visitDestinationList
                                                        .length -
                                                        1
                                                  }
                                                  value={item?.cityName}
                                                  onChange={(e) => {
                                                    setDestinationId(item?.id);
                                                    itineraryPlanStore.updateDestinationList(
                                                      item.id,
                                                      {
                                                        ...item,
                                                        cityName:
                                                          e.target.value,
                                                      }
                                                    );
                                                  }}
                                                  onBlur={() => {
                                                    updateCity(item);
                                                    checkIfPlanOptimized();
                                                  }}
                                                  list={`datalistOptions${index}`}
                                                  placeholder={`Enter Destination`}
                                                />
                                                <datalist
                                                  id={`datalistOptions${index}`}
                                                >
                                                  {cityList?.map(
                                                    (item: any, index: any) => {
                                                      if (
                                                        item.state &&
                                                        item.state.name &&
                                                        item.state.name.length >
                                                          0
                                                      ) {
                                                        return (
                                                          <option
                                                            value={item.name}
                                                            key={index}
                                                          >
                                                            {`${item.name}, ${item.state?.name}, ${item.country?.name}`}
                                                          </option>
                                                        );
                                                      } else {
                                                        return (
                                                          <option
                                                            value={item.name}
                                                            key={index}
                                                          >
                                                            {`${item.name}, ${item.country?.name}`}
                                                          </option>
                                                        );
                                                      }
                                                    }
                                                  )}
                                                </datalist>
                                              </td>
                                              <td
                                                className="col-sm-2"
                                                style={{ padding: "5px" }}
                                              >
                                                <select
                                                  className={`nights-itinerary`}
                                                  id={`opp_routing${index}_selDuration`}
                                                  disabled={false}
                                                  onChange={(e) => {
                                                    if (
                                                      item.cityName &&
                                                      item.stayDuration >
                                                        parseInt(e.target.value)
                                                    ) {
                                                      let dayCount = 0;
                                                      itineraryPlanStore.visitDestinationList.forEach(
                                                        (
                                                          destination,
                                                          itemIndex
                                                        ) => {
                                                          if (
                                                            itemIndex < index
                                                          ) {
                                                            dayCount =
                                                              dayCount +
                                                              destination.stayDuration;
                                                          } else if (
                                                            itemIndex === index
                                                          ) {
                                                            dayCount =
                                                              dayCount +
                                                              parseInt(
                                                                e.target.value
                                                              );
                                                          }
                                                        }
                                                      );

                                                      dayCount = dayCount + 1;

                                                      let isPresent = false;

                                                      for (
                                                        let indexPos = dayCount;
                                                        indexPos <=
                                                        dayCount +
                                                          item.stayDuration -
                                                          parseInt(
                                                            e.target.value
                                                          ) -
                                                          1;
                                                        indexPos++
                                                      ) {
                                                        const isPlacePresent =
                                                          itineraryPlanStore.placesToVisit.find(
                                                            (place) =>
                                                              place.dayIndex ===
                                                              indexPos
                                                          );
                                                        if (isPlacePresent) {
                                                          isPresent = true;
                                                        }
                                                      }

                                                      if (isPresent) {
                                                        setDayCounter(dayCount);
                                                        setDifferenceInDays(
                                                          item.stayDuration -
                                                            parseInt(
                                                              e.target.value
                                                            )
                                                        );
                                                        setOpenDurationValidationComponent(
                                                          true
                                                        );
                                                        setDestinationDuration(
                                                          item
                                                        );
                                                        setNewDuration(
                                                          parseInt(
                                                            e.target.value
                                                          )
                                                        );

                                                        let durationStr =
                                                          "The things to do filled on Day ";
                                                        for (
                                                          let indexPos =
                                                            dayCount;
                                                          indexPos <=
                                                          dayCount +
                                                            item.stayDuration -
                                                            parseInt(
                                                              e.target.value
                                                            ) -
                                                            1;
                                                          indexPos++
                                                        ) {
                                                          durationStr =
                                                            durationStr +
                                                            indexPos +
                                                            " ";
                                                        }

                                                        durationStr =
                                                          durationStr +
                                                          "will be cleared, do you want to proceed?";

                                                        setDurationMessage(
                                                          durationStr
                                                        );
                                                      } else {
                                                        itineraryPlanStore.updateDestinationList(
                                                          item.id,
                                                          {
                                                            ...item,
                                                            stayDuration:
                                                              parseInt(
                                                                e.target.value
                                                              ),
                                                          }
                                                        );
                                                      }
                                                    } else if (
                                                      item.stayDuration <
                                                      parseInt(e.target.value)
                                                    ) {
                                                      itineraryPlanStore.updateDestinationList(
                                                        item.id,
                                                        {
                                                          ...item,
                                                          stayDuration:
                                                            parseInt(
                                                              e.target.value
                                                            ),
                                                        }
                                                      );
                                                    }

                                                    if (
                                                      customItineraryStartDate
                                                    ) {
                                                      const endDate = new Date(
                                                        customItineraryStartDate
                                                      );
                                                      setCustomItineraryEndDate(
                                                        endDate.setDate(
                                                          endDate.getDate() +
                                                            itineraryPlanStore.numberOfDays -
                                                            1
                                                        )
                                                      );
                                                    }
                                                  }}
                                                  value={item.stayDuration}
                                                >
                                                  {[
                                                    0, 1, 2, 3, 4, 5, 6, 7, 8,
                                                    9, 10, 11, 12, 13, 14, 15,
                                                    16, 17, 18, 19, 20,
                                                  ].map((item: any) => {
                                                    return (
                                                      <option
                                                        key={`numNights${item}`}
                                                        value={item}
                                                      >
                                                        {`${item} Nights`}
                                                      </option>
                                                    );
                                                  })}
                                                </select>
                                              </td>
                                              <td
                                                className="col-sm-1"
                                                style={{
                                                  textAlign: "end",
                                                  padding: "5px",
                                                }}
                                              >
                                                {!(
                                                  index === 0 ||
                                                  index ===
                                                    itineraryPlanStore
                                                      .visitDestinationList
                                                      .length -
                                                      1
                                                ) && (
                                                  <button
                                                    className="btn btn-icon"
                                                    style={{
                                                      marginRight: "2px",
                                                      border: "none",
                                                      background: "none",
                                                      color: "black",
                                                    }}
                                                    id={`opp_routing${index}_btnDelete`}
                                                    disabled={
                                                      index === 0 ||
                                                      index ===
                                                        itineraryPlanStore
                                                          .visitDestinationList
                                                          .length -
                                                          1
                                                    }
                                                    onClick={() => {
                                                      itineraryPlanStore.deleteDestination(
                                                        item.id
                                                      );

                                                      updateSelectedCities();

                                                      if (
                                                        customItineraryStartDate
                                                      ) {
                                                        const endDate =
                                                          new Date(
                                                            customItineraryStartDate
                                                          );
                                                        setCustomItineraryEndDate(
                                                          endDate.setDate(
                                                            endDate.getDate() +
                                                              itineraryPlanStore.numberOfDays -
                                                              1
                                                          )
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    <RemoveDestinationMinus />
                                                  </button>
                                                )}
                                              </td>
                                            </tr>
                                          )}
                                        </Draggable>
                                      );
                                    }
                                  }
                                )}
                                {provided.placeholder}
                              </tbody>
                            </table>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                    {itineraryPlanStore.visitDestinationList.length >= 2 && (
                      <div className="add-destination-div">
                        <button
                          className="add-destination-btn"
                          onClick={() => {
                            itineraryPlanStore.addNewDestination(
                              initVisitDestinationModel({
                                itineraryPlanId:
                                  itineraryPlanStore.selectedItineraryPlan.id,
                                index:
                                  itineraryPlanStore.visitDestinationList
                                    .length + 1,
                                id: generateUniqueNegativeId(),
                                stayDuration: 2,
                              })
                            );
                            if (customItineraryStartDate) {
                              const endDate = new Date(
                                customItineraryStartDate
                              );

                              setCustomItineraryEndDate(
                                endDate.setDate(
                                  endDate.getDate() +
                                    itineraryPlanStore.numberOfDays -
                                    1
                                )
                              );
                            }
                          }}
                        >
                          <AddDestinationPlus />
                          Add destination
                        </button>
                        <button className="suggest-btn" onClick={suggestPlaces}>
                          Suggest
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="itinerary-planning-distances">
                    <h4 className="itinerary-planning-distances-heading">
                      Transfers (
                      {itineraryPlanStore.visitDestinationList.length === 0
                        ? 0
                        : itineraryPlanStore.visitDestinationList.length - 1}
                      )
                    </h4>
                    <div className="transfer-data-div">
                      {itineraryPlanStore.visitDestinationList.map(
                        (item, index) => {
                          if (
                            itineraryPlanStore.visitDestinationList.length ===
                              2 &&
                            itineraryPlanStore.visitDestinationList[0]
                              .cityName ===
                              itineraryPlanStore.visitDestinationList[1]
                                .cityName
                          ) {
                            return <></>;
                          } else {
                            return (
                              <div key={index}>
                                {index > 0 && (
                                  <div className="transfer-step">
                                    <div>
                                      <StepperIcon />
                                    </div>
                                    <div className="city-distance-transport-div">
                                      <div className="city-distance-div">
                                        <p className="city-link">{`${
                                          itineraryPlanStore
                                            .visitDestinationList[index - 1]
                                            .cityName
                                        }-${
                                          itineraryPlanStore
                                            .visitDestinationList[index]
                                            .cityName
                                        }`}</p>

                                        <p className="distance-time-link">
                                          {getDistanceOnly(index - 1, index)}
                                        </p>
                                      </div>
                                      <select
                                        id={`opp_routing${
                                          index - 1
                                        }-${index}_txtTransport`}
                                        className="transfers-itinerary"
                                        disabled={false}
                                        onChange={(e) =>
                                          updateTransportMode(
                                            index - 1,
                                            index,
                                            e.target.value
                                          )
                                        }
                                        value={getModeOfTransport(
                                          index - 1,
                                          index
                                        )}
                                      >
                                        {getAvailableTransportOptions(
                                          index - 1,
                                          index
                                        ).map((item: any) => {
                                          return (
                                            <option
                                              key={`transportMode${item.code}`}
                                              value={item.code}
                                            >
                                              {item.description} -{" "}
                                              {getTimeCalculation(
                                                item.code,
                                                index - 1,
                                                index
                                              )}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          }
                        }
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="optimize-div">
                    <div className="optimize-text-div">
                      {!isOptimized && (
                        <p>
                          Your itinerary can be optimized. Click optimize to do
                          it.
                        </p>
                      )}
                      {isOptimized && <p>Your itinerary is optimized.</p>}
                    </div>
                    <div className="itinerary-button-section">
                      <button
                        className="optimize-btn"
                        onClick={returnOptimizedRoute}
                        disabled={isOptimized}
                      >
                        <OptimizeStars />
                        Optimize
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        {itineraryPlanStore.visitDestinationList &&
          itineraryPlanStore.visitDestinationList.length === 0 && (
            <div className="outer-itinerary-div-no-data">
              <div className="inner-itinerary-div-no-data">
                <h3 className="no-data-heading">
                  Enter cities to start building your itinerary!
                </h3>
              </div>
            </div>
          )}

        {itineraryPlanStore.visitDestinationList &&
          itineraryPlanStore.visitDestinationList.length > 1 && (
            <div className="stays-places-to-visit-section">
              <div className="stays-places-to-visit-heading">
                <h1>Choose your stay and preferred places</h1>
                <p>
                  Design your ultimate getaway, customize your travel itinerary
                  to
                </p>
                <p>match your unique style and preferences</p>
              </div>
              <div className="tabs">
                <div className="btn-div">
                  <button
                    onClick={() => setActiveTab(0)}
                    className={
                      activeTab === 0 ? "stays-btn" : "stays-btn  tab-active"
                    }
                  >
                    {/* <FontAwesomeIcon className="icon-img" icon={faBed} /> */}
                    Stays
                  </button>
                  <button
                    onClick={() => setActiveTab(1)}
                    className={
                      activeTab === 1
                        ? "places-to-visit-btn"
                        : "places-to-visit-btn tab-active"
                    }
                  >
                    {/* <FontAwesomeIcon className="icon-img" icon={faMapPin} /> */}
                    Things to do
                  </button>
                </div>
              </div>

              <div className="tab-content">
                {activeTab === 0 &&
                  itineraryPlanStore.visitDestinationList.length === 2 &&
                  itineraryPlanStore.visitDestinationList[0].cityName ===
                    itineraryPlanStore.visitDestinationList[1].cityName &&
                  itineraryPlanStore.visitDestinationList.map(
                    (destination, index) => {
                      if (destination.stayDuration != 0 && index === 1) {
                        return (
                          <ItineraryStaysEdit
                            cityName={destination.cityName ?? ""}
                            existingProperty={
                              itineraryPlanStore.existingProperties.find(
                                (property) =>
                                  property.cityName === destination.cityName
                              )?.property
                            }
                            packageLocation={""}
                            packageType="default"
                            key={index}
                            roomInfo={[
                              {
                                ...itineraryPlanStore.stayRoomTypes.find(
                                  (room) =>
                                    room.cityName === destination.cityName
                                ),
                              },
                            ]}
                            propertyConfigInfo={itineraryPlanStore.propertyList.find(
                              (property) =>
                                property.destination === destination.cityName
                            )}
                            destinationIndex={index}
                            beginDate={customItineraryStartDate!}
                          />
                        );
                      } else if (
                        destination.stayDuration === 0 &&
                        index === 1
                      ) {
                        return (
                          <div className="stays-section">
                            <div
                              className="city-date-div"
                              style={{ textAlign: "center" }}
                            >
                              You have chosen 0 nights in {destination.cityName}
                              , change it to explore available stays.
                            </div>
                          </div>
                        );
                      }
                    }
                  )}
                {activeTab === 0 &&
                  itineraryPlanStore.visitDestinationList[0].cityName !==
                    itineraryPlanStore.visitDestinationList[1].cityName &&
                  itineraryPlanStore.visitDestinationList.map(
                    (destination, index) => {
                      if (destination.stayDuration != 0) {
                        return (
                          <ItineraryStaysEdit
                            cityName={destination.cityName ?? ""}
                            existingProperty={
                              itineraryPlanStore.existingProperties.find(
                                (property) =>
                                  property.cityName === destination.cityName
                              )?.property
                            }
                            packageLocation={""}
                            packageType="default"
                            key={index}
                            roomInfo={[
                              {
                                ...itineraryPlanStore.stayRoomTypes.find(
                                  (room) =>
                                    room.cityName === destination.cityName
                                ),
                              },
                            ]}
                            propertyConfigInfo={itineraryPlanStore.propertyList.find(
                              (property) =>
                                property.destination === destination.cityName
                            )}
                            destinationIndex={index}
                            beginDate={customItineraryStartDate!}
                          />
                        );
                      } else {
                        return (
                          <div className="stays-section">
                            <div
                              className="city-date-div"
                              style={{ textAlign: "center" }}
                            >
                              You have chosen 0 nights in {destination.cityName}
                              , change it to explore available stays.
                            </div>
                          </div>
                        );
                      }
                    }
                  )}
                {activeTab === 1 && (
                  <div>
                    <div className="add-place-to-visit-div">
                      {!itineraryPlanStore.placesToVisitEdit && (
                        <button
                          className="add-place-to-visit-btn"
                          onClick={() => {
                            itineraryPlanStore.setPlacesToVisitEdit(true);
                          }}
                        >
                          Edit Schedule
                        </button>
                      )}
                      {itineraryPlanStore.placesToVisitEdit && (
                        <button
                          className="save-place-to-visit-btn"
                          onClick={() => {
                            itineraryPlanStore.setPlacesToVisitEdit(false);
                          }}
                        >
                          Save Schedule
                        </button>
                      )}
                      <button
                        className="add-place-to-visit-btn"
                        disabled={
                          !itineraryPlanStore.placesToVisitEdit ||
                          itineraryPlanStore.placesToVisit.length ===
                            itineraryPlanStore.numberOfDays
                        }
                        onClick={() => {
                          if (
                            itineraryPlanStore.placesToVisit.length <=
                            itineraryPlanStore.numberOfDays - 1
                          ) {
                            addNewPlaceOfInterest();
                          }
                        }}
                      >
                        Add Day{" "}
                        {itineraryPlanStore.placesToVisit.length ===
                        itineraryPlanStore.numberOfDays
                          ? ""
                          : itineraryPlanStore.placesToVisit.length + 1}
                      </button>
                    </div>
                    {itineraryPlanStore.placesToVisit.map(
                      (placeToVisit, index) => (
                        <ItineraryPlacesToVisit
                          key={placeToVisit.id}
                          data={placeToVisit}
                          deletePlaceOfInterest={deletePlaceOfInterest}
                          index={index + 1}
                        />
                      )
                    )}
                    {itineraryPlanStore.placesToVisit.length > 2 && (
                      <div className="add-place-to-visit-div">
                        {!itineraryPlanStore.placesToVisitEdit && (
                          <button
                            className="add-place-to-visit-btn"
                            onClick={() => {
                              itineraryPlanStore.setPlacesToVisitEdit(true);
                            }}
                          >
                            Edit Schedule
                          </button>
                        )}
                        {itineraryPlanStore.placesToVisitEdit && (
                          <button
                            className="save-place-to-visit-btn"
                            onClick={() => {
                              itineraryPlanStore.setPlacesToVisitEdit(false);
                            }}
                          >
                            Save Schedule
                          </button>
                        )}
                        <button
                          className="add-place-to-visit-btn"
                          disabled={
                            !itineraryPlanStore.placesToVisitEdit ||
                            itineraryPlanStore.placesToVisit.length ===
                              itineraryPlanStore.numberOfDays
                          }
                          onClick={() => {
                            if (
                              itineraryPlanStore.placesToVisit.length <=
                              itineraryPlanStore.numberOfDays - 1
                            ) {
                              addNewPlaceOfInterest();
                            }
                          }}
                        >
                          Add Day{" "}
                          {itineraryPlanStore.placesToVisit.length ===
                          itineraryPlanStore.numberOfDays
                            ? ""
                            : itineraryPlanStore.placesToVisit.length + 1}
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {itineraryPlanStore.visitDestinationList.length > 0 && (
                <div className="tabs">
                  <div className="btn-div">
                    <button
                      onClick={() => setActiveTab(0)}
                      className={
                        activeTab === 0 ? "stays-btn" : "stays-btn  tab-active"
                      }
                    >
                      Stays
                    </button>
                    <button
                      onClick={() => setActiveTab(1)}
                      className={
                        activeTab === 1
                          ? "places-to-visit-btn"
                          : "places-to-visit-btn tab-active"
                      }
                    >
                      Things to do
                    </button>
                  </div>
                </div>
              )}

              <div className="footer-btn-div">
                <div className="footer-left-div">
                  <button
                    className="itinerary-plan-button-plain"
                    style={{ textWrap: "nowrap" }}
                    onClick={() => {
                      navigate("/custom-itinerary");
                    }}
                  >
                    <AddIcon />
                    Create a new itinerary
                  </button>
                  <button
                    className="itinerary-plan-button-plain"
                    onClick={() => {
                      handleSaveItinerary("share");
                    }}
                  >
                    <CustomItineraryShare />
                    Share
                  </button>
                  <button
                    className="itinerary-plan-button-plain"
                    onClick={() => {
                      handleSaveItinerary("save");
                    }}
                  >
                    <CustomerItinerarySave />
                    Save
                  </button>
                </div>
                <div className="footer-right-div">
                  <button
                    className="itinerary-plan-button"
                    onClick={() => handleGetMeAQuote()}
                  >
                    Get me a quote
                  </button>
                </div>
              </div>
            </div>
          )}
      </div>
      {showConfirmation && (
        <ConfirmationComponent
          clearDataAfterConfirmation={clearDataAfterConfirmation}
        />
      )}

      {openValidationComponent && (
        <ValidationComponent
          closeModal={setOpenValidationComponent}
          validationMessage={validationMessage}
          showLoginButton={true}
          showCloseButton={false}
        />
      )}

      {openSaveValidationComponent && (
        <SaveShareValidationComponent
          closeModal={setOpenSaveValidationComponent}
          validationMessage={saveValidationMessage}
        />
      )}

      {openClearValidationComponent && (
        <ClearConfirmationComponent
          closeModal={setOpenClearValidationComponent}
          clearContents={clearDataAfterConfirmation}
        />
      )}

      {openAccessDeniedComponent && (
        <AccessConfirmationComponent
          closeModal={setOpenAccessDeniedComponent}
        />
      )}

      {openDurationValidationComponent && (
        <DurationConfirmationComponent
          closeModal={setOpenDurationValidationComponent}
          proceedAction={checkDurationChange}
          confirmationText={durationMessage}
        />
      )}
    </>
  );
});

export default CustomItineraryEdit;
