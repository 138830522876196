import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import _ from "underscore";
import moment from "moment";
import { Bars } from "react-loader-spinner";
import ReactGA from "react-ga4";
import { getAnalyticsEnabled } from "../../helpers/backend_helper";
import ReactPixel from "react-facebook-pixel";
import Auth from "./Auth";
import { ect, formatMoney } from "../../helpers/utils";
import regexConfig from "../../constants/regexData";

// import useRazorpay, { RazorpayOptions } from "../../helpers/razorpay";
import { auth } from "../../helpers/firebase";
// import razorpayConfig from "../../configs/razorpay";
// import ccavenueConfig from "../../configs/ccavenue";

import {
  getPackagePricing,
  clearPackagePricing,
  // clearCouponCodeDetails,
} from "../../store/details/actions";
import itineraryPlanStore from "./../../store/itineraryPlanStore";
import {
  getBookingDetails,
  // clearBookingDetails,
  uploadBookingDocument,
} from "../../store/booking/actions";

import "./Review.scss";

interface CustomizedState {
  packageData: any;
  startDate: string;
  endDate: string;
  noOfRooms: number;
  noOfAdults: number;
  noOfChilds: number;
  roomType: string;
  dayDiff: number;
  couponCode: string;
  vendorName: string;
  childData: any;
}

function Review() {
  const location = useLocation();
  const state = location.state as CustomizedState;
  const {
    packageData,
    startDate,
    endDate,
    noOfRooms,
    noOfAdults,
    noOfChilds,
    roomType,
    dayDiff,
    couponCode,
    vendorName,
    childData,
  } = state;

  const dispatch = useDispatch();
  // const Razorpay = useRazorpay();
  const navigate = useNavigate();

  const [user] = useAuthState(auth);

  const [primaryContactName, setPrimaryContactName] = useState("");
  // const [editPrimaryContactName, setEditPrimaryContactName] = useState(false);
  const [primaryContactPhone, setPrimaryContactPhone] = useState("");
  // const [editPrimaryContactPhone, setEditPrimaryContactPhone] = useState(false);
  const [primaryContactEmail, setPrimaryContactEmail] = useState("");
  // const [editPrimaryContactEmail, setEditPrimaryContactEmail] = useState(false);
  const [guestData, setGuestData] = useState<any>([]);
  const [fare, setFare] = useState<any>(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [showAuth, setShowAuth] = useState(false);
  // const [orderId, setOrderId] = useState("");
  const [userCouponDetails, setUserCouponDetails] = useState<any>(null);
  const [isPrimaryUserTraveller, setIsPrimaryUserTraveller] = useState(false);
  // const [paymentType] = useState("ccavanue"); // "ccavanue or razorPay"
  // const [processRazorPayPayment, setProcessRazorPayPayment] = useState(false);
  const [navData, setNavData] = useState<any>({ title: "", link: "" });

  const pricingErrorRef = useRef<HTMLDivElement>(null);
  const errorRef = useRef<HTMLParagraphElement>(null);
  const primaryContactNameRef = useRef<HTMLInputElement>(null);
  const primaryContactPhoneRef = useRef(null);
  const primaryContactEmailRef = useRef(null);

  const [isPartnerSite, setIsPartnerSite] = useState<boolean>(false);
  const [pointsEarnedFormat, setPointsEarnedFormat] = useState<string>("");
  const [partnerUserData, setPartnerUserData] = useState<any>(null);

  const { pricing, loader } = useSelector(
    (state: any) => state.PackageDetailsReducer
  );
  const { booking, bookingLoader } = useSelector(
    (state: any) => state.BookingDetailsReducer
  );

  const { bookingDocument } = useSelector(
    (state: any) => state.BookingDetailsReducer
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Review | Voyaah";
    const navigationData = localStorage.getItem("listNav");
    if (navigationData) {
      setNavData(JSON.parse(navigationData));
    }
    const partnerUser = sessionStorage.getItem("partnerUser");
    if (partnerUser) {
      setPartnerUserData(JSON.parse(partnerUser));
    }
    if (
      process.env.REACT_APP_PARTNER_SITE &&
      process.env.REACT_APP_PARTNER_SITE === "true"
    ) {
      setIsPartnerSite(true);
    }
    if (process.env.REACT_APP_POINT_EARNED_FORMAT) {
      setPointsEarnedFormat(process.env.REACT_APP_POINT_EARNED_FORMAT);
    }
    if (
      process.env.REACT_APP_PARTNER_SITE &&
      process.env.REACT_APP_PARTNER_SITE === "true" &&
      partnerUser
    ) {
      const pud = JSON.parse(partnerUser);
      setPrimaryContactName(pud?.name || "");
      setPrimaryContactPhone(pud?.phoneNumber || "");
      setPrimaryContactEmail(pud?.email || "");
    }

    ReactPixel.fbq("init", process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq("track", "PageView");
    ReactPixel.fbq("track", "InitiateCheckout");
  }, []);

  // Create booking - Calling booking API
  const createBooking = useCallback(() => {
    if (getAnalyticsEnabled()) {
      ReactGA.event({
        action: "create_booking",
        category: "review_page",
      });
    }

    const traveler = _.map(guestData, function (item: any, index: any) {
      return {
        name: item.name || "NA",
        age: 0,
        primary: index === 0 ? true : false,
      };
    });

    // const childAges = _.pluck(childData, "age") || [];
    const childAges = _.compact(
      _.map(childData, function (a) {
        return parseInt(a.age);
      })
    );
    const itineraryRoomType = _.pluck(
      packageData?.itinerary?.[0]?.roomTypes,
      "roomType"
    );
    const getMealrateClass = (propertyid:any,type:any)=>{
      let tempplan:any = ''
        itineraryPlanStore?.availabilityData?.propertyPricing?.forEach((element:any) => {
          if(element.propertyId === propertyid && type === 'mealPlan'){
            tempplan = element.availableMealPlan[0].mealPlan
          }else if(element.propertyId === propertyid && type === 'rateClass'){
            tempplan = element.availableMealPlan[0].rateClass
          }
        });
        return tempplan;

    }
    const getRoomdetails = ()=>{
      let templist:any = []
      let roomDistribution:any = ''
      console.log(itineraryPlanStore.availabilitypayload,'roomdetails')
      itineraryPlanStore.availabilitypayload.map((element:any,i:any)=>{
        roomDistribution = getroomDistribustion(element.propertyId,element.roomTypeId);
        
        templist.push({
          "propertyId": element.propertyId,
            "roomTypeId": element.roomTypeId,
            "numRooms": element.numRooms?element.numRooms:getroomcount(element.propertyId,element.roomId,'other'),
            "fromDate": element.fromDate,
            "toDate": element.toDate,
            "mealPlan":element.mealPlan?element.mealPlan:getMealrateClass(element.propertyId,'mealPlan'),
            "rateClass":element.rateClass?element.rateClass:getMealrateClass(element.propertyId,'rateClass'),
            roomDistribution
        })
      })
      return templist;
    }
    const getroomcount = (propertyid:any,roomid:any,type:any)=>{
      let temproomcount:any = ''
        itineraryPlanStore?.availabilityData?.propertyPricing?.forEach((element:any) => {

          if(element?.propertyId !== propertyid && type === 'other'){
            if(element?.room[0]?.roomTypeId){
              temproomcount = element?.room[0]?.numRooms
            }
          }else if(element?.propertyId === propertyid && type === 'selected'){
            if(element?.room[0]?.roomTypeId){
              temproomcount = element?.room[0]?.numRooms
            }
          }
        });
        return temproomcount;

    }
    const getroomDistribustion = (propertyid:any,roomid:any)=>{
      let roomDistribution:any = [];
      itineraryPlanStore?.availabilityData?.propertyPricing?.map((ele:any,i:any)=>{
        if(ele.propertyId === propertyid){
          ele.room.map((elem:any,index:any)=>{
            if(elem.roomTypeId === roomid){
              if(elem?.priceData?.typeWisePriceBreakDown[0]?.type === "BAU"){
                roomDistribution.push(elem.priceData.typeWisePriceBreakDown[0].roomDistribution);
              }
            }
          })
        }
      })
      return roomDistribution;

    }
    const selectedRoomType = _.findWhere(itineraryRoomType, {
      name: roomType,
    });
    console.log(packageData,'PackageDAtapricing13');
    // console.log(pricing,traveler,'inside pricing13')
    let roomTypeArray:any = [];
    let priceObj:any ={
      mrp: 0,
      totalFare: 0,
      bookedPrice: 0,
      gst: 1,
      discount: 0,
      tcs: 0,
      totalTax: 0,
      currencyCode: "INR"
    } 

    priceObj.mrp = itineraryPlanStore?.plannedPriceData?.totalFare
    priceObj.totalFare = itineraryPlanStore?.plannedPriceData?.totalFare
    priceObj.bookedPrice = itineraryPlanStore?.plannedPriceData?.totalFare
    
    console.log(itineraryPlanStore.availabilityData,itineraryPlanStore.availabilitypayload,'propertyResponseData2');

    itineraryPlanStore?.propertyResponseData?.forEach((element:any,i:any) => {
      console.log(i,element,element[i],'listsr');
      const roomDistribution = [];
      if(element?.priceData?.typeWisePriceBreakDown[0]?.type === "BAU"){
        roomDistribution.push(element.priceData.typeWisePriceBreakDown[0].roomDistribution);
      }

      roomTypeArray.push({
        "propertyId": element.propertyId,
            "roomTypeId": element.roomTypeId,
            "numRooms": element.numRooms,
            "fromDate": element.fromDate,
            "toDate": element.toDate,
            "mealPlan": element.mealPlan,
            roomDistribution
            
      })

    });

    const data = {
      packageId: packageData.id,
      packageName: packageData.name,
      packageType: packageData.type,
      customerEmail: primaryContactEmail,
      customerName: primaryContactName,
      customerPhone: primaryContactPhone,
      numAdults: parseInt(noOfAdults.toString()),
      numChildren: parseInt(noOfChilds.toString()),
      numRooms: parseInt(noOfRooms.toString()),
      childAges: childAges,
      fromDate: moment(startDate.replace(/-/g, "/")).format("YYYY-MM-DD"),
      toDate: moment(endDate.replace(/-/g, "/")).format("YYYY-MM-DD"),
      bookingStatus: "bookingStatus.paymentPending",
      transactionType: "Final",
      "roomType":  getRoomdetails(),
        "travellers": traveler,
        "priceTotal": priceObj
    };

    

    const localStorageData = localStorage.getItem("voyaahCustomerTracker");
    let customerProfileId = "";
    if (localStorageData) {
      customerProfileId = JSON.parse(localStorageData!).id;
    }
    console.log(data,'datad');

   dispatch(getBookingDetails(data, couponCode, customerProfileId));
  }, [
    guestData,
    childData,
    packageData,
    startDate,
    endDate,
    primaryContactEmail,
    primaryContactPhone,
    noOfRooms,
    noOfAdults,
    noOfChilds,
    pricing,
    dispatch,
  ]);

  // Fetching logged-in user data and setting to primary contact
  useEffect(() => {
    if (!showAuth) {
      if (isPartnerSite && partnerUserData) {
        setPrimaryContactName(partnerUserData?.name || "");
        setPrimaryContactPhone(partnerUserData?.phoneNumber || "");
        setPrimaryContactEmail(partnerUserData?.email || "");
      } else if (user) {
        setPrimaryContactName(user?.displayName || "");
        setPrimaryContactPhone(user?.phoneNumber || "");
        setPrimaryContactEmail(user?.email || "");
      }
    } else {
      setShowAuth(false);
      if (errorRef.current) {
        errorRef.current.classList.remove("show");
      }
      // createBooking();
    }
  }, [user]);

  // Creating guest-data from selected no. of Adults and Children
  useEffect(() => {
    const tagCodeList = _.pluck(packageData.tags, "code");
    const tagNameList = _.pluck(packageData.tags, "name");
    let isInternational = false;
    if (
      _.contains(tagCodeList, "international") ||
      _.contains(tagCodeList, "International") ||
      _.contains(tagNameList, "international") ||
      _.contains(tagNameList, "International")
    ) {
      isInternational = true;
    }
    const adultsData = _.range(noOfAdults).map((item: any) => {
      return {
        id: `adult_${item + 1}`,
        title: `Adult ${item + 1}`,
        placeholder: "John Doe",
        name: "",
        file: undefined,
        isRequired: item === 0 ? true : isInternational ? true : false,
        isFileSizeError: false,
      };
    });
    const childData = _.range(noOfChilds).map((item: any) => {
      return {
        id: `child_${item + 1}`,
        title: `Child ${item + 1}`,
        placeholder: "John Doe",
        name: "",
        file: undefined,
        isRequired: isInternational ? true : false,
        isFileSizeError: false,
      };
    });
    setGuestData(adultsData.concat(childData));
  }, [noOfAdults, noOfChilds]);

  // Update name in guest-data
  const updateGuestName = (guestId: any, newName: string) => {
    let itemIndex = guestData.findIndex((obj: any) => obj.id === guestId);
    let guestDataObj = [...guestData];
    guestDataObj[itemIndex].name = newName;
    setGuestData(guestDataObj);
  };

  // Calling pricing API
  useEffect(() => {
    const data = {
      packageData,
      startDate,
      endDate,
      noOfRooms,
      noOfAdults,
      noOfChilds,
      roomType,
      dayDiff,
      couponCode,
      vendorName,
      childData,
    };

    const localStorageData = localStorage.getItem("voyaahCustomerTracker");
    let customerProfileId = "";
    if (localStorageData) {
      customerProfileId = JSON.parse(localStorageData!).id;
    }

    // dispatch(getPackagePricing(data, customerProfileId, user?.uid ?? ""));
  }, [
    dayDiff,
    dispatch,
    endDate,
    noOfAdults,
    noOfChilds,
    noOfRooms,
    packageData,
    roomType,
    startDate,
    couponCode,
    vendorName,
    childData,
  ]);

  // Handling pricing API call response
  // if response have packagePrice then setFare
  // else if response have message then setErrorMessage
  useEffect(() => {
   
    if (
      pricing 
    ) {
      console.log(pricing.propertyPricing,'pricing1125');

      setFare(itineraryPlanStore?.plannedPriceData.totalFare);
      setErrorMessage("");
      pricingErrorRef.current?.classList.remove("show");
     
      if (couponCode && pricing.priceTotals && pricing.priceTotals.discount) {
        setUserCouponDetails(true);
      }
    }
    if (
      pricing &&
      pricing.responseStatus &&
      pricing.responseStatus !== "status.Success"
    ) {
      setErrorMessage(pricing.responseMessages);
      pricingErrorRef.current?.classList.add("show");
    }
  }, [pricing,itineraryPlanStore.plannedPriceData]);

  // Validate Data
  const validateData = () => {
    // createBooking();
    const fileSizeArr = _.map(guestData, function (a) {
      return a.file ? a.file.size : 0;
    });
    const totalFileSizeArr = fileSizeArr.reduce(
      (partialSum, a) => partialSum + a,
      0
    );

    const nameRegex = new RegExp(regexConfig.name);
    const emailRegex = new RegExp(regexConfig.email);
    const numberRegex = new RegExp(regexConfig.mobileNumber);

    const guestNameArr = _.map(guestData, function (a) {
      if (a.isRequired) {
        return a.name;
      } else {
        return "NA";
      }
    });

    switch (true) {
      case !primaryContactName:
        if (errorRef.current) {
          errorRef.current.classList.add("show");
          errorRef.current.innerHTML = "Enter primary contact name";
        }
        break;

      case !nameRegex.test(primaryContactName):
        if (errorRef.current) {
          errorRef.current.classList.add("show");
          errorRef.current.innerHTML = "Enter valid primary contact name";
        }
        break;

      case !primaryContactEmail:
        if (errorRef.current) {
          errorRef.current.classList.add("show");
          errorRef.current.innerHTML = "Enter primary contact email";
        }
        break;

      case !emailRegex.test(primaryContactEmail):
        if (errorRef.current) {
          errorRef.current.classList.add("show");
          errorRef.current.innerHTML = "Enter valid primary contact email";
        }
        break;

      case !primaryContactPhone:
        if (errorRef.current) {
          errorRef.current.classList.add("show");
          errorRef.current.innerHTML = "Enter primary contact phone";
        }
        break;

      case !numberRegex.test(primaryContactPhone):
        if (errorRef.current) {
          errorRef.current.classList.add("show");
          errorRef.current.innerHTML = "Enter valid primary contact phone";
        }
        break;

      case _.contains(guestNameArr, ""):
        if (errorRef.current) {
          errorRef.current.classList.add("show");
          errorRef.current.innerHTML = "Enter traveler name";
        }
        break;

      case totalFileSizeArr > 12 * 1000 * 1000:
        if (errorRef.current) {
          errorRef.current.classList.add("show");
          errorRef.current.innerHTML =
            "Document Size is more than 12MB. Please resize.";
        }
        break;

      default:
        if (user || isPartnerSite) {
          setShowAuth(false);
          if (errorRef.current) {
            errorRef.current.classList.remove("show");
          }
          createBooking();
        } else if (!user && !isPartnerSite) {
          if (errorRef.current) {
            errorRef.current.classList.add("show");
            errorRef.current.innerHTML = "Please register / login to continue";
          }
          setShowAuth(true);
        }
        break;
    }
  };

  useEffect(() => {
    if (
      bookingDocument?.length > 0 &&
      booking?.paymentTransaction?.[0]?.payments?.[0]?.paymentUrl
    ) {
      const url = booking?.paymentTransaction?.[0]?.payments?.[0]?.paymentUrl;
      window.open(url, "_self");
    }
  }, [bookingDocument]);

  // Handling booking API call response
  // if status is paymentPending then setOrderId
  // else if status is completedSuccess then navigate to success page
  useEffect(() => {
    if (booking && booking.bookingStatus === "bookingStatus.paymentPending") {
      // setOrderId(booking.bookingId);
      // upload document
      // travellerDocumentUpload(booking.bookingId)

      const filesData = _.compact(
        _.map(guestData, function (item: any) {
          return item.file
            ? { name: item.name || "NA", file: item.file }
            : null;
        })
      );

      if (filesData.length > 0) {
        dispatch(uploadBookingDocument(filesData, booking.bookingId));
      }

      if (
        booking?.paymentTransaction?.[0]?.payments?.[0]?.paymentUrl &&
        filesData.length === 0
      ) {
        const url = booking?.paymentTransaction?.[0]?.payments?.[0]?.paymentUrl;
        window.open(url, "_self");
      }
    }
 
  }, [booking, dispatch, navigate]);


  const navigateBack = () => {
    dispatch(clearPackagePricing());
    // dispatch(clearCouponCodeDetails());
    const data = {
      startDate,
      endDate,
      noOfRooms,
      noOfAdults,
      noOfChilds,
      couponCode,
      childData,
    };
    navigate(`/packages/${packageData.reference}`, { state: data });
  };

  const onSelectFile = (guestId: any, data: any) => {
    if (!data.target.files || data.target.files.length === 0) {
      return;
    }

    let itemIndex = guestData.findIndex((obj: any) => obj.id === guestId);
    let guestDataObj = [...guestData];
    if (data.target.files[0].size < 3 * 1000 * 1000) {
      guestDataObj[itemIndex].file = data.target.files[0];
      guestDataObj[itemIndex].isFileSizeError = false;
    } else {
      guestDataObj[itemIndex].isFileSizeError = true;
    }
    setGuestData(guestDataObj);
  };

  const onIsPrimaryUserTravellerChanged = () => {
    setIsPrimaryUserTraveller(!isPrimaryUserTraveller);
    if (!isPrimaryUserTraveller) {
      updateGuestName("adult_1", primaryContactName);
    } else {
      updateGuestName("adult_1", "");
    }
  };

  const travellerDocumentUpload = (bookingId: any) => {
    const filesData = _.compact(
      _.map(guestData, function (item: any) {
        return item.file ? { name: item.name || "NA", file: item.file } : null;
      })
    );

    if (filesData.length > 0) {
      dispatch(uploadBookingDocument(filesData, bookingId));
    }
  };

  const onRemoveFile = (guestId: any) => {
    let itemIndex = guestData.findIndex((obj: any) => obj.id === guestId);
    let guestDataObj = [...guestData];
    guestDataObj[itemIndex].file = undefined;
    setGuestData(guestDataObj);
  };
  const getCancellationStatus = (propertyId: any, roomId: any) => {
    let policy = "";
    console.log(itineraryPlanStore.policyLists,'policyliststore')
    itineraryPlanStore.policyLists.forEach(element => {
      if(propertyId === element.propertyId && roomId ===element.roomid){
        policy = element.text;
      }
    });
    return policy;
  };
  const getpropertyName = (propertyId: any, roomId: any)=>{
    let tempropertyname = '';
    itineraryPlanStore?.availabilityData?.propertyPricing.forEach((ele:any,index:any)=>{
        if(ele?.propertyId === propertyId){
          tempropertyname = ele?.propertyName;
        }
    })
    return tempropertyname;
     
  }
  const getroomName = (propertyId: any, roomId: any)=>{
    let tempRoomname = '';
    itineraryPlanStore?.availabilityData?.propertyPricing.forEach((ele:any) => {
        if(ele?.propertyId === propertyId){
          ele?.room?.forEach((element:any) => {
            if(element?.roomTypeId === roomId){
              tempRoomname = element?.roomTypeName
            }
          });
        }
    })
    return tempRoomname
     
  }

  const compareDates = (policyDate: any) => {
    let result = "";
    const myDate = policyDate?.split("T")[0];
    const q = new Date();
    const m = q.getMonth() + 1;
    const d = q.getDay();
    const y = q.getFullYear();
    const date = new Date(y, m, d);
    const mydate = new Date(myDate);

    if (date > mydate) {
      result = "Non-Refundable";
    } else {
      result = "Free Cancellation Upto " + myDate;
    }
    return result;
  };
  return (
    <>
      <div className="inner-page details-page">
        <div className="section">
          <div className="breadcrum">
            <ul>
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li>›</li>
              <li>
                <Link to={navData.link}>{navData.title}</Link>
              </li>
              <li>›</li>
              <li onClick={navigateBack}>
                <a>{packageData?.name}</a>
              </li>
              <li>›</li>
              <li>Confirm and Pay</li>
            </ul>
          </div>

          <div className="inner-heading">
            <h1 className="head">Confirm and Pay</h1>
          </div>
        </div>

        <div className="white-bg">
          <div className="section">
            <div className="confirm-blk">
              <h2 className="head">My Trip</h2>

              <div className="confirm-layout">
                <div className="left">
                  <div className="room-details">
                    <div className="room-image">
                      <div className="img">
                        <img
                          src={
                            packageData?.mediaCollections?.[0]?.images?.[0]
                              ?.imageUrl ||
                            require("../../assets/images/image23.png")
                          }
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="room-content">
                      <h2 className="sub-head">{packageData?.destination}</h2>
                      <h2 className="head">{packageData?.name}</h2>
                      {packageData?.type == "packageType.Staycation" ? (
                        <div style={{ fontSize: "17px" }}>{roomType}</div>
                      ) : (
                        <div></div>
                      )}

                      <ul className="room-date">
                        <li>
                          <span className="head">Date</span>
                          <span className="detail">
                            {`${moment(
                              new Date(startDate.replace(/-/g, "/"))
                            ).format("MMM DD, YYYY")} to ${moment(
                              new Date(endDate.replace(/-/g, "/"))
                            ).format("MMM DD, YYYY")}`}
                            <img
                              onClick={navigateBack}
                              src={require("../../assets/images/edit.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </span>
                        </li>
                        <li>
                          <span className="head">Guests</span>
                          <span className="detail">
                            {`${noOfAdults} ${
                              noOfAdults > 1 ? "Adults" : "Adult"
                            } ${
                              noOfChilds > 0
                                ? "& " +
                                  noOfChilds +
                                  (noOfChilds > 1 ? " Children" : " Child")
                                : ""
                            }`}
                            <img
                              onClick={navigateBack}
                              src={require("../../assets/images/edit.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
</div>


                <div className="right">
                  <h2 className="head">Price Details</h2>
                  {errorMessage && (
                    <div className="form-fields error">
                      <p className="error-msg" ref={pricingErrorRef}>
                        {errorMessage}
                      </p>
                    </div>
                  )}
                  {!errorMessage && (
                    <>
                      {pricing?.priceDetails?.map((item: any, index: any) => {
                        return (
                          <div
                            className="total-amount"
                            key={`review-price-details-${index}`}
                          >
                            <div className="left w70">
                              {item?.itemDescription}
                            </div>
                            <div className="right lato-font">{`₹ ${formatMoney(
                              parseFloat(item?.itemTotal).toString()
                            )}`}</div>
                          </div>
                        );
                      })}
                     
                      <div className="total-amount border-bottom">
                        <div className="left">{`Total - ${
                          noOfAdults
                            ? noOfAdults +
                              (noOfAdults?.toString() === "1"
                                ? " Adult"
                                : " Adults")
                            : ""
                        } ${
                          noOfChilds
                            ? "+ " +
                              noOfChilds +
                              (noOfChilds?.toString() === "1"
                                ? " Child"
                                : " Children")
                            : ""
                        } (${
                          dayDiff +
                          (dayDiff?.toString() === "1" ? " Night" : " Nights")
                        }, ${
                          noOfRooms +
                          (noOfRooms?.toString() === "1" ? " Room" : " Rooms")
                        })`}</div>
                        <div className="right lato-font">{`₹ ${formatMoney(
                          itineraryPlanStore?.plannedPriceData?.totalFare
                        )}`}</div>
                      </div>
                      {/* <div className="total-amount">
                        <div className="left bold">Total</div>
                        <div className="right extra-bold lato-font">{`₹ ${formatMoney(
                          fare
                        )}`}</div>
                      </div> */}
                      {couponCode && (
                        <div className="total-amount">
                          <div className="left">Discount Code</div>
                          <div className="right bold">{couponCode}</div>
                        </div>
                      )}
                      {userCouponDetails && (
                        <>
                          <div className="total-amount border-bottom">
                            <div className="left">Discount</div>
                            <div className="right lato-font">{`₹ ${formatMoney(
                              pricing?.priceTotals?.discount
                            )}`}</div>
                          </div>
                          <div className="total-amount pb-0">
                            <div className="left bold">Grand Total</div>
                            <div className="right extra-bold lato-font">{`₹ ${formatMoney(
                              itineraryPlanStore?.plannedPriceData?.totalFare
                            )}`}</div>
                          </div>
                        </>
                      )}
                      {!userCouponDetails && (
                        <>
                          <div className="total-amount pb-0">
                            <div className="left bold">Grand Total</div>
                            <div className="right extra-bold lato-font">{`₹ ${formatMoney(
                              itineraryPlanStore?.plannedPriceData?.totalFare
                            )}`}</div>
                          </div>
                        </>
                      )}
                      {isPartnerSite && (
                        <div
                          className="points-earned-detail color-black"
                          dangerouslySetInnerHTML={{
                            __html: pointsEarnedFormat.replace(
                              "{points}",
                              pricing?.pointsEarned
                            ),
                          }}
                        />
                      )}
                      {!showAuth && (
                        <div className="form-fields">
                          <div className="green-btn" onClick={validateData}>
                            Confirm and Pay
                          </div>
                        </div>
                      )}
                      <div className="form-fields error">
                        <p className="error-msg" ref={errorRef}></p>
                      </div>
                    </>
                  )}
                </div>

                {showAuth && (
                  <Auth
                    name={primaryContactName}
                    email={primaryContactEmail}
                    phone={primaryContactPhone}
                  />
                )}
              </div>
              <div className="confirm-layout">
              <h2 className="inner-head" style={{marginTop:'12px'}}>Stays</h2>    
<div className="bookingdetailsDiv">
  <table border={1}>
    <tbody>
      <tr>
        <th>Sl. No</th>
        <th>Accommodation</th>
        <th>Check in</th>
        <th>Check out</th>
        <th>Room Details</th>
        <th>Room #</th>
        <th>Cancellation</th>
        </tr>
          {itineraryPlanStore?.availabilitypayload?.map((e:any,i:any)=>{
            return (
        <tr key={i}>
          <td>{i+1}</td>
          <td>{getpropertyName(e?.propertyId,e?.roomTypeId)}</td>
          
            <td>{e?.fromDate?.split("T")[0]}</td>
            <td>{e?.toDate?.split("T")[0]}</td>
            <td>{getroomName(e?.propertyId,e?.roomTypeId)}</td>
            <td>{e?.numRooms}</td>
            <td>{getCancellationStatus(e?.propertyId,e?.roomTypeId)}</td>
            
          
        </tr>
            )
          })}
          
        </tbody>
  </table>
</div>
              </div>
              <div className="confirm-layout">
              <div className="left">
                  <div className="room-details">
                    <h2 className="inner-head" style={{marginTop:'12px'}}>Primary Contact</h2>
                    <div className="room-form-fields">
                      <div className="form">
                        <label>Name</label>
                        <input
                          ref={primaryContactNameRef}
                          type="text"
                          // disabled={!editPrimaryContactName}
                          placeholder="John Doe"
                          value={primaryContactName}
                          onChange={(e) =>
                            setPrimaryContactName(e.target.value)
                          }
                        />
                       
                      </div>
                      <div className="form">
                        <label>Phone</label>
                        <input
                          ref={primaryContactPhoneRef}
                          type="text"
                          // disabled={!editPrimaryContactPhone}
                          placeholder="9876543210"
                          value={primaryContactPhone}
                          onChange={(e) =>
                            setPrimaryContactPhone(e.target.value)
                          }
                        />
                        
                      </div>
                      <div className="form">
                        <label>Email</label>
                        <input
                          ref={primaryContactEmailRef}
                          type="text"
                          // disabled={!editPrimaryContactEmail}
                          placeholder="john.doe@gmail.com"
                          value={primaryContactEmail}
                          onChange={(e) =>
                            setPrimaryContactEmail(e.target.value)
                          }
                        />
                        {/* <img
                        src={`${
                          !editPrimaryContactEmail
                            ? require("../../assets/images/edit.png")
                            : require("../../assets/images/cancel.png")
                        }`}
                        alt=""
                        className="img-fluid"
                        onClick={() =>
                          setEditPrimaryContactEmail(!editPrimaryContactEmail)
                        }
                      /> */}
                      </div>
                    </div>
                  </div>

                  <div className="splitter"></div>

                  <h2 className="inner-head">
                    Please enter travellers details below
                  </h2>
                  {guestData.map((item: any, index: any) => {
                    return (
                      <>
                        <div
                          className="room-details"
                          key={`detail_${item.id}_${index}`}
                        >
                          <div className="room-form-fields">
                            <div className="form w100">
                              <label>
                                {`${item.title}`}
                                <span style={{ color: "#ff0000" }}>
                                  {item.isRequired ? "*" : ""}
                                </span>
                                {index === 0 && (
                                  <span className="checkbox">
                                    <input
                                      type="checkbox"
                                      name=""
                                      defaultChecked={isPrimaryUserTraveller}
                                      onChange={() =>
                                        onIsPrimaryUserTravellerChanged()
                                      }
                                    />{" "}
                                    Same as Primary contact
                                  </span>
                                )}
                              </label>
                              <input
                                className="w50"
                                type="text"
                                placeholder={item.placeholder}
                                value={item.name}
                                onChange={(e) =>
                                  updateGuestName(item.id, e.target.value)
                                }
                              />
                            </div>
                            {!item.file && (
                              <>
                                <div className="form w100 mt25">
                                  <label>Documents (optional)</label>
                                  <label
                                    htmlFor="fileUpload"
                                    className="submit"
                                  >
                                    Upload Document
                                  </label>
                                  <input
                                    id="fileUpload"
                                    type="file"
                                    accept=".png,.jpeg,.pdf"
                                    className="submit"
                                    name=""
                                    onChange={(e) => onSelectFile(item.id, e)}
                                  />
                                </div>
                                {item.isFileSizeError && (
                                  <div className="form w100">
                                    <p className="note red">
                                      Document Size is more than 3MB. Please
                                      resize.
                                    </p>
                                  </div>
                                )}
                                <div className="form w100">
                                  <p className="note">
                                    Please upload a copy of your passport & PAN
                                    card in PNG, jpeg or pdf format not larger
                                    than 3MB in size
                                  </p>
                                </div>
                              </>
                            )}
                            {item.file && (
                              <>
                                <div className="form w100 mt25">
                                  <label>Documents (optional)</label>
                                  <div>
                                    <img
                                      className="traveller-document img-fluid"
                                      src={URL.createObjectURL(item.file)}
                                      alt={item.file.name}
                                    />
                                    <img
                                      onClick={() => onRemoveFile(item.id)}
                                      src={require("../../assets/images/cancel.png")}
                                      alt=""
                                      className="traveller-document-close img-fluid"
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="splitter"></div>
                      </>
                    );
                  })}

                  <div className="room-details">
                    <p className="note">
                      <span>Note:</span> All travellers details are mandatory
                      for international travel
                    </p>
                  </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>

      {(loader || bookingLoader) && (
        <div className="loader-container">
          <div className="loader">
            <Bars color="#00BFFF" height={50} width={100} />
          </div>
        </div>
      )}
    </>
  );
}

export default Review;
