import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

const AccessConfirmationComponent = observer(
  ({ closeModal }: { closeModal: any }) => {
    const navigate = useNavigate();
    return (
      <div className="confirmation-overlay">
        <div className="confirmation-popup">
          <div className="confirmation-cancel"></div>
          <div className="confirmation-content">
            <div className="confirmation-content-text">
              <p className="content-text-first">
                You do not have access to edit this itinerary since you are not
                the owner
              </p>
            </div>
          </div>
          <div className="validation-btn-div">
            <button
              className="confirmation-okay-btn"
              style={{ color: "white" }}
              onClick={() => {
                navigate("/");
              }}
            >
              Proceed to home page
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export default AccessConfirmationComponent;
